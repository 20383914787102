import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Label
} from 'reactstrap';

import FullCalendar from '@fullcalendar/react';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimeLinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DatePicker from 'react-datepicker';
import Loader from 'react-loader-spinner';
import Header from '../../components/Headers/Header';
import './calendar.scss';
import { getZones } from '../../actions/Zones';
import { filterWashersForZone, filterWashersListWithSlot } from '../../actions/Washers';
import Tooltip from 'tooltip.js';
import InputError from '../../components/Elements/InputError';
import moment from 'moment';

class SlotCalendar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingZones: true,
      isLoadingWashers: false,
      isLoadingEvents: false,
      zoneId: '',
      zones: [],
      washers: []
    };

    this.eventLoaderToasts = [];
  }

  componentWillMount() {
    getZones()
      .then(response => {
        if (response.data.success) {
          if (response.data.success.data) {
            let responseData = response.data.success.data;
            this.setState({
              zones: responseData,
              isLoadingZones: false
            });
          }
        }
      })
      .catch(error => {
        this.setState({ isLoadingZones: false });
        console.log(error);
      });
  }
  componentWillUnmount() {
    this.clearEventLoaderToasts();
  }

  clearEventLoaderToasts = () => {
    for (let eventLoaderToast of this.eventLoaderToasts) {
      toast.dismiss(eventLoaderToast);
    }
    this.eventLoaderToasts = [];
  };

  filterWashers = () => {
    this.clearEventLoaderToasts();
    const { zoneId } = this.state;
    if (!zoneId) {
      return;
    }
    filterWashersForZone({ zoneId })
      .then(response => {
        if (response.data.success.data) {
          const washers = response.data.success.data.map(washer => ({
            ...washer,
            title: washer.name,
            id: washer._id
          }));
          this.setState({ washers, isLoadingWashers: false });
        } else {
          throw new Error(response.data);
        }
      })
      .catch(error => {
        this.setState({ isLoadingWashers: false });
        console.error(error);
      });
  };

  loadEvents = (info, successCallback, failureCallback) => {
    const { zoneId } = this.state;
    filterWashersListWithSlot({
      start: info.startStr,
      end: info.endStr,
      timeZone: info.timeZone,
      zoneId
    }).then((response) => {
      if (response.data.success) {
        if (response.data.success.data) {
          const { washers } = response.data.success.data;
          return successCallback(
            washers.reduce((events, { washerId, washerName, slots }) => [
              ...events,
              ...slots.map(({ _id: slotId, viewTime, start, end, assigned, booked, bookings }) => ({
                id: `${washerId}-${slotId}`,
                resourceId: washerId,
                title: viewTime,
                start,
                end,
                details: `${viewTime} ~ ${booked ? 'Booked'
                  : assigned ? 'Assigned (Not booked yet)'
                    : 'Not Assigned' } 
                      (${washerName})`,
                backgroundColor: booked ? '#6585FF'
                  : assigned ? '#bfbd25'
                    : '#9e7979'
              }))
            ], [])
          );
        }
      }
      throw new Error(response.data);
    }).catch(failureCallback)
  };

  onChangeZone = e => {
    const { value } = e.target;
    this.setState({
      zoneId: value,
      isLoadingWashers: !!value
    }, this.filterWashers);
  };
  onChangeLoadingEvents = (isLoading) => {
    console.log('load event', isLoading);
    if (isLoading) {
      this.eventLoaderToasts.push(toast('Loading events, please wait...', {
        type: toast.TYPE.INFO,
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        position: 'bottom-right',
        hideProgressBar: true,
        newestOnTop: false
      }));
    } else {
      const eventLoaderToast = this.eventLoaderToasts.shift();
      toast.update(eventLoaderToast, {
        render: "Loaded",
        type: toast.TYPE.SUCCESS,
        autoClose: 500,
        closeOnClick: true,
        closeButton: true
      });
    }
  };
  handleEventRender = (info) => {
    new Tooltip(info.el, {
      title: info.event.extendedProps.details,
      placement: 'top',
      trigger: 'hover',
      container: 'body'
    });
    /*
    info.el.setAttribute('data-toggle', 'tooltip');
    info.el.setAttribute('data-placement', 'top');
    info.el.setAttribute('title', info.event.extendedProps.details);*/
  };

  renderCalendar() {
    const { zoneId, washers, isLoadingWashers } = this.state;
    if (!zoneId) {
      return null;
    }
    if (isLoadingWashers) {
      return <Loader type="Circles" color="#5e72e4" height={30} width={30}/>;
    }
    return (
      <FullCalendar
        ref={this.calenderRef}
        defaultView="resourceTimelineDay"
        header={{
          left: 'prev,next today',
          center: 'title',
          right: 'resourceTimelineDay'
        }}
        aspectRatio={1.5}
        timeZone="Europe\/Istanbul"
        resourceLabelText="Technicians"
        plugins={[resourceTimeLinePlugin]}
        eventTextColor="#fff"
        resources={washers}
        loading={this.onChangeLoadingEvents}
        eventRender={this.handleEventRender}
        events={this.loadEvents}
      />
    );
  }

  renderZoneSelect = () => {
    const { zones, zoneId, isLoadingZones, isLoadingWashers } = this.state;
    if (isLoadingZones) {
      return <Loader type="Circles" color="#5e72e4" height={30} width={30}/>;
    }

    return (
      <InputGroup className="input-group-alternative">
        <Input
          className="input-shadow"
          type="select"
          name="zoneId"
          id="zoneId"
          onChange={this.onChangeZone}
          disabled={!!isLoadingWashers}
          value={zoneId}
        >
          <option value="">Select Region</option>
          {zones.map((z, k) => {
            return (
              <option value={z._id} key={k}>
                {z.name}
              </option>
            );
          })}
        </Input>
      </InputGroup>
    );
  };

  render() {
    return (
      <>
        <Header/>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={4} md={4} lg={3} xl={2}>
                      {this.renderZoneSelect()}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.renderCalendar()}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default SlotCalendar;
