import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import Loader from 'react-loader-spinner';
import {
  filterCampaigns,
  updateCampaign,
  deleteCampaign
} from '../../actions/Campaigns';
import Header from '../../components/Headers/Header';
import { campaignData } from '../../lib/Action/Campaign';
import Switch from 'react-ios-switch';
import { OTOPARLAK_LOGO } from '../../Constant/Constant';
import moment from 'moment';
import { isThisSecond } from 'date-fns/esm';

class Campaigns extends React.Component {
  limit = 100;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      campaigns: [],
      totalCampaigns: '',
      pages: 1,
      startIndex: 1,
      endIndex: 1,
      current_page: 1,
      keyword: '',
      sortBy: 'fname',
      dir: 'asc'
    };
  }

  componentWillMount() {
    this.getCampaigns();
  }

  filterQuery() {
    const { sortBy, keyword, dir, current_page, pages } = this.state;

    let query = {};

    if (sortBy !== '') {
      query.sortBy = sortBy;
    }

    if (keyword !== '') {
      query.keyword = keyword;
    }

    if (dir !== '') {
      query.dir = dir;
    }

    query.start = (current_page - 1) * this.limit;
    query.limit = this.limit;

    return query;
  }

  goToPage(page) {
    let startIndex = this.state.startIndex;
    let endIndex = this.state.endIndex;
    const pages = this.state.pages;
    const current_page = this.state.current_page;
    if (page > current_page) {
      endIndex = page <= 2 ? Math.min(5, pages) : Math.min(page + 2, pages);
      startIndex =
        page >= pages - 2
          ? Math.min(page - 2, pages - 5)
          : Math.max(startIndex, page - 2);
    } else if (page < current_page) {
      endIndex = page <= 2 ? Math.min(5, pages) : Math.min(endIndex, page + 2);
      startIndex =
        page >= pages - 2
          ? Math.min(pages - 5, page - 2)
          : Math.max(1, page - 2);
    }

    this.setState(
      { current_page: page, startIndex: startIndex, endIndex: endIndex },
      () => {
        this.getCampaigns(false);
      }
    );
  }

  getCampaigns(page = true) {
    this.setState({ isLoading: true });
    const query = this.filterQuery();
    filterCampaigns(query)
      .then(response => {
        const responseData = response.data.success.data;
        const campaigns = responseData;
        const totalCount = campaigns.length;
        const nextState = {
          campaigns,
          isLoading: false
        };
        if (page) {
          const pages = Math.ceil(totalCount / this.limit);
          const endIndex = Math.min(pages, 5);
          nextState.pages = pages;
          nextState.totalCampaigns = totalCount;
          nextState.endIndex = endIndex;
        }
        this.setState(nextState);
      })
      .catch(console.error);
  }

  sort(by, dir) {
    this.setState({ sortBy: by, dir }, () => {
      this.getCampaigns();
    });
  }

  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.getCampaigns();
    });
  };

  clearFilter() {
    this.setState({ keyword: '' }, () => {
      this.getCampaigns();
    });
  }

  goToEdit(campaign) {
    this.props.campaignData(campaign);
    this.props.history.push('/dashboard/campaign-edit');
  }

  deleteCampaign(campaign) {
    deleteCampaign(campaign)
      .then(res => {
        this.getCampaigns();
      })
      .catch(err => console.error(err));
  }
  /*
  goToDetails(campaign) {
    this.props.campaignData(campaign);
    this.props.history.push("/dashboard/campaign-details");
  }

  changeStatus(key,id,status){
    let  { campaigns } = this.state;
    campaigns[key].accountStatus = !status;

    this.setState({
      campaigns,
    });

    updateCampaign({
      _id:id,
      accountStatus:!status
    }).then(response => {
      // this.componentWillMount();
    }).catch(error=> {
      this.setState({err:true,message:'Server Error!'});
    });

  }

  */
  changeStatus(key, id, isSystemMsg) {
    let { campaigns } = this.state;
    campaigns[key].isSystemMsg = !isSystemMsg;

    this.setState({
      campaigns
    });

    updateCampaign({
      _id: id,
      isSystemMsg: !isSystemMsg
    })
      .then(response => {
        // this.componentWillMount();
      })
      .catch(error => {
        this.setState({ err: true, message: 'Server Error!' });
      });
  }

  CustomPagination = () => {
    const { startIndex, endIndex, current_page, pages } = this.state;
    return (
      <Pagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem disabled={current_page <= 1}>
          <PaginationLink
            onClick={this.goToPage.bind(this, 1)}
            first
            href="#"
          />
        </PaginationItem>
        {_.times(endIndex - startIndex + 1, i => {
          return (
            <PaginationItem
              key={startIndex + i}
              className={current_page === startIndex + i ? 'active' : ''}
            >
              <PaginationLink
                href="#"
                onClick={this.goToPage.bind(this, startIndex + i)}
              >
                {i + startIndex}
              </PaginationLink>
            </PaginationItem>
          );
        })}
        <PaginationItem disabled={current_page >= pages}>
          <PaginationLink
            onClick={this.goToPage.bind(this, pages)}
            last
            href="#"
          />
        </PaginationItem>
      </Pagination>
    );
  };

  render() {
    const {
      campaigns,
      isLoading,
      totalCampaigns,
      pages,
      current_page,
      keyword,
      dir,
      sortBy
    } = this.state;

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={4}>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="form-control-alternative"
                          value={keyword}
                          id="input-keyword"
                          placeholder="Search"
                          type="text"
                          name="keyword"
                          onChange={this.onChange.bind(this)}
                        />
                        {keyword && (
                          <InputGroupAddon addonType="append">
                            <a onClick={this.clearFilter.bind(this)}>
                              <InputGroupText style={{ height: '100%' }}>
                                <i className="fas fa-times-circle"></i>
                              </InputGroupText>
                            </a>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </Col>
                    <Col sm={8}>
                      <Link to="/dashboard/campaign-add">
                        <Button
                          className="pull-right"
                          color="primary"
                          style={{ float: 'right' }}
                          size="sm"
                        >
                          Add Campaign
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr className="row m-0">
                      {/* <th className="col">S. No.</th> */}
                      <th className="col">Title</th>
                      <th className="col">Image</th>
                      <th className="col">Show Mode</th>
                      <th className="col">User Type</th>
                      <th className="col">Is System Message</th>
                      <th className="col">Expiry Date</th>
                      <th className="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center' }}>
                          <Loader
                            type="Circles"
                            color="#5e72e4"
                            height={40}
                            width={40}
                          />
                        </td>
                      </tr>
                    )}

                    {!isLoading && campaigns == null && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: 'center' }}>
                          No data available
                        </td>
                      </tr>
                    )}

                    {!isLoading &&
                      totalCampaigns > 0 &&
                      campaigns.map((campaign, key) => {
                        return (
                          <tr className="row m-0" key={key}>
                            {/* <th className="col">{key + 1}</th> */}
                            <td className="col">{campaign.title}</td>
                            <td className="col">
                              {
                                <img
                                  className="icon"
                                  alt={`${campaign.title}`}
                                  title={`${campaign.title}`}
                                  src={campaign.imageUrl}
                                />
                              }
                            </td>
                            <td className="col">{campaign.showMode}</td>
                            <td className="col">{campaign.userType}</td>
                            <td className="col">
                              <Switch
                                checked={campaign.isSystemMsg}
                                className="switch"
                                onChange={() =>
                                  this.changeStatus(
                                    key,
                                    campaign._id,
                                    campaign.isSystemMsg
                                  )
                                }
                              />
                            </td>
                            <td className="col">
                              {moment(campaign.expiryDate).toNow()}
                            </td>
                            <td className="col">
                              <i
                                style={{ marginRight: 15 }}
                                onClick={() => this.goToEdit(campaign)}
                                className="fa fa-edit btn p-1"
                              ></i>
                              <i
                                style={{ marginRight: 15 }}
                                onClick={() => this.deleteCampaign(campaign)}
                                className="fa fa-trash-alt btn p-1"
                                style={{ color: '#f00' }}
                              ></i>
                              {/*<i onClick={() => this.goToDetails(campaign)} className="fa fa-file-invoice btn p-1"></i>*/}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <this.CustomPagination />
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

// export default Campaigns;
const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  campaignData: campaign => dispatch(campaignData(campaign))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Campaigns);
