const Rules = {
    login:[
        {
            field: 'email', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Email is required.' 
        },
        { 
            field: 'email',
            method: 'isEmail', 
            validWhen: true, 
            message: 'That is not a valid email.'
        },
        { 
            field: 'password', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Password is required.'
        }
    ],
    forgotpassword:[
        { 
          field: 'email', 
          method: 'isEmpty', 
          validWhen: false, 
          message: 'Email is required.' 
        },
        { 
          field: 'email',
          method: 'isEmail', 
          validWhen: true, 
          message: 'That is not a valid email.'
        }
    ],
    addPromotion:[
        {
            field: 'price',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Price is required.'  
        },
        {
            field: 'benefits',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Benefits is required.'  
        },
        {
            field: 'generated_code',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Code is required.' 
        },
        {
            field: 'pcode',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Promotion Code is required.' 
        }
    ],
    addQuestion:[
        {
            field: 'title',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Title is required.'  
        },
        {
            field: 'explanation',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Explanation is required.'  
        },
    ],
    addAdmin:[
        { 
            field: 'email', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Email is required.' 
        },
        { 
            field: 'email',
            method: 'isEmail', 
            validWhen: true, 
            message: 'That is not a valid email.'
        },
        { 
            field: 'confirm_password', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Confirm Password is required.'
        }, 
        { 
            field: 'password', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Password is required.'
        }, 
        { 
            field: 'fname', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Firstname is required.' 
        }, 
        { 
            field: 'lname', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Lastname is required.' 
        },
        { 
            field: 'phone', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Phone is required.' 
        },
        { 
            field: 'countryName', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Country Name is required.' 
        },
        { 
            field: 'cityName', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'City Name is required.' 
        }
    ],
    editAdmin:[
        { 
            field: 'fname', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Firstname is required.' 
        }, 
        { 
            field: 'lname', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Lastname is required.' 
        },
    ],
    addCarMake:[
        {
            field: 'vehicleId',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Vehicle Id is required.'  
        },
        {
            field: 'make',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Make is required.'  
        },
    ],
    addCarModel:[
        {
            field: 'makeId',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Make Id is required.'  
        },
        {
            field: 'carModel',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Car Model is required.'  
        },
        {
            field: 'modelYears',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Model Years is required.'  
        },
        {
            field: 'modelColors',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Model Colors is required.'  
        },
    ],
    addCity:[
        {
            field: 'cityName',
            method: 'isEmpty',
            validWhen: false, 
            message: 'City Name is required.'  
        },
        {
            field: 'cityCoordinates',
            method: 'isEmpty',
            validWhen: false, 
            message: 'City Coordinates is required.'  
        },
    ],
    addZone:[
        {
            field: 'name',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Name is required.'  
        },
        {
            field: 'code',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Code is required.'  
        },
        {
            field: 'cityId',
            method: 'isEmpty',
            validWhen: false, 
            message: 'City Name is required.'
        },
        {
            field: 'zoneCoordinates',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Zone Coordinates is required.'  
        },
    ],
    editProfile:[
        {
            field: 'fname',
            method: 'isEmpty',
            validWhen: false, 
            message: 'First Name is required.'  
        },
        {
            field: 'lname',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Last Name is required.'  
        },
        {
            field: 'loginPhoneNo',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Phone No is required.'  
        },
        {
            field: 'cityName',
            method: 'isEmpty',
            validWhen: false, 
            message: 'City Name is required.'  
        },
        {
            field: 'countryName',
            method: 'isEmpty',
            validWhen: false, 
            message: 'Country Name is required.'  
        },
    ],
    addService:[
        { 
            field: 'name', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Name is required.' 
        },
        // { 
        //     field: 'serviceType', 
        //     method: 'isEmpty', 
        //     validWhen: false, 
        //     message: 'Service Type is required.'
        // }, 
        { 
            field: 'listOrder', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'List Order is required.'
        }, 
        { 
            field: 'duration', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Duration is required.' 
        }, 
        { 
            field: 'description', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Description is required.' 
        },
        // { 
        //     field: 'longDescription', 
        //     method: 'isEmpty', 
        //     validWhen: false, 
        //     message: 'Long Description is required.' 
        // },
        { 
            field: 'price', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Price is required.' 
        },
        { 
            field: 'timeDescription', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Time Description is required.' 
        },
        { 
            field: 'slotDuration', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Slot Duration is required.' 
        },
      /*
        {
            field: 'logo',
            method: 'isDataURI',
            validWhen: true,
            message: 'Logo is required'
        },
       */
        {
            field: 'logoReady',
            method: 'equals',
            args: ['true'],
            validWhen: true,
            message: 'Logo is required'
        }
    ],
    addPromocodeBase: [
        {
            field: 'title',
            method: 'isEmpty',
            validWhen: false,
            message: 'Title is required.'
        },
        {
            field: 'rideType',
            method: 'isEmpty',
            validWhen: false,
            message: 'Ride Type is required.'
        },
        {
            field: 'amount',
            method: 'isNumeric',
            validWhen: true,
            message: 'Amount is required and must be numeric.'
        },
        {
            field: 'description',
            method: 'isEmpty',
            validWhen: false,
            message: 'Description is required.'
        },
        {
            field: 'cityId',
            method: 'isEmpty',
            validWhen: false,
            message: 'City is required.'
        },
        {
            field: 'usageLimit',
            method: 'isInt',
            validWhen: true,
            message: 'Usage Limit is required and must be an integer.'
        },
        {
            field: 'logoReady',
            method: 'equals',
            args: ['true'],
            validWhen: true,
            message: 'Logo is required'
        },
        {
            field: 'expiryDate',
            method: 'isEmpty',
            validWhen: false,
            message: 'Expiry Date is required.'
        }
    ],
    addPromocodeManual:[
        {
            field: 'code',
            method: 'isEmpty',
            validWhen: false,
            message: 'Code is required.'
        },
    ],
    addPromocodeAuto: [
        {
            field: "codePrefix",
            method: "isEmpty",
            validWhen: false,
            message: "Code Prefix is required."
        },
        {
            field: "lengthOfCoupon",
            method: "isInt",
            validWhen: true,
            message: "Length of Coupon is required and must be an integer"
        },
        {
            field: "numberOfCoupons",
            method: "isInt",
            validWhen: true,
            message: "Number of Coupons is required and must be an integer"
        }
    ],
    editPromocode:[
        { 
            field: 'title', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Title is required.' 
        },
        { 
            field: 'rideType', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Ride Type is required.' 
        },
        { 
            field: 'amount', 
            method: 'isNumeric',
            validWhen: true,
            message: 'Amount is required and must be a number.'
        },
        { 
            field: 'code', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Code is required.' 
        },
        { 
            field: 'description', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Description is required.' 
        },
        { 
            field: 'cityId', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'City is required.' 
        },
        { 
            field: 'usageLimit', 
            method: 'isInt',
            validWhen: true,
            message: 'Usage Limit is required and must be an integer.'
        },
        {
            field: 'logoReady',
            method: 'equals',
            args: ['true'],
            validWhen: true,
            message: 'Logo is required'
        },
        {
            field: 'expiryDate',
            method: 'isEmpty',
            validWhen: false,
            message: 'Expiry Date is required.'
        }
    ],
    addAdditionalService:[
        { 
            field: 'name', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Name is required.' 
        },
        { 
            field: 'description', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Description is required.' 
        },
        { 
            field: 'price', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Price is required.' 
        },
        { 
            field: 'timeDescription', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Time Description is required.' 
        },
        { 
            field: 'longDescription', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Long Description is required.' 
        },
        {
            field: 'logoReady',
            method: 'equals',
            args: ['true'],
            validWhen: true,
            message: 'Logo is required'
        }
    ],
    addWasher:[
        { 
            field: 'email', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Email is required.' 
        },
        { 
            field: 'email',
            method: 'isEmail', 
            validWhen: true, 
            message: 'That is not a valid email.'
        },
        { 
            field: 'confirm_password', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Confirm Password is required.'
        }, 
        { 
            field: 'password', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Password is required.'
        }, 
        { 
            field: 'fname', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Firstname is required.' 
        }, 
        { 
            field: 'lname', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Lastname is required.' 
        },
        { 
            field: 'phone', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Phone is required.' 
        },
        { 
            field: 'phone',
            method: 'isNumeric', 
            validWhen: true, 
            message: 'That is not a valid Number.'
        },
        { 
            field: 'countryName', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Country Name is required.' 
        },
        { 
            field: 'cityId', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'City Name is required.' 
        }
    ],
    addClient: [
        {
            field: 'name',
            method: 'isEmpty',
            validWhen: false,
            message: 'Name is required.'
        },
        {
            field: 'code',
            method: 'isEmpty',
            validWhen: false,
            message: 'Code is required.'
        },
        {
            field: 'phoneNumber',
            method: 'isEmpty',
            validWhen: false,
            message: 'Phone Number is required.'
        },
        {
            field: 'discount',
            method: 'isEmpty',
            validWhen: false,
            message: 'Discount is required.'
        },
        {
            field: 'discType',
            method: 'isEmpty',
            validWhen: false,
            message: 'Discount type is required.'
        }

    ],
    addCustomer:[
        { 
            field: 'email', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Email is required.' 
        },
        { 
            field: 'email',
            method: 'isEmail', 
            validWhen: true, 
            message: 'That is not a valid email.'
        },
        { 
            field: 'fname', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Firstname is required.' 
        }, 
        { 
            field: 'lname', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Lastname is required.' 
        },
        { 
            field: 'phone', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Phone is required.' 
        },
        { 
            field: 'phone',
            method: 'isNumeric', 
            validWhen: true, 
            message: 'That is not a valid Number.'
        },
        { 
            field: 'countryName', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Country Name is required.' 
        },
        { 
            field: 'cityId', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'City Name is required.' 
        }
    ],
    editAddress: [
        {
            field: 'editAddressName',
            method: 'isEmpty',
            validWhen: false,
            message: 'Name is required.'
        },
        {
            field: 'editAddressDetail',
            method: 'isEmpty',
            validWhen: false,
            message: 'Details is required.'
        },
        {
            field: 'editAddressLongitude',
            method: 'isNumeric',
            validWhen: true,
            message: 'Longitude is required and must be numeric.'
        },
        {
            field: 'editAddressLatitude',
            method: 'isNumeric',
            validWhen: true,
            message: 'Latitude is required and must be numeric.'
        },
    ],
    addSolution:[
        { 
            field: 'name', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Name is required.' 
        },
        { 
            field: 'description', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Description is required.' 
        },
        { 
            field: 'stock', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Stock is required.' 
        },
        { 
            field: 'type', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Type is required.' 
        },
        // { 
        //     field: 'Expiry Date', 
        //     method: 'isEmpty', 
        //     validWhen: false, 
        //     message: 'expiryDate is required.' 
        // },
        { 
            field: 'defaultUsage', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Default Usage is required.' 
        },
        /*{
            field: 'logoReady',
            method: 'equals',
            args: ['true'],
            validWhen: true,
            message: 'Logo is required'
        },*/
    ],
    editWasherCoorporate:[
        { 
            field: 'identityNumber', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Identity Number is required.' 
        },
        { 
            field: 'legalEmail', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Legal Email is required.' 
        },
        { 
            field: 'subMerchantExternalId', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'SubMerchant ExternalId is required.' 
        },
        { 
            field: 'taxOffice', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Tax Office is required.' 
        },
        { 
            field: 'contactName', 
            method: 'isEmpty', 
            validWhen: false, 
            message: 'Company Name is required.' 
        },
    ],
    pushNotification:[
        {
            field: 'title',
            method: 'isEmpty',
            validWhen: false,
            message: 'Title is required.'
        },
        {
            field: 'body',
            method: 'isEmpty',
            validWhen: false,
            message: 'Body is required.'
        },
        {
            field: 'segment',
            method: 'isEmpty',
            validWhen: false,
            message: 'Segment is required.'
        },
        {
            field: 'os',
            method: 'isEmpty',
            validWhen: false,
            message: 'Operating System is required.'
        },
    ],
    addCampaign: [
        {
            field: 'title',
            method: 'isEmpty',
            validWhen: false,
            message: 'Title is required.'
        },
        {
            field: 'description',
            method: 'isEmpty',
            validWhen: false,
            message: 'Description is required.'
        },
        {
            field: 'imageReady',
            method: 'equals',
            args: ['true'],
            validWhen: true,
            message: 'Image is required'
        },
        {
            field: 'showMode',
            method: 'isEmpty',
            validWhen: false,
            message: 'Show Mode is required'
        },
        {
            field: 'userType',
            method: 'isEmpty',
            validWhen: false,
            message: 'User Type is required'
        },
      /*
        {
            field: 'showMode',
            method: 'isIn',
            args: [['android', 'ios']],
            validWhen: true,
            message: 'Show Mode is required'
        },
        {
            field: 'userType',
            method: 'isIn',
            args: [['customer', 'washer']],
            validWhen: true,
            message: 'User Type is required'
        },
       */
        {
            field: 'expiryDate',
            method: 'isEmpty',
            validWhen: false,
            message: 'Expiry Date is required.'
        }
    ]
};

export default Rules;
