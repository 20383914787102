import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import _ from 'lodash';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardFooter, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import Switch from 'react-ios-switch';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
import { filterWashers, updateWasher, getWashersReport, activateWasher } from '../../actions/Washers';
import { setLocalValue  } from "../../lib/Helper";
import { washerData } from "../../lib/Action/Washer";
import moment from 'moment'
import DatePicker from "react-datepicker";


class Washers extends React.Component {
    limit = 10;
    constructor(props) {
        super(props);
        this.state = { 
            isLoading:true,
            washers: [],
            totalUsers: '',
            pages:1,
            current_page:1,
            keyword:'',
            sortBy:'fname',
            dir:1,
            type: '',
            submerchantType: ''
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});
        this.selectStartDateChange(new Date(moment().subtract(1, "months")));
        this.selectEndDateChange(new Date());
        filterWashers(this.filterQuery()).then( response => {
            if(response.data.success){
                if(response.data.success.data.users){
                    let responseData = response.data.success.data.users;
                    let totalUsers = responseData.totalUsers;
                    let pages  = Math.ceil(totalUsers/this.limit );
                    this.setState({
                        washers:responseData.users,
                        isLoading:false,
                        totalUsers: responseData.totalUsers,
                        pages,
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    filterQuery(){
        const  {sortBy, keyword, dir, current_page, pages, type, submerchantType }  = this.state;
        
        let query = {}
    
        if(sortBy !== ''){
          query.sortBy = sortBy;
        }
        
        if(keyword !== ''){
          query.keyword = keyword;
        }
        
        if(dir !== ''){
          query.dir = dir;
        }

        if(type !== ''){
            query.type = type;
        }

        if(submerchantType !== ''){
            query.submerchantType = submerchantType;
        }
    
        query.start = (current_page - 1)*this.limit;
        query.limit = this.limit;
    
        return query;
    }

    goToPage(page){
        this.setState({current_page:page}, () =>{
          this.getWashers(false);
        });
    }

    getWashers(page = true){
        this.setState({isLoading:true});
        const query = this.filterQuery();
        filterWashers(query).then( response => {
            let totalusers = response.data.success.data.users.totalUsers;
            let pages  = Math.ceil(totalusers/50 );
            console.log(response);
            if(page){
                this.setState({
                    washers:response.data.success.data.users.users,
                    pages,
                    isLoading:false,
                    totalUsers: response.data.success.data.users.totalUsers,
                });
            }else{
                this.setState({
                    washers:response.data.success.data.users.users,
                    isLoading:false,
                    totalUsers: response.data.success.data.users.totalUsers,
                });
            }
        }).catch(error=> {
            console.log(error)
        });
    }

    sort(by,dir){
        this.setState({sortBy:by,dir}, () =>{
          this.getWashers();
        });
    }

    clearFilter(){
        this.setState({keyword:''},() => {
          this.getWashers();
        });
    }

    changeStatus(key,id,status){
        const washers = [...(this.state.washers || [])];
        washers[key] = {
            ...washers[key],
            accountStatusChanging: true
        };
    
        this.setState({
            washers,
        }, () => {
            activateWasher({
              _id:id,
              accountStatus:!status
            }).then(response => {
                const nextWashers = [...(this.state.washers || [])];
                nextWashers[key] = {
                    ...nextWashers[key],
                    accountStatus: response.data.success ? !status : status,
                    accountStatusChanging: false
                };
                this.setState({
                    washers: nextWashers
                });
            }).catch(error=> {
              this.setState({err:true,message:'Server Error!'});
            });
        });
    }

    goToAssign(washer){
        console.log(washer);
        this.props.washerData(washer);
        this.props.history.push("/dashboard/washer-assign");
    }

    goToEdit(washer){
        washer['fromType'] = 'washer';
        this.props.washerData(washer);
        this.props.history.push("/dashboard/washer-edit");
    }

    goToDocuments(washer) {
        this.props.washerData(washer);
        this.props.history.push("/dashboard/washer-documents")
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value},() => {
            this.getWashers();
        });
    }

    toggleReportModal = () => {
        const { reportUrl, reportModal } = this.state;
        this.setState({ reportModal: !reportModal });
        // if (!reportUrl && !reportModal) {
        //     getWashersReport().then(res => {
        //         if (res.data.success.data) {
        //             this.setState({
        //                 reportUrl: res.data.success.data
        //             });
        //         }
        //     }).catch(console.error);
        // }
    };

    selectStartDateChange = date => {
        if (!date) {
            return;
        }
        console.log("start Date : ", date);
        this.setState(
            {
                selectStartDate: date
            },
            () => {
                const selectedDate = this.state.selectStartDate;
                var month = moment(selectedDate).format("YYYY-MM-DD");
                var day = moment(selectedDate).format("DD");
                console.log("month ", month);
                var year = selectedDate.getFullYear();
                this.setState({
                    expiryDateStart: moment(selectedDate).format("YYYY-MM-DD")
                });
            }
        );
    };
    selectEndDateChange = date => {
        if (!date) {
            return;
        }
        console.log("end Date: ", date);
        this.setState(
            {
                selectEndDate: date
            },
            () => {
                const selectedDate = this.state.selectEndDate;
                var month = moment(selectedDate).format("mm");
                var day = moment(selectedDate).format("DD");

                var year = selectedDate.getFullYear();
                this.setState({
                    expiryDateEnd: moment(selectedDate).format("YYYY-MM-DD")
                });
                console.log(this.state.expiryDateEnd);
            }
        );
    };

    downloadReport = () => {
        const { expiryDateStart, expiryDateEnd } = this.state;
        this.setState({ reportLoading: true });
        if (!expiryDateStart && !expiryDateEnd) return;
        const date = `?start=${expiryDateStart}&&end=${expiryDateEnd}`;
        getWashersReport({ date: date })
            .then(res => {
                if (res.data.success.data) {
                    this.setState({
                        reportUrl: res.data.success.data,
                        reportLoading: false
                    });
                }
                window.open(res.data.success.data); //to open new page
            })
            .catch(err => {
                this.setState({ reportLoading: false });
                console.log(err);
            });
    };
    renderReport = () => {
        const { reportModal, reportUrl, reportLoading } = this.state;
        return (
            <>
                <Button
                    className="pull-right ml-2 mr-0"
                    color="warning"
                    style={{ float: "right" }}
                    size="sm"
                    onClick={this.toggleReportModal}
                >
                    <i className="fa fa-file-download" /> Download Report
        </Button>
                <Modal
                    size={"sz"}
                    isOpen={reportModal}
                    toggle={this.toggleReportModal}
                    className="text-center"
                >
                    <ModalHeader toggle={this.toggleReportModal}>
                        Download Report for Bookings
          </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                Start Date
                <DatePicker
                                    selected={this.state.selectStartDate}
                                    onChange={this.selectStartDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    popperPlacement="bottom"
                                    style={{ textAlign: "center" }}
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                            </Col>
                            <Col>
                                End Date
                <DatePicker
                                    selected={this.state.selectEndDate}
                                    onChange={this.selectEndDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    popperPlacement="bottom"
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-center">
                                {!reportLoading && (
                                    <Button
                                        href={reportUrl}
                                        color="primary"
                                        onClick={this.downloadReport}
                                    >
                                        <i className="fa fa-file-download" /> Download Report
                  </Button>
                                )}
                                {reportLoading && (
                                    <Loader
                                        type="Circles"
                                        color="#5e72e4"
                                        height={30}
                                        width={30}
                                    />
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        );
    };

    render(){

        const {washers, isLoading, totalUsers, pages, current_page, keyword, dir, sortBy, type, submerchantType} = this.state;
        console.log(totalUsers);
        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={3}>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    value={keyword}
                                                    id="input-keyword"
                                                    placeholder="Search"
                                                    type="text"
                                                    name="keyword"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                { 
                                                    keyword &&  
                                                    <InputGroupAddon addonType="append">
                                                    <a onClick={this.clearFilter.bind(this)}>
                                                        <InputGroupText  style={{height:'100%'}}><i className='fas fa-times-circle'></i></InputGroupText>
                                                    </a>
                                                    </InputGroupAddon>
                                                }
                                            </InputGroup>
                                        </Col>
                                        <Col sm={3}>
                                            <InputGroup className="input-group-alternative">
                                                <Input type="select" name="type" id="type" onChange={this.onChange.bind(this)}>
                                                    <option value="all">All</option>
                                                    <option value="submerchants">Submerchants</option>
                                                    <option value="washers">Washers</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={3}>
                                            {type === 'submerchants' &&
                                                <InputGroup className="input-group-alternative">
                                                    <Input type="select" name="submerchantType" id="submerchantType" onChange={this.onChange.bind(this)}>
                                                        <option value="">Filter By Submerchant Type</option>
                                                        <option selected={submerchantType == 'personal'} value="personal">Personal</option>
                                                        <option selected={submerchantType == 'private'} value="private">Private</option>
                                                        <option selected={submerchantType == 'limited'} value="limited">Limited</option>
                                                    </Input>
                                                </InputGroup>
                                            }
                                        </Col>
                                        <Col sm={3}>
                                            { this.renderReport() }
                                            <Link to="/dashboard/washer-add"><Button className="pull-right" color="primary" style={{float:'right'}} size="sm">Add Technician</Button></Link>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr className="row m-0">
                                            <th className="col">First Name</th>
                                            <th className="col">Last Name</th>
                                            <th className="col">Email</th>
                                            <th className="col">Phone No.</th>
                                            <th className="col">Status</th>
                                            <th className="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        {
                                            !isLoading && totalUsers == 0 && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                            </tr>
                                        }

                                        {
                                            !isLoading && totalUsers > 0 &&
                                            washers.map((washer, key) => {
                                                return(
                                                    <tr className="row m-0" key={key}>
                                                        <th className="col">{washer.fname}</th>
                                                        <td className="col">{washer.lname}</td>
                                                        <td className="col">{washer.email}</td>
                                                        <td className="col">{washer.loginPhoneNo}</td>
                                                        <td className="col">
                                                            <Switch
                                                                checked={washer.accountStatus}
                                                                disabled={washer.accountStatusChanging}
                                                                className="switch"
                                                                onChange={() => this.changeStatus(key,washer._id,washer.accountStatus)}
                                                            />
                                                        </td>
                                                        <td className="col">
                                                            <i style={{marginRight: 15}} onClick={() => this.goToEdit(washer)} className="fa fa-edit btn p-1"></i>
                                                            <i style={{marginRight: 15}} onClick={() => this.goToAssign(washer)} title="Assign" className="fa fa-bars btn p-1"></i>
                                                            <i onClick={() => this.goToDocuments(washer)} title="Documents" className="fa fa-file-invoice btn p-1"></i>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </Table>
                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            { 
                                                pages >1 &&
                                                _.times(pages, i => {
                                                    return (
                                                        <PaginationItem key={i} className={current_page === i+1 ?'active':''}>
                                                            <PaginationLink
                                                                href="#"
                                                                onClick={this.goToPage.bind(this,i+1)}
                                                            >
                                                                {i+1}
                                                            </PaginationLink>
                                                        </PaginationItem>
                                                    );
                                                })
                                            }
                                        </Pagination>
                                    </nav>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    washerData: (washer) => dispatch(washerData(washer))
})

export default connect(mapStateToProps, mapDispatchToProps)(Washers);
