/*global google*/
import React from "react";
import { toast } from "react-toastify";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap
} from 'react-google-maps';
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import { compose, withProps } from "recompose";

const HeatMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyANl6n1ocSKfkecsPu4BqWvIv_BLb4HmmQ&v=3.exp&libraries=geometry,drawing,places,visualization",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const { positions, options } = props;
  return (
    <GoogleMap
      defaultZoom={11}
      defaultCenter={{
        lng: 28.94966,
        lat: 41.01384
      }}
    >
      <HeatmapLayer
        data={positions.map(({ lat, lng }) => new google.maps.LatLng(lat, lng))}
        options={options}
      />
    </GoogleMap>
  );
});

export default HeatMap;
