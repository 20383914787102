import React from 'react';
import Loader from 'react-loader-spinner';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment';
import BookingDetails from '../Bookings/BookingDetails';
import Table from 'reactstrap/es/Table';


class SolutionUsage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      bookingDetailId: null,
      bookingDetailModal: false
    };
  }

  openBookingDetailsModal = (bookingId) => {
    this.setState({ bookingDetailId: bookingId, bookingDetailModal: true });
  };

  closeBookingDetailsModal = () => {
    this.setState({ bookingDetailModal: false });
  };

  renderBookingDetailsModal = () => {
    const { bookingDetailId, bookingDetailModal } = this.state;
    return (
      <Modal
        size="xl"
        isOpen={bookingDetailModal}
        toggle={this.closeBookingDetailsModal}
      >
        <ModalHeader toggle={this.closeBookingDetailsModal}>
          <span style={{ fontSize: 20 }}>Booking Details</span>
        </ModalHeader>
        <ModalBody>
          <BookingDetails
            bookingId={bookingDetailId}
            updatesDisabled={true}
          />
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { usageHistory } = this.props;
    if (!usageHistory) {
      return null;
    }
    return (
      <>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">#</th>
            <th className="col" style={{ flex: 3 }}>Used Date</th>
            <th className="col">Action</th>
          </tr>
          </thead>
          <tbody>
          {
            usageHistory.map(({ bookingId, usedDate }, index) => {
              return (
                <tr className="row m-0" key={`${bookingId}`}>
                  <td className="col">
                    { index + 1 }
                  </td>
                  <th className="col" style={{ flex: 3 }}>{moment(usedDate).format('LLLL')}</th>
                  <td className="col">
                    <i
                      onClick={() => this.openBookingDetailsModal(bookingId)}
                      className="fa fa-search btn p-1"
                    ></i>
                  </td>
                </tr>
              );
            })
          }
          { usageHistory.length === 0 && <tr>
            <td colSpan="3" style={{ textAlign: 'center' }}>No usage </td>
          </tr> }
          </tbody>
        </Table>
        { this.renderBookingDetailsModal() }
      </>
    );
  }
}

export default SolutionUsage;

