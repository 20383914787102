import { getRequestWithToken, putRequest, postRequest, deleteRequest } from "../Constant/Api";

export const getSolutions = (params) => {
  const api_url = 'solutions';
  return getRequestWithToken(api_url,params);
};

export const createSolution = (params) => {
  const api_url = `solutions`;
  return postRequest(api_url,params);
};

export const updateSolution = (params) => {

  const api_url = `solutions/${params._id}`;

  return putRequest(api_url,params);
};

export const updateSolutionLogo = (params) => {
  const api_url = `solutions/${params._id}/logo`;

  return putRequest(api_url, params, { "Content-Type": 'multipart/form-data' });
};

export const getServiceSolutions = (params) => {
  const api_url = `solutions/servicesolutions/${params.serviceId}`;
  return getRequestWithToken(api_url,params);
};

export const updateServiceSolution = (params) => {
  const api_url = `solutions/servicesolutions/${params.serviceId}`;
  return putRequest(api_url,params);
};
