import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Loader from 'react-loader-spinner';
import { getUser } from "../../actions/User";
import { updateUser, updatePicture } from "../../actions/User";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";

class Profile extends React.Component {

  constructor(props){
    super(props);

    this.validator = new FormValidator(Rules.editProfile);

    this.state = {
      isLoading: true,
      userDetail: '',
      editEnable: false,
      buttonText: 'Edit Profile',
      myText: 'My Profile',
      validation: this.validator.valid(),
      profilePicturePreview: '',
      profilePicture: ''
    };
  }

  componentWillMount(){
    getUser().then(response => {
      if(response.data.success){
        if(response.data.success.data._id){
          this.setState({ isLoading: false ,
            userDetail:response.data.success.data,
            fname: response.data.success.data.fname,
            lname: response.data.success.data.lname,
            loginPhoneNo: response.data.success.data.loginPhoneNo,
            cityName: response.data.success.data.cityName,
            countryName: response.data.success.data.countryName,
            profilePicturePreview: response.data.success.data.picture,
            userId: response.data.success.data._id
          });
        }
      }
    }).catch(error => {
        this.setState({ isLoading: false });
    });
  }

  editEnable = (e) => {
    this.setState({ editEnable: !this.state.editEnable });

    if(this.state.editEnable){
      this.setState({ 
        buttonText: 'Edit Profile' ,
        myText: 'My Profile'
      });
    } else {
      this.setState({ 
        buttonText: 'Back to Profile' ,
        myText: 'Edit Profile'
      });
    }
  }

  onChange(e){
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  onSubmit(e){
    e.preventDefault();
    const {email,fname,lname} = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.setState({ isLoading:true });

      updateUser({
          fname:this.state.fname,
          lname:this.state.lname,
          loginPhoneNo:this.state.loginPhoneNo,
          cityName:this.state.cityName,
          countryName:this.state.countryName,
      }).then(response => {
          if(response.data.success){
              this.setState({err:false,success:true,message:'Information have been successfully updated!'}); toast.success('Information have been successfully updated!');
              setTimeout(()=>{
                this.componentWillMount();
              },3000)
          }else{
              this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
              setTimeout(() =>{
                  this.setState({err:false,success:false,isLoading:false});
              },3000);
          }
      }).catch(error=> {
          this.setState({err:true,message:'Server Error!'});
          setTimeout(() =>{
              this.setState({err:false,success:false,isLoading:false});
          },3000);
      });
    }
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();

    const {name, files} = e.target;
    console.log(name);
    console.log(files[0]);
    // const {userId} = this.state;

    reader.onloadend = () => {

      this.setState({
        [name]: files[0],
        [`${name}Preview`]: reader.result
      },() => {
        if(name == 'profilePicture'){
          this.updatePicture();
        }
      });
    }
    reader.readAsDataURL(files[0]) 
  }

  updatePicture(){
    const {profilePicture, userId, userPicture} = this.state;
    updatePicture({
      _id: userId,
      picture:this.state.profilePicture
    }).then(response => {
      if(response.data.success){
        this.setState({err:false,success:true,message:'Picture have been successfully updated!'}); toast.success('Picture have been successfully updated!');
        setTimeout(()=>{
          this.componentWillMount();
        },3000)
      }else{
        this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
        setTimeout(() =>{
            this.setState({err:false,success:false,isLoading:false});
        },3000);
      }
    }).catch(error=> {
        this.setState({err:true,message:'Server Error!'});
        setTimeout(() =>{
            this.setState({err:false,success:false,isLoading:false});
        },3000);
    });
  }

  // isNotFilled(){
  //   const {email,fname,lname,loginPhoneNo,cityName,countryName} = this.state;
  //   return (fname === this.props.location.admin.fname && lname === this.props.location.admin.lname)
  // }

  render() {
    const {userDetail, isLoading, editEnable, fname, lname, loginPhoneNo, cityName, countryName, profilePicturePreview} = this.state;
    let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
    return (
      <>
        {isLoading && 
          <Container className="mt-8 text-center">
            <Loader 
              type="Circles"
              color="#00BFFF"
              height="30"	
              width="30"
            />
          </Container>
        }
        {!isLoading && 
        <Container>
          <Col sm="12" md="12">
            <div className="card-profile shadow2">
              <div className="card-profile-image2">
                <div className="avatar-upload" style={{position: 'relative', maxWidth: '180px', margin: '0 auto'}}>
                  <div className="avatar-edit" style={{position: 'absolute', right: '0'}}>
                      <input style={{display: 'none'}} type='file' id="profilePicture" name="profilePicture" accept=".png, .jpg, .jpeg" onChange={(e) => this.handleImageChange(e)} />
                      { editEnable &&
                        <label htmlFor="profilePicture"><i className="fas fa-pen"></i></label>
                      }
                  </div>
                  <div className="avatar-preview">
                    {profilePicturePreview == null &&
                      <img
                        src={require("../../assets/img/theme/avatar.png")}
                      />
                    }
                    {profilePicturePreview &&
                      <img
                        src={profilePicturePreview}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </Col> 
          <Col sm="12" md="12">
            <div className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{this.state.myText}</h3>
                  </Col>
                  <Col xs="4" className="text-right" onClick={this.editEnable.bind(this)}>
                    <Button color="primary" size="sm">{this.state.buttonText}</Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-fname"
                          >
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={fname}
                            id="input-fname"
                            placeholder="First Name"
                            name="fname"
                            type="text"
                            disabled={!editEnable}
                            onChange={this.onChange.bind(this)}
                          />
                          <InputError show={validation.fname.isInvalid} message={validation.fname.message} />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-lname"
                          >
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-lname"
                            placeholder="Last Name"
                            name="lname"
                            defaultValue={lname}
                            type="text"
                            disabled={!editEnable}
                            onChange={this.onChange.bind(this)}
                          />
                          <InputError show={validation.lname.isInvalid} message={validation.lname.message} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={userDetail.email}
                            id="input-email"
                            placeholder="Email"
                            type="email"
                            name="email"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-loginPhoneNo"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={loginPhoneNo}
                            id="input-loginPhoneNo"
                            placeholder="Phone Number"
                            type="text"
                            disabled={!editEnable}
                            onChange={this.onChange.bind(this)}
                          />
                          <InputError show={validation.loginPhoneNo.isInvalid} message={validation.loginPhoneNo.message} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cityName"
                          >
                            City
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={cityName}
                            id="input-cityName"
                            placeholder="City"
                            type="text"
                            name="cityName"
                            disabled={!editEnable}
                            onChange={this.onChange.bind(this)}
                          />
                          <InputError show={validation.cityName.isInvalid} message={validation.cityName.message} />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-countryName"
                          >
                            Country
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={countryName}
                            id="input-countryName"
                            placeholder="Country"
                            type="text"
                            disabled={!editEnable}
                            onChange={this.onChange.bind(this)}
                          />
                          <InputError show={validation.countryName.isInvalid} message={validation.countryName.message} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                          <div className="text-right">
                              { editEnable &&  
                                <Button onClick={this.onSubmit.bind(this)} className="mt-4" color="primary" type="button">
                                  Update
                                </Button>
                              }
                          </div>
                      </Col>
                  </Row> 
                  </div>
                  <hr className="my-4" />
                </Form>
              </CardBody>
            </div>
          </Col>
        </Container>
        }
      </>
    );
  }
}

export default Profile;
