import React from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { getCities } from "../../actions/Cities";
import { createWasher } from "../../actions/Washers";

class AddService extends React.Component {
    constructor(props){
        super(props);   

        this.validator = new FormValidator(Rules.addWasher);
        
        this.state = {
            isLoading: false,
            cities: [],
            fname : '',
            lname: '',
            phone:'',
            countryPhoneCode:'+90',
            email: '',
            password: '',
            countryName: '',
            cityId: '',
            cityName: '',
            showPasword:false,
            showConfirmPassword:false,
            confirm_password:'',
            status: false,
            err:false,
            success:false,
            validation: this.validator.valid(),
            submerchantType: 'personal',
            legalName: '',
            gsmNumber: '',
            taxNumber: '',
            taxOffice: '',
            legalCompanyTitle: '',
            legalEmail: '',
            legalAddress: '',
            iban: '',
            commissionRate: '',
            parasutName: '',
            parasutId: '',
            accountingId: ''
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {
            fname, lname, email, password, countryName, cityId, cityName, phone, countryPhoneCode, confirm_password, submerchantType, accountingId,
            legalName, gsmNumber, taxNumber, taxOffice, legalCompanyTitle, legalEmail, legalAddress, iban, commissionRate,parasutName, parasutId
        } = this.state;

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if(password !== confirm_password){
            return;
        }

        if (validation.isValid) {
            this.setState({ isLoading:true });

            createWasher({
                fname, lname, email, password, countryName, cityId, cityName, countryPhoneCode, loginPhoneNo:`${countryPhoneCode}${phone}`,
                submerchantType, legalName, gsmNumber, taxNumber, taxOffice, legalCompanyTitle, legalEmail, legalAddress, iban, commissionRate,
                parasutName, parasutId, accountingId
            }).then(response => {
                console.log(response);
                if(response.data.success.data){
                    this.setState({err:false,success:true,message:'Washer details have been successfully added!'}); toast.success('Washer details have been successfully added!');
                    setTimeout(()=>{
                        this.setState({isLoading:false});
                        this.props.history.push("/dashboard/washers");
                    },3000)
                }else{
                    this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});

        getCities().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        cities:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    togglePassword(e){
        e.preventDefault();
        let {showPasword} = this.state;
          showPasword = !showPasword;
        this.setState({showPasword})
    }
    
    toggleConfirmPassword(e){
        e.preventDefault();
        let {showConfirmPassword} = this.state;
          showConfirmPassword = !showConfirmPassword;
        this.setState({showConfirmPassword})
    }

    onChange(e){
        const {name, value} = e.target;
        const { cities } = this.state;
        if(name == 'cityId'){
            this.setState({
                cityId: cities[value]._id,
                cityName: cities[value].cityName
            });
        } else {
            this.setState({[name]: value});
            if(name === 'submerchantType' && (value != 'limited')){
                this.setState({
                    legalCompanyTitle: ''
                })
            }
        }
    } 

render() {
    let {err,success, message, submerchantType, countryPhoneCode, cities,fname, lname, countryName, email, cityId, cityName, showPasword, isLoading,showConfirmPassword,password,confirm_password} = this.state;
    let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;

    return (
     <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
            <Row>
                <Col sm="12" md="12">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Add Washer</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Link to="/dashboard/washers">
                                    <Button
                                        color="primary"
                                        size="sm"
                                    >
                                    Back to Technicians
                                    </Button>
                                </Link>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" method="POST">
                                <div className="pl-lg-4">
                                    

                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-fname"
                                                >
                                                    First Name*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-fname"
                                                    placeholder="Enter a First Name"
                                                    type="text"
                                                    name="fname"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validation.fname.isInvalid} message={validation.fname.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-lname"
                                                >
                                                    Last Name*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-lname"
                                                    placeholder="Enter a Last Name"
                                                    type="text"
                                                    name="lname"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validation.lname.isInvalid} message={validation.lname.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-email"
                                                >
                                                    Email address*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-email"
                                                    placeholder="Enter a Email address"
                                                    type="text"
                                                    name="email"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validation.email.isInvalid} message={validation.email.message} />
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-phone"
                                                >
                                                    Phone*
                                                </label>
                                                <InputGroup>
                                                    <InputGroupAddon className="input-shadow" addonType="prepend">
                                                        <InputGroupText className="border-none">{countryPhoneCode}</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        className="input-shadow"
                                                        id="input-phone"
                                                        placeholder="Enter a Phone"
                                                        type='text'
                                                        name="phone"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </InputGroup>
                                                <InputError show={validation.phone.isInvalid} message={validation.phone.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-countryName"
                                                >
                                                    Country Name*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-countryName"
                                                    placeholder="Enter a Country name"
                                                    type="text"
                                                    name="countryName"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validation.countryName.isInvalid} message={validation.countryName.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-cityId"
                                                >
                                                    Select City*
                                                </label>

                                                <Input className="input-shadow" type="select" name="cityId" id="cityId" onChange={this.onChange.bind(this)}>
                                                    <option value=''>Select City</option>
                                                    {
                                                        cities.map((c, k) => {
                                                            return (
                                                                <option value={k} key={k}>{c.cityName}</option>
                                                            );
                                                        })
                                                    }
                                                </Input>
                                                <InputError show={validation.cityId.isInvalid} message={validation.cityId.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-password"
                                                >
                                                    Password*
                                                </label>
                                                <InputGroup className="input-group-alternative">     
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-password"
                                                        placeholder="*******"
                                                        type={showPasword?'text':'password'}
                                                        name="password"
                                                        autoComplete="nope"
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <a onClick={this.togglePassword.bind(this)}>
                                                            <InputGroupText style={{height:'100%'}}><i className={!showPasword?'fas fa-eye-slash':'fas fa-eye'}></i></InputGroupText>
                                                        </a>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                                <InputError show={validation.password.isInvalid} message={validation.password.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-confirm"
                                                >
                                                    Confirm password*
                                                </label>
                                                <InputGroup className="input-group-alternative">
                                                    <Input
                                                        className="form-control-alternative"
                                                        defaultValue=''
                                                        id="input-password"
                                                        placeholder="*******"
                                                        type={showConfirmPassword?'text':'password'}
                                                        name="confirm_password"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                    <InputGroupAddon addonType="append">
                                                        <a onClick={this.toggleConfirmPassword.bind(this)}>
                                                            <InputGroupText  style={{height:'100%'}}><i className={!showConfirmPassword?'fas fa-eye-slash':'fas fa-eye'}></i></InputGroupText>
                                                        </a>
                                                    </InputGroupAddon>
                                                </InputGroup>
                                                <InputError show={validation.confirm_password.isInvalid} message={validation.confirm_password.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {/* <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-submerchantType"
                                                >
                                                    Select SubMerchant Type*
                                                </label>
                                                <Input type="select" name="submerchantType" id="submerchantType" onChange={this.onChange.bind(this)}>
                                                    <option selected={submerchantType == 'limited'} value="limited">Limited</option>
                                                    <option selected={submerchantType == 'private'} value="private">Private</option>
                                                    <option selected={submerchantType == 'personal'} value="personal">Personal</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    { (submerchantType === 'limited' || submerchantType === 'private' || submerchantType === 'personal') && 
                                        <>
                                            

                                            <Row>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-parasutName"
                                                        >
                                                            Parasut Name
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-parasutName"
                                                            placeholder="Enter a Parasut Name"
                                                            type="text"
                                                            name="parasutName"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-parasutId"
                                                        >
                                                            Parasut Id
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-parasutId"
                                                            placeholder="Enter a Parasut Id"
                                                            type="text"
                                                            name="parasutId"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-legalName"
                                                        >
                                                            Legal Name
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-legalName"
                                                            placeholder="Enter a Legal Name"
                                                            type="text"
                                                            name="legalName"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-gsmNumber"
                                                        >
                                                            GSM Number
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-gsmNumber"
                                                            placeholder="Enter a GSM Number"
                                                            type="text"
                                                            name="gsmNumber"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-taxOffice"
                                                        >
                                                            Tax Office
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-taxOffice"
                                                            placeholder="Enter a Tax Office"
                                                            type="text"
                                                            name="taxOffice"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-taxNumber"
                                                        >
                                                            Tax Number
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-taxNumber"
                                                            placeholder="Enter a Tax Number"
                                                            type="text"
                                                            name="taxNumber"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-legalEmail"
                                                        >
                                                            Legal Email
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-legalEmail"
                                                            placeholder="Enter a Legal Email"
                                                            type="text"
                                                            name="legalEmail"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-legalAddress"
                                                        >
                                                            Legal Address
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-legalAddress"
                                                            placeholder="Enter a Legal Address"
                                                            type="text"
                                                            name="legalAddress"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-iban"
                                                        >
                                                            IBAN
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-iban"
                                                            placeholder="Enter a IBAN"
                                                            type="text"
                                                            name="iban"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-commissionRate"
                                                        >
                                                            Commission Rate
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-commissionRate"
                                                            placeholder="Enter a Commission Rate"
                                                            type="number"
                                                            name="commissionRate"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-accountingId"
                                                        >
                                                            Accounting Id
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-accountingId"
                                                            placeholder="Enter a Accounting Id"
                                                            type="text"
                                                            name="accountingId"
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                { (submerchantType === 'limited') && 
                                                    <Col md="6" sm="12">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-legalCompanyTitle"
                                                            >
                                                                Legal Company Title
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-legalCompanyTitle"
                                                                placeholder="Enter a Legal Company Title"
                                                                type="text"
                                                                name="legalCompanyTitle"
                                                                onChange={this.onChange.bind(this)} 
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                }
                                            </Row>
                                        </>
                                    } */}

                                    { (submerchantType === 'limited' || submerchantType === 'private' || submerchantType === 'personal') && 
                                        <Row>
                                            <Col sm="12">
                                                <div className="text-right">
                                                    { !isLoading &&  <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button">
                                                            Submit
                                                        </Button>
                                                    }
                                                    {
                                                        isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                                    }
                                                </div>
                                            </Col>
                                        </Row> 
                                    }
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
    );
  }
}

export default AddService;
