import { WASHER_SUCCESS } from '../Action/Washer';

const initialState = {
    isLoading: false,
}

const Washer = (state = initialState, action) =>{
    
    if(state === null || state === undefined || action === null){
        return { ...state }
    }

    switch(action.type){
        case WASHER_SUCCESS :
        const washerData = action.washer;
            return {
                ...state,
                washerData
            };
        default:
            return state;
    }

}

export default Washer;