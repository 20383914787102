import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const getCities = (params) => {
    const api_url = 'cities';  
    return getRequestWithToken(api_url,params);
};

export const createCity = (params) => {

    const api_url = `cities`;

    return postRequest(api_url,params);
  
};