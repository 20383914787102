import React from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";

import { createService, updateServiceLogo } from "../../actions/Services";
import { OTOPARLAK_LOGO } from '../../Constant/Constant';
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';

class AddService extends React.Component {
    constructor(props){
        super(props);

        this.validator = new FormValidator(Rules.addService);

        this.state = {
            isLoading: false,
            name : '',
            // serviceType : '',
            description : '',
            longDescription : '',
            duration : '',
            listOrder : '',
            price : '',
            slotDuration : '',
            timeDescription: '',
            status: false,
            err:false,
            success:false,
            logo: null,
            logoReady: false,
            validation: this.validator.valid(),
        };

        this.handleImageChange = this.handleImageChange.bind(this);
        this.uploadLogoPhoto = this.uploadLogoPhoto.bind(this);
    }

    onSubmit(e){
        e.preventDefault();
        const {name,description, longDescription, duration, price, slotDuration,timeDescription, status, listOrder} = this.state;

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            this.setState({ isLoading:true });
            createService(
                {
                    "status": false,
                    // "serviceType": serviceType,
                    "name": name,
                    "listOrder": listOrder,
                    "duration": duration,
                    "description": description,
                    "longDescription": longDescription,
                    "price": price,
                    "timeDescription": timeDescription,
                    "slotDuration": slotDuration
                }
            ).then(response => {
                console.log(response);
                if(response.data.success.data){
                    this.uploadLogoPhoto(response.data.success.data);
                }else{
                    this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
    }

    uploadLogoPhoto({ _id }) {
        const { logo } = this.state;
        let payload = new FormData();
        payload._id = _id;
        payload.append('picture', logo);
        updateServiceLogo(payload ).then(response => {
            if (response.data.success.data) {
                this.setState({err:false,success:true,message:'Service have been successfully added!'}); toast.success('Service have been successfully added!');
                setTimeout(()=>{
                    this.setState({isLoading:false});
                    this.props.history.push("/dashboard/services");
                },3000)
            } else{
                this.setState({err:true,message:'Successfully added but failed to upload logo! Please edit its logo!'}); toast.warn('Successfully added but failed to upload logo! Please edit its logo!');
                setTimeout(() =>{
                    this.setState({err:false,isLoading:false});
                    this.props.history.push("/dashboard/services");
                },3000);
            }
        }).catch(error => {
            this.setState({ err:true, message: 'Successfully added but server error when uploading logo! Please edit its logo!' });
            setTimeout(() =>{
                this.setState({err:false,isLoading:false});
                this.props.history.push("/dashboard/services");
            },3000);
        });
    }

    handleImageChange(imageFile) {
        this.setState({
            logo: imageFile,
            logoReady: !!imageFile // cast to bool
        });
    }

    onChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    isNotFilled(){
        const {name,description, longDescription, duration, listOrder, price, slotDuration,timeDescription, logoReady} = this.state;
        return (
            name === '' ||
            description === '' ||
            longDescription === '' ||
            duration === '' ||
            listOrder === '' ||
            price === '' ||
            slotDuration === '' ||
            timeDescription === '' ||
            logoReady === false
        )
    }

  render() {
    let {err,success, message, name,description, longDescription, duration, listOrder, price, slotDuration,timeDescription, isLoading} = this.state;
    let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
    return (
     <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
            <Row>
                <Col sm="12" md="12">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Add Service</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Link to="/dashboard/services">
                                    <Button
                                        color="primary"
                                        size="sm"
                                    >
                                    Back to Services
                                    </Button>
                                </Link>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" method="POST">
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-name"
                                                >
                                                    Name*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-fname"
                                                    placeholder="Enter a name"
                                                    type="text"
                                                    name="name"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.name.isInvalid} message={validation.name.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-price"
                                                >
                                                    Price*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-price"
                                                    placeholder="Enter a Price"
                                                    type="text"
                                                    name="price"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.price.isInvalid} message={validation.price.message} />
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-serviceType"
                                                >
                                                    Service Type*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-serviceType"
                                                    placeholder="Enter a Service Type"
                                                    type="text"
                                                    name="serviceType"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.serviceType.isInvalid} message={validation.serviceType.message} />
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-listOrder"
                                                >
                                                    List Order*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-listOrder"
                                                    placeholder="Enter a List Order"
                                                    type="text"
                                                    name="listOrder"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.listOrder.isInvalid} message={validation.listOrder.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-duration"
                                                >
                                                    Duration*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-duration"
                                                    placeholder="Enter a duration"
                                                    type="text"
                                                    name="duration"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.duration.isInvalid} message={validation.duration.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-description"
                                                >
                                                    Description*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-description"
                                                    placeholder="Enter a Description"
                                                    type="textarea"
                                                    name="description"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.description.isInvalid} message={validation.description.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-longDescription"
                                                >
                                                    Long Description
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-longDescription"
                                                    placeholder="Enter a Long Description"
                                                    type="textarea"
                                                    name="longDescription"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                {/* <InputError show={validation.longDescription.isInvalid} message={validation.longDescription.message} /> */}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-slotDuration"
                                                >
                                                    Slot Duration*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-slotDuration"
                                                    placeholder="Enter a Slot Duration"
                                                    type="text"
                                                    name="slotDuration"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.slotDuration.isInvalid} message={validation.slotDuration.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-timeDescription"
                                                >
                                                    Time Description*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-timeDescription"
                                                    placeholder="Enter a Time Description"
                                                    type="text"
                                                    name="timeDescription"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.timeDescription.isInvalid} message={validation.timeDescription.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="logo">
                                                    Logo*
                                                </label>
                                                <UploadableImage
                                                    uploadable={ true }
                                                    onChange={this.handleImageChange}
                                                />
                                                <InputError show={validation.logoReady.isInvalid} message={validation.logoReady.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-slotDuration"
                                                >
                                                    Slot Duration*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-slotDuration"
                                                    placeholder="Enter a Slot Duration"
                                                    type="text"
                                                    name="slotDuration"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputError show={validation.slotDuration.isInvalid} message={validation.slotDuration.message} />
                                            </FormGroup>
                                        </Col>

                                    </Row> */}

                                    <Row>
                                        <Col sm="12">
                                            <div className="text-right">
                                                { !isLoading &&  <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button">
                                                        Submit
                                                    </Button>
                                                }
                                                {
                                                    isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
    );
  }
}

export default AddService;
