import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,  
    Label
} from "reactstrap";
import Switch from "react-switch";
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
import {getCarModels, createCarModel} from "../../actions/CarModels";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";

class Carmodels extends React.Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator(Rules.addCarModel);

        this.state = { 
            checked: false ,
            carModal: false,
            makeId:'',
            carModel:'',
            carModels: [],
            modelYears: '',
            modelColors: '',
            validation: this.validator.valid(),
            
        };
    }

    addCarModel(){
        this.setState(prevState => ({
            carModal: !prevState.carModal
        }));
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    onSubmit = (e) => {

        e.preventDefault();
        const {makeId, carModel, modelYears, modelColors} = this.state;
        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            this.setState({ isLoading:true });
            createCarModel({
                makeId, carModel, modelYears, modelColors
            }).then(response => {
                console.log(response);
                if(response.data.success.data){
                    this.setState({err:false,success:true,message:'Car Model have been successfully added!'}); toast.success('Car Model have been successfully added!');
                    this.componentWillMount();
                    this.addCarModel();
                }else{
                    this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});
        getCarModels().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    
                    this.setState({
                        carModels:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    render(){
        const { carModal, carModels, isLoading, makeId, carModel, modelYears, modelColors } = this.state;
        let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={4}>
                                        </Col>
                                        <Col sm={8}>
                                            <Button  onClick={(e) => this.addCarModel()} className="pull-right" color="primary" style={{float:'right'}} size="sm">Add Car Model</Button>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Vehicle ID</th>
                                            <th scope="col">Car Model</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                        !isLoading && carModels == null && <tr>
                                            <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                        </tr>
                                        }

                                        { 
                                            !isLoading && carModels != null &&
                                            carModels.map((carModel,key) => {
                                                return (
                                                    <tr key={key}>
                                                        <th>{key+1}</th>
                                                        <td>{carModel._id}</td>
                                                        <td>{carModel.carModel}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>

                <Modal size="lg" isOpen={carModal} toggle={(e) => this.addCarModel()} className={this.props.className}>
                    <ModalHeader toggle={(e) => this.addCarModel()}><span style={{fontSize: 20}}> Add Car </span></ModalHeader>
                    <ModalBody>
                        <Form role="form">
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="price">Make Id</Label>
                                        <Input type="text" name="makeId" id="makeId" placeholder="Make Id"  onChange={this.onChange.bind(this)} />
                                        <InputError show={validation.makeId.isInvalid} message={validation.makeId.message} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="price">Model</Label>
                                        <Input type="text" name="carModel" id="carModel" placeholder="Car Model"  onChange={this.onChange.bind(this)} />
                                        <InputError show={validation.carModel.isInvalid} message={validation.carModel.message} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="price">Model Years</Label>
                                        <Input type="text" name="modelYears" id="modelYears" placeholder="Model Years"  onChange={this.onChange.bind(this)} />
                                        <InputError show={validation.modelYears.isInvalid} message={validation.modelYears.message} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="price">Model Colors</Label>
                                        <Input type="text" name="modelColors" id="modelColors" placeholder="Car Colors"  onChange={this.onChange.bind(this)} />
                                        <InputError show={validation.modelColors.isInvalid} message={validation.modelColors.message} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="text-right" onClick={this.onSubmit.bind(this)}>
                            { !isLoading &&  
                                <Button color="primary" type="button">Save Car Make</Button>
                            }
                            {
                                isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                            }
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default Carmodels;
