import {
  getRequestWithToken,
  putRequest,
  postRequest,
  deleteRequest
} from "../Constant/Api";

export const getClients = params => {
  const api_url = `clients`;
  return getRequestWithToken(api_url, params);
};

export const filterClients = filter => {
  console.log(filter);
  let query = "";

  Object.entries(filter).forEach(
    ([key, value]) => (query += `${key}=${value}&&`)
  );

  const api_url = `clients?${query}`;
  return getRequestWithToken(api_url, filter);
};

export const createClients = params => {
  const api_url = `clients`;
  return postRequest(api_url, params);
};

export const deleteClients = params => {
  console.log(params);
  const api_url = `clients/${params._id}`;
  return deleteRequest(api_url, params);
};

export const updateClients = params => {
  const api_url = `clients/${params._id}`;
  return putRequest(api_url, params);
};

export const activateClient = params => {
  const api_url = `clients/${params._id}/status`;
  return putRequest(api_url, params);
};
