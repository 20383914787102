import React from 'react';
import { toast } from "react-toastify";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import Loader from 'react-loader-spinner';
import { Link } from "react-router-dom";
import Header from "../../components/Headers/Header";

import { compose, withProps } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polygon
} from "react-google-maps";

import { getCities, createCity } from "../../actions/Cities";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";

class Cities extends React.Component {

    constructor(props) {
        super(props)

        this.validator = new FormValidator(Rules.addCity);

        this.state = {
            cityMapModal: false,
            coordinates: '',
            cities: [],
            cityAddModal: false,
            cityName: '',
            cityCoordinates: '',
            validation: this.validator.valid(),
        }
    }

    viewCoordinates = (coordinates) => {;
        this.setState(prevState => ({
            cityMapModal: !prevState.cityMapModal
        }));

        if(!this.state.cityMapModal){
            this.setState({
                selectedMarker: false,
                coordinates: coordinates
            });
        } else {
            this.setState({
                selectedMarker: false,
                coordinates: []
            });
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});
        getCities().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        cities:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    addCityModal(){
        this.setState(prevState => ({
            cityAddModal: !prevState.cityAddModal
        }));
    }

    onChange(e){
        const {name, value} = e.target;
        if(name == 'cityCoordinates') {
            this.setState({[name]: JSON.parse(value)});
        } else {
            this.setState({[name]: value});
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {cityName, cityCoordinates} = this.state;

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            this.setState({ isLoading:true });

            createCity({
                cityName, cityCoordinates
            }).then(response => {
                console.log(response);
                if(response.data.success.data){
                    this.addCityModal();
                    this.componentWillMount();
                }else{
                    this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
    }

    render(){
        const { cityMapModal, cities, isLoading, cityAddModal, cityCoordinates, cityName } = this.state;
        let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;

        const MapWithAMarker = compose(
            withProps({
                googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places",
                loadingElement: <div style={{ height: `100%` }} />,
                containerElement: <div style={{ height: `400px` }} />,
                mapElement: <div style={{ height: `100%` }} />,
            }),
            withScriptjs, 
            withGoogleMap
        )(props => {
            var coordinateArray = [];
            var i = 0;
            for(let coordinate of props.markers){
                coordinateArray[i] = {
                    lat: coordinate[0], lng: coordinate[1]
                }
                i++;
            }

            let polygonCoords = coordinateArray.map( ll => {
                return { lat: ll.lng, lng: ll.lat }
            });
            return (
                <GoogleMap defaultZoom={7} defaultCenter={{ lat: props.markers.length > 0 ? polygonCoords[0].lat : 28.0426,  lng: props.markers.length > 0 ? polygonCoords[0].lng : 40.96331 }}>
                    
                    {
                        // props.markers.map((marker, key) => {
                        //     return (
                        //         <Marker key={key}
                        //             position={{ lat: marker[0], lng: marker[1] }}
                        //         >   
                        //         </Marker>
                        //     )
                        // })
                    }
                
                    <Polygon
                        path={polygonCoords}
                        //key={1}
                        options={{
                            fillColor: "#000",
                            fillOpacity: 0.4,
                            strokeColor: "#000",
                            strokeOpacity: 1,
                            strokeWeight: 1
                        }} 
                    />
                </GoogleMap>
            )
        })

        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={4}>
                                            
                                        </Col>
                                        <Col sm={8}>
                                            <Button onClick={(e) => this.addCityModal()} className="pull-right" color="primary" style={{float:'right'}} size="sm">Add City</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr className="row m-0">
                                            <th className="col">S No.</th>
                                            <th className="col">City Name</th>
                                            {/* <th className="col">Total Coordinates</th> */}
                                            <th className="col">View Coordinates</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                            !isLoading && cities == null && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                            </tr>
                                        }

                                        { 
                                            !isLoading && cities != null &&
                                            cities.map((city,key) => {
                                                return (
                                                    <tr className="row m-0" key={key}>
                                                        <th className="col">{key+1}</th>
                                                        <td className="col">{city.cityName}</td>
                                                        {/* <td className="col">{city.fence.coordinates[0].length}</td> */}
                                                        <td className="col">
                                                            <i onClick={(e) => this.viewCoordinates(city.fence.coordinates[0])} className="fa fa-eye btn p-1"></i>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>

                <Modal size="lg" isOpen={cityMapModal} toggle={(e) => this.viewCoordinates()} className={this.props.className}>
                    <ModalHeader toggle={(e) => this.viewCoordinates()}><span style={{fontSize: 20}}> View </span></ModalHeader>
                    <ModalBody>
                    <MapWithAMarker
                        selectedMarker={this.state.selectedMarker}
                        markers={this.state.coordinates}
                    />
                    </ModalBody>
                </Modal>

                <Modal size="lg" isOpen={cityAddModal} toggle={(e) => this.addCityModal()} className={this.props.className}>
                    <ModalHeader toggle={(e) => this.addCityModal()}><span style={{fontSize: 20}}> Add City </span></ModalHeader>
                    <ModalBody>
                        <Form role="form" method="POST">
                            <div className="pl-lg-4">
                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="cityName"
                                            >
                                                City Name*
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="cityName"
                                                placeholder="Enter a City Name"
                                                type="text"
                                                name="cityName"
                                                onChange={this.onChange.bind(this)} 
                                            />
                                            <InputError show={validation.cityName.isInvalid} message={validation.cityName.message} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="cityCoordinates"
                                            >
                                                City Coordinates*
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="cityCoordinates"
                                                placeholder="Enter a Coordinates"
                                                type="text"
                                                name="cityCoordinates"
                                                onChange={this.onChange.bind(this)} 
                                            />
                                            <InputError show={validation.cityCoordinates.isInvalid} message={validation.cityCoordinates.message} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <div className="text-right">
                                            { !isLoading &&  <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button">
                                                    Submit
                                                </Button>
                                            }
                                            {
                                                isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default Cities;
