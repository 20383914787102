import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,  
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardFooter
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { getSlotsByDay, updateSlot } from "../../actions/Slots";

class Slots extends React.Component {
    constructor(props){
        super(props);
        this.state = { 
            isLoading:true,
            slots: [],
            reservationLimit: '',
            day: 1,
            value: moment(),
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});
        const {day} = this.state;
        getSlotsByDay({day}).then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;

                    this.setState({
                        slots:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    onChange(slot, key, e){
        const {name, value} = e.target;
        
        let postArray = {};
        if(name == slot._id){

            const { slots } = this.state;

            postArray = {
                _id:slot._id,
                'isVisible':  slot.isVisible ? false : true
            }

            slots[key].isVisible = !slots[key].isVisible;

            this.setState({
                slots
            });
            
        } else {
            this.setState({[name]: value});
            postArray = {
                _id:slot._id,
                [name]: value
            }
        }

        updateSlot(postArray).then(response => {
            if(response.data.success){
                // this.componentWillMount();
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
            setTimeout(() =>{
                this.setState({err:false,success:false,isLoading:false});
            },3000);
        });
    }

    onDayChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value},
            ()=> {
                this.componentWillMount();
            });
    }

    handleValueChange = (value,slot) => {
        // console.log(slot);
        // console.log(value.format('HH:mm'));

        this.setState({ value });
        if (!value) {
            return
        }
        updateSlot({
            _id:slot._id,
            'viewTime': value.format('HH:mm')
        }).then(response => {
            if(response.data.success){
                // this.componentWillMount();
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
            setTimeout(() =>{
                this.setState({err:false,success:false,isLoading:false});
            },3000);
        });
      };

    render(){

        const { slots, isLoading, day, value } = this.state;
        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={3}>
                                            <InputGroup className="input-group-alternative">
                                                <Input type="select" name="day" id="day" onChange={this.onDayChange.bind(this)}>
                                                    <option selected={day == '0'} value="0">Sunday</option>
                                                    <option selected={day == '1'} value="1">Monday</option>
                                                    <option selected={day == '2'} value="2">Tuesday</option>
                                                    <option selected={day == '3'} value="3">Wednesday</option>
                                                    <option selected={day == '4'} value="4">Thursday</option>
                                                    <option selected={day == '5'} value="5">Friday</option>
                                                    <option selected={day == '6'} value="6">Saturday</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr className="row">
                                            <th className="col">Slot Id</th>
                                            <th className="col">View Time</th>
                                            {/* <th className="col">How does it look</th> */}
                                            <th className="col">Reservation Limit</th>
                                            <th className="col">Status(Active / Deactive)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                            !isLoading && slots == null && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                            </tr>
                                        }

                                        { 
                                            !isLoading && slots != null &&
                                            slots.map((slot, key) => {
                                                return(
                                                    <tr className="row" key={key}>
                                                        <td className="col">
                                                            <span className="rc-time-picker">
                                                                <input className="rc-time-picker-input" type="text" onChange={this.onChange.bind(this, slot, key)} name="slotNum" id="slotNum" defaultValue={slot.slotNum} disabled />
                                                            </span>
                                                        </td>
                                                        <td className="col">
                                                            <TimePicker 
                                                                defaultValue={moment('2018-11-25 '+slot.viewTime)} 
                                                                onChange={(value)=>this.handleValueChange(value,slot)} 
                                                                showSecond={false}
                                                            />
                                                            {/* <input className="slots_input" type="text" onChange={this.onChange.bind(this, slot, key)} name="viewTime" id="viewTime" defaultValue={slot.viewTime} /> */}
                                                        </td>
                                                        {/* <td className="col">
                                                            <input className="slots_input" type="text" onChange={this.onChange.bind(this, slot, key)} name="description" id="description" defaultValue={slot.description} />
                                                        </td> */}
                                                        <td className="col">
                                                            <span className="rc-time-picker">
                                                                <input className="rc-time-picker-input" type="text" onChange={this.onChange.bind(this, slot, key)} name="reservationLimit" id="reservationLimit" defaultValue={slot.reservationLimit} />
                                                            </span>
                                                        </td>
                                                        <td className="col" style={{textAlign: 'center'}}>
                                                            <input type="checkbox" checked={slot.isVisible} onChange={this.onChange.bind(this, slot, key)}  name={slot._id} id="isVisible" />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Slots;
