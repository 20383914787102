import React from "react";
import { toast } from "react-toastify";
// import GoogleMapDrawFilter from "react-google-map-draw-filter";
import GoogleMapDrawFilter from "./DrawMap/src/GoogleMapDrawFilter";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const markers = [
  {
    info: " Marker1",
    icon: "image/icon.jpg",
    label: "A",
    latLng: { lng: 2.13815342634916, lat: 41.39485570794 }
  },
  {
    info: " Marker2",
    label: "B",
    latLng: { lng: 2.13815342634926, lat: 41.39485570795 }
  }
];

class DrawMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: []
    };
  }

  render() {
    return (
      <>
        <GoogleMapDrawFilter
          apiKey="AIzaSyANl6n1ocSKfkecsPu4BqWvIv_BLb4HmmQ"
          drawMopde={true}
          handleReturnCoords={this.props.handleReturnCoords}
          markers={markers}
          zones={this.props.zones}
        />
      </>
    );
  }
}

export default DrawMap;
