import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { updateService, updateServiceLogo } from "../../actions/Services";
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';
import { serviceData } from '../../lib/Action/Service';

// import { updateAdmin } from "../../actions/Admins";

class EditService extends React.Component {
    constructor(props){
        super(props);

        this.validator = new FormValidator(Rules.addService);

        this.state = {
            isLoading: false,
            name : '',
            description : '',
            longDescription : '',
            duration : '',
            listOrder : '',
            price : '',
            slotDuration : '',
            timeDescription: '',
            _id: '',
            err: false,
            success: false,
            logo: null,
            logoReady: false,
            validation: this.validator.valid(),
        }

        this.handleImageChange = this.handleImageChange.bind(this);
    }


    componentWillMount(){
        if(this.props.ServiceReducer.serviceData){
            console.log(this.props.ServiceReducer.serviceData);
            this.setState({
                'name': this.props.ServiceReducer.serviceData.name,
                // 'serviceType': this.props.ServiceReducer.serviceData.serviceType,
                'description': this.props.ServiceReducer.serviceData.description,
                'longDescription': this.props.ServiceReducer.serviceData.longDescription,
                'duration': this.props.ServiceReducer.serviceData.duration,
                'listOrder': this.props.ServiceReducer.serviceData.listOrder,
                'price': this.props.ServiceReducer.serviceData.price,
                'slotDuration': this.props.ServiceReducer.serviceData.slotDuration,
                'timeDescription': this.props.ServiceReducer.serviceData.timeDescription,
                'logo': this.props.ServiceReducer.serviceData.logo,
                'logoReady': !!this.props.ServiceReducer.serviceData.logo,
                '_id': this.props.ServiceReducer.serviceData._id,
            });
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {name,description, longDescription, duration, listOrder, price, slotDuration,timeDescription,_id} = this.state;

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submmited = true;

        if (validation.isValid) {
            this.setState({ isLoading: true });
            const payload = {
                name, description, longDescription, duration, listOrder, price, slotDuration, timeDescription, _id
            };
            updateService(payload).then(response => {
                console.log(response);
                if (response.data.success) {
                    this.props.serviceData(payload);

                    this.setState({ err: false, success: true, message: 'Service have been successfully updated!' });
                    setTimeout(() => {
                        this.setState({ err: false, success: false, isLoading: false });
                    }, 3000)
                } else {
                    this.setState({ err: true, message: 'Unable to edit!', isLoading: false });
                    setTimeout(() => {
                        this.setState({ err: false, success: false, isLoading: false });
                    }, 3000);
                }
            }).catch(error => {
                this.setState({ err: true, message: 'Server Error!' });
                setTimeout(() => {
                    this.setState({ err: false, success: false, isLoading: false });
                }, 3000);
            });
        }
    }

    onChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    } 

    isNotFilled(){
        const {name,description, longDescription, duration, listOrder, price, slotDuration,timeDescription, logo} = this.state;
        return (
            name === this.props.ServiceReducer.serviceData.name && 
            description === this.props.ServiceReducer.serviceData.description &&
            longDescription === this.props.ServiceReducer.serviceData.longDescription &&
            duration === this.props.ServiceReducer.serviceData.duration &&
            listOrder === this.props.ServiceReducer.serviceData.listOrder &&
            price === this.props.ServiceReducer.serviceData.price &&
            slotDuration === this.props.ServiceReducer.serviceData.slotDuration &&
            timeDescription === this.props.ServiceReducer.serviceData.timeDescription
        )
    }

    handleImageChange(imageFile) {
        const { _id } = this.state;
        let prevLogo = this.state.logo;
        this.setState({
            logo: imageFile,
            logoReady: !!imageFile // cast to bool
        });
        let payload = new FormData();
        payload._id = _id;
        payload.append("picture", imageFile);
        updateServiceLogo(payload).then(response => {
            if (response.data.success) {
                this.props.serviceData(response.data.success.data);

                this.setState({ err: false, success: true, message: 'Service logo have been successfully updated!' });
                setTimeout(() => {
                    this.setState({ err: false, success: false });
                }, 3000);
            } else {
                this.setState({ err: true, message: 'Failed to update service logo!', logo: prevLogo, logoReady: !!prevLogo });
                setTimeout(() => {
                    this.setState({ err: false, success: false });
                }, 3000);

            }
        }).catch(error => {
            console.error('logo upload error', error.message);
            this.setState({ err: true, message: 'Server Error!', logo: prevLogo, logoReady: !!prevLogo });
            setTimeout(() => {
                this.setState({ err: false, success: false });
            }, 3000);
        });
    }

    render() {
        let {err, success, name,description, longDescription, duration, listOrder, price, slotDuration,timeDescription,_id, logo, isLoading} = this.state;
        let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
        
        return (
            <>
            <Header />
            {/* Table */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col sm="12" md="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Edit Service</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Link to="/dashboard/services">
                                        <Button
                                            color="primary"
                                            size="sm"
                                        >
                                        Back to Services
                                        </Button>
                                    </Link>
                                </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form role="form" method="POST">
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-name"
                                                    >
                                                        Name*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-fname"
                                                        placeholder="Enter a name"
                                                        type="text"
                                                        name="name"
                                                        defaultValue={name}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.name.isInvalid} message={validation.name.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-price"
                                                    >
                                                        Price*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-price"
                                                        placeholder="Enter a Price"
                                                        type="text"
                                                        name="price"
                                                        defaultValue={price}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.price.isInvalid} message={validation.price.message} />
                                                </FormGroup>
                                            </Col>
                                            {/* <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-serviceType"
                                                    >
                                                        Service Type*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-serviceType"
                                                        placeholder="Enter a Service Type"
                                                        type="text"
                                                        name="serviceType"
                                                        defaultValue={serviceType}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.serviceType.isInvalid} message={validation.serviceType.message} />
                                                </FormGroup>
                                            </Col> */}
                                        </Row>
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-listOrder"
                                                    >
                                                        List Order*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-listOrder"
                                                        placeholder="Enter a List Order"
                                                        type="text"
                                                        name="listOrder"
                                                        defaultValue={listOrder}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.listOrder.isInvalid} message={validation.listOrder.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-duration"
                                                    >
                                                        Duration*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-duration"
                                                        placeholder="Enter a duration"
                                                        type="text"
                                                        name="duration"
                                                        defaultValue={duration}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.duration.isInvalid} message={validation.duration.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-description"
                                                    >
                                                        Description*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-description"
                                                        placeholder="Enter a Description"
                                                        type="textarea"
                                                        name="description"
                                                        defaultValue={description}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.description.isInvalid} message={validation.description.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-longDescription"
                                                    >
                                                        Long Description*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-longDescription"
                                                        placeholder="Enter a Long Description"
                                                        type="textarea"
                                                        name="longDescription"
                                                        defaultValue={longDescription}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    {/* <InputError show={validation.longDescription.isInvalid} message={validation.longDescription.message} /> */}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                      className="form-control-label"
                                                      htmlFor="input-slotDuration"
                                                    >
                                                        Slot Duration*
                                                    </label>
                                                    <Input
                                                      className="form-control-alternative"
                                                      id="input-slotDuration"
                                                      placeholder="Enter a Slot Duration"
                                                      type="text"
                                                      name="slotDuration"
                                                      defaultValue={slotDuration}
                                                      onChange={this.onChange.bind(this)}
                                                    />
                                                    <InputError show={validation.slotDuration.isInvalid} message={validation.slotDuration.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-timeDescription"
                                                    >
                                                        Time Description*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-timeDescription"
                                                        placeholder="Enter a Time Description"
                                                        type="text"
                                                        name="timeDescription"
                                                        defaultValue={timeDescription}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.timeDescription.isInvalid} message={validation.timeDescription.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="logo"
                                                    >
                                                        Logo*
                                                    </label>
                                                    <UploadableImage
                                                      uploadable={true}
                                                      imageSource={logo}
                                                      onChange={this.handleImageChange}
                                                    />
                                                    <InputError show={validation.logoReady.isInvalid} message={validation.logoReady.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm="12">
                                                <div className="text-right">
                                                    { !isLoading &&  
                                                        <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button" disabled={this.isNotFilled()}>
                                                            Update
                                                        </Button>
                                                    }
                                                    {
                                                        isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                                    }
                                                </div>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                
            </>
        );
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    serviceData: (service) => dispatch(serviceData(service))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditService);
