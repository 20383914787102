import React from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { getCities } from "../../actions/Cities";
import { createCustomer } from "../../actions/Customers";

class AddCustomer extends React.Component {
    constructor(props){
        super(props);   

        this.validator = new FormValidator(Rules.addCustomer);
        
        this.state = {
            isLoading: false,
            cities: [],
            fname : '',
            lname: '',
            phone:'',
            countryPhoneCode:'+90',
            email: '',
            countryName: '',
            cityId: '',
            cityName: '',
            status: false,
            err:false,
            success:false,
            validation: this.validator.valid(),
            // parasutName: '',
            // parasutId: '',
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {
            fname, lname, email, countryName, cityId, cityName, phone, countryPhoneCode
        } = this.state;

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            this.setState({ isLoading:true });

            createCustomer({
                fname, lname, email, countryName, cityId, cityName, phone, countryPhoneCode
            }).then(response => {
                console.log(response);
                if(response.data.success.data){
                    this.setState({err:false,success:true,message:'Customer details have been successfully added!'}); toast.success('Customer details have been successfully added!');
                    setTimeout(()=>{
                        this.setState({isLoading:false});
                        this.props.history.push("/dashboard/customers");
                    },3000)
                }else{
                    this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});

        getCities().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        cities:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    onChange(e){
        const {name, value} = e.target;
        const { cities } = this.state;
        if(name == 'cityId'){
            if (value !== '') {
                this.setState({
                    cityId: cities[value]._id,
                    cityName: cities[value].cityName
                });
            } else {
                this.setState({
                    cityId: '',
                    cityName: 'Please select city'
                });
            }
        } else {
            this.setState({[name]: value});
        }
    } 

render() {
    let {err,success, message, countryPhoneCode, cities,fname, lname, countryName, email, cityId, cityName, isLoading} = this.state;
    let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;

    return (
     <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
            <Row>
                <Col sm="12" md="12">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Add Customer</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Link to="/dashboard/customers">
                                    <Button
                                        color="primary"
                                        size="sm"
                                    >
                                    Back to Customers
                                    </Button>
                                </Link>
                            </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" method="POST">
                                <div className="pl-lg-4">
 
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-fname"
                                                >
                                                    First Name*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-fname"
                                                    placeholder="Enter a First Name"
                                                    type="text"
                                                    name="fname"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validation.fname.isInvalid} message={validation.fname.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-lname"
                                                >
                                                    Last Name*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-lname"
                                                    placeholder="Enter a Last Name"
                                                    type="text"
                                                    name="lname"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validation.lname.isInvalid} message={validation.lname.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-email"
                                                >
                                                    Email address*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-email"
                                                    placeholder="Enter a Email address"
                                                    type="text"
                                                    name="email"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validation.email.isInvalid} message={validation.email.message} />
                                            </FormGroup>
                                        </Col>
                                        
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-phone"
                                                >
                                                    Phone*
                                                </label>
                                                <InputGroup>
                                                    <InputGroupAddon className="input-shadow" addonType="prepend">
                                                        <InputGroupText className="border-none">{countryPhoneCode}</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        className="input-shadow"
                                                        id="input-phone"
                                                        placeholder="Enter a Phone"
                                                        type='text'
                                                        name="phone"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                </InputGroup>
                                                <InputError show={validation.phone.isInvalid} message={validation.phone.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-countryName"
                                                >
                                                    Country Name*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-countryName"
                                                    placeholder="Enter a Country name"
                                                    type="text"
                                                    name="countryName"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validation.countryName.isInvalid} message={validation.countryName.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-cityId"
                                                >
                                                    Select City*
                                                </label>

                                                <Input className="input-shadow" type="select" name="cityId" id="cityId" onChange={this.onChange.bind(this)}>
                                                    <option value=''>Select City</option>
                                                    {
                                                        cities.map((c, k) => {
                                                            return (
                                                                <option value={k} key={k}>{c.cityName}</option>
                                                            );
                                                        })
                                                    }
                                                </Input>
                                                <InputError show={validation.cityId.isInvalid} message={validation.cityId.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {/* <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-parasutName"
                                                >
                                                    Parasut Name
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-parasutName"
                                                    placeholder="Enter a Parasut Name"
                                                    type="text"
                                                    name="parasutName"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-parasutId"
                                                >
                                                    Parasut Id
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-parasutId"
                                                    placeholder="Enter a Parasut Id"
                                                    type="text"
                                                    name="parasutId"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row> */}

                                    <Row>
                                        <Col sm="12">
                                            <div className="text-right">
                                                { !isLoading &&  <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button">
                                                        Submit
                                                    </Button>
                                                }
                                                {
                                                    isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                                }
                                            </div>
                                        </Col>
                                    </Row> 
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
    );
  }
}

export default AddCustomer;
