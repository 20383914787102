import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const filterPromocodes = (filter) => {
    let query = '';

    Object.entries(filter).forEach(
      ([key, value]) => query += `${key}=${value}&&`
    );

    const api_url = `promocodes?${query}`;

    return getRequestWithToken(api_url,filter);
};

export const createPromocode = (params) => {

    const api_url = `promocodes`;

    return postRequest(api_url,params);
  
};

export const updatePromocode = (params) => {

    const api_url = `promocodes/${params._id}`;

    return putRequest(api_url,params);
  
};

export const updatePromocodeLogo = (params) => {
    const api_url = `promocodes/${params._id}/logo`;

    return putRequest(api_url, params, { "Content-Type": 'multipart/form-data' });
};

export const getPromocode = (params) => {
    console.log(params);
    const api_url = `promocodes/${params._id}`;
    return getRequestWithToken(api_url,params);
};
