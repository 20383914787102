import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const sendPushNotification = params => {
  const api_url = `pushnotifications/sendBySegment`;
  return postRequest(api_url, params);
};
export const sendPushNotificationWithExcel = params => {
  const api_url = `pushnotifications/sendByExcel`;
  return postRequest(api_url, params, {
    "Content-Type": "multipart/form-data"
  });
};
