import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const getCarModels = (params) => {
    const api_url = 'car-models';  
    return getRequestWithToken(api_url,params);
};

export const createCarModel = (params) => {

    const api_url = `car-models`;

    return postRequest(api_url,params);
  
};