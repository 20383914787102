import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { updateAdditionalService, updateAdditionalServiceLogo } from '../../actions/Services';
import { additionalServiceData } from '../../lib/Action/AdditionalService';
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';

class EditAdditionalService extends React.Component {
    constructor(props){
        super(props);

        this.validator = new FormValidator(Rules.addAdditionalService);

        this.state = {
            isLoading: false,
            name : '',
            description : '',
            longDescription : '',
            price : '',
            timeDescription: '',
            logo: null,
            logoReady: false,
            _id: ''
        }

        this.handleImageChange = this.handleImageChange.bind(this);
    }


    componentWillMount(){
        if(this.props.AdditionalServiceReducer.additionalServiceData){
            this.setState({
                'name': this.props.AdditionalServiceReducer.additionalServiceData.name,
                'description': this.props.AdditionalServiceReducer.additionalServiceData.description,
                'longDescription': this.props.AdditionalServiceReducer.additionalServiceData.longDescription,
                'price': this.props.AdditionalServiceReducer.additionalServiceData.price,
                'timeDescription': this.props.AdditionalServiceReducer.additionalServiceData.timeDescription,
                'logo': this.props.AdditionalServiceReducer.additionalServiceData.logo,
                'logoReady': !!this.props.AdditionalServiceReducer.additionalServiceData.logo,
                '_id': this.props.AdditionalServiceReducer.additionalServiceData._id,
            });
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {name, description, longDescription, price, timeDescription,_id} = this.state;

        this.setState({ isLoading:true });
        const payload = {name,description, longDescription, price, timeDescription, _id};
        updateAdditionalService(payload).then(response => {
            console.log(response);
            if(response.data.success){
                this.props.serviceData(payload);

                this.setState({err:false,success:true,message:'Additional service have been successfully updated!'}); toast.success('Additional service have been successfully updated!');
                setTimeout(()=>{
                    this.setState({ err: false, success: false, isLoading: false });
                },3000)
            }else{
                this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
            setTimeout(() =>{
                this.setState({err:false,success:false,isLoading:false});
            },3000);
        });
    }

    onChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    } 

    isNotFilled(){
        const {name,description, longDescription, price, timeDescription} = this.state;
        return (
            name === this.props.AdditionalServiceReducer.additionalServiceData.name &&
            description === this.props.AdditionalServiceReducer.additionalServiceData.description &&
            longDescription === this.props.AdditionalServiceReducer.additionalServiceData.longDescription &&
            price === this.props.AdditionalServiceReducer.additionalServiceData.price &&
            timeDescription === this.props.AdditionalServiceReducer.additionalServiceData.timeDescription
        )
    }

    handleImageChange(imageFile) {
        const { _id } = this.state;
        let prevLogo = this.state.logo;
        this.setState({
            logo: imageFile,
            logoReady: !!imageFile // cast to bool
        });
        let payload = new FormData();
        payload._id = _id;
        payload.append("picture", imageFile);
        updateAdditionalServiceLogo(payload).then(response => {
            if (response.data.success) {
                this.props.additionalServiceData(response.data.success.data);

                this.setState({ err: false, success: true, message: 'Additional service logo have been successfully updated!' });
                setTimeout(() => {
                    this.setState({ err: false, success: false });
                }, 3000);
            } else {
                this.setState({ err: true, message: 'Failed to update service logo!', logo: prevLogo, logoReady: !!prevLogo });
                setTimeout(() => {
                    this.setState({ err: false, success: false });
                }, 3000);

            }
        }).catch(error => {
            console.error('logo upload error', error.message);
            this.setState({ err: true, message: 'Server Error!', logo: prevLogo, logoReady: !!prevLogo });
            setTimeout(() => {
                this.setState({ err: false, success: false });
            }, 3000);
        });
    }

    render() {
        let {name,description, longDescription, price, timeDescription, _id, isLoading, logo} = this.state;
        
        return (
            <>
            <Header />
            {/* Table */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col sm="12" md="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Edit Additional Service</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Link to="/dashboard/service-additional">
                                        <Button
                                            color="primary"
                                            size="sm"
                                        >
                                        Back to Additional Services
                                        </Button>
                                    </Link>
                                </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form role="form" method="POST">
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-name"
                                                    >
                                                        Name*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-fname"
                                                        placeholder="Enter a name"
                                                        type="text"
                                                        name="name"
                                                        defaultValue={name}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-price"
                                                    >
                                                        Price*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-price"
                                                        placeholder="Enter a Price"
                                                        type="text"
                                                        name="price"
                                                        defaultValue={price}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-description"
                                                    >
                                                        Description*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-description"
                                                        placeholder="Enter a Description"
                                                        type="textarea"
                                                        name="description"
                                                        defaultValue={description}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-longDescription"
                                                    >
                                                        Long Description*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-longDescription"
                                                        placeholder="Enter a Long Description"
                                                        type="textarea"
                                                        name="longDescription"
                                                        defaultValue={longDescription}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-timeDescription"
                                                    >
                                                        Time Description*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-timeDescription"
                                                        placeholder="Enter a Time Description"
                                                        type="text"
                                                        name="timeDescription"
                                                        defaultValue={timeDescription}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <FormGroup>
                                                    <label
                                                      className="form-control-label"
                                                      htmlFor="logo"
                                                    >
                                                        Logo*
                                                    </label>
                                                    <UploadableImage
                                                      uploadable={true}
                                                      imageSource={logo}
                                                      onChange={this.handleImageChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col sm="12">
                                                <div className="text-right">
                                                    { !isLoading &&  
                                                        <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button" disabled={this.isNotFilled()}>
                                                            Update
                                                        </Button>
                                                    }
                                                    {
                                                        isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                                    }
                                                </div>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                
            </>
        );
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    additionalServiceData: (additionalService) => dispatch(additionalServiceData(additionalService))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAdditionalService);
