import { CUSTOMER_SUCCESS } from '../Action/Customer';

const initialState = {
    isLoading: false,
}

const Customer = (state = initialState, action) =>{
    
    if(state === null || state === undefined || action === null){
        return { ...state }
    }

    switch(action.type){
        case CUSTOMER_SUCCESS :
        const customerData = action.customer;
            return {
                ...state,
                customerData
            };
        default:
            return state;
    }

}

export default Customer;