import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";

import { getPromocode, updatePromocode, updatePromocodeLogo } from '../../actions/Promocode';
import { getLocalValue  } from "../../lib/Helper";
import { getCities } from "../../actions/Cities";
import { getZones } from "../../actions/Zones";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { promocodeData } from '../../lib/Action/Promocode';
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';
import Switch from 'react-ios-switch';

// import { updateAdmin } from "../../actions/Admins";

class EditPromocode extends React.Component {
    constructor(props){
        super(props);

        this.validator = new FormValidator(Rules.editPromocode);

        this.state = {
            isLoading: false,
            promocode : [],
            cities : [],
            zones : [],
            title : '',
            rideType : '',
            description : '',
            amount : '',
            isAbsolute: false,
            code : '',
            cityId : '',
            usageLimit : '',
            selectDate: new Date(),
            expiryDate: '',
            err:false,
            success:false,
            logo: null,
            logoReady: false,
            validation: this.validator.valid(),
            isFreeRide:false,
            isFirstRide:false,
            _id: ''
        }
        this.selectDateChange = this.selectDateChange.bind(this);
        this.toggleIsAbsoluteDropdown = this.toggleIsAbsoluteDropdown.bind(this);
        this.setIsAbsolute = this.setIsAbsolute.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }


    componentWillMount(){
        if(this.props.PromocodeReducer.promocodeData){
            console.log(this.props.PromocodeReducer.promocodeData);
            const { expiryDate } = this.props.PromocodeReducer.promocodeData;
            this.setState({
                title: this.props.PromocodeReducer.promocodeData.title,
                rideType: this.props.PromocodeReducer.promocodeData.rideType,
                description: this.props.PromocodeReducer.promocodeData.desc || '',
                amount: this.props.PromocodeReducer.promocodeData.amount,
                isAbsolute: this.props.PromocodeReducer.promocodeData.isAbsolute,
                code: this.props.PromocodeReducer.promocodeData.code,
                cityId: this.props.PromocodeReducer.promocodeData.cityId,
                usageLimit: this.props.PromocodeReducer.promocodeData.usageLimit,
                _id: this.props.PromocodeReducer.promocodeData._id,
                expiryDate: expiryDate || '',
                logo: this.props.PromocodeReducer.promocodeData.logo,
                logoReady: !!this.props.PromocodeReducer.promocodeData.logo,
                isFirstRide:this.props.PromocodeReducer.promocodeData.isFirstRide,
                isFreeRide:this.props.PromocodeReducer.promocodeData.isFreeRide,
                selectDate: expiryDate ? new Date(expiryDate) : this.state.selectDate
            });
        }
    }

    componentDidMount(){
        getCities().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        cities:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });

        getZones().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        zones:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    onSubmit(e){
        e.preventDefault();
        const {_id, title, rideType, amount, isAbsolute, code, cityId, usageLimit, description, expiryDate, isFreeRide,isFirstRide} = this.state;

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            this.setState({isLoading:true});
            updatePromocode({
                _id, title, rideType, amount, isAbsolute, code, cityId, usageLimit, desc: description, expiryDate,isFreeRide,isFirstRide
            }).then(response => {
                console.log(response);
                if(response.data.success){
                    toast.success('Promocode details have been successfully updated!');
                    this.setState({err:false,success:true,message:'Promocode details have been successfully updated!'}); toast.success('Promocode details have been successfully updated!');
                    setTimeout(()=>{
                        this.props.history.push("/dashboard/promocode");
                    },3000)
                }else{
                    this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);

            });
        }
    }

    onChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value || ''});
    } 

    selectDateChange(date) { if (!date) { return; }
        this.setState({
            selectDate: date
        },()=> {
            const selectedDate = this.state.selectDate;
            var month = (selectedDate .getMonth() + 1);
            var day = (selectedDate .getDate());
            var year = (selectedDate .getFullYear());
            this.setState({
                expiryDate: year+'-'+month+'-'+day
            })
        });
    }

    isNotFilled(){
        const {title, code, amount, isAbsolute, rideType, description, usageLimit, cityId, expiryDate,isFreeRide,isFirstRide} = this.state;
        return (
            title === this.props.PromocodeReducer.promocodeData.title &&
            code === this.props.PromocodeReducer.promocodeData.code &&
            amount === this.props.PromocodeReducer.promocodeData.amount &&
            isAbsolute === this.props.PromocodeReducer.promocodeData.isAbsolute &&
            rideType === this.props.PromocodeReducer.promocodeData.rideType &&
            description === this.props.PromocodeReducer.promocodeData.desc &&
            usageLimit === this.props.PromocodeReducer.promocodeData.usageLimit &&
            cityId === this.props.PromocodeReducer.promocodeData.cityId &&
            expiryDate === this.props.PromocodeReducer.promocodeData.expiryDate &&
            isFreeRide === this.props.PromocodeReducer.promocodeData.isFreeRide &&
            isFirstRide === this.props.PromocodeReducer.promocodeData.isFirstRide
        )
    }

    toggleIsAbsoluteDropdown() {
        this.setState((prevState) => ({
            isAbsoluteDropdownOpen: !prevState.isAbsoluteDropdownOpen
        }));
    }
    setIsAbsolute(isAbsolute) {
        this.setState({ isAbsolute });
    }

    handleImageChange(imageFile) {
        const { _id } = this.state;
        let prevLogo = this.state.logo;
        this.setState({
            logo: imageFile,
            logoReady: !!imageFile // cast to bool
        });
        let payload = new FormData();
        payload._id = _id;
        payload.append("picture", imageFile);
        updatePromocodeLogo(payload).then(response => {
            if (response.data.success) {
                this.props.promocodeData(response.data.success.data);
                toast.success('Promocode logo have been successfully updated!');
                this.setState({ err: false, success: true, message: 'Promocode logo have been successfully updated!' });
                setTimeout(() => {
                    this.setState({ err: false, success: false });
                }, 3000);
            } else {
                this.setState({ err: true, message: 'Failed to update promocode logo!', logo: prevLogo, logoReady: !!prevLogo });
                setTimeout(() => {
                    this.setState({ err: false, success: false });
                }, 3000);

            }
        }).catch(error => {
            console.error('logo upload error', error.message);
            this.setState({ err: true, message: 'Server Error!', logo: prevLogo, logoReady: !!prevLogo });
            setTimeout(() => {
                this.setState({ err: false, success: false });
            }, 3000);
        });
    }

    render() {
        let {zones, isLoading, cities, title, code, amount, isAbsolute, rideType, description, usageLimit, cityId, expiryDate,
            isAbsoluteDropdownOpen, logo, isFreeRide,isFirstRide } = this.state;
        let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
        return (
            <>
            <Header />
            {/* Table */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col sm="12" md="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Edit Promocode</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Link to="/dashboard/promocode">
                                        <Button
                                            color="primary"
                                            size="sm"
                                        >
                                        Back to Promocodes
                                        </Button>
                                    </Link>
                                </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form role="form" method="POST">
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-title"
                                                    >
                                                        Title*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-title"
                                                        placeholder="Enter a title"
                                                        type="text"
                                                        name="title"
                                                        defaultValue={title}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.title.isInvalid} message={validation.title.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-rideType"
                                                    >
                                                        Select Ride Type*
                                                    </label>
                                                    <Input type="select" name="rideType" id="rideType" onChange={this.onChange.bind(this)}>
                                                        <option value="">Select Type</option>
                                                        <option value="PRIMARY" selected={rideType == 'PRIMARY'}>PRIMARY</option>
                                                        <option value="SECONDARY" selected={rideType == 'SECONDARY'}>SECONDARY</option>
                                                        <option value="BOTH" selected={rideType == 'BOTH'}>BOTH</option>
                                                    </Input>
                                                    <InputError show={validation.rideType.isInvalid} message={validation.rideType.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-amount"
                                                    >
                                                        Amount*
                                                    </label>
                                                    <InputGroup>
                                                        <Input
                                                          className="form-control-alternative"
                                                          id="input-amount"
                                                          placeholder="Enter a Amount"
                                                          type="text"
                                                          name="amount"
                                                          defaultValue={amount}
                                                          onChange={this.onChange.bind(this)}
                                                        />
                                                        <InputGroupButtonDropdown
                                                          addonType={"append"}
                                                          isOpen={isAbsoluteDropdownOpen}
                                                          toggle={this.toggleIsAbsoluteDropdown}
                                                        >
                                                            <DropdownToggle
                                                              caret
                                                              aria-expanded={isAbsoluteDropdownOpen}
                                                            >
                                                                { isAbsolute ? 'Raw Amount' : 'Percentage' }
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem
                                                                  onClick={() => this.setIsAbsolute(true)}
                                                                >
                                                                    Raw Amount
                                                                </DropdownItem>
                                                                <DropdownItem
                                                                  onClick={() => this.setIsAbsolute(false)}
                                                                >
                                                                    Percentage
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </InputGroupButtonDropdown>
                                                    </InputGroup>
                                                    <InputError show={validation.amount.isInvalid} message={validation.amount.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-code"
                                                    >
                                                        Code*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-code"
                                                        placeholder="Enter a Code"
                                                        type="text"
                                                        name="code"
                                                        defaultValue={code}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.code.isInvalid} message={validation.code.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-description"
                                                    >
                                                        Description*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-description"
                                                        placeholder="Enter a Description"
                                                        type="textarea"
                                                        name="description"
                                                        value={description}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.description.isInvalid} message={validation.description.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-cityId"
                                                    >
                                                        Select City
                                                    </label>

                                                    <Input type="select" name="cityId" id="cityId" onChange={this.onChange.bind(this)}>
                                                        <option value=''>Select City</option>
                                                        {
                                                            cities.map((c, k) => {
                                                                return (
                                                                    <option selected={cityId == c._id} value={c._id} key={k}>{c.cityName}</option>
                                                                );
                                                            })
                                                        }
                                                    </Input>
                                                    <InputError show={validation.cityId.isInvalid} message={validation.cityId.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-usageLimit"
                                                    >
                                                        Usage Limit*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-usageLimit"
                                                        placeholder="Enter a Usage Limit"
                                                        type="text"
                                                        name="usageLimit"
                                                        defaultValue={usageLimit}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.usageLimit.isInvalid} message={validation.usageLimit.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-selectDate"
                                                    > Expiry Date </label>
                                                    <DatePicker
                                                        selected={this.state.selectDate}
                                                        onChange={this.selectDateChange}
                                                        dateFormat="yyyy-MM-dd"
                                                        className="form-control"
                                                        popperPlacement="bottom"
                                                        minDate={new Date()}
                                                        popperModifiers={{
                                                            flip: {
                                                                behavior: ["bottom"] // don't allow it to flip to be above
                                                            },
                                                            preventOverflow: {
                                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                            },
                                                            hide: {
                                                                enabled: false // turn off since needs preventOverflow to be enabled
                                                            }
                                                        }}
                                                    />
                                                    <InputError show={validation.expiryDate.isInvalid} message={validation.expiryDate.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/*
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-firstZone"
                                                    >
                                                        First Zone*
                                                    </label>
                                                    <Input type="select" name="firstZone" id="firstZone" onChange={this.onChange.bind(this)}>
                                                        <option value=''>Select First Zone</option>
                                                        {
                                                            zones.map((z, k) => {
                                                                return (
                                                                    <option selected={firstZone == z._id} value={z._id} key={k}>{z.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-secondZone"
                                                    >
                                                        Second Zone*
                                                    </label>
                                                    <Input type="select" name="secondZone" id="secondZone" onChange={this.onChange.bind(this)}>
                                                        <option value=''>Select Second Zone</option>
                                                        {
                                                            zones.map((z, k) => {
                                                                return (
                                                                    <option selected={secondZone == z._id} value={z._id} key={k}>{z.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        */}
                                        <Row>
                                            <Col sm="6">
                                                <FormGroup>
                                                    <label
                                                      className="form-control-label"
                                                      htmlFor="logo"
                                                    >
                                                        Logo*
                                                    </label>
                                                    <UploadableImage
                                                      uploadable={true}
                                                      imageSource={logo}
                                                      onChange={this.handleImageChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                                <Col sm="6">
                                                    <FormGroup className="d-block flex-row px-6">
                                                        <div className="float-left">
                                                            <label className="form-control-label" htmlFor="input-isFreeRide">Is Free Service ?</label>
                                                            <br />
                                                            <Switch
                                                                checked={isFreeRide}
                                                                className="switch center"
                                                                id="isFreeRide"
                                                                name="isFreeRide"

                                                                onChange={(e) => { this.setState({ isFreeRide: e }) }}
                                                            // onChange={() => this.changeStatus(key, customer._id, customer.accountStatus)}
                                                            />
                                                        </div>
                                                        <div className="float-right">
                                                            <label className="form-control-label" htmlFor="input-isFirstRide">Is First Service ?</label>
                                                            <br />
                                                            <Switch
                                                                checked={isFirstRide}
                                                                className="switch center"
                                                                id="isFirstRide"
                                                                name="isFirstRide"
                                                                onChange={(e) => { this.setState({ isFirstRide: e }) }}
                                                            // onChange={() => this.changeStatus(key, customer._id, customer.accountStatus)}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <div className="text-right">
                                                    { !isLoading &&  
                                                        <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button" disabled={this.isNotFilled()}>
                                                            Update
                                                        </Button>
                                                    }
                                                    {
                                                        isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                                    }
                                                </div>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                
            </>
        );
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    promocodeData: (promocode) => dispatch(promocodeData(promocode))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPromocode);
