import React from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Headers/Header";
import { getLegalList, updateLegal } from "../../actions/Legal";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "react-loader-spinner";
import InputError from "../../components/Elements/InputError";
import Rules from "../../lib/Rules/FormRules";
import FormValidator from "../../lib/Rules/FormValidator";
import {
  sendPushNotification,
  sendPushNotificationWithExcel
} from "../../actions/pushNotification";
import UploadableImage from "../../components/Elements/UploadableImage/UploadableImage";

class PushNotification extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator(Rules.pushNotification);
    this.state = {
      isLoading: false,
      legals: [],
      description: "",
      type: "1",
      isVisible: false,
      id: "",
      title: "",
      body: "",
      segment: "",
      os: "",
      file: "",
      validation: this.validator.valid()
    };
  }

  componentWillMount() {
    // this.setState({isLoading:true});
    // getLegalList().then( response => {
    //     if(response.data.success){
    //         if(response.data.success.data){
    //             let responseData = response.data.success.data;
    //             this.setState({
    //                 legals:responseData,
    //                 isLoading:false
    //             });
    //         }
    //     }
    // }).catch(error=> {
    // console.log(error)
    // });
  }

  // onTypeChange(e){
  //     const {name, value} = e.target;

  //     if(value) {
  //         let { legals } = this.state;
  //         let legal = legals[value];
  //         this.setState({
  //             isVisible: true,
  //             description: legal.description,
  //             id: legal._id
  //         })
  //     } else {
  //         this.setState({
  //             isVisible: false
  //         })
  //     }
  // }

  onSubmit(e) {
    e.preventDefault();
    const { title, body, os, segment } = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      this.setState({ isLoading: true });
      sendPushNotification({
        title,
        bodyMsg: body,
        deviceType: os === "all" ? null : os,
        segment: segment === "all" ? null : segment
      })
        .then(response => {
          console.log("push notification response ", response);
          if (response.data.success.data) {
            this.setState({
              err: false,
              success: true,
              message: "Notification have been successfully sended!"
            });
            toast.success("Notification have been successfully sended");
            setTimeout(() => {
              this.setState({ isLoading: false });
            }, 3000);
          } else {
            this.setState({
              err: true,
              message: "Unable to send!",
              isLoading: false
            });
            toast.error("Unable to send!");
            setTimeout(() => {
              this.setState({ err: false, success: false, isLoading: false });
            }, 3000);
          }
        })
        .catch(err => {
          this.setState({ err: true, message: "Server Error!" });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        });
    }
  }
  onSubmit2(e) {
    e.preventDefault();
    const { file } = this.state;
    this.setState({ isLoading: true });
    let payload = new FormData();
    payload.append("file", file);
    return sendPushNotificationWithExcel(payload)
      .then(response => {
        console.log("push notification response ", response);
        if (response.data.success.data) {
          this.setState({
            err: false,
            success: true,
            message: "Notification have been successfully sended!"
          });
          toast.success("Notification have been successfully sended");
          setTimeout(() => {
            this.setState({ isLoading: false });
          }, 3000);
        } else {
          this.setState({
            err: true,
            message: "Unable to send!",
            isLoading: false
          });
          toast.error("Unable to send!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        }
      })
      .catch(err => {
        this.setState({ err: true, message: "Server Error!" });
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 3000);
      });
  }

  onChange(e) {
    const { name, value } = e.target;
    const { cities } = this.state;

    this.setState({ [name]: value });
  }
  handleImageChange = (file) => {
    this.setState({
      file: file,
      logoReady: !!file // cast to bool
    });
  };
  render() {
    const { legals, isLoading, isVisible, description, type } = this.state;
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Col sm="12" md="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                Push Notification
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                {isLoading && (
                  <span colSpan="6" style={{ textAlign: "center" }}>
                    <Loader
                      type="Circles"
                      color="#5e72e4"
                      height={40}
                      width={40}
                    />
                  </span>
                )}
                <Row className="mb-4">
                  <Col sm="3">
                    <Input
                      className="form-control-alternative"
                      id="input-type"
                      type="select"
                      name="type"
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="1">Custom</option>
                      <option value="2">Excel</option>
                    </Input>
                  </Col>
                </Row>
                {type === "1" ? (
                  <Form role="form">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="mb-0">Segment</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-segment"
                              placeholder="Enter a title"
                              type="select"
                              name="segment"
                              onChange={this.onChange.bind(this)}
                            >
                              <option value="">Select Type</option>
                              <option value="all">All</option>
                              <option value="customers">Customers</option>
                              <option value="washers">Technicians</option>
                            </Input>
                          </InputGroup>
                          <InputError
                            show={validation.segment.isInvalid}
                            message={validation.segment.message}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <label className="mb-0">Operating System</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-os"
                              placeholder="Enter a title"
                              type="select"
                              name="os"
                              onChange={this.onChange.bind(this)}
                            >
                              <option value="">Select Type</option>
                              <option value="all">All</option>
                              <option value="ios">Ios</option>
                              <option value="android">Android</option>
                            </Input>
                          </InputGroup>
                          <InputError
                            show={validation.os.isInvalid}
                            message={validation.os.message}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="mb-0">Title</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Enter a title"
                              type="textarea"
                              multiple
                              name="title"
                              onChange={this.onChange.bind(this)}
                            ></Input>
                          </InputGroup>
                          <InputError
                            show={validation.title.isInvalid}
                            message={validation.title.message}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="mb-0">Body Text</label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              className="form-control-alternative"
                              id="input-body"
                              placeholder="Enter a text"
                              type="text"
                              name="body"
                              onChange={this.onChange.bind(this)}
                            ></Input>
                          </InputGroup>
                          <InputError
                            show={validation.body.isInvalid}
                            message={validation.body.message}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <div className="text-right">
                          {!isLoading && (
                            <Button
                              className="mt-4"
                              onClick={this.onSubmit.bind(this)}
                              color="primary"
                              type="button"
                            >
                              Submit
                            </Button>
                          )}
                          {isLoading && (
                            <Loader
                              type="Circles"
                              color="#5e72e4"
                              height={30}
                              width={30}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  <Form>
                    <Row className="mb-4">
                      <Col>
                        <div>
                          <label className="mb-2">Sample Excel File</label>
                        </div>
                        <Link
                          to="/files/sampleExcel.xlsx"
                          target="_blank"
                          download
                        >
                          Download <i className="fa fa-download" />
                        </Link>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="float-left text-left">
                          <label className="mb-2">
                            Customer List{" "}
                            <label className="text-muted">
                              ( sampleExcel.xlsx )
                            </label>
                          </label>
                          <UploadableImage
                            uploadable={true}
                            onChange={this.handleImageChange}
                            accept={'.xlsx,application/vnd.ms-excel,application/vnd.openxmlformatsfficedocument.spreadsheetml,application/vnd.oasis.opendocument.spreadsheet'}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div className="text-left">
                          {!isLoading && (
                            <Button
                              className="mt-4"
                              onClick={this.onSubmit2.bind(this)}
                              color="primary"
                              type="button"
                            >
                              Submit
                            </Button>
                          )}
                          {isLoading && (
                            <Loader
                              type="Circles"
                              color="#5e72e4"
                              height={30}
                              width={30}
                            />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                )}

                {/* {isVisible && 
                                                <div className="text-center mt-3">
                                                    <Button color="primary" type="button">Submit</Button>
                                                </div>
                                            } */}
              </CardBody>
            </Card>
          </Col>
        </Container>
      </>
    );
  }
}

export default PushNotification;
