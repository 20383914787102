//Login Module Components
import Register from "./views/Register/Register";
import Login from "./views/Login/Login";
import ForgetPassword from "./views/ForgetPassword/ForgetPassword";

//Dashboard Module Components

import Dashboard from "./views/Dashboard/Dashboard";
import Customers from "./views/Customers/Customers";
import CustomerDetails from "./views/Customers/CustomerDetails";

// import EmployeesList from './views/Employees/EmployeesList';
import OrdersList from "./views/Orders/OrdersList";
import Services from "./views/Services/Services";
import Promocode from "./views/Promocode/Promocode";
import Promotions from "./views/Promotions/promotions";
// import ManagePages from './views/Pages/ManagePages';
import Cities from "./views/Cities/Cities";
import Zones from "./views/Zones/Zones";
import Admin from "./views/Admin/Admin";
import CarMakes from "./views/Cars/CarMakes";
import CarModels from "./views/Cars/CarModels";
import SubMerchant from "./views/SubMerchant/SubMerchant";
import Slots from "./views/Slots/Slots";
import SlotCalendar from './views/Slots/SlotCalendar';
import Legal from "./views/Legal/Legal";
import Washers from "./views/Washers/Washers";
import Bookings from "./views/Bookings/Bookings";
import Clients from "./views/Clients/Clients";
import PushNotification from "./views/PushNotification/PushNotification";
//Profile Module Components
import Profile from "./views/Profile/Profile";
import ViewProfile from "./views/Profile/ViewProfile";
import ChangePassword from "./views/Profile/ChangePassword";

//Page related routes
// import questionList from './views/Pages/Questions/questionList';
// import TermsOfUse from './views/Pages/TermsofUse/TermsofUse';
// import PrivacyPolicy from "./views/Pages/PrivacyPolicy/PrivacyPolicy";
// import CancellationPolicy from "./views/Pages/CancellationPolicy/CancellationPolicy";
// import AboutUs from "./views/Pages/AboutUs/AboutUs";

//User related routes
import AddAdmin from "./views/Admin/AddAdmin";
import EditAdmin from "./views/Admin/EditAdmin";
// import AddIyzico from './views/Iyzico/AddIyzico';

import EditService from "./views/Services/EditService";
import AddService from "./views/Services/AddService";
import AdditionalService from "./views/Services/AdditionalService";
import AddAdditionalService from "./views/Services/AddAdditionalService";
import EditAdditionalService from "./views/Services/EditAdditionalService";
import ServiceSolutions from "./views/Services/ServiceSolutions";
import AddPromocode from "./views/Promocode/AddPromocode";
import EditPromocode from "./views/Promocode/EditPromocode";
import AddWasher from "./views/Washers/AddWasher";
import addClient from "./views/Clients/AddClients";
import EditClients from "./views/Clients/EditClients";
import AssignWasher from "./views/Washers/AssignWasher2";
import EditWasher from "./views/Washers/EditWasher";
import EditCustomer from "./views/Customers/EditCustomer";
import AddCustomer from "./views/Customers/AddCustomer";
import WasherDocuments from "./views/Washers/WasherDocuments";
import Solutions from "./views/Solutions/Solutions";
import AddSolution from "./views/Solutions/AddSolution";
import EditSolution from "./views/Solutions/EditSolution";
import Campaigns from "./views/Campaigns/Campaigns";
import AddCampaign from "./views/Campaigns/AddCampaign";
import EditCampaign from "./views/Campaigns/EditCampaign";

//Login routes
var loginRoutes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/forgotpassword",
    name: "Forget Password",
    icon: "ni ni-circle-08 text-pink",
    component: ForgetPassword,
    layout: "/auth"
  }
];

//Dashboard routes
var dashboardRoutes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/dashboard"
  },
  {
    name: "Users",
    icon: "fa fa-users",
    isTitle: true,
    show: true
  },
  {
    path: "/admin",
    name: "Admin",
    icon: "ni ni-single-02",
    component: Admin,
    layout: "/dashboard",
    child: null,
    show: true
  },
  {
    path: "/admin-add",
    name: "Add Admin",
    id: "AddAdmin",
    component: AddAdmin,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/admin-edit",
    name: "Edit Admin",
    id: "EditAdmin",
    component: EditAdmin,
    layout: "/dashboard",
    show: false
  },
 /* {
    path: "/customers",
    name: "Customers",
    icon: "fa fa-users",
    component: Customers,
    layout: "/dashboard",
    child: null,
    show: true
  },*/
  {
    path: "/customer-edit",
    name: "Customer",
    id: "EditCustomer",
    component: EditCustomer,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/customer-add",
    name: "Add Customer",
    id: "AddCustomer",
    component: AddCustomer,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/client-edit",
    name: "Networks",
    id: "EditClient",
    component: EditClients,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/customer-details",
    name: "Customer Details",
    id: "CustomerDetails",
    component: CustomerDetails,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/clients",
    name: "Networks",
    icon: "fa fa-users",
    component: Clients,
    layout: "/dashboard",
    child: null,
    show: true
  },
  {
    path: "/washers",
    name: "Technicians",
    icon: "fa fa-users",
    component: Washers,
    layout: "/dashboard",
    child: null,
    show: true
  },
  {
    path: "/washer-documents",
    name: "Technician Documents",
    component: WasherDocuments,
    layout: "/dashboard",
    show: false
  },
  /*
  {
    path: "/documents",
    name: "Documents",
    icon: "fa fa-file-invoice",
    component: Documents,
    layout: "/dashboard",
    child: null,
    show: true
  },
  */
  /*{
    path: "/submerchant",
    name: "Submerchant",
    icon: "fa fa-handshake",
    component: SubMerchant,
    layout: "/dashboard",
    show: true
  },*/
  {
    name: "Incidents",
    // icon: "fa fa-users",
    isTitle: true,
    show: true
  },
  {
    path: "/bookings",
    name: "Incidents",
    icon: "fa fa-check",
    component: Bookings,
    layout: "/dashboard",
    child: null,
    show: true
  },
  // {
  //   name: "Vehicles",
  //   icon: "fa fa-car",
  //   isTitle:true,
  //   show:true
  // },
  // {
  //   path: "/car-makes",
  //   name: "Car Makes",
  //   icon: "fa fa-car",
  //   component: CarMakes,
  //   layout: "/dashboard",
  //   child:null
  // },
  // {
  //   path: "/car-models",
  //   name: "Car Models",
  //   icon: "fa fa-car",
  //   component: CarModels,
  //   layout: "/dashboard",
  //   child:null
  // },
  {
    name: "Areas",
    isTitle: true,
    show: true
  },
  {
    path: "/cities",
    name: "Cities",
    icon: "fa fa-map-marker-alt",
    component: Cities,
    layout: "/dashboard",
    child: null
  },
  {
    path: "/zones",
    name: "Zones",
    icon: "fa fa-map-signs",
    component: Zones,
    layout: "/dashboard",
    child: null
  },
  /*{
    name: "Services",
    isTitle: true,
    show: true
  },
  {
    path: "/services",
    name: "Service",
    icon: "ni ni-settings",
    component: Services,
    layout: "/dashboard",
    child: null
  },
  {
    path: "/service-edit",
    name: "Service",
    id: "EditService",
    component: EditService,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/service-add",
    name: "Service",
    id: "AddService",
    component: AddService,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/service-additional",
    name: "Additional Service",
    id: "AdditionalService",
    component: AdditionalService,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/service-additional-add",
    name: "Add Additional Service",
    id: "AddAdditionalService",
    component: AddAdditionalService,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/service-additional-edit",
    name: "Edit Additional Service",
    id: "EditAdditionalService",
    component: EditAdditionalService,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/service-solutions",
    name: "Service Solutions",
    id: "ServiceSolutions",
    component: ServiceSolutions,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/solutions",
    name: "Solutions",
    id: "Solutions",
    icon: "fa fa-laptop",
    component: Solutions,
    layout: "/dashboard",
    child: null
  },
  {
    path: "/solution-add",
    name: "Add Solution",
    id: "AddSolution",
    component: AddSolution,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/solution-edit",
    name: "Edit Solution",
    id: "EditSolution",
    component: EditSolution,
    layout: "/dashboard",
    show: false
  },*/
  // {
  //   path: "/iyzico-add",
  //   name: "Add Iyzico",
  //   id: "AddIyzico",
  //   component: AddIyzico,
  //   layout: "/dashboard",
  //   show:false
  // },
  {
    path: "/washer-add",
    name: "Technician",
    id: "AddWasher",
    component: AddWasher,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/client-add",
    name: "Network",
    id: "AddClient",
    component: addClient,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/washer-assign",
    name: "Technician",
    id: "AssignWasher",
    component: AssignWasher,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/washer-edit",
    name: "Technician",
    id: "EditWasher",
    component: EditWasher,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/slots",
    name: "Slots",
    icon: "fa fa-clock",
    component: Slots,
    layout: "/dashboard",
    child: null
  },
  {
    path: "/slot-calendar",
    name: "Slot Calendar",
    icon: "fa fa-calendar-alt",
    component: SlotCalendar,
    layout: "/dashboard",
    child: null
  },/*
  {
    path: "/promocode",
    name: "Promocode",
    icon: "fa fa-money-bill-alt",
    component: Promocode,
    layout: "/dashboard",
    child: null
  },
  {
    path: "/promocode-add",
    name: "Add Promocode",
    id: "AddPromocode",
    component: AddPromocode,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/promocode-edit",
    name: "Edit Promocode",
    id: "EditPromocode",
    component: EditPromocode,
    layout: "/dashboard",
    show: false
  },
  {
    name: "Others",
    isTitle: true,
    show: true
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    icon: "fa fa-gift",
    component: Campaigns,
    layout: "/dashboard",
    child: null
  },
  {
    path: "/campaign-add",
    name: "Add Campaign",
    id: "AddCampaign",
    component: AddCampaign,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/campaign-edit",
    name: "Edit Campaign",
    id: "EditCampaign",
    component: EditCampaign,
    layout: "/dashboard",
    show: false
  },
  {
    path: "/legal",
    name: "Legal",
    icon: "fa fa-file-signature",
    component: Legal,
    layout: "/dashboard",
    child: null
  },*/
  {
    path: "/PushNotification",
    name: "Push Notification",
    icon: "fa fa-file-signature",
    component: PushNotification,
    layout: "/dashboard",
    child: null
  },
  {
    path: "/profile",
    name: "Profile",
    id: "Profile",
    icon: "ni ni-single-02 text-info",
    component: Profile,
    layout: "/dashboard",
    child: null
  }
];

//Profile routes
var profileRoutes = [
  {
    path: "/user-profile",
    name: "My Profile",
    icon: "ni ni-single-02",
    component: ViewProfile,
    layout: "/profile"
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-support-16",
    component: ChangePassword,
    layout: "/profile"
  }
];

export { loginRoutes, dashboardRoutes, profileRoutes };
// export {loginRoutes, dashboardRoutes, profileRoutes, pageRoutes};
