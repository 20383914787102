import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const getSlots = (params) => {
    const api_url = 'slots';  
    return getRequestWithToken(api_url,params);
};

export const getSlotsByDay = (params) => {
    const api_url = `slots/day/${params.day}`;  
    return getRequestWithToken(api_url,params);
};

export const updateSlot = (params) => {

    const api_url = `slots/${params._id}`;

    return putRequest(api_url,params);
  
};