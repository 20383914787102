import { SERVICE_SUCCESS } from '../Action/Service';

const initialState = {
    isLoading: false,
}

const Service = (state = initialState, action) =>{
    
    if(state === null || state === undefined || action === null){
        return { ...state }
    }

    switch(action.type){
        case SERVICE_SUCCESS :
        const serviceData = action.service;
            return {
                ...state,
                serviceData: {
                    ...(state.serviceData || {}),
                    ...serviceData
                }
            };
        default:
            return state;
    }

}

export default Service;
