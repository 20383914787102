import { PROMOCODE_SUCCESS } from '../Action/Promocode';

const initialState = {
  isLoading: false,
}

const Promocode = (state = initialState, action) =>{

  if(state === null || state === undefined || action === null){
    return { ...state }
  }

  switch(action.type){
    case PROMOCODE_SUCCESS :
      const promocodeData = action.promocode;
      return {
        ...state,
        promocodeData: {
          ...(state.promocodeData || {}),
          ...promocodeData
        }
      };
    default:
      return state;
  }

}

export default Promocode;
