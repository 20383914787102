import { SOLUTION_SUCCESS } from '../Action/Solution';

const initialState = {
  isLoading: false,
}

const SolutionReducer = (state = initialState, action) =>{

  if(state === null || state === undefined || action === null){
    return { ...state }
  }

  switch(action.type){
    case SOLUTION_SUCCESS :
      const solutionData = action.solution;
      return {
        ...state,
        solutionData: {
          ...(state.solutionData || {}),
          ...solutionData
        }
      };
    default:
      return state;
  }

}

export default SolutionReducer;
