import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const getZones = (params) => {
    const api_url = 'zones';  
    return getRequestWithToken(api_url,params);
};

export const createZone = (params) => {

    const api_url = `zones`;

    return postRequest(api_url,params);
  
};

export const getNonOperablePositionList = (params) => {
  const api_url = 'zones/heatmap/nonoperable';

  return getRequestWithToken(api_url, params)
};

export const getNonOperablePositionReport = (params) => {
  const api_url = 'zones/export/nonoperable';

  return getRequestWithToken(api_url, params);
};
