import React, { Component } from "react";
import PropTypes from "prop-types";
import cache from "./ApiComponents/ScriptCache";
import GoogleApi from "./ApiComponents/GoogleApi";
import GoogleApiComponent from "./ApiComponents/GoogleApiComponent";
import Map from "./Map";
// import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

class GoogleMapDrawFilter extends React.Component {
  render() {
    let theme = createMuiTheme();
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Map
            google={this.props.google}
            heatMap={this.props.heatMap}
            drawMode={this.props.drawMode}
            markers={this.props.markers}
            mapConfig={this.props.mapConfig}
            mapStyle={this.props.mapStyle}
            polygonOptions={this.props.polygonOptions}
            handleReturnedMarkers={this.props.handleReturnedMarkers}
            handleReturnCoords={this.props.handleReturnCoords}
            onMarkerClick={this.props.onMarkerClick}
            insertMarker={this.props.insertMarker}
            apiKey={this.props.apiKey}
            zones={this.props.zones}
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

GoogleMapDrawFilter.propTypes = {
  apiKey: PropTypes.string.isRequired,
  drawMode: PropTypes.bool,
  heatMap: PropTypes.bool,
  markers: PropTypes.array,
  mapConfig: PropTypes.object,
  polygonOptions: PropTypes.object,
  google: PropTypes.object, //is provided by wrapper
  mapStyle: PropTypes.object,
  handleReturnedMarkers: PropTypes.func,
  handleReturnCoords: PropTypes.func,
  onMarkerClick: PropTypes.func,
  insertMarker: PropTypes.bool,
  zones: PropTypes.array
};

GoogleMapDrawFilter.defaultProps = {
  drawMode: true,
  insertMarker: false,
  mapConfig: {
    zoom: 11,
    lat: 41.01384,
    lng: 28.94966
  },
  mapStyle: {
    height: "600px",
    width: "100%"
  },
  polygonOptions: {
    fillColor: "#455A64",
    fillOpacity: 0.3,
    strokeColor: "#455A64",
    strokeWeight: 3,
    clickable: true,
    editable: true,
    zIndex: 1
  },
  markers: []
};

export default GoogleApiComponent(GoogleMapDrawFilter);
