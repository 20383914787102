import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const filterAdmins = (filter) => {

    let query = '';

    Object.entries(filter).forEach(
        ([key, value]) => query += `${key}=${value}&&`
    );

    const api_url = `admins?${query}`;

    return getRequestWithToken(api_url, {});

};

export const updateAdmin = (params) => {

    const api_url = `admins/${params._id}`;

    return putRequest(api_url, params);

};

export const createAdmin = (params) => {

    const api_url = `admins`;

    return postRequest(api_url, params);

};

export const getStatistics = () => {
    const api_url = `admins/dashboard`;

    return getRequestWithToken(api_url);
};

export const pushFCM = (params) => {
    const api_url = `admins/fcm`
    return postRequest(api_url, params);
}