import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const getWashers = params => {
  const api_url = `washers`;
  return getRequestWithToken(api_url, params);
};

export const filterWashers = filter => {
  console.log(filter);
  let query = "";

  Object.entries(filter).forEach(
    ([key, value]) => (query += `${key}=${value}&&`)
  );

  const api_url = `washers?${query}`;
  return getRequestWithToken(api_url, filter);
};

export const createWasher = params => {
  const api_url = `washers`;
  return postRequest(api_url, params);
};

export const updateWasher = params => {
  const api_url = `washers/${params._id}`;
  return putRequest(api_url, params);
};

export const activateWasher = params => {
  const api_url = `washers/activate/${params._id}`;
  return putRequest(api_url, params);
};

export const assignServices = params => {
  const api_url = `washers/assign/services/${params._id}`;

  return putRequest(api_url, params);
};

export const assignSlots = params => {
  const api_url = `washers/assign/slots/${params._id}`;

  return putRequest(api_url, params);
};

export const assignZones = params => {
  const api_url = `washers/assign/regions/${params._id}`;

  return putRequest(api_url, params);
};
export const getLocations = () => {
  const api_url = "washers/locations";
  return getRequestWithToken(api_url);
};

export const getAssignSlots = params => {
  const api_url = `washers/slots/${params._id}`;
  return putRequest(api_url, params);
};

export const filterWashersForZone = filter => {
  let query = "";

  Object.entries(filter).forEach(
    ([key, value]) => (query += `${key}=${value}&&`)
  );

  const api_url = `washers/zones?${query}`;
  return getRequestWithToken(api_url, filter);
};

export const getWashersReport = params => {
  let api_url = "washers/reports/export";
  if (params.date) api_url = `washers/reports/export/${params.date}`;
  else api_url = "washers/reports/export/";

  return getRequestWithToken(api_url, params);
};

export const getEligibleWashersForBooking = params => {
  const api_url = `washers/eligibles/${params.washerId}/${params.bookingDateTime}`;

  return getRequestWithToken(api_url);
};

export const filterWashersListWithSlot = filter => {
  let query = "";

  Object.entries(filter || {}).forEach(
    ([key, value]) => (query += `${key}=${value}&&`)
  );

  const api_url = `washers/listWithSlot?${query}`;
  return getRequestWithToken(api_url, filter);
};
