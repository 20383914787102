import React from "react";
import { toast } from "react-toastify";

class UserHeader extends React.Component {
  render() {
    return (
      <>
        <div
          className="header bg-gradient-info pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        >
        </div>
      </>
    );
  }
}

export default UserHeader;
