import { ADDITIONAL_SERVICE_SUCCESS } from '../Action/AdditionalService';

const initialState = {
    isLoading: false,
}

const AdditionalService = (state = initialState, action) =>{
    
    if(state === null || state === undefined || action === null){
        return { ...state }
    }

    switch(action.type){
        case ADDITIONAL_SERVICE_SUCCESS :
        const additionalServiceData = action.service;
            return {
                ...state,
                additionalServiceData: {
                    ...(state.additionalServiceData || {}),
                    ...additionalServiceData
                }
            };
        default:
            return state;
    }

}

export default AdditionalService;
