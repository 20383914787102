import React from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getStatistics } from '../../actions/Admins';
import Loader from 'react-loader-spinner';
import BookingDetails from '../../views/Bookings/BookingDetails';
import BookingsList from '../../views/Bookings/BookingsList';

class DashboardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      todayCustomers: '',
      todayBookings: '',
      todayEmptySlots: '',
      todayEarning: '',
      bookingsListModal: false,
      bookingsListStatusFilter: ''
    }
  }

  componentWillMount() {
    getStatistics().then(response => {
      if (response.data.success.data) {
        const statisticsData = response.data.success.data;
        this.setState({
          todayCustomers: statisticsData.todayCustomers,
          todayBookings: statisticsData.todayBookings,
          todayEmptySlot: statisticsData.todayEmptySlot,
          todayEarning: statisticsData.todayEarning,
          isLoading: false
        });
      } else {
        throw new Error(JSON.stringify(response.data));
      }
    }).catch(error => {
      console.log('error when loading statistics');
    })
  }

  goToCustomers = () => {
    this.props.history.push("/dashboard/customers");
  };

  goToSlotCalendar = () => {
    this.props.history.push("/dashboard/slot-calendar");
  };

  openBookingsListModal = (statusFilter) => {
    this.setState(
      {
        bookingsListModal: true,
        bookingsListStatusFilter: statusFilter
      }
    );
  };

  closeBookingsListModal = () => {
    this.setState({
      bookingsListModal: false,
      bookingsListStatusFilter: ''
    });
  };

  renderBookingsListModal = () => {
    const {
      bookingsListModal,
      bookingsListStatusFilter
    } = this.state;
    return (
      <Modal
        size="xl"
        isOpen={bookingsListModal}
        toggle={this.closeBookingsListModal}
      >
        <ModalHeader toggle={this.closeBookingDetailsModal}>
          <span style={{ fontSize: 20 }}>Today's New Incidents</span>
        </ModalHeader>
        <ModalBody>
          <BookingsList statusFilter={bookingsListStatusFilter} />
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { isLoading, todayCustomers, todayBookings, todayEmptySlot, todayEarning } = this.state;
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Today's Incidents!
                          </CardTitle>
                          { isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} /> }
                          { !isLoading && <span className="h2 font-weight-bold mb-0">{ todayBookings}</span> }
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar btn p-3" onClick={() => this.openBookingsListModal()}/>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                           Today's Technician Empty Slots
                          </CardTitle>
                          { isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} /> }
                          { !isLoading && <span className="h2 font-weight-bold mb-0">{ todayEmptySlot}</span> }
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie btn p-3" onClick={this.goToSlotCalendar}/>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        { this.renderBookingsListModal() }
      </>
    );
  }
}

export default withRouter(DashboardHeader);
