import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const getUser = (params) => {
    const api_url = 'admins/profile';  
    return getRequestWithToken(api_url,params);
};

export const updateUser = (params) => {
    const api_url = `admins/profile`;
    return putRequest(api_url,params);
};

export const updatePicture = (params) => {
    const api_url = `admins/picture`;
    return postRequest(api_url,params);
};
