import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,  
    Label
} from "reactstrap";
import Switch from "react-switch";
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
import {getCarMakes, createCarMake} from "../../actions/CarMakes";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";

class Carmakes extends React.Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator(Rules.addCarMake);

        this.state = { 
            checked: false ,
            carModal: false,
            vehicleId:'',
            make:'',
            validation: this.validator.valid(),
            
        };
        this.handleChange = this.handleChange.bind(this);
    }

    addCar(){
        this.setState(prevState => ({
            carModal: !prevState.carModal
        }));
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleChange(checked) {
        this.setState({ checked });
    }

    onSubmit = (e) => {

        e.preventDefault();
        const {vehicleId, make} = this.state;

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            this.setState({ isLoading:true });
            createCarMake({
                vehicleId, make,
            }).then(response => {
                console.log(response);
                if(response.data.success.data){
                    this.setState({err:false,success:true,message:'Car details have been successfully added!'}); toast.success('Car details have been successfully added!');
                    this.componentWillMount();
                    this.addCar();
                }else{
                    this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});
        getCarMakes().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    
                    this.setState({
                        carMakes:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    render(){
        const { carModal, carMakes, isLoading, vehicleId, make } = this.state;
        let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={4}>
                                        </Col>
                                        <Col sm={8}>
                                            <Button  onClick={(e) => this.addCar()} className="pull-right" color="primary" style={{float:'right'}} size="sm">Add Car</Button>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">Vehicle ID</th>
                                            <th scope="col">Car Make</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                        !isLoading && carMakes == null && <tr>
                                            <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                        </tr>
                                        }

                                        { 
                                            !isLoading && carMakes != null &&
                                            carMakes.map((carMake,key) => {
                                                return (
                                                    <tr key={key}>
                                                        <th>{key+1}</th>
                                                        <td>{carMake.id}</td>
                                                        <td>{carMake.name}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>

                <Modal size="lg" isOpen={carModal} toggle={(e) => this.addCar()} className={this.props.className}>
                    <ModalHeader toggle={(e) => this.addCar()}><span style={{fontSize: 20}}> Add Car </span></ModalHeader>
                    <ModalBody>
                        <Form role="form">
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="price">Vehicle Id</Label>
                                        <Input type="text" name="vehicleId" id="vehicleId" placeholder="Vehicle Id"  onChange={this.onChange.bind(this)} />
                                        <InputError show={validation.vehicleId.isInvalid} message={validation.vehicleId.message} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="price">Make</Label>
                                        <Input type="text" name="make" id="make" placeholder="Make"  onChange={this.onChange.bind(this)} />
                                        <InputError show={validation.make.isInvalid} message={validation.make.message} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="text-right" onClick={this.onSubmit.bind(this)}>
                            { !isLoading &&  
                                <Button color="primary" type="button">Save Car Make</Button>
                            }
                            {
                                isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                            }
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

export default Carmakes;
