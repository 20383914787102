import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import _ from 'lodash';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from "reactstrap";
import Loader from 'react-loader-spinner';
import { filterDocuments, updateDocument } from "../../actions/Documents";
import Header from "../../components/Headers/Header";
import { documentData } from "../../lib/Action/Document";
import Switch from 'react-ios-switch';

class WatcherDocuments extends React.Component {
  limit = 10;
  constructor(props) {
    super(props);
    this.state = {
      isLoading:true,
      documents: [],
      totalDocuments: '',
      pages:1,
      current_page:1,
      keyword:'',
      sortBy:'title',
      dir:1
    }
  }

  componentWillMount(){
    this.setState({isLoading:true});
    filterDocuments(this.props.WasherReducer.washerData._id, this.filterQuery()).then( response => {
      if(response.data.success){
        if(response.data.success.data){
          let responseData = response.data.success.data;
          let totalDocuments = response.data.success.data.length;
          let pages  = Math.ceil(totalDocuments/this.limit );
          this.setState({
            documents:responseData,
            isLoading:false,
            pages,
            totalDocuments: totalDocuments
          });
        }
      }
    }).catch(error=> {
      console.log(error)
    });
  }

  /*
  fname, lname, phone number, expireDate, imageUrl, title, status
  */

  filterQuery(){
    const  {sortBy, keyword, dir, current_page, pages }  = this.state;

    let query = {}

    if(sortBy !== ''){
      query.sortBy = sortBy;
    }

    if(keyword !== ''){
      query.keyword = keyword;
    }

    if(dir !== ''){
      query.dir = dir;
    }

    query.start = (current_page - 1)*this.limit;
    query.limit = this.limit;

    return query;
  }

  goToPage(page){
    this.setState({current_page:page}, () =>{
      this.getDocuments(false);
    });
  }

  getDocuments(page = true){
    this.setState({isLoading:true});
    const query = this.filterQuery();
    const { _id: washerId } = this.props.WasherReducer.washerData;
    filterDocuments(washerId, query).then( response => {
      let totalDocuments = response.data.totalDocuments;
      let pages  = Math.ceil(totalDocuments/50 );
      console.log(response);
      if(page){
        this.setState({
          documents:response.data.success.data,
          pages,
          isLoading:false
        });
      }else{
        this.setState({
          documents:response.data.success.data,
          isLoading:false
        });
      }
    }).catch(error=> {
      console.log(error)
    });
  }

  sort(by,dir){
    this.setState({sortBy:by,dir}, () =>{
      this.getDocuments();
    });
  }

  onChange = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value},() => {
      this.getDocuments();
    });
  }

  clearFilter(){
    this.setState({keyword:''},() => {
      this.getDocuments();
    });
  }
/*
  goToEdit(washer){
    this.props.documentData(washer);
    this.props.history.push("/dashboard/document-edit");
  }
*/
  changeStatus(key,id,status){
    let  { documents } = this.state;
    documents[key].accountStatus = !status;

    this.setState({
      documents,
    });

    updateDocument({
      _id:id,
      accountStatus:!status
    }).then(response => {
      // this.componentWillMount();
    }).catch(error=> {
      this.setState({err:true,message:'Server Error!'});
    });

  }

  render(){

    const {documents, isLoading, totalDocuments, pages, current_page, keyword, dir, sortBy} = this.state;

    const { fname, lname, loginPhoneNo } = this.props.WasherReducer.washerData;

    return(
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                  {/*
                    <Col sm={4}>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="form-control-alternative"
                          value={keyword}
                          id="input-keyword"
                          placeholder="Search"
                          type="text"
                          name="keyword"
                          onChange={this.onChange.bind(this)}
                        />
                        {
                          keyword &&
                          <InputGroupAddon addonType="append">
                            <a onClick={this.clearFilter.bind(this)}>
                              <InputGroupText  style={{height:'100%'}}><i className='fas fa-times-circle'></i></InputGroupText>
                            </a>
                          </InputGroupAddon>
                        }
                      </InputGroup>
                    </Col>
                    */}
                    <Col sm={4}>
                      { fname } { lname }
                    </Col>
                    <Col sm={4}>
                      { loginPhoneNo }
                    </Col>
                    <Col sm={4}>
                      <Link to="/dashboard/washers">
                        <Button className="pull-right" color="primary" style={{float:'right'}} size="sm">
                          Back to Technicians
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                {/* <CardBody>
                                        <Row>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Document Number" />
                                            </div>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Name" />
                                            </div>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Surname" />
                                            </div>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Phone Number" />
                                            </div>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Email" />
                                            </div>
                                            <div className="col-2" style={{textAlign: 'center'}}>
                                                <Button color="primary">Search</Button>
                                            </div>
                                        </Row>
                                    </CardBody> */}
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr className="row m-0">
                    {/* <th className="col">S. No.</th> */}
                    {/*<th className="col" onClick={this.sort.bind(this,'fname',`${dir === 1? -1:1}`)}>First name<i className={sortBy === 'fname'? `fas ${dir === 1 ?'fa-sort-up':'fa-sort-down'} mr-4`:'' }></i></th>*/}
                    {/*<th className="col" onClick={this.sort.bind(this,'lname',`${dir === 1? -1:1}`)}>Last name<i className={sortBy === 'lname'? `fas ${dir === 1 ?'fa-sort-up':'fa-sort-down'} mr-4`:'' }></i></th>*/}
                    {/*<th className="col" onClick={this.sort.bind(this,'loginPhoneNo',`${dir === 1? -1:1}`)}>Phone No.<i className={sortBy === 'loginPhoneNo'? `fas ${dir === 1 ?'fa-sort-up':'fa-sort-down'} mr-4`:'' }></i></th>*/}
                    <th className="col" onClick={this.sort.bind(this,'title',`${dir === 1? -1:1}`)}>Title<i className={sortBy === 'title'? `fas ${dir === 1 ?'fa-sort-up':'fa-sort-down'} mr-4`:'' }></i></th>
                    {/*<th className="col">Status</th>*/}
                    <th className="col">Action</th>
                  </tr>
                  </thead>
                  <tbody>

                  {
                    isLoading && <tr>
                      <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                    </tr>
                  }

                  {
                    !isLoading && documents == null && <tr>
                      <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                    </tr>
                  }

                  {
                    !isLoading && totalDocuments > 0 &&
                    documents.map((document, key) => {
                      return(
                        <tr className="row m-0" key={key}>
                          {/* <th className="col">{key + 1}</th> */}
                          {/*<td className="col">{document.userId.fname}</td>*/}
                          {/*<td className="col">{document.userId.lname}</td>*/}
                          {/*<td className="col">{document.userId.loginPhoneNo}</td>*/}
                          <td className="col">{document.title}</td>
                          {/*<td className="col">
                            <Switch
                              checked={document.status}
                              className="switch"
                              onChange={() => this.changeStatus(key,document._id,document.status)}
                            />
                          </td>*/}
                          <td className="col">
                            {/* <i style={{marginRight: 15}} onClick={() => this.goToEdit(document)} className="fa fa-edit btn p-1"></i> */}
                            <a href={document.fileUrl} target="_blank" rel="noreferrer noopener">
                              <i style={{marginRight: 15}} className="fa fa-eye"></i>
                            </a>
                          </td>
                        </tr>
                      )
                    })
                  }

                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {
                        pages > 1 &&
                        _.times(pages, i => {
                          return (
                            <PaginationItem key={i} className={current_page === i+1 ?'active':''}>
                              <PaginationLink
                                href="#"
                                onClick={this.goToPage.bind(this,i+1)}
                              >
                                {i+1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        })
                      }
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

// export default WatcherDocuments;
const mapStateToProps = (state) =>({
  ...state
})

const mapDispatchToProps = (dispatch) =>({
  documentData: (document) => dispatch(documentData(document))
})

export default connect(mapStateToProps, mapDispatchToProps)(WatcherDocuments);
