import {
  getRequestWithToken,
  putRequest,
  postRequest,
  putMobileRequest
} from "../Constant/Api";

export const filterCustomers = filter => {
  console.log(filter);
  let query = "";

  Object.entries(filter).forEach(
    ([key, value]) => (query += `${key}=${value}&&`)
  );

  const api_url = `customers?${query}`;

  return getRequestWithToken(api_url, filter);
};

export const createCustomer = params => {
  const api_url = `customers`;
  return postRequest(api_url, params);
};

export const updateCustomer = params => {
  const api_url = `customers/${params._id}`;
  return putRequest(api_url, params);
};

export const getCustomerDetails = params => {
  const api_url = `customers/${params._id}`;

  return getRequestWithToken(api_url);
};

export const updateAddress = params => {
  const api_url = `customers/address`;

  return putMobileRequest(api_url, params);
};

export const getCustomersReport = params => {
  let api_url = "";
  if (params.date) api_url = `customers/reports/export/${params.date}`;
  else api_url = "customers/reports/export/";
  // const api_url = "customers/reports/export";

  return getRequestWithToken(api_url, params);
};
