import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from '../../components/Headers/Header';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupButtonDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupText,
  InputGroupAddon,
  Container
} from 'reactstrap';

import FormValidator from '../../lib/Rules/FormValidator';
import Rules from '../../lib/Rules/FormRules';
import InputError from '../../components/Elements/InputError';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createCampaign, updateCampaignImage } from '../../actions/Campaigns';
import { getCities } from '../../actions/Cities';
import { getZones } from '../../actions/Zones';
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';
import Switch from 'react-ios-switch';

class AddCampaign extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator(Rules.addCampaign);

    this.state = {
      isLoading: false,
      title: '',
      description: '',
      showMode: '',
      userType: '',
      selectDate: new Date(),
      expiryDate: '',
      validation: this.validator.valid(),
      image: null,
      imageReady: false,
      isSystemMsg: false,
      isUpdateNeeded: false
    };
  }

  onSubmit = e => {
    e.preventDefault();
    const {
      title,
      description,
      showMode,
      userType,
      expiryDate,
      isUpdateNeeded,
      isSystemMsg
    } = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.setState({ isLoading: true });

      const campaignQuery = {
        title,
        description,
        showMode,
        userType,
        expiryDate,
        isSystemMsg,
        isUpdateNeeded
      };

      createCampaign(campaignQuery)
        .then(response => {
          if (response.data.success.data) {
            this.uploadImagePhoto(response.data.success.data);
          } else {
            this.setState({
              err: true,
              message: 'Unable to add!',
              isLoading: false
            });
            toast.error('Unable to add!');
            setTimeout(() => {
              this.setState({ err: false, success: false, isLoading: false });
            }, 3000);
          }
        })
        .catch(error => {
          this.setState({ err: true, message: 'Server Error!' });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        });
    }
  };

  uploadImagePhoto = campaignData => {
    const { image } = this.state;
    let payload = new FormData();
    payload._id = campaignData._id;
    payload.append('picture', image);
    updateCampaignImage(payload)
      .then(response => {
        if (response.data.success.data) {
          this.setState({
            err: false,
            success: true,
            message: 'Campaign have been successfully added!'
          });
          toast.success('Campaign have been successfully added!');
          setTimeout(() => {
            this.setState({ isLoading: false });
            this.props.history.push('/dashboard/campaigns');
          }, 3000);
        } else {
          this.setState({
            err: true,
            message:
              'Successfully added but failed to upload image! Please edit its image!'
          });
          toast.warn(
            'Successfully added but failed to upload image! Please edit its image!'
          );
          setTimeout(() => {
            this.setState({ err: false, isLoading: false });
            this.props.history.push('/dashboard/campaigns');
          }, 3000);
        }
      })
      .catch(error => {
        this.setState({
          err: true,
          message:
            'Successfully added but server error when uploading image! Please edit its image!'
        });
        setTimeout(() => {
          this.setState({ err: false, isLoading: false });
          this.props.history.push('/dashboard/campaigns');
        }, 3000);
      });
  };

  handleImageChange = imageFile => {
    this.setState({
      image: imageFile,
      imageReady: !!imageFile // cast to bool
    });
  };

  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value || '' });
  };

  selectDateChange = date => {
    if (!date) {
      return;
    }
    this.setState(
      {
        selectDate: date
      },
      () => {
        const selectedDate = this.state.selectDate;
        var month = selectedDate.getMonth() + 1;
        var day = selectedDate.getDate();
        var year = selectedDate.getFullYear();
        this.setState({
          expiryDate: year + '-' + month + '-' + day
        });
      }
    );
  };

  isNotFilled() {
    const {
      title,
      description,
      showMode,
      userType,
      expiryDate,
      imageReady
    } = this.state;
    return (
      title === '' ||
      description === '' ||
      showMode === '' ||
      userType === '' ||
      expiryDate === '' ||
      imageReady === false
    );
  }

  expireDateHTML = () => {
    return (
      <Col md="6" sm="12">
        <FormGroup style={{ display: 'grid' }} className="datePickerDiv">
          <label className="form-control-label" htmlFor="input-selectDate">
            {' '}
            Expiry Date*{' '}
          </label>
          <DatePicker
            selected={this.state.selectDate}
            onChange={this.selectDateChange}
            dateFormat="yyyy-MM-dd"
            className="form-control"
            popperPlacement="bottom"
            minDate={new Date()}
            popperModifiers={{
              flip: {
                behavior: ['bottom'] // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
          />
        </FormGroup>
      </Col>
    );
  };

  toggleIsAbsoluteDropdown() {
    this.setState(prevState => ({
      isAbsoluteDropdownOpen: !prevState.isAbsoluteDropdownOpen
    }));
  }
  setIsAbsolute(isAbsolute) {
    this.setState({ isAbsolute });
  }

  render() {
    let { isLoading, isSystemMsg, isUpdateNeeded } = this.state;
    const validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
          <Row>
            <Col sm="12" md="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Add Campaign</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link to="/dashboard/campaigns">
                        <Button color="primary" size="sm">
                          Back to Campaigns
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" method="POST">
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-title"
                            >
                              Title*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Enter a title"
                              type="text"
                              name="title"
                              onChange={this.onChange}
                            />
                            <InputError
                              show={validation.title.isInvalid}
                              message={validation.title.message}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-description"
                            >
                              Description*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-description"
                              placeholder="Enter a Description"
                              type="textarea"
                              name="description"
                              onChange={this.onChange}
                            />
                            <InputError
                              show={validation.description.isInvalid}
                              message={validation.description.message}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="showMode"
                            >
                              Show Mode*
                            </label>
                            <Input
                              type="select"
                              name="showMode"
                              id="showMode"
                              onChange={this.onChange}
                            >
                              <option value="">Select show mode</option>
                              <option value="android">Android</option>
                              <option value="ios">iOS</option>
                            </Input>
                            <InputError
                              show={validation.showMode.isInvalid}
                              message={validation.showMode.message}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="userType"
                            >
                              User Type*
                            </label>
                            <Input
                              type="select"
                              name="userType"
                              id="userType"
                              onChange={this.onChange}
                            >
                              <option value="">Select user type</option>
                              <option value="customer">Customer</option>
                              <option value="washer">Washer</option>
                            </Input>
                            <InputError
                              show={validation.userType.isInvalid}
                              message={validation.userType.message}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {this.expireDateHTML()}
                        {/* <Col sm="3">
                          <div className="px-1 my-2 text-center">
                            <label
                              className="form-control-label"
                              htmlFor="input-isSystemMsg"
                            >
                              Is System Message ?
                            </label>
                            <br />
                            <Switch
                              checked={isSystemMsg}
                              className="switch"
                              id="isSystemMsg"
                              name="isSystemMsg"
                              onChange={e => {
                                this.setState({ isSystemMsg: e });
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="px-1 my-1 text-center">
                            <label
                              className="form-control-label"
                              htmlFor="input-isUpdateNeeded"
                            >
                              Is Update Needed ?
                            </label>
                            <br />
                            <Switch
                              checked={isUpdateNeeded}
                              className="switch"
                              id="isUpdateNeeded"
                              name="isUpdateNeeded"
                              onChange={e => {
                                this.setState({ isUpdateNeeded: e });
                              }}
                            />
                          </div>
                        </Col> */}
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="image"
                            >
                              Image*
                            </label>
                            <UploadableImage
                              uploadable={true}
                              onChange={this.handleImageChange}
                            />
                            <InputError
                              show={validation.imageReady.isInvalid}
                              message={validation.imageReady.message}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <div className="text-right">
                            {!isLoading && (
                              <Button
                                className="mt-4"
                                onClick={this.onSubmit}
                                color="primary"
                                type="button"
                                disabled={this.isNotFilled()}
                              >
                                Submit
                              </Button>
                            )}
                            {isLoading && (
                              <Loader
                                type="Circles"
                                color="#5e72e4"
                                height={30}
                                width={30}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AddCampaign;
