import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const filterDocuments = (washerId, filter) => {
  console.log(filter);
  let query = '';

  Object.entries(filter).forEach(
    ([key, value]) => query += `${key}=${value}&&`
  );

  const api_url = `documents/${washerId}/document?${query}`

  return getRequestWithToken(api_url,filter);
};

/*
export const createDocument = (params) => {
  const api_url = `documents`;
  return postRequest(api_url,params);
};
*/

export const updateDocument = (params) => {
  const api_url = `documents/${params._id}`;
  return putRequest(api_url,params);
};
