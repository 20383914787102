import React from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupButtonDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createPromocode } from "../../actions/Promocode";
import { getCities } from "../../actions/Cities";
import { getZones } from "../../actions/Zones";
import { updatePromocodeLogo } from '../../actions/Promocode';
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';
import Switch from 'react-ios-switch';

class AddPromocode extends React.Component {
    constructor(props){
        super(props);   

        this.validatorBase = new FormValidator(Rules.addPromocodeBase);
        this.validatorManual = new FormValidator(Rules.addPromocodeManual);
        this.validatorAuto = new FormValidator(Rules.addPromocodeAuto);
        
        this.state = {
            isLoading: false,
            cities: [],
            zones: [],
            title : '',
            rideType : '',
            description : '',
            amount : '',
            isAbsolute: false,
            code : '',
            codePrefix: '',
            lengthOfCoupon: '',
            numberOfCoupons: '',
            cityId : '',
            usageLimit : '',
            selectDate: new Date(),
            expiryDate: '',
            err:false,
            success:false,
            validationBase: this.validatorBase.valid(),
            validationManual: this.validatorManual.valid(),
            validationAuto: this.validatorAuto.valid(),
            activeTab: 1,
            logo: null,
            logoReady: false,
            isFreeRide:false,
            isFirstRide:false
        }
        this.selectDateChange = this.selectDateChange.bind(this);
        this.toggleTab = this.toggleTab.bind(this);
        this.toggleIsAbsoluteDropdown = this.toggleIsAbsoluteDropdown.bind(this);
        this.setIsAbsolute = this.setIsAbsolute.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.uploadLogoPhoto = this.uploadLogoPhoto.bind(this);
    }
    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {title, rideType, amount, isAbsolute, code, codePrefix, lengthOfCoupon, numberOfCoupons, cityId, usageLimit, description, expiryDate,
            activeTab,isFirstRide,isFreeRide} = this.state;
        
        const validationBase = this.validatorBase.validate(this.state);
        const validationManual = this.validatorManual.validate(this.state);
        const validationAuto = this.validatorAuto.validate(this.state);
        this.setState({ validationBase, validationManual, validationAuto });
        this.submitted = true;

        const isValidationValid = validationBase.isValid && (activeTab === 1 ? validationManual : validationAuto).isValid;

        if (isValidationValid) {
            this.setState({ isLoading:true });

            const promoCodeQuery = {
                title, rideType, amount, isAbsolute, cityId, usageLimit, desc: description, expiryDate,isFreeRide,isFirstRide
            };
            if (activeTab === 1) {
                promoCodeQuery.code = code;
            } else {
                promoCodeQuery.auto = {
                    codePrefix,
                    lengthOfCoupon,
                    numberOfCoupons
                };
            }

            createPromocode(promoCodeQuery).then(response => {
                if(response.data.success.data){
                    this.uploadLogoPhoto(response.data.success.data);
                }else{
                    this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
    }

    uploadLogoPhoto(promocodeData) {
        if (!Array.isArray(promocodeData)) {
            promocodeData = [promocodeData];
        }
        promocodeData = promocodeData.filter(({ _id }) => _id);
        const { logo } = this.state;
        Promise.all(
            promocodeData.map(({ _id }) => {
                let payload = new FormData();
                payload._id = _id;
                payload.append('picture', logo);
                return updatePromocodeLogo(payload);
            })
        ).then(responses => {
           if (responses.reduce((acc, curr) => acc && curr.data.success.data, true)) {
               this.setState({err:false,success:true,message:'Promocode have been successfully added!'}); toast.success('Promocode have been successfully added!');
               setTimeout(()=>{
                   this.setState({isLoading:false});
                   this.props.history.push("/dashboard/promocode");
               },3000)
           } else{
               this.setState({err:true,message:'Successfully added but failed to upload logo! Please edit its logo!'}); toast.warn('Successfully added but failed to upload logo! Please edit its logo!');
               setTimeout(() =>{
                   this.setState({err:false,isLoading:false});
                   this.props.history.push("/dashboard/promocode");
               },3000);
           }
        }).catch(error => {
            this.setState({ err:true, message: 'Successfully added but server error when uploading logo! Please edit its logo!' });
            setTimeout(() =>{
                this.setState({err:false,isLoading:false});
                this.props.history.push("/dashboard/promocode");
            },3000);
        });
    }

    handleImageChange(imageFile) {
        this.setState({
            logo: imageFile,
            logoReady: !!imageFile // cast to bool
        });
    }

    onChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value || ''});
    } 

    selectDateChange(date) { if (!date) { return; }
        this.setState({
            selectDate: date
        },()=> {
            const selectedDate = this.state.selectDate;
            var month = (selectedDate .getMonth() + 1);
            var day = (selectedDate .getDate());
            var year = (selectedDate .getFullYear());
            this.setState({
                expiryDate: year+'-'+month+'-'+day
            })
        });
        
    }

    componentWillMount(){
        this.setState({isLoading:true});

        getCities().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        cities:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });

        getZones().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        zones:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }
    isNotFilledBase() {
        const {title, rideType, amount, code, cityId, usageLimit, description, logoReady} = this.state;
        return (
            title === '' ||
            rideType === '' ||
            amount === '' ||
            cityId === '' ||
            usageLimit === '' ||
            description === '' ||
            logoReady === false
        )
    }
    isNotFilledAuto() {
        const { codePrefix, lengthOfCoupon, numberOfCoupons } = this.state;
        return (
            codePrefix === '' ||
            lengthOfCoupon === '' ||
            numberOfCoupons === ''
        );
    }
    isNotFilledManual() {
        const { code } = this.state;
        return (
            code === ''
        );
    }

    isNotFilled(){
        const { activeTab } = this.state;
        return this.isNotFilledBase() || (activeTab === 1 ? this.isNotFilledManual() : this.isNotFilledAuto());
    }

    expireDateHTML(){
        const { validationBase } = this.state;
        return(
            <Col md="6" sm="12">
                <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                    <label
                        className="form-control-label"
                        htmlFor="input-selectDate"
                    > Expiry Date* </label>
                    <DatePicker
                        selected={this.state.selectDate}
                        onChange={this.selectDateChange}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        popperPlacement="bottom"
                        minDate={new Date()}
                        popperModifiers={{
                            flip: {
                                behavior: ["bottom"] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                    />
                    <InputError show={validationBase.expiryDate.isInvalid} message={validationBase.expiryDate.message} />
                </FormGroup>
            </Col>
        )
    }

    /*
    secondZoneHTML(){
        let { zones } = this.state;
        return(
            <Col md="6" sm="12">
                <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                    <label
                        className="form-control-label"
                        htmlFor="input-secondZone"
                    > Second Zone </label>
                    <Input type="select" name="secondZone" id="secondZone" onChange={this.onChange.bind(this)}>
                        <option value=''>Select Zone</option>
                        {
                            zones.map((z, k) => {
                                return (
                                    <option value={z._id} key={k}>{z.name}</option>
                                );
                            })
                        }
                    </Input>
                </FormGroup>
            </Col>
        )
    }

    twoZoneHTML(){
        let { zones } = this.state;
        return(
            <>
                <Col md="6" sm="12">
                    <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                        <label
                            className="form-control-label"
                            htmlFor="input-firstZone"
                        > First Zone </label>
                        <Input type="select" name="firstZone" id="firstZone" onChange={this.onChange.bind(this)}>
                            <option value=''>Select First Zone</option>
                            {
                                zones.map((z, k) => {
                                    return (
                                        <option value={z._id} key={k}>{z.name}</option>
                                    );
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="6" sm="12">
                    <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                        <label
                            className="form-control-label"
                            htmlFor="input-secondZone"
                        > Second Zone </label>
                        <Input type="select" name="secondZone" id="secondZone" onChange={this.onChange.bind(this)}>
                            <option value=''>Select Second Zone</option>
                            {
                                zones.map((z, k) => {
                                    return (
                                        <option value={z._id} key={k}>{z.name}</option>
                                    );
                                })
                            }
                        </Input>
                    </FormGroup>
                </Col>
            </>
        )

    }
    */
    toggleIsAbsoluteDropdown() {
        this.setState((prevState) => ({
           isAbsoluteDropdownOpen: !prevState.isAbsoluteDropdownOpen
        }));
    }
    setIsAbsolute(isAbsolute) {
        this.setState({ isAbsolute });
    }

  render() {
    let {cities, zones, isLoading, activeTab, isAbsolute, isAbsoluteDropdownOpen,isFreeRide,isFirstRide} = this.state;
    const [validationBase, validationManual, validationAuto] = this.submitted ?
      [this.validatorBase, this.validatorManual, this.validatorAuto].map((validator) => validator.validate(this.state)) :
      [this.state.validationBase, this.state.validationManual, this.state.validationAuto];

    return (
     <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
            <Row>
                <Col sm="12" md="12">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                            <Col xs="8">
                                <h3 className="mb-0">Add Promocode</h3>
                            </Col>
                            <Col className="text-right" xs="4">
                                <Link to="/dashboard/promocode">
                                    <Button
                                        color="primary"
                                        size="sm"
                                    >
                                    Back to Promocodes
                                    </Button>
                                </Link>
                            </Col>
                            </Row>
                            <Row className="m-0">
                                <Col lg="12">
                                    <ul className="stepper stepper-horizontal">
                                        <li className={activeTab === 1? 'completed':''}>
                                            <a onClick={() => this.toggleTab(1)}>
                                                <span className="circle">
                                                    <i className="fa fa-hand-point-up" aria-hidden="true"></i>
                                                </span>
                                                <span className="label">Manual</span>
                                            </a>
                                        </li>
                                        <div className="line"></div>
                                        <li className={activeTab === 2? 'completed':''}>
                                            <a onClick={() => this.toggleTab(2)}>
                                                <span className="circle">
                                                    <i className="fa fa-cogs" aria-hidden="true"></i>
                                                </span>
                                                <span className="label">Auto</span>
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Form role="form" method="POST">
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-title"
                                                >
                                                    Title*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-title"
                                                    placeholder="Enter a title"
                                                    type="text"
                                                    name="title"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validationBase.title.isInvalid} message={validationBase.title.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-rideType"
                                                >
                                                    Select Ride Type*
                                                </label>
                                                <Input type="select" name="rideType" id="rideType" onChange={this.onChange.bind(this)}>
                                                    <option value="">Select Type</option>
                                                    <option value="PRIMARY">PRIMARY</option>
                                                    <option value="SECONDARY">SECONDARY</option>
                                                    <option value="BOTH">BOTH</option>
                                                </Input>
                                                <InputError show={validationBase.rideType.isInvalid} message={validationBase.rideType.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-amount"
                                                >
                                                    Amount*
                                                </label>
                                                <InputGroup>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-amount"
                                                        placeholder="Enter a Amount"
                                                        type="text"
                                                        name="amount"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                    <InputGroupButtonDropdown
                                                      addonType={"append"}
                                                      isOpen={isAbsoluteDropdownOpen}
                                                      toggle={this.toggleIsAbsoluteDropdown}
                                                    >
                                                        <DropdownToggle
                                                          caret
                                                          aria-expanded={isAbsoluteDropdownOpen}
                                                        >
                                                            { isAbsolute ? 'Raw Amount' : 'Percentage' }
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem
                                                              onClick={() => this.setIsAbsolute(true)}
                                                            >
                                                                Raw Amount
                                                            </DropdownItem>
                                                            <DropdownItem
                                                              onClick={() => this.setIsAbsolute(false)}
                                                            >
                                                                Percentage
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </InputGroupButtonDropdown>
                                                </InputGroup>
                                                <InputError show={validationBase.amount.isInvalid} message={validationBase.amount.message} />
                                            </FormGroup>
                                        </Col>
                                        {
                                            activeTab === 1 && <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-code"
                                                    >
                                                        Code*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-code"
                                                        placeholder="Enter a Code"
                                                        type="text"
                                                        name="code"
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                    <InputError show={validationManual.code.isInvalid} message={validationManual.code.message} />
                                                </FormGroup>
                                            </Col>
                                        }
                                        {
                                            activeTab === 2 && <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                      className="form-control-label"
                                                      htmlFor="input-code-prefix"
                                                    >
                                                        Code Prefix*
                                                    </label>
                                                    <Input
                                                      className="form-control-alternative"
                                                      id="input-code-prefix"
                                                      placeholder="Enter a Code Prefix"
                                                      type="text"
                                                      name="codePrefix"
                                                      onChange={this.onChange.bind(this)}
                                                    />
                                                    <InputError show={validationAuto.codePrefix.isInvalid} message={validationAuto.codePrefix.message} />
                                                </FormGroup>
                                            </Col>
                                        }
                                    </Row>
                                    {
                                        activeTab === 2 && <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                      className="form-control-label"
                                                      htmlFor="input-length-of-coupon"
                                                    >
                                                        Length of Coupon*
                                                    </label>
                                                    <Input
                                                      className="form-control-alternative"
                                                      id="input-length-of-coupon"
                                                      placeholder="Enter Length of Coupon"
                                                      type="text"
                                                      name="lengthOfCoupon"
                                                      onChange={this.onChange.bind(this)}
                                                    />
                                                    <InputError show={validationAuto.lengthOfCoupon.isInvalid} message={validationAuto.lengthOfCoupon.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                      className="form-control-label"
                                                      htmlFor="input-number-of-coupons"
                                                    >
                                                        Number of Coupons*
                                                    </label>
                                                    <Input
                                                      className="form-control-alternative"
                                                      id="input-number-of-coupons"
                                                      placeholder="Enter Number of Coupons"
                                                      type="text"
                                                      name="numberOfCoupons"
                                                      onChange={this.onChange.bind(this)}
                                                    />
                                                    <InputError show={validationAuto.numberOfCoupons.isInvalid} message={validationAuto.numberOfCoupons.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-description"
                                                >
                                                    Description*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-description"
                                                    placeholder="Enter a Description"
                                                    type="textarea"
                                                    name="description"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validationBase.description.isInvalid} message={validationBase.description.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-cityId"
                                                >
                                                    Select City*
                                                </label>

                                                <Input type="select" name="cityId" id="cityId" onChange={this.onChange.bind(this)}>
                                                    <option value=''>Select City</option>
                                                    {
                                                        cities.map((c, k) => {
                                                            return (
                                                                <option value={c._id} key={k}>{c.cityName}</option>
                                                            );
                                                        })
                                                    }
                                                </Input>
                                                <InputError show={validationBase.cityId.isInvalid} message={validationBase.cityId.message} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-usageLimit"
                                                >
                                                    Usage Limit*
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-usageLimit"
                                                    placeholder="Enter a Usage Limit"
                                                    type="text"
                                                    name="usageLimit"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputError show={validationBase.usageLimit.isInvalid} message={validationBase.usageLimit.message} />
                                            </FormGroup>
                                        </Col>
                                        { this.expireDateHTML() }
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="logo">
                                                    Logo*
                                                </label>
                                                <UploadableImage
                                                  uploadable={ true }
                                                  onChange={this.handleImageChange}
                                                />
                                                <InputError show={validationBase.logoReady.isInvalid} message={validationBase.logoReady.message} />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6">
                                            <FormGroup className="d-block flex-row px-6">
                                                    <div className="float-left">
                                                    <label className="form-control-label" htmlFor="input-isFreeRide">Is Free Service ?</label>
                                                    <br/>
                                                    <Switch
                                                        checked={isFreeRide}
                                                        className="switch center"
                                                        id="isFreeRide"
                                                        name= "isFreeRide"
                                                        
                                                        onChange={(e) => {this.setState({isFreeRide:e})}} 
                                                        // onChange={() => this.changeStatus(key, customer._id, customer.accountStatus)}
                                                    />
                                                    </div>
                                                    <div className="float-right">
                                                    <label className="form-control-label" htmlFor="input-isFirstRide">Is First Service ?</label>
                                                        <br />
                                                    <Switch
                                                        checked={isFirstRide}
                                                        className="switch center"
                                                        id="isFirstRide"
                                                        name="isFirstRide"
                                                        onChange={(e) => { this.setState({ isFirstRide: e }) }}
                                                    // onChange={() => this.changeStatus(key, customer._id, customer.accountStatus)}
                                                    />
                                                    </div>
                                            </FormGroup>
                                            </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <div className="text-right">
                                                { !isLoading &&  <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button"  disabled={this.isNotFilled()}>
                                                        Submit
                                                    </Button>
                                                }
                                                {
                                                    isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                                }
                                            </div>
                                        </Col>
                                    </Row> 
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
    );
  }
}

export default AddPromocode;
