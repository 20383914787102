import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import Switch from "react-ios-switch";
import Loader from "react-loader-spinner";
import Header from "../../components/Headers/Header";
import {
  filterClients,
  getClients,
  activateClient,
  deleteClients
} from "../../actions/Clients";
import { setLocalValue } from "../../lib/Helper";
import { washerData } from "../../lib/Action/Washer";

class Clients extends React.Component {
  limit = 10;
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      washers: [],
      totalUsers: "",
      pages: 1,
      current_page: 1,
      keyword: "",
      sortBy: "name",
      dir: 1,
      type: "",
      submerchantType: ""
    };
  }

  componentWillMount() {
    this.setState({ isLoading: true });
    this.getWashers();
    // filterClients(this.filterQuery()).then(response => {
    //     if (response.data.success) {
    //         if (response.data.success.data.users) {
    //             let responseData = response.data.success.data.users;
    //             let totalUsers = responseData.totalUsers;
    //             let pages = Math.ceil(totalUsers / this.limit);
    //             this.setState({
    //                 washers: responseData.users,
    //                 isLoading: false,
    //                 totalUsers: responseData.totalUsers,
    //                 pages,
    //             });
    //         }
    //     }
    // }).catch(error => {
    //     console.log(error)
    // });
  }

  filterQuery() {
    const {
      sortBy,
      keyword,
      dir,
      current_page,
      pages,
      type,
      submerchantType
    } = this.state;

    let query = {};

    if (sortBy !== "") {
      query.sortBy = sortBy;
    }

    if (keyword !== "") {
      query.keyword = keyword;
    }

    if (dir !== "") {
      query.dir = dir;
    }

    if (type !== "") {
      query.type = type;
    }

    if (submerchantType !== "") {
      query.submerchantType = submerchantType;
    }

    query.start = (current_page - 1) * this.limit;
    query.limit = this.limit;

    return query;
  }

  goToPage(page) {
    this.setState({ current_page: page }, () => {
      this.getWashers(false);
    });
  }
  delete = washer => {
    console.log(washer);
    deleteClients({ _id: washer._id })
      .then(response => {
        console.log(response);
        if (response.data.success.data) {
          console.log("Giriyor");
          let washers = [...this.state.washers];

          washers = washers.filter((item, index, arr) => {
            return item._id !== washer._id;
          });
          this.setState({
            err: false,
            success: true,
            message: "Client has been deleted succesfully",
            washers: washers
          });
          toast.success("Client has been added succesfully");
          this.setState({ isLoading: false });
        } else {
          this.setState({
            err: true,
            message: "Unable to delete!",
            isLoading: false
          });
          toast.error("Unable to delete!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        }
      })
      .catch(error => {
        this.setState({ err: true, message: "Server Error!" });
        console.log("error : ", error);
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 3000);
      });
  };

  getWashers(page = true) {
    this.setState({ isLoading: true });
    const query = this.filterQuery();
    filterClients(query)
      .then(response => {
        let totalusers = response.data.success.data.totalClients;
        let pages = Math.ceil(totalusers / 50);
        console.log("response", response);
        if (page) {
          this.setState({
            washers: response.data.success.data.clients,
            pages,
            isLoading: false,
            totalUsers: totalusers
          });
        } else {
          this.setState({
            washers: response.data.success.data.clients,
            isLoading: false,
            totalUsers: totalusers
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  sort(by, dir) {
    this.setState({ sortBy: by, dir }, () => {
      this.getWashers();
    });
  }

  clearFilter() {
    this.setState({ keyword: "" }, () => {
      this.getWashers();
    });
  }

  changeStatus(key, id, status) {
    const washers = [...(this.state.washers || [])];
    washers[key] = {
      ...washers[key],
      accountStatusChanging: true
    };

    this.setState(
      {
        washers
      },
      () => {
        activateClient({
          _id: id,
          isActive: !status
        })
          .then(response => {
            const nextWashers = [...(this.state.washers || [])];
            nextWashers[key] = {
              ...nextWashers[key],
              isActive: response.data.success ? !status : status,
              accountStatusChanging: false
            };
            this.setState({
              washers: nextWashers
            });
          })
          .catch(error => {
            this.setState({ err: true, message: "Server Error!" });
          });
      }
    );
  }

  goToAssign(washer) {
    console.log(washer);
    this.props.washerData(washer);
    this.props.history.push("/dashboard/washer-assign");
  }

  goToEdit(washer) {
    washer["fromType"] = "washer";

    this.props.history.push("/dashboard/client-edit", { client: washer });
  }

  goToDocuments(washer) {
    this.props.washerData(washer);
    this.props.history.push("/dashboard/washer-documents");
  }

  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.getWashers();
    });
  };

  // toggleReportModal = () => {
  //     const { reportUrl, reportModal } = this.state;
  //     this.setState({ reportModal: !reportModal });
  //     if (!reportUrl && !reportModal) {
  //         getWashersReport().then(res => {
  //             if (res.data.success.data) {
  //                 this.setState({
  //                     reportUrl: res.data.success.data
  //                 });
  //             }
  //         }).catch(console.error);
  //     }
  // };

  renderReport = () => {
    const { reportModal, reportUrl } = this.state;
    return (
      <>
        <Button
          className="pull-right ml-2 mr-0"
          color="warning"
          style={{ float: "right" }}
          size="sm"
          onClick={this.toggleReportModal}
        >
          <i className="fa fa-file-download" /> Download Report
        </Button>
        <Modal
          size={"sz"}
          isOpen={reportModal}
          toggle={this.toggleReportModal}
          className="text-center"
        >
          <ModalHeader toggle={this.toggleReportModal}>
            Download Report for Washers
          </ModalHeader>
          <ModalBody>
            {reportUrl && (
              <a href={reportUrl}>
                <i className="fa fa-file-download" /> Download Report
              </a>
            )}
            {!reportUrl && (
              <Loader type="Circles" color="#5e72e4" height={30} width={30} />
            )}
          </ModalBody>
        </Modal>
      </>
    );
  };

  render() {
    const {
      washers,
      isLoading,
      totalUsers,
      pages,
      current_page,
      keyword,
      dir,
      sortBy,
      type,
      submerchantType
    } = this.state;
    console.log(totalUsers);
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={3}>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="form-control-alternative"
                          value={keyword}
                          id="input-keyword"
                          placeholder="Search"
                          type="text"
                          name="keyword"
                          onChange={this.onChange.bind(this)}
                        />
                        {keyword && (
                          <InputGroupAddon addonType="append">
                            <a onClick={this.clearFilter.bind(this)}>
                              <InputGroupText style={{ height: "100%" }}>
                                <i className="fas fa-times-circle" />
                              </InputGroupText>
                            </a>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </Col>
                    <Col sm={3}>
                      {/* <InputGroup className="input-group-alternative">
                                                <Input type="select" name="type" id="type" onChange={this.onChange.bind(this)}>
                                                    <option value="all">All</option>
                                                    <option value="submerchants">Submerchants</option>
                                                    <option value="washers">Washers</option>
                                                </Input>
                                            </InputGroup> */}
                    </Col>
                    <Col sm={3}>
                      {type === "submerchants" && (
                        <InputGroup className="input-group-alternative">
                          <Input
                            type="select"
                            name="submerchantType"
                            id="submerchantType"
                            onChange={this.onChange.bind(this)}
                          >
                            <option value="">Filter By Submerchant Type</option>
                            <option
                              selected={submerchantType == "personal"}
                              value="personal"
                            >
                              Personal
                            </option>
                            <option
                              selected={submerchantType == "private"}
                              value="private"
                            >
                              Private
                            </option>
                            <option
                              selected={submerchantType == "limited"}
                              value="limited"
                            >
                              Limited
                            </option>
                          </Input>
                        </InputGroup>
                      )}
                    </Col>
                    <Col sm={3}>
                      {/* {this.renderReport()} */}
                      <Link to="/dashboard/client-add">
                        <Button
                          className="pull-right"
                          color="primary"
                          style={{ float: "right" }}
                          size="sm"
                        >
                          Add Client
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr className="row m-0">
                      <th className="col">Name</th>
                      <th className="col">Code</th>
                      <th className="col">Discount</th>
                      <th className="col">Status</th>
                      <th className="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <Loader
                            type="Circles"
                            color="#5e72e4"
                            height={40}
                            width={40}
                          />
                        </td>
                      </tr>
                    )}

                    {!isLoading && totalUsers == 0 && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}

                    {!isLoading &&
                      totalUsers > 0 &&
                      washers.map((washer, key) => {
                        return (
                          <tr className="row m-0" key={key}>
                            <th className="col">{washer.name}</th>

                            <td className="col">{washer.code}</td>
                            <td className="col">{washer.discount}</td>
                            <td className="col">
                              <Switch
                                checked={washer.isActive}
                                disabled={washer.accountStatusChanging}
                                className="switch"
                                onChange={() =>
                                  this.changeStatus(
                                    key,
                                    washer._id,
                                    washer.isActive
                                  )
                                }
                              />
                            </td>
                            <td className="col">
                              <i
                                style={{ marginRight: 5 }}
                                onClick={() => this.goToEdit(washer)}
                                className="fa fa-edit btn p-1"
                              />
                              <i
                                style={{ marginLeft: 5, color: "red" }}
                                color="#ff0000"
                                onClick={() => this.delete(washer)}
                                className="fa fa-trash-alt btn p-1"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {pages > 1 &&
                        _.times(pages, i => {
                          return (
                            <PaginationItem
                              key={i}
                              className={current_page === i + 1 ? "active" : ""}
                            >
                              <PaginationLink
                                href="#"
                                onClick={this.goToPage.bind(this, i + 1)}
                              >
                                {i + 1}
                              </PaginationLink>
                            </PaginationItem>
                          );
                        })}
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  washerData: washer => dispatch(washerData(washer))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Clients);
