import React from "react";
import { toast } from "react-toastify";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          {this.props.children || null}
        </div>
      </>
    );
  }
}

export default Header;
