import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table
  } from "reactstrap";
import Switch from 'react-ios-switch';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
import { getAdditionalServices, updateAdditionalService } from "../../actions/Services";
import { additionalServiceData } from "../../lib/Action/AdditionalService";
import { OTOPARLAK_LOGO } from '../../Constant/Constant';

class Services extends React.Component {

    constructor() {
        super();
        this.state = { 
            isLoading:true,
            services: [],
            service_id: ''
        }
    }

    componentWillMount(){
        if(this.props.ServiceReducer.serviceData){
            this.setState({
                'service_id': this.props.ServiceReducer.serviceData._id
            })
            getAdditionalServices({
                'service_id': this.props.ServiceReducer.serviceData._id
            }).then((response) => {
                if(response.data.success){
                    if(response.data.success.data){
                        let responseData = response.data.success.data;
                        this.setState({
                            services:responseData,
                            isLoading:false
                        });
                    }
                }
            })
        }
    }

    changeStatus(key,id,status){
        let  { services } = this.state;
        services[key].status = !status;
    
        this.setState({
            services,
        });
        
        updateAdditionalService({
          _id:id,
          status:!status,
        }).then(response => {
            // this.componentWillMount();
        }).catch(error=> {
          this.setState({err:true,message:'Server Error!'});
        });
    
    }

   
    goToEdit(service){
        this.props.additionalServiceData(service);
        this.props.history.push("/dashboard/service-additional-edit");
    }

    render(){

        const {services, isLoading} = this.state;
        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={4}>
                                            
                                        </Col>
                                        <Col sm={8}>
                                            <Link to="/dashboard/service-additional-add"><Button className="pull-right" color="primary" style={{float:'right'}} size="sm">Add Additional Service</Button></Link>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr className="row m-0">
                                            <th className="col">Logo</th>
                                            <th className="col">Name</th>
                                            <th className="col">Price</th>
                                            <th className="col">Status</th>
                                            <th className="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                        !isLoading && services.length == 0 && <tr>
                                            <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                        </tr>
                                        }

                                        { 
                                            !isLoading && services.length > 0 &&
                                            services.map((service,key) => {
                                                return (
                                                    <tr className="row m-0" key={key}>
                                                        <td className="col">
                                                            {
                                                                <img
                                                                  className="icon"
                                                                  src={service.logo || OTOPARLAK_LOGO}
                                                                  alt={`${service.name}`}
                                                                  title={`${service.name}`}
                                                                />
                                                            }
                                                        </td>
                                                        <th className="col">{service.name}</th>
                                                        <td className="col">{service.price}</td>
                                                        <td className="col">
                                                            <Switch
                                                                checked={service.status}
                                                                className="switch"
                                                                onChange={() => this.changeStatus(key,service._id,service.status)}
                                                            />
                                                        </td>
                                                        <td  className="col">
                                                            <i onClick={() => this.goToEdit(service)} className="fa fa-edit btn p-1"></i>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    additionalServiceData: (service) => dispatch(additionalServiceData(service))
})

export default connect(mapStateToProps, mapDispatchToProps)(Services);
