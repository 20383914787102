import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import isInside from "point-in-polygon";
import { CircularProgress } from "@material-ui/core";

let markersArray = [];
let bounds;
let drawingManager;
class Map extends React.Component {
  constructor() {
    super();
    this.state = {
      drawMode: false,
      loaded: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.google !== this.props.google) {
      this.loadMap();
      if (this.props.drawMode) {
        this.drawPolyline();
      }
      if (this.props.insertMarker) {
        this.insertMarker();
      }
      if (this.props.heatMap) {
        this.heatMap();
      }
    }
    if (
      prevProps.markers.length !== this.props.markers.length &&
      this.markers != prevProps.markers &&
      this.state.loaded &&
      !this.props.heatMap
    ) {
      this.getMarkers();
    }
  }
  componentDidMount() {
    console.log("props", this.state.google);
    //this.drawExistingPolygons();
  }

  componentWillReceiveProps(nextProps) {
    const google = this.props.google;

    console.log("google:", google);

    if (this.props.google) {
      this.setState(
        { google: google, infoWindow: new google.maps.InfoWindow() },
        () => this.drawExistingPolygons()
      );

      console.log("adadad", google);
    }

    if (drawingManager && nextProps.drawMode != this.props.drawMode) {
      drawingManager.setDrawingMode(null);
    }
    if (
      this.props.drawMode !== nextProps.drawMode &&
      nextProps.drawMode &&
      this.props.google
    ) {
      this.setState({ google: google });

      this.drawPolyline();
    }
  }

  heatMap() {
    const { google } = this.props;
    const maps = google.maps;
    const points = this.props.markers.map(
      point => new google.maps.LatLng(point.latLng.lat, point.latLng.lng)
    );

    let heatmap = new maps.visualization.HeatmapLayer({
      data: points,
      map: this.map
    });
  }

  insertMarker() {
    const { google } = this.props;
    const maps = google.maps;

    google.maps.event.addListener(
      this.map,
      "click",
      function(e) {
        const markerProps = {
          position: new google.maps.LatLng(e.latLng.lat(), e.latLng.lng()),
          map: this.map,
          draggable: true
        };
        const marker = new maps.Marker(markerProps);

        this.props.handleReturnedMarkers({
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        });
        marker.addListener("dragend", e => {
          this.props.handleReturnedMarkers({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
          });
        });
      }.bind(this)
    );
  }
  drawExistingPolygons() {
    const google = this.state.google;
    const zones = this.props.zones;
    const infoWindow = this.state.infoWindow;
    // console.log("zones", zones);
    // console.log("goioooggle", google);
    if (zones.length > 0) {
      zones.map(async zone => {
        var polygonCoords = [];
        const promiseCoord = await zone.fence.coordinates[0].map(coord => {
          //console.log("coord", coord);
          polygonCoords.push({ lng: coord[0], lat: coord[1] });
        });
        Promise.all(promiseCoord).then(res => {
          // console.log("polygonCoords:", polygonCoords);
          let polygon = new google.maps.Polygon({
            paths: polygonCoords,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "rgba(256,0,0,0.35)",
            fillOpacity: 0.35
          });
          polygon.setMap(this.map);

          polygon.addListener("rightclick", event => {
            // console.log("mouseoverr");
            var bounds = new google.maps.LatLngBounds();
            var i;
            for (let i = 0; i < polygonCoords.length; i++) {
              bounds.extend(polygonCoords[i]);
            }
            let center = bounds.getCenter();
            var contentString = "<b>" + zone.name + "</b><br>";
            infoWindow.setContent(contentString);
            infoWindow.setPosition(center);

            infoWindow.open(this.map);
            //console.log("center:", center);
            // console.log("zones", zone);
          });
        });
      });
    }
  }

  drawPolyline() {
    const google = this.props.google;

    drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: this.props.polygonOptions
    });
    drawingManager.setMap(this.map);
    //======================================================
    // Event listeners after Polygon closed
    //======================================================
    google.maps.event.addListener(
      drawingManager,
      "polygoncomplete",
      function(polyline) {
        drawingManager.setDrawingMode(null);
        let resizablePolygon = polyline.getPath();
        //======================================================
        // Delete Polygon on click
        //======================================================
        google.maps.event.addListener(polyline, "click", e => {
          polyline.setMap(null);
          resizablePolygon = [];
          this.getMarkers();
          this.drawPolyline();

          if (this.props.handleReturnCoords) {
            let polygonCoords = null;
            this.props.handleReturnCoords(polygonCoords);
          }
        });
        //======================================================
        // Filtering function
        //======================================================
        const filterMarkers = () => {
          let polygon = [];
          let insideMarkers = [];
          let polygonCoords = [];
          resizablePolygon.forEach(coord => {
            polygonCoords.push([coord.lng(), coord.lat()]);
            polygon.push([coord.lat(), coord.lng()]);
          });
          console.log(resizablePolygon);
          polygonCoords.push([polygonCoords[0][0], polygonCoords[0][1]]);
          markersArray.forEach(marker => {
            const x = marker.getPosition().lat();
            const y = marker.getPosition().lng();
            if (!isInside([x, y], polygon)) {
              marker.setMap(null);
            } else {
              insideMarkers.push(marker);
              if (!marker.map) {
                marker.setMap(this.map);
              }
            }
          });
          //    console.log("Inside Markers");
          //     console.log(polygonCoords);
          if (this.props.handleReturnCoords) {
            //   console.log("inside handle");
            this.props.handleReturnCoords(polygonCoords);
          }
          if (this.props.handleReturnedMarkers) {
            this.props.handleReturnedMarkers(insideMarkers);
          }
        };
        filterMarkers();
        //======================================================
        // Resize polygon
        //======================================================
        google.maps.event.addListener(resizablePolygon, "set_at", function(
          edge
        ) {
          resizablePolygon = polyline.getPath();
          filterMarkers();
        });
        google.maps.event.addListener(resizablePolygon, "insert_at", function(
          edge
        ) {
          resizablePolygon = polyline.getPath();
          filterMarkers();
        });
      }.bind(this)
    );
  }
  //======================================================
  // DISPLAY MARKERS IN MAP
  //======================================================
  getMarkers() {
    console.log("getMarkers");
    const { google } = this.props;
    const maps = google.maps;
    markersArray.forEach(marker => {
      marker.setMap(null);
    });
    markersArray = [];

    this.props.markers.forEach(flag => {
      const markerProps = {
        ...flag,
        position: new google.maps.LatLng(flag.latLng.lat, flag.latLng.lng),
        map: this.map
      };

      const marker = new maps.Marker(markerProps);

      if (this.props.onMarkerClick) {
        google.maps.event.addListener(marker, "click", event => {
          this.props.onMarkerClick(marker);
        });
      }
      //======================================================
      // Render info window if we have an info property
      //======================================================
      if (marker.info) {
        const infowindow = new google.maps.InfoWindow({
          content: marker.info
        });
        google.maps.event.addListener(marker, "click", event => {
          infowindow.open(this.map, marker);
        });
      }
      markersArray.push(marker);
      if (this.props.handleReturnedMarkers) {
        this.props.handleReturnedMarkers(markersArray);
      }
    });
  }

  loadMap() {
    try {
      const { google } = this.props;
      const maps = google.maps;
      const mapRef = this.refs.map;
      const node = ReactDOM.findDOMNode(mapRef);
      const { mapConfig } = this.props;
      let { zoom } = mapConfig;
      let { lat } = mapConfig;
      let { lng } = mapConfig;
      const center = new maps.LatLng(lat, lng);
      const mapConfiguration = Object.assign(
        {},
        {
          center: center,
          zoom: zoom
        }
      );
      this.map = new maps.Map(node, mapConfiguration);
      google.maps.event.addListenerOnce(this.map, "idle", () => {
        if (!this.props.heatMap) {
          this.getMarkers();
        }
      });
      this.setState({
        loaded: true
      });
    } catch (e) {
      console.log("error in load");
    }
  }
  loading() {
    let out = <div>loaded</div>;
    if (!this.state.loaded)
      out = (
        <div>
          <CircularProgress size={380} thickness={5} />
        </div>
      );
    return out;
  }
  render() {
    return (
      <div style={this.props.mapStyle} ref="map">
        {this.loading()}
      </div>
    );
  }
}

export default Map;
