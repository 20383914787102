import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { getCities } from "../../actions/Cities";
import { createClients, updateClients } from "../../actions/Clients";

class EditClient extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator(Rules.addClient);

    this.state = {
      isLoading: false,

      countryPhoneCode: "+90",
      showPasword: false,
      showConfirmPassword: false,
      confirm_password: "",
      status: false,
      err: false,
      success: false,
      validation: this.validator.valid(),
      discType: "percentage",
      submerchantType: "personal",
      legalName: "",
      gsmNumber: "",
      taxNumber: "",
      taxOffice: "",
      legalCompanyTitle: "",
      legalEmail: "",
      legalAddress: "",
      iban: "",
      commissionRate: "",
      parasutName: "",
      parasutId: "",
      accountingId: "",
      discount: "",
      name: "",
      code: ""
    };
  }

  componentWillMount() {
    console.log(this.props.location.state.client);
    const client = this.props.location.state.client;
    const { name, code, phone, isAbsolute, discount, _id } = client;
    if (client) {
      console.log(phone);
      this.setState({ loading: true });

      this.setState({
        _id,
        name,
        code,
        phoneNumber: phone.slice(3),
        discType: isAbsolute === true ? "absolute" : "percentage",
        discount
      });
      console.log(this.state, client);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const {
      _id,
      name,
      code,
      phoneNumber,
      discType,
      discount,
      countryPhoneCode
    } = this.state;
    console.log(phoneNumber);
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.setState({ isLoading: true });
      updateClients({
        _id,
        loginPhoneNo: countryPhoneCode + phoneNumber.toString(),
        name,
        code,
        discount,
        isAbsolute: discType === "absolute" ? true : false
      })
        .then(response => {
          console.log(response);
          console.log(response.data.success.data);
          if (response.data.success.data) {
            console.log("Giriyor");
            this.setState({
              err: false,
              success: true,
              message: "Client has been updated succesfully"
            });
            toast.success("Client has been updated succesfully");
            setTimeout(() => {
              this.setState({ isLoading: false });
              this.props.history.push("/dashboard/clients");
            }, 2000);
          } else {
            this.setState({
              err: true,
              message: "Unable to update!",
              isLoading: false
            });
            toast.error("Unable to update!");
            setTimeout(() => {
              this.setState({ err: false, success: false, isLoading: false });
            }, 2000);
          }
        })
        .catch(error => {
          this.setState({ err: true, message: "Server Error!" });
          console.log("error : ", error);
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        });
      /*createWasher({
        fname,
        lname,
        email,
        password,
        countryName,
        cityId,
        cityName,
        countryPhoneCode,
        loginPhoneNo: `${countryPhoneCode}${phone}`,
        submerchantType,
        legalName,
        gsmNumber,
        taxNumber,
        taxOffice,
        legalCompanyTitle,
        legalEmail,
        legalAddress,
        iban,
        commissionRate,
        parasutName,
        parasutId,
        accountingId
      })
        .then(response => {
          console.log(response);
          if (response.data.success.data) {
            this.setState({
              err: false,
              success: true,
              message: "Washer details have been successfully added!"
            });
            toast.success("Washer details have been successfully added!");
            setTimeout(() => {
              this.setState({ isLoading: false });
              this.props.history.push("/dashboard/washers");
            }, 3000);
          } else {
            this.setState({
              err: true,
              message: "Unable to add!",
              isLoading: false
            });
            toast.error("Unable to add!");
            setTimeout(() => {
              this.setState({ err: false, success: false, isLoading: false });
            }, 3000);
          }
        })
        .catch(error => {
          this.setState({ err: true, message: "Server Error!" });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        });*/
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    const { cities } = this.state;
    if (name == "cityId") {
      this.setState({
        cityId: cities[value]._id,
        cityName: cities[value].cityName
      });
    } else {
      this.setState({ [name]: value });
      if (name === "submerchantType" && value != "limited") {
        this.setState({
          legalCompanyTitle: ""
        });
      }
    }
  }

  render() {
    let {
      err,
      success,
      message,
      submerchantType,
      countryPhoneCode,
      isLoading,
      name,
      code,
      discType,
      discount,
      phoneNumber
    } = this.state;
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
          <Row>
            <Col sm="12" md="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Add Client</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link to="/dashboard/clients">
                        <Button color="primary" size="sm">
                          Back to Clients
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" method="POST">
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-fname"
                            >
                              Name*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-fname"
                              placeholder="Enter a Name"
                              type="text"
                              name="name"
                              defaultValue={name}
                              onChange={this.onChange.bind(this)}
                            />
                            <InputError
                              show={validation.name.isInvalid}
                              message={validation.name.message}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-lname"
                            >
                              Code*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-lname"
                              placeholder="Enter a Code"
                              type="text"
                              defaultValue={code}
                              name="code"
                              onChange={this.onChange.bind(this)}
                            />
                            <InputError
                              show={validation.code.isInvalid}
                              message={validation.code.message}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone Number*
                            </label>

                            <InputGroup>
                              <InputGroupAddon
                                className="input-shadow"
                                addonType="prepend"
                              >
                                <InputGroupText className="border-none">
                                  {countryPhoneCode}
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                className="input-shadow"
                                id="input-phone"
                                placeholder="Enter a Phone"
                                type="number"
                                defaultValue={phoneNumber}
                                name="phoneNumber"
                                onChange={this.onChange.bind(this)}
                              />
                            </InputGroup>
                            <InputError
                              show={validation.phoneNumber.isInvalid}
                              message={validation.phoneNumber.message}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Discount*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              placeholder="Enter a Discount"
                              type="number"
                              name="discount"
                              defaultValue={discount}
                              onChange={this.onChange.bind(this)}
                            />
                            <InputError
                              show={validation.discount.isInvalid}
                              message={validation.discount.message}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="3" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Discount Type*
                            </label>
                            <Input
                              className="input-shadow"
                              type="select"
                              name="discType"
                              id="discType"
                              defaultValue={discType}
                              onChange={this.onChange.bind(this)}
                            >
                              <option value="percentage">Percentage</option>
                              <option value="absolute">Absolute</option>
                            </Input>
                            <InputError
                              show={validation.discType.isInvalid}
                              message={validation.discType.message}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {(submerchantType === "limited" ||
                        submerchantType === "private" ||
                        submerchantType === "personal") && (
                        <Row>
                          <Col sm="12">
                            <div className="text-right">
                              {!isLoading && (
                                <Button
                                  className="mt-4"
                                  onClick={this.onSubmit.bind(this)}
                                  color="primary"
                                  type="button"
                                >
                                  Update
                                </Button>
                              )}
                              {isLoading && (
                                <Loader
                                  type="Circles"
                                  color="#5e72e4"
                                  height={30}
                                  width={30}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default EditClient;
