import React from 'react';
import PropTypes from 'prop-types';

export const icon_classes = {
  // Media
  image: "fa-file-image",
  audio: "fa-file-audio",
  video: "fa-file-video",
  // Documents
  "application/pdf": "fa-file-pdf",
  "application/msword": "fa-file-word",
  "application/vnd.ms-word": "fa-file-word",
  "application/vnd.oasis.opendocument.text": "fa-file-word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml":
    "fa-file-word",
  "application/vnd.ms-excel": "fa-file-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml":
    "fa-file-excel",
  "application/vnd.oasis.opendocument.spreadsheet": "fa-file-excel",
  "application/vnd.ms-powerpoint": "fa-file-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml":
    "fa-file-powerpoint",
  "application/vnd.oasis.opendocument.presentation": "fa-file-powerpoint",
  "text/plain": "fa-file-alt",
  "text/html": "fa-file-code",
  "application/json": "fa-file-code",
  // Archives
  "application/gzip": "fa-file-archive",
  "application/zip": "fa-file-archive"
};

export const getFontAwesomeIconFromMIME = (mimeType) => {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  for (let key in icon_classes) {
    if (icon_classes.hasOwnProperty(key)) {
      if (mimeType.search(key) === 0) {
        // Found it
        return icon_classes[key];
      }
    }
  }
  return "fa-file";
};

class FilePreview extends React.PureComponent {
  static propTypes = {
    fileName: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
  };
  render() {
    const { fileName, fileType } = this.props;
    return (
      <div style={styles.container}>
        <i
          className={`far ${getFontAwesomeIconFromMIME(fileType)}`}
          style={styles.icon}
        />
        <span
          style={styles.fileName}
        >
          { fileName }
        </span>
      </div>
    );
  }
};

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  icon: {
    fontSize: 64,
    marginBottom: 4
  },
  fileName: {
    fontSize: 12,
    lineHeight: '12px',
    maxWidth: '100%',
    wordBreak: 'break-all'
  }
};

export default FilePreview;
