import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axios from 'axios';
import getExceptionMessage from './getExceptionMessage';
import { removeToken } from '../Helper';

let initialized = false;

const toasterCreator = (browserRouter) => {

  if (!initialized) {
    initialized = true;

    toast.configure({
      hideProgressBar: true,
      newestOnTop: true,
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000
    });
    // bind toasts to axios defaults
    const isToastsEnabled = (config = {}) =>
      // todo adding custom fields to config is not supported yet https://github.com/axios/axios/pull/2207
      !(config.hasOwnProperty('toastsEnabled') && !config.toastsEnabled);
    axios.defaults.isToastsEnabled = true;
    const requestHandler = (config) => {
      if (isToastsEnabled(config)) {
        config.uploadToast = { id: null };
        if (config.headers["Content-Type"] === 'multipart/form-data') {
          const prevUploadProgress = config.onUploadProgress;
          config.onUploadProgress = (p) => {
            const progress = p.loaded / (p.total + 1);
            if (config.uploadToast.id === null) {
              config.uploadToast.id = toast.info('Upload in progress', { progress, hideProgressBar: false });
            } else {
              toast.update(config.uploadToast.id, { progress });
            }
            return prevUploadProgress && prevUploadProgress(p);
          };
        }
        config.downloadToast = { id: null };
        /*
        const prevDownloadProgress = config.onDownloadProgress;
        config.onDownloadProgress = (p) => {
          const progress = p.loaded / (p.total + 1);
          if (config.downloadToast.id === null) {
            config.downloadToast.id = toast.info('Download in progress', { progress, hideProgressBar: false });
          } else {
            toast.update(config.downloadToast.id, { progress });
          }
          return prevDownloadProgress && prevDownloadProgress(p);
        };
         */
      }
      return config;
    };
    const errorHandler = (error) => {
      if (isToastsEnabled(error.config)) {
        // toast update/done checks performs noop/returns null if toastId is invalid, so no need to null check toastId
        toast.done(error.config.uploadToast.id);
        toast.done(error.config.downloadToast.id);
        let errorMessage = null;
        console.log('error interceptor', error, error.response);
        if (error.response) {
          const { error: respError } = error.response.data || {};
          if (error.response.status === 401 || error.response.status === 403) {
            errorMessage = "Unauthorized, please login again. Your token may have been expired.";
            removeToken();
            if(browserRouter.current) {
              browserRouter.current.history.push("/auth/login");
            }
            console.log('current state in hisory', browserRouter);
          } else {
            errorMessage = (respError || {}).message || 'Oops something went wrong!';
          }
        } else if (error.request) {
          errorMessage = 'Oops something went wrong in the server!';
        } else {
          console.log('Error building axios request', error.message);
        }
        if (errorMessage) {
          toast.error(errorMessage);
        }
      }
      return Promise.reject({ ...error });
    };
    const responseHandler = (response) => {
      if (isToastsEnabled(response.config)) {
        toast.done(response.config.uploadToast.id);
        toast.done(response.config.downloadToast.id);
        const { success, error } = response.data;
        if (error) {
          toast.error(getExceptionMessage(error.message) || 'Oops something went wrong!');
        } else if (success) {
          let successMessage = null;
          // todo adding custom fields to config is not supported yet https://github.com/axios/axios/pull/2207
          if (response.config.hasOwnProperty('successMessage') && response.config.successMessage) {
            successMessage = response.config.successMessage;
          } else if (success.message && success.message !== 'SUCCESS' && success.message !== 'CREATED') {
            // ignore the simple SUCCESS messages
            successMessage = success.message;
          }
          if (successMessage) {
            toast.success(successMessage);
          }
        }
      }
      return response;
    };
    axios.interceptors.request.use(
      request => requestHandler(request)
    );
    axios.interceptors.response.use(
      response => responseHandler(response),
      error => errorHandler(error),
    );
  }
};

export default toasterCreator;
