import React from "react";
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";

import { createAdmin } from "../../actions/Admins";

class AddAdmin extends React.Component {
    constructor(props){
        super(props);   

        this.validator = new FormValidator(Rules.addAdmin);
        
        this.state = {
            email : '',
            password: '',
            fname:'',
            lname:'',
            phone:'',
            countryPhoneCode:'+90',
            err:false,
            success:false,
            showPasword:false,
            showConfirmPassword:false,
            validation: this.validator.valid(),
            isLoading:false,
            confirm_password:'',
            cityName: '',
            countryName: ''
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {email,fname,lname,phone,password,countryName,confirm_password,cityName,countryPhoneCode} = this.state;

        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;
        
        if(password !== confirm_password){
            return;
        }

        if (validation.isValid) {
            this.setState({ isLoading:true });

            createAdmin({
                fname, lname, email, password, countryName, cityName, countryPhoneCode, loginPhoneNo:`${countryPhoneCode}${phone}`,
            }).then(response => {
                if(response.data.success.data){
                    this.setState({err:false,success:true,message:'Admin details have been successfully added!'}); toast.success('Admin details have been successfully added!');
                    setTimeout(()=>{
                        this.setState({isLoading:false});
                        this.props.history.push("/dashboard/admin");
                    },3000)
                }else{
                    this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
        
    }

    onChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    } 
    
    togglePassword(e){
        e.preventDefault();
        let {showPasword} = this.state;
          showPasword = !showPasword;
        this.setState({showPasword})
    }
    
    toggleConfirmPassword(e){
        e.preventDefault();
        let {showConfirmPassword} = this.state;
          showConfirmPassword = !showConfirmPassword;
        this.setState({showConfirmPassword})
    }

    isNotFilled(){
        const {email, fname,lname,phone,password,country,confirm_password} = this.state;
        return (email === '' || fname === '' || lname === '' || phone === '' || password === '' || confirm_password === ''|| password !== confirm_password)
    }

  render() {
    let {err,success, message, showPasword, isLoading,showConfirmPassword,password,confirm_password, countryPhoneCode} = this.state;
    let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
    return (
     <>
     <Header />
    {/* Table */}
    <Container className="mt--7" fluid>
        <Row>
            <Col sm="12" md="12">
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                        <Col xs="8">
                            <h3 className="mb-0">Add Admin</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                            <Link to="/dashboard/admin">
                                <Button
                                    color="primary"
                                    size="sm"
                                >
                                Back to Admins
                                </Button>
                            </Link>
                        </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {err && <Alert color="danger">{message}</Alert>}
                        {success && <Alert color="success">{message}</Alert>}
                        <Form role="form" method="POST">
                            <div className="pl-lg-4">
                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-fname"
                                            >
                                                First name*
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-fname"
                                                placeholder="Enter a First name"
                                                type="text"
                                                name="fname"
                                                onChange={this.onChange.bind(this)} 
                                            />
                                            <InputError show={validation.fname.isInvalid} message={validation.fname.message} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-lname"
                                            >
                                                Last name*
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-lname"
                                                placeholder="Enter a Last name"
                                                type="text"
                                                name="lname"
                                                onChange={this.onChange.bind(this)} 
                                            />
                                            <InputError show={validation.lname.isInvalid} message={validation.lname.message} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-email"
                                            >
                                                Email address*
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-email"
                                                placeholder="Enter a Email address"
                                                type="email"
                                                name="email"
                                                onChange={this.onChange.bind(this)} 
                                            />
                                            <InputError show={validation.email.isInvalid} message={validation.email.message} />
                                        </FormGroup>
                                    </Col>
                                    
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-phone"
                                            >
                                                Phone*
                                            </label>
                                            <InputGroup>
                                                <InputGroupAddon className="input-shadow" addonType="prepend">
                                                    <InputGroupText className="border-none">{countryPhoneCode}</InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    className="input-shadow"
                                                    id="input-phone"
                                                    placeholder="Enter a Phone"
                                                    type='text'
                                                    name="phone"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                            </InputGroup>
                                            <InputError show={validation.phone.isInvalid} message={validation.phone.message} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-countryName"
                                            >
                                                Country Name*
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-countryName"
                                                placeholder="Enter a Country name"
                                                type="text"
                                                name="countryName"
                                                onChange={this.onChange.bind(this)} 
                                            />
                                            <InputError show={validation.countryName.isInvalid} message={validation.countryName.message} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-cityName"
                                            >
                                                City Name*
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-cityName"
                                                placeholder="Enter a City Name"
                                                type="text"
                                                name="cityName"
                                                onChange={this.onChange.bind(this)} 
                                            />
                                            <InputError show={validation.cityName.isInvalid} message={validation.cityName.message} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-password"
                                            >
                                                Password*
                                            </label>
                                            <InputGroup className="input-group-alternative">     
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-password"
                                                    placeholder="*******"
                                                    type={showPasword?'text':'password'}
                                                    name="password"
                                                    autoComplete="nope"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <a onClick={this.togglePassword.bind(this)}>
                                                        <InputGroupText style={{height:'100%'}}><i className={!showPasword?'fas fa-eye-slash':'fas fa-eye'}></i></InputGroupText>
                                                    </a>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <InputError show={validation.password.isInvalid} message={validation.password.message} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-confirm"
                                            >
                                                Confirm password*
                                            </label>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    defaultValue=''
                                                    id="input-password"
                                                    placeholder="*******"
                                                    type={showConfirmPassword?'text':'password'}
                                                    name="confirm_password"
                                                    onChange={this.onChange.bind(this)}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <a onClick={this.toggleConfirmPassword.bind(this)}>
                                                        <InputGroupText  style={{height:'100%'}}><i className={!showConfirmPassword?'fas fa-eye-slash':'fas fa-eye'}></i></InputGroupText>
                                                    </a>
                                                </InputGroupAddon>
                                            </InputGroup>
                                            <InputError show={validation.confirm_password.isInvalid} message={validation.confirm_password.message} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm="12">
                                        <div className="text-right">
                                            { !isLoading &&  <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button">
                                                    Submit
                                                </Button>
                                            }
                                            {
                                                isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                            }
                                        </div>
                                    </Col>
                                </Row> 
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
        
    </>
    );
  }
}

export default AddAdmin;
