import {combineReducers} from 'redux'
import LoginReducer, {migrations} from './LoginReducer';
import AdminReducer from './AdminReducer';
import ServiceReducer from './ServiceReducer';
import WasherReducer from './WasherReducer';
import AdditionalServiceReducer from './AdditionalServiceReducer';
import CustomerReducer from './CustomerReducer';
import DocumentReducer from "./DocumentReducer";
import PromocodeReducer from "./PromocodeReducer";
import ServiceSolutionReducer from './ServiceSolutionReducer';
import SolutionReducer from './SolutionReducer';
import CampaignReducer from './CampaignReducer';
import { persistStore, createMigrate, persistReducer } from "redux-persist";
import {persist} from "../Service/reduxPersist";
import storage from 'redux-persist/lib/storage'

const MIGRATION_DEBUG = false;

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['auth']
}

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['somethingTemporary']
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, LoginReducer),
  other: 
    AdminReducer, 
  ServiceReducer,
  AdditionalServiceReducer,
  WasherReducer,
  CustomerReducer,
  DocumentReducer,
  PromocodeReducer,
  ServiceSolutionReducer,
  SolutionReducer,
  CampaignReducer
})

// const AppReducer = combineReducers({
//     persistedStore: persist(numberPersistConfig, LoginReducer, AdminReducer),
// });

// const rootReducer = (state, action) => {
//     return AppReducer(state, action);
// }

export default persistReducer(rootPersistConfig, rootReducer)
