import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Modal,
    ModalHeader,
    ModalBody,
  } from "reactstrap";
import Switch from 'react-ios-switch';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
import { getServices, updateService, assignSibling, deleteSiblings } from "../../actions/Services";
import { serviceData } from "../../lib/Action/Service";
import { OTOPARLAK_LOGO } from '../../Constant/Constant';

class Services extends React.Component {

    constructor() {
        super();
        this.state = { 
            isLoading:true,
            services: [],
            service_parent_id: '',
            siblingAddModal: false,
            selectedServices: [],
            service_parent_key: '',
            isSubmitedClicked: false
        }
    }

    componentWillMount(){
        getServices().then( response => {
            
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        services:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    changeStatus(key,id,status){
        let  { services } = this.state;
        services[key].status = !status;
    
        this.setState({
            services,
        });
        
        updateService({
          _id:id,
          status:!status
        }).then(response => {
            // this.componentWillMount();
        }).catch(error=> {
          this.setState({err:true,message:'Server Error!'});
        });
    
    }

    changeSiblingStatus(key,id,status){
        let  { services } = this.state;
        
        if(!status){
            services[key].hasSibling = !status;
            this.setState({
                services,
                service_parent_id: id,
                service_parent_key: key
            },() => {
                this.addSiblingModal();
            });
        } else {
            services[key].hasSibling = !status;
            this.setState({
                services
            })

            deleteSiblings({
                service_id: id
            }).then(response => {
                if(response.data.success){
                    this.setState({
                        selectedServices: [],
                        err:false,
                        success:true,
                        message:'Siblings deleted successfully!'
                    },() => {
                        this.componentWillMount();
                    });
                }else{
                    this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            });
        }
    }

    addSiblingModal(){
        const { isSubmitedClicked, services, service_parent_key } = this.state;
        if(isSubmitedClicked){
            this.setState(prevState => ({
                siblingAddModal: !prevState.siblingAddModal,
                isLoading:false,
            }));
            this.componentWillMount();
        } else {
            services[service_parent_key].hasSibling = false;
            this.setState(prevState => ({
                siblingAddModal: !prevState.siblingAddModal
            }));
        }
    }

    goToEdit(service){
        this.props.serviceData(service);
        this.props.history.push("/dashboard/service-edit");
    }

    goToAdditional(service, type){
        this.props.serviceData(service);
        if(type === 'service'){
            this.props.history.push("/dashboard/service-additional");
        } else {
            this.props.history.push("/dashboard/service-solutions");
        }
    }

    isNotServicesFilled(){
        const {selectedServices} = this.state;
        return(
            selectedServices.length == 0
        )
    }

    handleCheckbox(e, id){
        const { selectedServices } = this.state;
        let servicesArr = [...selectedServices];
        let index = servicesArr.indexOf(id);
        if (index !== -1) {
            servicesArr.splice(index, 1);
        } else {
            servicesArr.push(id);
        }
        
        this.setState({
            selectedServices: servicesArr
        });
    }

    onSubmitSiblings(e){
        e.preventDefault();
        const { selectedServices, service_parent_id, services, service_parent_key } = this.state;
        
        this.setState({
            isLoading:true,
            isSubmitedClicked: true
        });
        assignSibling({
            siblings: selectedServices,
            service_parent_id
        }).then(response => {
            if(response.data.success){
                this.setState({
                    selectedServices: [],
                    err:false,
                    success:true,
                    message:'Siblings assigned successfully!'
                });
                setTimeout(()=>{
                    this.addSiblingModal();
                },3000)
            }else{
                this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
                setTimeout(() =>{
                    this.addSiblingModal();
                    services[service_parent_key].hasSibling = false;
                },3000);
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
            setTimeout(() =>{
                this.setState({err:false,success:false,isLoading:false});
            },3000);
        });
    }

    render(){

        const {services, isLoading, siblingAddModal,service_parent_id} = this.state;
        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={4}>
                                            <ul>
                                                <li style={{listStyleType: 'none'}}>
                                                    <span style={{paddingLeft: 20, background: "#DCBEBE"}}></span> Has Child
                                                </li> 
                                                <li style={{listStyleType: 'none'}}>
                                                <span style={{paddingLeft: 20, background: "#d4cccc"}}></span> Is Child
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col sm={8}>
                                            <Link to="/dashboard/service-add"><Button className="pull-right" color="primary" style={{float:'right'}} size="sm">Add Service</Button></Link>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr className="row m-0">
                                            <th className="col">Logo</th>
                                            <th className="col">Name</th>
                                            <th className="col">Price</th>
                                            <th className="col">Slot Duration</th>
                                            <th className="col">Child</th>
                                            <th className="col">Status</th>
                                            <th className="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                        !isLoading && services == null && <tr>
                                            <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                        </tr>
                                        }

                                        { 
                                            !isLoading && services != null &&
                                            services.map((service,key) => {
                                                return (
                                                    <tr className={`row m-0 ${service.hasSibling ? 'hasSibling' : ''} ${service.parentId != null ? 'hasParent' : ''}`} key={key}>
                                                        <td className="col">
                                                            {
                                                                <img
                                                                  className="icon"
                                                                  src={service.logo || OTOPARLAK_LOGO}
                                                                  alt={`${service.name}`}
                                                                  title={`${service.name}`}
                                                                />
                                                            }
                                                        </td>
                                                        <th className="col">{service.name}</th>
                                                        <td className="col">{service.price}</td>
                                                        <td className="col">{service.slotDuration}</td>
                                                        <td className="col">
                                                            <Switch
                                                                checked={service.hasSibling}
                                                                className="switch"
                                                                onChange={() => this.changeSiblingStatus(key,service._id,service.hasSibling)}
                                                            />
                                                        </td>
                                                        <td className="col">
                                                            <Switch
                                                                checked={service.status}
                                                                className="switch"
                                                                onChange={() => this.changeStatus(key,service._id,service.status)}
                                                            />
                                                        </td>
                                                        <td className="col">
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    className="btn-icon-only text-light"
                                                                    href="#pablo"
                                                                    role="button"
                                                                    size="sm"
                                                                    color=""
                                                                    onClick={e => e.preventDefault()}
                                                                >
                                                                    <i className="fas fa-ellipsis-v" style={{color:'#000'}}/>
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                                    <a className="dropdown-item" onClick={() => this.goToEdit(service)}>
                                                                        <i className="fas fa-edit mr-4"></i> Edit
                                                                    </a>
                                                                    <a className="dropdown-item" onClick={() => this.goToAdditional(service, 'service')}>
                                                                        <i className="fas fa-plus mr-4"></i> Additional Service
                                                                    </a>
                                                                    <a className="dropdown-item" onClick={() => this.goToAdditional(service, 'solution')}>
                                                                        <i className="fas fa-plus mr-4"></i> Solution
                                                                    </a>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                        {/* <td  className="col">
                                                            <i onClick={() => this.goToEdit(service)} className="fa fa-edit btn p-1"></i>
                                                            <i  onClick={() => this.goToAdditionalService(service)} style={{marginLeft: 10}} className="fa fa-plus btn p-1"></i>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>

                <Modal size="lg" isOpen={siblingAddModal} toggle={(e) => this.addSiblingModal()} className={this.props.className}>
                    <ModalHeader toggle={(e) => this.addSiblingModal()}><span style={{fontSize: 20}}> Add Sibling </span></ModalHeader>
                    <ModalBody>
                        <Form role="form" method="POST">
                            <div className="pl-lg-4">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-siblings"
                                    >
                                        Select Siblings*
                                    </label>  
                                    <ul>
                                        {
                                            services.map((service, key) => {
                                                return(
                                                    <>
                                                        {service_parent_id != service._id && 
                                                            <li key={key} style={{listStyleType: 'none'}}>
                                                                <input type="checkbox" onChange={e => this.handleCheckbox(e, service._id)} value={service._id} /> {service.name}
                                                            </li>
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </FormGroup>   
                            </div>
                            <div>
                                { !isLoading &&  
                                    <Button className="mt-4"  onClick={this.onSubmitSiblings.bind(this)}   color="primary" type="button" disabled={this.isNotServicesFilled()}>
                                        Submit
                                    </Button>
                                }
                                {
                                    isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                }
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    serviceData: (service) => dispatch(serviceData(service))
})

export default connect(mapStateToProps, mapDispatchToProps)(Services);
