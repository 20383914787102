import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import Header from "../../components/Headers/Header";
import { connect } from "react-redux";
import InputError from "../../components/Elements/InputError";
import Select from "react-select";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container,
  Table
} from "reactstrap";
import { getServices } from "../../actions/Services";
import { getSlots, getSlotsByDay } from "../../actions/Slots";
import { getZones } from "../../actions/Zones";
import { getLocalValue } from "../../lib/Helper";
import {
  assignServices,
  assignSlots,
  assignZones,
  getWashers,
  getAssignSlots
} from "../../actions/Washers";
import { washerData } from "../../lib/Action/Washer";
import DatePicker from "react-datepicker";
import moment from "moment";

class AssignWasher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      activeTab: 1,
      startDate: new Date(),
      services: [],
      zones: [],
      selectedServicesArray: [],
      selectedZonesArray: [],
      selectedSlotsArray: [],
      selectedServicesArrayForSlot: [],
      selectedZonesArrayForSlots: null,
      washerId: [],
      slots: [],
      assignedSlots: []
    };
  }

  componentWillMount() {
    if (this.props.WasherReducer.washerData) {
      const data = this.props.WasherReducer;
      this.setState({
        washerData: data.washerData,
        selectedServicesArray: data.washerData.services || [],
        selectedZonesArray: data.washerData.zones || [],
        washerId: data.washerData._id
      });
    }
    try {
      getServices().then(response => {
        if (response.data.success && response.data.success.data) {
          let responseData = response.data.success.data;
          this.setState({
            services: responseData,
            isLoading: false
          });
        }
      });

      getZones().then(response => {
        if (response.data.success) {
          if (response.data.success.data) {
            let responseData = response.data.success.data;
            console.log("ZONES RESPONSE", response);
            this.setState({
              zones: responseData
            });
          }
        }
      });

      // getSlots().then(response => {
      //   if (response.data.success) {
      //     if (response.data.success.data) {
      //       let responseData = response.data.success.data;

      //       let slotsArraySunday = [];
      //       let slotsArrayMonday = [];
      //       let slotsArrayTuesday = [];
      //       let slotsArrayWednesday = [];
      //       let slotsArrayThursday = [];
      //       let slotsArrayFriday = [];
      //       let slotsArraySaturday = [];

      //       let slotsArray = [];
      //       responseData.map((s, k) => {
      //         if (s.dayOfWeek === 0) {
      //           slotsArraySunday.push({
      //             dayOfWeek: s.dayOfWeek,
      //             _id: s._id,
      //             reservationLimit: s.reservationLimit,
      //             isVisible: s.isVisible,
      //             description: s.description,
      //             viewTime: s.viewTime,
      //             slotNum: s.slotNum
      //           });
      //         }
      //         if (s.dayOfWeek === 1) {
      //           slotsArrayMonday.push({
      //             dayOfWeek: s.dayOfWeek,
      //             _id: s._id,
      //             reservationLimit: s.reservationLimit,
      //             isVisible: s.isVisible,
      //             description: s.description,
      //             viewTime: s.viewTime,
      //             slotNum: s.slotNum
      //           });
      //         }
      //         if (s.dayOfWeek === 2) {
      //           slotsArrayTuesday.push({
      //             dayOfWeek: s.dayOfWeek,
      //             _id: s._id,
      //             reservationLimit: s.reservationLimit,
      //             isVisible: s.isVisible,
      //             description: s.description,
      //             viewTime: s.viewTime,
      //             slotNum: s.slotNum
      //           });
      //         }
      //         if (s.dayOfWeek === 3) {
      //           slotsArrayWednesday.push({
      //             dayOfWeek: s.dayOfWeek,
      //             _id: s._id,
      //             reservationLimit: s.reservationLimit,
      //             isVisible: s.isVisible,
      //             description: s.description,
      //             viewTime: s.viewTime,
      //             slotNum: s.slotNum
      //           });
      //         }
      //         if (s.dayOfWeek === 4) {
      //           slotsArrayThursday.push({
      //             dayOfWeek: s.dayOfWeek,
      //             _id: s._id,
      //             reservationLimit: s.reservationLimit,
      //             isVisible: s.isVisible,
      //             description: s.description,
      //             viewTime: s.viewTime,
      //             slotNum: s.slotNum
      //           });
      //         }
      //         if (s.dayOfWeek === 5) {
      //           slotsArrayFriday.push({
      //             dayOfWeek: s.dayOfWeek,
      //             _id: s._id,
      //             reservationLimit: s.reservationLimit,
      //             isVisible: s.isVisible,
      //             description: s.description,
      //             viewTime: s.viewTime,
      //             slotNum: s.slotNum
      //           });
      //         }
      //         if (s.dayOfWeek === 6) {
      //           slotsArraySaturday.push({
      //             dayOfWeek: s.dayOfWeek,
      //             _id: s._id,
      //             reservationLimit: s.reservationLimit,
      //             isVisible: s.isVisible,
      //             description: s.description,
      //             viewTime: s.viewTime,
      //             slotNum: s.slotNum
      //           });
      //         }
      //       });

      //       slotsArray = {
      //         1: slotsArraySunday,
      //         2: slotsArrayMonday,
      //         3: slotsArrayTuesday,
      //         4: slotsArrayWednesday,
      //         5: slotsArrayThursday,
      //         6: slotsArrayFriday,
      //         7: slotsArraySaturday
      //       };
      //       this.setState({
      //         slotsArray: slotsArray,
      //         slots: responseData,
      //         isLoading: false
      //       });
      //     }
      //   }
      // });
    } catch (err) {
      console.log(err);
    }
  }
  isNotServicesFilled() {
    const { selectedServicesArray } = this.state;
    return selectedServicesArray.length == 0 ? true : false;
  }

  isNotSlotsFilled() {
    const { selectedSlotsArray } = this.state;
    return selectedSlotsArray.length == 0 ? true : false;
  }

  isNotZonesFilled() {
    const { selectedZonesArray, serviceId } = this.state;
    return selectedZonesArray.length == 0 || serviceId === "";
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };
  onSubmitServices(e) {
    e.preventDefault();
    const {
      selectedServicesArray,
      washerId,
      washerData,
      selectedZonesArray
    } = this.state;

    this.setState({ isLoading: true });

    assignServices({
      _id: washerId,
      services: selectedServicesArray
    })
      .then(response => {
        console.log("aAAAAAA", response.data);
        console.log("aAAAAAA", this.props.WasherReducer.washerData.zones);

        if (response.data.success) {
          if (
            selectedZonesArray === this.props.WasherReducer.washerData.zones
          ) {
            return response;
          }
          return assignZones({
            _id: washerId,
            zones: selectedZonesArray
          });
        } else {
          this.setState({
            err: true,
            message: "Unable to edit!",
            isLoading: false
          });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 1000);
        }
      })
      .then(response => {
        if (response.data.success) {
          getWashers().then(response => {
            if (response.data.success) {
              if (response.data.success.data.users) {
                let responseData = response.data.success.data.users;

                responseData.users.map((washer, key) => {
                  if (washer._id === washerId) {
                    this.props.washerData(washer);
                  }
                });
              }
            }
          });
          this.setState({
            selectedServicesArrayForSlot: selectedServicesArray
          });
          this.toggleTab(2);
          this.setState({
            err: false,
            success: false,
            message: "Services assigned successfully!"
          });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 1000);
        } else {
          this.setState({
            err: true,
            message: "Unable to edit!",
            isLoading: false
          });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 1000);
        }
      })
      .catch(error => {
        this.setState({ err: true, message: "Server Error!" });
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 1000);
      });
  }
  selectDateChange = date => {
    if (!date) { return; }
    this.setState({
      startDate: date
    });
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    this.setState({
      date: year + "-" + month + "-" + day,
      dayType: date.getDay(),
      slotsAssignSlots: []
    });
  };

  showSlots = e => {
    this.setState({ isLoading: true });
    e.preventDefault();
    const {
      selectedServicesArrayForSlot,
      selectedZonesArrayForSlots,
      washerId,
      date,
      slotsArray,
      startDate
    } = this.state;
    const dayOfWeek = moment(date).day();
    if (
      selectedServicesArrayForSlot.length >= 0 &&
      selectedZonesArrayForSlots !== null
    ) {
      getAssignSlots({
        _id: washerId,
        services: selectedServicesArrayForSlot,
        zoneId: selectedZonesArrayForSlots,
        date: moment(startDate).format("YYYY-MM-DD"),
        dayOfWeek: dayOfWeek
      }).then(response => {
        console.log("Assigned Slots: ", response.data);
        if (response.data.success) {
          let responseData = response.data.success.data;
          this.setState({
            assignedSlots: responseData,
            isLoading: false,
            slots: responseData,
            showSlots: true
          });
        }
      });
    } else {
      this.setState({
        serviceError: true,
        regionError: true,
        isLoading: false
      });
    }
  };
  handleSelectSlots = (slotId, assign) => {
    let slotsArray = [...this.state.slots];
    slotsArray.find(x => x._id === slotId).assigned = !assign;
    this.setState({ slots: slotsArray });
  };

  async onSubmitSlots(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    const {
      slots,
      washerId,
      selectedServicesArrayForSlot,
      selectedZonesArrayForSlots,
      date
    } = this.state;
    const dayOfWeek = moment(date).day();
    let assignedSlots = [];
    console.log(slots);

    Promise.all(
      slots.map(slot =>
        slot.assigned === true ? assignedSlots.push(slot._id) : null
      )
    )
      .then(res =>
        assignSlots({
          _id: washerId,
          slots: assignedSlots,
          services: selectedServicesArrayForSlot,
          zoneId: selectedZonesArrayForSlots,
          date: moment(date).format("YYYY-MM-DD")
        })
      )
      .then(response => {
        if (response.data.success) {
          getWashers().then(response => {
            if (response.data.success) {
              if (response.data.success.data.users) {
                let responseData = response.data.success.data.users;

                responseData.users.map((washer, key) => {
                  if (washer._id === washerId) {
                    this.props.washerData(washer);
                  }
                });
              }
            }
          });

          this.setState({
            err: false,
            success: true,
            message: "Slots assigned successfully!"
          });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        } else {
          this.setState({
            err: true,
            message: "Unable to edit!",
            isLoading: false
          });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        }
      })
      .catch(error => {
        this.setState({ err: true, message: "Server Error!" });
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 3000);
      });
  }

  render() {
    const {
      err,
      success,
      message,
      isLoading,
      activeTab,
      services,
      zones,
      selectedServicesArray,
      selectedSlotsArray,
      selectedServicesArrayForSlot,
      selectedZonesArray,
      selectedZonesArrayForSlots,
      slots,
      showSlots
    } = this.state;
    console.log("seltedServices", selectedServicesArray);
    console.log("Services", services);
    console.log("Zones", selectedZonesArray);
    console.log("Zoness", zones);
    console.log("Slots", slots);

    //Select Services

    const servicesOptions = services.map(service => {
      return { value: service._id, label: service.name };
    });
    const servicesValues = [];
    selectedServicesArray.map(service => {
      if (services.length > 0) {
        const select = services.find(x => x._id === service);

        if (select)
          servicesValues.push({ value: select._id, label: select.name });
      }
      //return { value: service._id, label: service.name };
    });
    console.log("servicesValues", servicesValues);
    //Select Regions
    const zonesOptions = zones.map(service => {
      return { value: service._id, label: service.name };
    });
    const zonesValues = [];
    selectedZonesArray.map(zone => {
      if (zones.length > 0) {
        const select = zones.find(x => x._id === zone);
        console.log("select Zone", select);
        if (select) zonesValues.push({ value: select._id, label: select.name });
      }
      //return { value: service._id, label: service.name };
    });
    const selectedServicesForSlotValues = selectedServicesArrayForSlot.map(
      service => {
        if (servicesValues.length > 0) {
          const select = servicesValues.find(x => x.value === service);
          if (select) return { value: select.value, label: select.label };
        }
      }
    );
    let selectedZonessForSlotValues = null;
    console.log("Select zones", selectedZonesArrayForSlots);
    if (servicesValues.length > 0) {
      const select = zonesValues.find(
        x => x.value === selectedZonesArrayForSlots
      );
      if (select)
        selectedZonessForSlotValues = {
          value: select.value,
          label: select.label
        };
    }
    let slotsArray = [];
    if (showSlots) {
      let row = [];
      for (let index = 0; index < slots.length; index++) {
        const element = slots[index];
        row.push(element);
        if (index % 6 === 5) {
          slotsArray.push(row);
          row = [];
        }
      }
      if (slots.length % 6 !== 0) {
        slotsArray.push(row);
      }
    }

    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={4} />
                    <Col sm={8}>
                      <Link to="/dashboard/washers">
                        <Button
                          className="pull-right"
                          color="primary"
                          style={{ float: "right" }}
                          size="sm"
                        >
                          Back To Technicians
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody style={{ background: "#f5f5f5" }}>
                  <Row className=" m-0">
                    <Col lg="12">
                      <ul className="stepper stepper-horizontal mr-10 pr-9">
                        <li className={activeTab === 1 ? "completed" : ""}>
                          <a>
                            <span className="circle">
                              <i
                                className="fa fa-map-marked-alt "
                                aria-hidden="true"
                              />
                            </span>
                            <span className="label text-black">
                              Services and Regions
                            </span>
                          </a>
                        </li>
                        <div className="line" />
                        <li className={activeTab === 2 ? "completed" : ""}>
                          <a>
                            <span className="circle">
                              <i
                                className="fa fa-edit pt-1"
                                aria-hidden="true"
                              />
                            </span>
                            <span className="label text-black">Slots</span>
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>

                  <Row>
                    {err && (
                      <Alert color="danger" className="center">
                        {message}
                      </Alert>
                    )}
                    {success && (
                      <Alert color="success" className="center">
                        {message}
                      </Alert>
                    )}
                    <Col sm="12">
                      <Form role="form" method="POST">
                        <Row>
                          {activeTab === 1 && (
                            <Col sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-services"
                                >
                                  Select Services*
                                </label>
                                <Select
                                  options={servicesOptions}
                                  isMulti={true}
                                  value={servicesValues}
                                  isLoading={services.length <= 0}
                                  onChange={selectedOption => {
                                    selectedOption = selectedOption
                                      ? selectedOption
                                      : [];
                                    let array = selectedOption.map(
                                      option => option.value
                                    );
                                    if (array === null) array = [];
                                    this.setState({
                                      selectedServicesArray: array
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {activeTab === 1 && (
                            <Col sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-zones"
                                >
                                  Select Regions*
                                </label>
                                <Select
                                  options={zonesOptions}
                                  isMulti={true}
                                  isLoading={zones.length <= 0}
                                  value={zonesValues}
                                  onChange={selectedOption => {
                                    selectedOption = selectedOption
                                      ? selectedOption
                                      : [];
                                    let array = selectedOption.map(
                                      option => option.value
                                    );
                                    console.log(array);
                                    if (array === null) array = [];
                                    this.setState({
                                      selectedZonesArray: array
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {activeTab === 2 && (
                            <Col sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-services"
                                >
                                  Select Services*
                                </label>
                                <Select
                                  options={servicesValues}
                                  isMulti={true}
                                  value={selectedServicesForSlotValues}
                                  isLoading={services.length <= 0}
                                  onChange={selectedOption => {
                                    selectedOption = selectedOption
                                      ? selectedOption
                                      : [];
                                    let array = selectedOption.map(
                                      option => option.value
                                    );
                                    if (array === null) array = [];
                                    this.setState({
                                      selectedServicesArrayForSlot: array
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          )}
                          {activeTab === 2 && (
                            <Col sm="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-services"
                                >
                                  Select Region
                                </label>
                                <Select
                                  options={zonesValues}
                                  isMulti={false}
                                  value={selectedZonessForSlotValues}
                                  isLoading={services.length <= 0}
                                  onChange={selectedOption => {
                                    this.setState({
                                      selectedZonesArrayForSlots:
                                        selectedOption.value
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          )}
                        </Row>
                        {activeTab === 2 && (
                          <Row>
                            <Col md="6" sm="12">
                              <FormGroup
                                style={{ display: "grid" }}
                                className="datePickerDiv"
                              >
                                <label
                                  className="form-control-label"
                                  htmlFor="input-dob"
                                >
                                  Select Date
                                </label>
                                <InputGroup
                                  style={{ width: "50%" }}
                                  className="datepicker-input-group input-shadow"
                                >
                                  <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.selectDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    className="datepicker-form-control border-none"
                                    popperPlacement="bottom"
                                    minDate={new Date()}
                                    popperModifiers={{
                                      flip: {
                                        behavior: ["bottom"] // don't allow it to flip to be above
                                      },
                                      preventOverflow: {
                                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                      },
                                      hide: {
                                        enabled: false // turn off since needs preventOverflow to be enabled
                                      }
                                    }}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText className="border-none">
                                      <i className="fa fa-calendar" />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                </InputGroup>
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                        {!isLoading && activeTab === 2 && (
                          <Row className="mt--2">
                            <Col>
                              <Button
                                className="mt-4 float-left"
                                onClick={this.showSlots}
                                color="primary"
                                type="button"
                                disabled={selectedZonesArrayForSlots === null}
                              >
                                Show Slots
                              </Button>
                            </Col>
                          </Row>
                        )}
                        {!isLoading && activeTab === 2 && showSlots && (
                          <Table className="align-items-center table-flush col">
                            <thead className="thead-light">
                              {/* <tr className="row">
                                                <th className="col">
                                                    Sunday
                                                </th>
                                            </tr> */}
                            </thead>
                            <tbody>
                              {slotsArray != null &&
                                slotsArray.map((row, key) => {
                                  return (
                                    <tr className={key + "row"} key={key}>
                                      {row &&
                                        row.map((slot, index) => {
                                          return (
                                            <td
                                              className="col-2"
                                              style={{
                                                maxWidth: "16.6667% !important",
                                                width: "16.6666%"
                                              }}
                                              key={index}
                                            >
                                              <input
                                                checked={slot.assigned}
                                                disabled={slot.booked}
                                                type="checkbox"
                                                style={{
                                                  transform: "scale(2)",
                                                  marginRight: "15px"
                                                }}
                                                onChange={e =>
                                                  this.handleSelectSlots(
                                                    slot._id,
                                                    slot.assigned
                                                  )
                                                }
                                                value={slot._id}
                                              />{" "}
                                              <span
                                                style={{ fontSize: "17px" }}
                                              >
                                                {slot.viewTime}
                                              </span>
                                            </td>
                                          );
                                        })}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        )}

                        <div>
                          {!isLoading && activeTab === 1 && (
                            <Button
                              className="mt-4 float-right"
                              onClick={e => {
                                console.log("dasd");
                                this.onSubmitServices(e);
                              }}
                              color="primary"
                              type="button"
                              disabled={this.isNotServicesFilled()}
                            >
                              Next
                            </Button>
                          )}
                          {!isLoading && activeTab === 2 && (
                            <Button
                              className="mt-4 float-right"
                              onClick={e => {
                                this.onSubmitSlots(e);
                              }}
                              color="primary"
                              type="button"
                              disabled={!showSlots}
                            >
                              Submit
                            </Button>
                          )}
                          {!isLoading && activeTab === 2 && (
                            <Button
                              className="mt-4 float-left"
                              onClick={() => {
                                this.toggleTab(1);
                              }}
                              color="primary"
                              type="button"
                              disabled={this.isNotServicesFilled()}
                            >
                              Back
                            </Button>
                          )}
                          {isLoading && (
                            <Loader
                              type="Circles"
                              color="#5e72e4"
                              height={30}
                              width={30}
                            />
                          )}
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  washerData: washer => dispatch(washerData(washer))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignWasher);
