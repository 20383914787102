import React from "react";
import { toast } from "react-toastify";
import { filterWashers, getEligibleWashersForBooking, getWashers } from '../../actions/Washers';
import { filterBookings, updateBooking, reBook } from '../../actions/Bookings';
import Switch from "react-ios-switch/lib";
import { Col, Container, Input, InputGroup, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledCarousel } from 'reactstrap';
import { OTOPARLAK_LOGO } from "../../Constant/Constant";
import moment, { fn } from 'moment';
import {
  Nav,
  NavItem,
  Form,
  Label,
  Button,
  NavLink,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  TabPane,
  TabContent,
  FormGroup
} from "reactstrap";
import Loader from 'react-loader-spinner';
import Select from "react-select";
import { getZones } from '../../actions/Zones';
import BookingDetails from './BookingDetails';

const statusCodeList = [
  { statusCode: 100, statusMessage: "Reserved", color: "#bfbd25" },
  { statusCode: 101, statusMessage: "Confirmed", color: "#C3FF00" },
  { statusCode: 102, statusMessage: "Washer on the way", color: "#d2a51c" },
  { statusCode: 103, statusMessage: "Cleaning Started", color: "#45A163" },
  { statusCode: 200, statusMessage: "Cleaning Completed", color: "#6585FF" },
  { statusCode: 400, statusMessage: "Cancelled", color: "#d06c6c" },
  { statusCode: 401, statusMessage: "Cancelled (Paid)", color: "#c33030" }
];

const statusCodeToMessage = (() => {
  const codeToMessage = statusCodeList
    .reduce((acc, { statusCode, statusMessage }) =>
      ({ ...acc, [statusCode]: statusMessage}), {});
  return (statusCode) => codeToMessage[statusCode] || statusCode;
})();
const statusCodeToColor = (() => {
  const codeToMessage = statusCodeList
    .reduce((acc, { statusCode, color }) =>
      ({ ...acc, [statusCode]: color}), {});
  return (statusCode) => codeToMessage[statusCode] || '#fff';
})();
class BookingsList extends React.Component {
  constructor(props) {
    super(props);
    const { statusFilter } = this.props;

    this.state = {
      isLoading: true,
      bookings: [],
      zones: {},
      washers: {},
      bookingDetailModal: false,
      bookingDetailId: null,
      statusFilter: statusFilter || ""
    };
  }

  componentWillMount() {
    const endDate = (new Date()).toISOString(); // backend converts it to start of today.
    // const startDate = (new Date(Date.now() - 24 * 3600 * 1000)).toISOString();
    const startDate = (new Date()).toISOString(); // because backend converts it to end of today.
    Promise.all([
      filterWashers({ limit: 1000, type: 'submerchants' }),
      getZones(),
      filterBookings({ start: startDate, end: endDate })
    ]).then(([washersResponse, zonesResponse, bookingsResponse]) => {
      const nextState = { isLoading: false };
      if (washersResponse.data.success) {
        if (washersResponse.data.success.data.users) {
          const washerData = washersResponse.data.success.data.users;
          nextState.washers = washerData.users.reduce((washersById, washer) => ({
            ...washersById,
            [washer._id]: washer
          }), {});
        }
      }
      if (zonesResponse.data.success) {
        if (zonesResponse.data.success.data) {
          const zoneData = zonesResponse.data.success.data;
          nextState.zones = zoneData.reduce((zonesById, zone) => ({
            ...zonesById,
            [zone._id]: zone
          }), {});
        }
      }
      if (bookingsResponse.data.success) {
        if (bookingsResponse.data.success.data) {
          let bookingData = bookingsResponse.data.success.data;
          bookingData = bookingData.map((booking) => ({
            ...booking,
            start:new Date(booking.start)})
          );
          bookingData.sort((a, b) => b.start - a.start);
          nextState.bookings = bookingData;
        }
      }
      this.setState(nextState);
    }).catch(console.error);
  }

  openBookingDetailsModal = (booking) => {
    const bookingDetailId = booking.bookingId;
    this.setState(
      {
        bookingDetailId,
        bookingDetailModal: true
      }
    );
  };

  closeBookingDetailsModal = () => {
    this.setState({
      bookingDetailModal: false,
      bookingDetailId: null,
    });
  };

  changeBooking = (bookingUpdates) => {
    this.setState(({ bookings }) => ({
      bookings: bookings.map((booking) => booking.bookingId === bookingUpdates._id ? {
          ...booking,
          ...bookingUpdates
        } : booking)
    }));
  };

  onChange = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value});
  };

  renderBookingDetailsModal = () => {
    const {
      bookingDetailId,
      bookingDetailModal,
    } = this.state;
    return (
      <Modal
        size="xl"
        isOpen={bookingDetailModal}
        toggle={this.closeBookingDetailsModal}
      >
        <ModalHeader toggle={this.closeBookingDetailsModal}>
          <span style={{ fontSize: 20 }}>Booking Details</span>
        </ModalHeader>
        <ModalBody>
          <BookingDetails
            bookingId={ bookingDetailId }
            reloadWashers={this.filterWashers}
            onChangeBooking={this.changeBooking}
          />
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { isLoading, washers, zones, bookings, statusFilter } = this.state;
    return (
      <>
        <Row className="mb-2">
          <Col xl="2" lg="3" md="4" sm="5" style={{ display: 'flex', alignItems: 'center' }}>
            <Label>
              Filter by Status:
            </Label>
          </Col>
          <Col xl="2" lg="3" md="4" sm="5">
            <InputGroup className="input-group-alternative">
              <Input type="select" name="statusFilter" id="statusFilter" onChange={this.onChange}>
                <option selected={statusFilter === ""} value="">All</option>
                {
                  statusCodeList.map(({ statusCode, statusMessage }) => (
                    <option selected={statusFilter == statusCode} value={statusCode}>
                      { statusMessage }
                    </option>
                  ))
                }
              </Input>
            </InputGroup>
          </Col>
        </Row>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">#</th>
            <th className="col">Status</th>
            <th className="col">Start</th>
            <th className="col">Duration</th>
            <th className="col">Zone</th>
            <th className="col">Washer</th>
            <th className="col">Action</th>
          </tr>
          </thead>
          <tbody>

          {
            isLoading && <tr>
              <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
            </tr>
          }

          {
            !isLoading && bookings.length == 0 && <tr>
              <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
            </tr>
          }

          {
            !isLoading && bookings.length > 0 &&
            bookings
              .filter(({ statusCode }) => !statusFilter || statusCode == statusFilter)
              .map((booking, index) => {
                const { start, end, bookingId, statusCode, zoneId, washerId } = booking;
                const washer = washers[washerId] || {};
                const zone = zones[zoneId] || {};
                const duration = moment.duration(moment(end).diff(start));
                return(
                  <tr className="row m-0" key={`booking-${index}`}
                      style={{ backgroundColor: statusCodeToColor(statusCode), color: '#fff', textShadow: '#000 1px 1px 4px'}}>
                    <th className="col">{index + 1}</th>
                    <td className="col">{statusCodeToMessage(statusCode)}</td>
                    <td className="col">{booking.title}</td>
                    <td className="col">{`${duration.hours()}:${duration.minutes()}`}</td>
                    <td className="col">{zone.name}</td>
                    <td className="col">{`${washer.fname} ${washer.lname}`}</td>
                    <td className="col">
                      <i
                        onClick={() => this.openBookingDetailsModal(booking)}
                        title='Details' className="fa fa-eye btn p-1"></i>
                    </td>
                  </tr>
                )
              })
          }

          </tbody>
        </Table>
        {this.renderBookingDetailsModal()}
      </>
    );
  }
}

export default BookingsList;
