import { ADMIN_SUCCESS } from '../Action/Admin';

const initialState = {
    isLoading: false,
}

const Admin = (state = initialState, action) =>{
    
    if(state === null || state === undefined || action === null){
        return { ...state }
    }

    switch(action.type){
        case ADMIN_SUCCESS :
        const adminData = action.admin;
            return {
                ...state,
                adminData
            };
        default:
            return state;
    }

}

export default Admin;