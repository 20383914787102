import { SERVICE_SOLUTION_SUCCESS } from '../Action/ServiceSolution';

const initialState = {
  isLoading: false,
}

const ServiceSolutionReducer = (state = initialState, action) =>{

  if(state === null || state === undefined || action === null){
    return { ...state }
  }

  switch(action.type){
    case SERVICE_SOLUTION_SUCCESS :
      const serviceSolutionData = action.serviceSolution;
      return {
        ...state,
        serviceSolutionData: {
          ...(state.serviceSolutionData || {}),
          ...serviceSolutionData
        }
      };
    default:
      return state;
  }

}

export default ServiceSolutionReducer;
