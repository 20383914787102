import {createStore, applyMiddleware, compose} from 'redux'
import ReduxThunk from 'redux-thunk'
import Reducer from '../Reducer/index';

import { persistStore } from "redux-persist";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    Reducer, composeEnhancers(applyMiddleware(ReduxThunk))
);

export const persistor = persistStore(store);

export default store;
