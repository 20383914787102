import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import moment from "moment";
import _ from 'lodash';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardFooter, Modal, ModalHeader, ModalBody
} from 'reactstrap';
  import Loader from 'react-loader-spinner';
import DatePicker from "react-datepicker";

import { filterCustomers, updateCustomer, getCustomersReport } from "../../actions/Customers";
import Header from "../../components/Headers/Header";
import { customerData } from "../../lib/Action/Customer";
import Switch from 'react-ios-switch';

class Customers extends React.Component {
    limit = 10;
    constructor(props) {
        super(props);
        this.state = { 
            isLoading:true,
            customers: [],
            totalUsers: '',
            pages:1,
            startIndex:1,
            endIndex:1,
            current_page:1,
            keyword:'',
            sortBy:'fname',
            dir:'asc',
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});
        this.selectStartDateChange(new Date(moment().subtract(1, "months")));
        this.selectEndDateChange(new Date());
        filterCustomers(this.filterQuery()).then( response => {
            
            if(response.data.success){
                if(response.data.success.data.users){
                    let responseData = response.data.success.data.users;
                    let totalUsers = responseData.totalUsers;
                    let pages  = Math.ceil(totalUsers/this.limit );
                    let endIndex = Math.min(pages,5);
                    this.setState({
                        customers:responseData.users,
                        isLoading:false,
                        pages, endIndex,
                        totalUsers: totalUsers
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    filterQuery(){
        const  {sortBy, keyword, dir, current_page, pages }  = this.state;
        
        let query = {}
    
        if(sortBy !== ''){
          query.sortBy = sortBy;
        }
        
        if(keyword !== ''){
          query.keyword = keyword;
        }
        
        if(dir !== ''){
          query.dir = dir;
        }
    
        query.start = (current_page - 1)*this.limit;
        query.limit = this.limit;
    
        return query;
    }

    goToPage(page){
        let startIndex=this.state.startIndex;
        let endIndex = this.state.endIndex;
        const pages= this.state.pages;
        const current_page=this.state.current_page;
        if(page > current_page){
            endIndex = page <= 2 ? Math.min(5,pages):Math.min(page+2,pages);
            startIndex =  page >= pages-2 ? Math.min(page-2 , pages-5):Math.max(startIndex,page-2)
        }
        else if(page < current_page){
            endIndex = page <= 2 ? Math.min(5 , pages) : Math.min(endIndex , page+2);
            startIndex = page >= pages-2 ? Math.min(pages-5 , page-2) : Math.max(1,page-2);
        }
       
       
        this.setState({current_page:page,startIndex:startIndex,endIndex:endIndex}, () =>{
          this.getCustomers(false);
        });
    }

    getCustomers(page = true){
        this.setState({isLoading:true});
        const query = this.filterQuery();
        filterCustomers(query).then( response => {
            let totalusers = response.data.success.data.users.totalUsers;
            let pages  = Math.ceil(totalusers/this.limit );
            console.log(response);
            if(page){
                this.setState({
                customers:response.data.success.data.users.users,
                pages,
                isLoading:false
                });
            }else{
                this.setState({
                customers:response.data.success.data.users.users,
                isLoading:false
                });
            }
        }).catch(error=> {
            console.log(error)
        });
    }

    sort(by,dir){
        this.setState({sortBy:by,dir}, () =>{
          this.getCustomers();
        });
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value},() => {
            this.getCustomers();
        });
    }

    clearFilter(){
        this.setState({keyword:''},() => {
          this.getCustomers();
        });
    }

    goToEdit(customer){
        this.props.customerData(customer);
        this.props.history.push("/dashboard/customer-edit");
    }

    goToDetails(customer) {
        this.props.customerData(customer);
        this.props.history.push("/dashboard/customer-details");
    }

    changeStatus(key,id,status){
        let  { customers } = this.state;
        customers[key].accountStatus = !status;
    
        this.setState({
            customers,
        });
        
        updateCustomer({
          _id:id,
          accountStatus:!status
        }).then(response => {
            // this.componentWillMount();
        }).catch(error=> {
          this.setState({err:true,message:'Server Error!'});
        });
    
    }

    CustomPagination =() =>{
        const {startIndex,endIndex,current_page,pages}=this.state;
        return (<Pagination
            className="pagination justify-content-end mb-0"
            listClassName="justify-content-end mb-0"
        >
            <PaginationItem disabled={current_page <= 1}>
                <PaginationLink onClick={this.goToPage.bind(this,1)} first href="#" /></PaginationItem>
            {
                _.times(endIndex - startIndex +1,i  => {
                    return (
                        <PaginationItem key={startIndex+i} className={current_page === startIndex + i ? 'active' : ''}>
                            <PaginationLink
                                href="#"
                                onClick={this.goToPage.bind(this, startIndex + i )}
                            >
                                {i + startIndex }
                            </PaginationLink>
                        </PaginationItem>
                    );
                })
            }
            <PaginationItem disabled={current_page >= pages}>
                <PaginationLink onClick={this.goToPage.bind(this, pages)} last href="#" /></PaginationItem>

        </Pagination>)
    }


    toggleReportModal = () => {
        const { reportUrl, reportModal } = this.state;
        this.setState({ reportModal: !reportModal });
        if (!reportUrl && !reportModal) {
            // getBookingsReport()
            //   .then(res => {
            //     if (res.data.success.data) {
            //       this.setState({
            //         reportUrl: res.data.success.data
            //       });
            //     }
            //   })
            //   .catch(console.error);
        }
    };

    selectStartDateChange = date => {
        if (!date) {
            return;
        }
        console.log("start Date : ", date);
        this.setState(
            {
                selectStartDate: date
            },
            () => {
                const selectedDate = this.state.selectStartDate;
                var month = moment(selectedDate).format("YYYY-MM-DD");
                var day = moment(selectedDate).format("DD");
                console.log("month ", month);
                var year = selectedDate.getFullYear();
                this.setState({
                    expiryDateStart: moment(selectedDate).format("YYYY-MM-DD")
                });
            }
        );
    };
    selectEndDateChange = date => {
        if (!date) {
            return;
        }
        console.log("end Date: ", date);
        this.setState(
            {
                selectEndDate: date
            },
            () => {
                const selectedDate = this.state.selectEndDate;
                var month = moment(selectedDate).format("mm");
                var day = moment(selectedDate).format("DD");

                var year = selectedDate.getFullYear();
                this.setState({
                    expiryDateEnd: moment(selectedDate).format("YYYY-MM-DD")
                });
                console.log(this.state.expiryDateEnd);
            }
        );
    };

    downloadReport = () => {
        const { expiryDateStart, expiryDateEnd } = this.state;
        this.setState({ reportLoading: true });
        if (!expiryDateStart && !expiryDateEnd) return;
        const date = `?start=${expiryDateStart}&&end=${expiryDateEnd}`;
        getCustomersReport({ date: date })
            .then(res => {
                if (res.data.success.data) {
                    this.setState({
                        reportUrl: res.data.success.data,
                        reportLoading: false
                    });
                }
                window.open(res.data.success.data); //to open new page
            })
            .catch(err => {
                this.setState({ reportLoading: false });
                console.log(err);
            });
    };
    renderReport = () => {
        const { reportModal, reportUrl, reportLoading } = this.state;
        return (
            <>
                <Button
                    className="pull-right ml-2 mr-0"
                    color="warning"
                    style={{ float: "right" }}
                    size="sm"
                    onClick={this.toggleReportModal}
                >
                    <i className="fa fa-file-download" /> Download Report
        </Button>
                <Modal
                    size={"sz"}
                    isOpen={reportModal}
                    toggle={this.toggleReportModal}
                    className="text-center"
                >
                    <ModalHeader toggle={this.toggleReportModal}>
                        Download Report for Bookings
          </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                Start Date
                <DatePicker
                                    selected={this.state.selectStartDate}
                                    onChange={this.selectStartDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    popperPlacement="bottom"
                                    style={{ textAlign: "center" }}
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                            </Col>
                            <Col>
                                End Date
                <DatePicker
                                    selected={this.state.selectEndDate}
                                    onChange={this.selectEndDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    popperPlacement="bottom"
                                    popperModifiers={{
                                        flip: {
                                            behavior: ["bottom"] // don't allow it to flip to be above
                                        },
                                        preventOverflow: {
                                            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                        },
                                        hide: {
                                            enabled: false // turn off since needs preventOverflow to be enabled
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col className="text-center">
                                {!reportLoading && (
                                    <Button
                                        href={reportUrl}
                                        color="primary"
                                        onClick={this.downloadReport}
                                    >
                                        <i className="fa fa-file-download" /> Download Report
                  </Button>
                                )}
                                {reportLoading && (
                                    <Loader
                                        type="Circles"
                                        color="#5e72e4"
                                        height={30}
                                        width={30}
                                    />
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        );
    };
    render(){

        const {customers, isLoading, totalUsers, pages, current_page, keyword, dir, sortBy} = this.state;
        
        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={4}>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    value={keyword}
                                                    id="input-keyword"
                                                    placeholder="Search"
                                                    type="text"
                                                    name="keyword"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                { 
                                                    keyword &&  
                                                    <InputGroupAddon addonType="append">
                                                    <a onClick={this.clearFilter.bind(this)}>
                                                        <InputGroupText  style={{height:'100%'}}><i className='fas fa-times-circle'></i></InputGroupText>
                                                    </a>
                                                    </InputGroupAddon>
                                                }
                                            </InputGroup>
                                        </Col>
                                        <Col sm={8}>
                                            { this.renderReport() }
                                            <Link to="/dashboard/customer-add"><Button className="pull-right" color="primary" style={{float:'right'}} size="sm">Add Customer</Button></Link>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                {/* <CardBody>
                                        <Row>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Customer Number" />
                                            </div>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Name" />
                                            </div>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Surname" />
                                            </div>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Phone Number" />
                                            </div>
                                            <div className="col-2">
                                                <Input type="text" placeholder="Email" />
                                            </div>
                                            <div className="col-2" style={{textAlign: 'center'}}>
                                                <Button color="primary">Search</Button>
                                            </div>
                                        </Row>
                                    </CardBody> */}
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr className="row m-0">
                                            {/* <th className="col">S. No.</th> */}
                                            <th className="col" onClick={this.sort.bind(this,'fname',`${dir === 'asc' ? 'desc' : 'asc'}`)}>First name<i className={sortBy === 'fname'? `fas ${dir === 'asc' ?'fa-sort-up':'fa-sort-down'} mr-4`:'' }></i></th>
                                            <th className="col" onClick={this.sort.bind(this,'lname',`${dir === 'asc' ? 'desc' : 'asc'}`)}>Last name<i className={sortBy === 'lname'? `fas ${dir === 'asc' ?'fa-sort-up':'fa-sort-down'} mr-4`:'' }></i></th>
                                            <th className="col" onClick={this.sort.bind(this,'email',`${dir === 'asc' ? 'desc' : 'asc'}`)}>Email<i className={sortBy === 'email'? `fas ${dir === 'asc' ?'fa-sort-up':'fa-sort-down'} mr-4`:'' }></i></th>
                                            <th className="col" onClick={this.sort.bind(this,'loginPhoneNo',`${dir === 'asc' ? 'desc' : 'asc'}`)}>Phone No.<i className={sortBy === 'loginPhoneNo'? `fas ${dir === 'asc' ?'fa-sort-up':'fa-sort-down'} mr-4`:'' }></i></th>
                                            <th className="col">Status</th>
                                            <th className="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                            !isLoading && customers == null && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                            </tr>
                                        }

                                        {
                                            !isLoading && totalUsers > 0 &&
                                            customers.map((customer, key) => {
                                                return(
                                                    <tr className="row m-0" key={key}>
                                                        {/* <th className="col">{key + 1}</th> */}
                                                        <td className="col">{customer.fname}</td>
                                                        <td className="col">{customer.lname}</td>
                                                        <td className="col">{customer.email}</td>
                                                        <td className="col">{customer.loginPhoneNo}</td>
                                                        <td className="col">
                                                            <Switch
                                                                checked={customer.accountStatus}
                                                                className="switch"
                                                                onChange={() => this.changeStatus(key,customer._id,customer.accountStatus)}
                                                            />
                                                        </td>
                                                        <td className="col">
                                                            <i style={{marginRight: 15}} onClick={() => this.goToEdit(customer)} className="fa fa-edit btn p-1"></i>
                                                            <i onClick={() => this.goToDetails(customer)} className="fa fa-file-invoice btn p-1"></i>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </Table>
                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <this.CustomPagination/>
                                        {/* <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            { 
                                                pages > 1 &&
                                                
                                                _.times(pages, i => {
                                                return (
                                                    <PaginationItem key={i} className={current_page === i+1 ?'active':''}>
                                                        <PaginationLink
                                                            href="#"
                                                            onClick={this.goToPage.bind(this,i+1)}
                                                        >
                                                            {i+1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                                })
                                            }
                                        </Pagination> */}
                                    </nav>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

// export default Customers;
const mapStateToProps = (state) =>({
    ...state
  })
  
  const mapDispatchToProps = (dispatch) =>({
        customerData: (customer) => dispatch(customerData(customer))
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(Customers);
