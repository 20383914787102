import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
  CardHeader,
  Alert
} from "reactstrap";
import Loader from 'react-loader-spinner';
import { loginUser } from "../../lib/Action/Login";
import Rules from "../../lib/Rules/FormRules.js";
import FormValidator from "../../lib/Rules/FormValidator"; 
import InputError from "../../components/Elements/InputError";
import { userLogin } from "../../actions/Login";
import { setToken, getToken } from "../../lib/Helper";


class Login extends React.Component {

  constructor(props){
    super(props);

    this.validator = new FormValidator(Rules.login);

    this.state = {
      email : '',
      password: '',
      err:false,
      loginsuccess:false,
      message:'',
      showPasword:false,
      validation: this.validator.valid(),
      isLoading: false
    }

    this.formsubmitted = false;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {email , password} = this.state;
    const validation = this.validator.validate(this.state);
    this.setState({ validation,isLoading:true });
    
    if(validation.isValid){
      userLogin({
        email, 
        password
      }).then(response => {
        if(response.data.success){
          if(response.data.success.data.token){

            this.setState({
              loginsuccess:true,
              message:'Login successful!'
            });

            setToken(response.data.success.data.token);

            this.setState({
              message:'Logging you in....'
            });

            this.setState({
              isLoading:false
            },()=>{
              this.props.history.push("/dashboard/index")
            });
          }
        } else {
          this.setState({err:true, isLoading:false, message:'Invalid Credentials!'}); toast.error('Invalid Credentials!');
        }
      }).catch(error => {
        this.setState({err:true, isLoading:false, message:'Server Error!'});
      });
      // this.props.loginUser('email@email.com', '12345678');
      
    } else {
      this.setState({ isLoading:false });
    }
  }

  onChange = (e) => {
    const {name, value} = e.target;

    this.setState({[name]: value});
  }

  togglePassword(e){
    e.preventDefault();
    let {showPasword} = this.state;
      showPasword = !showPasword;
    this.setState({showPasword})
  }

  render() {

    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation;

    const {showPasword, err, message, loginsuccess, isLoading, email, password} = this.state;

    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent" style={{padding:0}}>
              <div className="text-center text-muted mb-2 mt-2" style={{fontSize: 20}}>
                <span>Sign In</span>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              {err && <Alert color="danger">
                {message}
              </Alert>}
              {/* { loginsuccess && <Alert color="success">
                <i className="fas fa-check-circle"></i>{message}
              </Alert>} */}
              <Form role="form">
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" name="email" defaultValue={email} onChange={this.onChange.bind(this)} />
                  </InputGroup>
                  <InputError show={validation.email.isValid} message={validation.email.message} />
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type={showPasword?'text':'password'} name="password" defaultValue={password} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <a onClick={this.togglePassword.bind(this)}>
                        <InputGroupText  style={{height:'100%'}}><i className={!showPasword?'fas fa-eye-slash':'fas fa-eye'}></i></InputGroupText>
                      </a>
                    </InputGroupAddon>
                  </InputGroup>
                  <InputError show={validation.password.isValid} message={validation.password.message} />
                </FormGroup>
                <Row className="mt-3">
                  <Col xs="6">
                    <Link to="/auth/forgotpassword" className="text-light">
                      <span className="align-middle text-muted">Forgot password?</span>
                    </Link>
                  </Col>
                  <Col xs="6">
                    {isLoading && 
                      <div style={{position: 'relative'}}>
                        <div style={{position: "absolute", right: 30}}>
                          <Loader 
                            type="Circles"
                            color="#00BFFF"
                            height="30"	
                            width="30"
                          />
                        </div>
                      </div>
                    }

                    {!isLoading && <Button className="float-right" color="primary" type="button" onClick={this.onSubmit.bind(this)}>
                      Sign in
                    </Button> }
                  </Col>
                </Row>
                {/* <div class="row mt-4">
                  <div class="col-sm-6">
                    <span class="d-inline-block align-middle text-muted">Forget Password?</span>
                  </div>
                  <div class="col-sm-6">
                    <Button className="float-right" color="primary" type="button" onClick={this.onSubmit.bind(this)}>
                      Sign in
                    </Button>
                  </div>
                </div> */}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) =>({
  ...state
})

const mapDispatchToProps = (dispatch) =>({
  loginUser: (email, password) => dispatch(loginUser(email, password))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
