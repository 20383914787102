import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import _ from 'lodash';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter, Modal, ModalHeader, ModalBody, Label
} from 'reactstrap';
import Loader from 'react-loader-spinner';

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import { getCustomerDetails, updateAddress } from "../../actions/Customers";
import Header from "../../components/Headers/Header";
import { customerData } from "../../lib/Action/Customer";
import Switch from 'react-ios-switch';
import { OTOPARLAK_LOGO } from '../../Constant/Constant';
import InputError from '../../components/Elements/InputError';

class CustomerDetails extends React.Component {
  limit = 10;
  constructor(props) {
    super(props);

    this.validator = new FormValidator(Rules.editAddress);
    this.state = {
      isLoading:false,
      activeTab: 1,
      isUpdatingAddress: false,
      editAddressModal: false,
      editAddressIndex: '',
      editAddressName: '',
      editAddressDetail: '',
      editAddressLatitude: '',
      editAddressLongitude: '',
      addresses: [],
      cars: [],
      creditCards: [],
      validation: this.validator.valid(),
    };

    this.maybeRenderLoadingInTr = this.maybeRenderLoadingInTr.bind(this);
    this.renderCars = this.renderCars.bind(this);
    this.renderAddresses = this.renderAddresses.bind(this);
    this.renderCreditCardAssociationImage = this.renderCreditCardAssociationImage.bind(this);
    this.renderCreditCards = this.renderCreditCards.bind(this);
    this.renderEditAddressModal = this.renderEditAddressModal.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.updateEditAddress = this.updateEditAddress.bind(this);
    this.openEditAddressModal = this.openEditAddressModal.bind(this);
    this.closeEditAddressModal = this.closeEditAddressModal.bind(this);
  }
  onChange(e){
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentWillMount(){
    if (this.props.CustomerReducer.customerData) {
      /*
      this.setState({
        addresses: this.props.CustomerReducer.customerData.addresses,
        cars: this.props.CustomerReducer.customerData.cars,
        creditCards: this.props.CustomerReducer.customerData.creditCards
      });
      /*/
      this.setState({ isLoading: true });
      getCustomerDetails({
        _id: this.props.CustomerReducer.customerData._id
      }).then(response => {
        if (response.data.success.data) {
          const customerData = response.data.success.data;
          this.setState({
            addresses: customerData.addresses || [],
            cars: customerData.cars || [],
            creditCards: customerData.creditCards || [],
            isLoading: false
          })
        } else {
          throw new Error(response.data);
        }
      }).catch(error => {
        console.error(error);
        this.setState({
          isLoading: false
        })
      })
    // */
    } else {
      this.props.history.push('/dashboard/customers');
    }
  }

  openEditAddressModal(addressIndex) {
    this.setState(({addresses}) => ({
      editAddressModal: true,
      editAddressIndex: addressIndex,
      editAddressName: addresses[addressIndex].name,
      editAddressDetail: addresses[addressIndex].detail,
      editAddressLongitude: addresses[addressIndex].location[0],
      editAddressLatitude: addresses[addressIndex].location[1],
    }));
  }
  closeEditAddressModal() {
    this.setState({
      editAddressModal: false
    });
  }
  updateEditAddress() {
    const {addresses, editAddressIndex, editAddressName, editAddressDetail, editAddressLongitude, editAddressLatitude} = this.state;
    const validation = this.validator.validate(this.state);
    this.setState({
      validation
    });
    if (validation.isValid) {
      this.setState({
        isUpdatingAddress: true
      });

      const payload = {
        customerId: this.props.CustomerReducer.customerData._id,
        _id: addresses[editAddressIndex]._id,
        name: editAddressName,
        detail: editAddressDetail,
        longitude: editAddressLongitude,
        latitude: editAddressLatitude
      };

      updateAddress(payload).then(response => {
        if (response.data.success.data) {
          const nextAddresses = [...addresses];
          nextAddresses[editAddressIndex] = {
            ...nextAddresses[editAddressIndex],
            ...payload,
            location: [editAddressLongitude, editAddressLatitude]
          };
          this.setState({
            addresses: nextAddresses,
            isUpdatingAddress: false,
            editAddressModal: false
          });
        } else {
          throw new Error(response.data);
        }
      }).catch(error => {
        console.error(error);
        this.setState({
          isUpdatingAddress: false
        });
      })
    }
  }

  maybeRenderLoadingInTr() {
    const { isLoading } = this.state;
    if (!isLoading) {
      return null;
    }
    return (
      <tr>
        <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
      </tr>
    );
  }


  renderCars() {
    const { activeTab, cars } = this.state;
    if (activeTab !== 1) {
      return null;
    }
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
        <tr className="row m-0">
          <th className="col">#</th>
          <th className="col">Logo</th>
          <th className="col">Brand</th>
          <th className="col">Model</th>
          <th className="col">Motor Brand</th>
          <th className="col">Plate Number</th>
          <th className="col">Color</th>
          <th className="col">Status</th>
        </tr>
        </thead>
        <tbody>
        {this.maybeRenderLoadingInTr()}
        { cars && cars.map(({carImage, isDeleted, brandId, modelId, motorBrandId, plateNo, color }, index) => (
          <tr className="row m-0" key={`car-${index}`}>
            <td className="col">{index+1}</td>
            <td className="col">
              <img src={carImage || OTOPARLAK_LOGO} className="icon" />
            </td>
            <td className="col">
              { (brandId || {}).name }
            </td>
            <td className="col">
              { (modelId || {}).name }
            </td>
            <td className="col">
              { (motorBrandId || {}).name }
            </td>
            <td className="col">{plateNo}</td>
            <td className="col">{color}</td>
            <td className="col">
              <Switch
                checked={!isDeleted}
                className="switch"
              />
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    );
  };

  renderAddresses() {
    const { activeTab, addresses } = this.state;
    if (activeTab !== 2) {
      return null;
    }
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">#</th>
            <th className="col">Name</th>
            <th className="col">Detail</th>
            <th className="col">Location (Lat/Lon)</th>
            <th className="col">Status</th>
            <th className="col">Action</th>
          </tr>
        </thead>
        <tbody>
        {this.maybeRenderLoadingInTr()}
        { addresses && addresses.map(({name, detail, location:[lng, lat], isDeleted}, index) => (
          <tr className="row m-0" key={`address-${index}`}>
            <td className="col">{index+1}</td>
            <td className="col">{name}</td>
            <td className="col">{detail}</td>
            <td className="col">
              <a href={`http://maps.google.com/?q=${lat},${lng}`} target="_blank" rel="noreferrer noopener">
                <i style={{marginRight: 15 }} className="fa fa-map-pin" />
                {lat} / {lng}
              </a>
            </td>
            <td className="col">
              <Switch checked={!isDeleted} className="switch" />
            </td>
            <td className="col">
              <i className="fa fa-pen btn p-1" onClick={() => this.openEditAddressModal(index)} />
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    );
  }

  renderCreditCardAssociationImage(association) {
    let imageUrl = null;
    switch ((association || '').toLowerCase()) {
      case 'visa':
        imageUrl = 'http://d1r6jkt5r6dt8f.cloudfront.net/cdn/assets/customer/cards/visa.png';
        break;
      case 'master_card':
        imageUrl = 'http://d1r6jkt5r6dt8f.cloudfront.net/cdn/assets/customer/cards/master.png';
        break;
      case 'american_express':
        imageUrl = 'http://d1r6jkt5r6dt8f.cloudfront.net/cdn/assets/customer/cards/americanexpress.png';
        break;
      default:
    }
    return imageUrl && <img src={imageUrl} style={{ width: '3rem', height: '2rem' }} />;
  }

  renderCreditCards() {
    const { activeTab, creditCards } = this.state;
    if (activeTab !== 3) {
      return null;
    }

    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
        <tr className="row m-0">
          {/*<th className="col">#</th>*/}
          <th className="col">Alias</th>
          <th className="col">Type</th>
          <th className="col">Association</th>
          <th className="col">Family</th>
          <th className="col">Bank</th>

          <th className="col">Last 4 Digits</th>

          <th className="col">Expire YY/MM</th>

          <th className="col">Default</th>
          <th className="col">Validated</th>
          <th className="col">Active</th>
        </tr>
        </thead>
        <tbody>
        {this.maybeRenderLoadingInTr()}
        { creditCards && creditCards
          .map(({cardType, cardAssociation, cardFamily, cardBankName, cardAlias, lastFourDigits,
                  expireYear = '??', expireMonth = '??', isDefault, isValidated, isActivated }, index) => (
            <tr className="row m-0" key={`creditCard-${index}`}>
              {/*<td className="col">{index+1}</td>*/}
              <td className="col">{cardAlias}</td>
              <td className="col">{(cardType||'').replace(/_/g,' ')}</td>
              <td className="col">
                {this.renderCreditCardAssociationImage(cardAssociation)}
              </td>
              <td className="col">{cardFamily}</td>
              <td className="col">{cardBankName}</td>
              <td className="col">{lastFourDigits}</td>

              <td className="col">{`${expireYear}/${expireMonth}`}</td>

              <td className="col">
                <Switch checked={isDefault} className="switch" />
              </td>
              <td className="col">
                <Switch checked={isValidated} className="switch" />
              </td>
              <td className="col">
                <Switch checked={isActivated} className="switch" />
              </td>
            </tr>
        ))}
        </tbody>
      </Table>
    );
  }

  isNotFilledAddress(){
    const {addresses, editAddressIndex, editAddressName, editAddressDetail, editAddressLongitude, editAddressLatitude} = this.state;
    const editAddress = addresses[editAddressIndex] || {};
    return (
        editAddress.name === editAddressName &&
        editAddress.detail === editAddressDetail &&
        editAddress.location[0] === editAddressLongitude &&
        editAddress.location[1] === editAddressLatitude
    );
  }

  renderEditAddressModal() {
    const { validation, editAddressModal, editAddressIndex, editAddressName, editAddressDetail, editAddressLongitude, editAddressLatitude, isUpdatingAddress } = this.state;
    return (

      <Modal size="lg" isOpen={editAddressModal} toggle={this.closeEditAddressModal} className={this.props.className}>
        <ModalHeader toggle={this.closeEditAddressModal}><span style={{fontSize: 20}}> Edit Address </span></ModalHeader>
        <ModalBody>
          <Form role="form">
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="editAddressName">Address Name</Label>
                  <Input type="text" name="editAddressName" id="editAddressName" placeholder="Address Name" onChange={this.onChange.bind(this)} value={editAddressName} />
                  <InputError show={validation.editAddressName.isInvalid} message={validation.editAddressName.message} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="editAddressDetail">Address Detail</Label>
                  <Input type="text" name="editAddressDetail" id="editAddressDetail" placeholder="Address Detail"  onChange={this.onChange.bind(this)} value={editAddressDetail}/>
                  <InputError show={validation.editAddressDetail.isInvalid} message={validation.editAddressDetail.message} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="editAddressLongitude">Address Location Longitude</Label>
                  <Input type="text" name="editAddressLongitude" id="editAddressLongitude" placeholder="Address Location Longitude"  onChange={this.onChange.bind(this)} value={editAddressLongitude}/>
                  <InputError show={validation.editAddressLongitude.isInvalid} message={validation.editAddressLongitude.message} />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="editAddressLatitude">Address Location Latitude</Label>
                  <Input type="text" name="editAddressLatitude" id="editAddressLatitude" placeholder="Address Location Latitude"  onChange={this.onChange.bind(this)} value={editAddressLatitude}/>
                  <InputError show={validation.editAddressLatitude.isInvalid} message={validation.editAddressLatitude.message} />
                </FormGroup>
              </Col>
            </Row>
            <div className="text-right">
              { !isUpdatingAddress &&
              <Button
                disabled={this.isNotFilledAddress()}
                color="primary"
                type="button"
                onClick={this.updateEditAddress}
              >
                Save Address
              </Button>
              }
              {
                isUpdatingAddress && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
              }
            </div>
          </Form>
        </ModalBody>
      </Modal>
    )
  }

  render(){

    const { activeTab } = this.state;
    const { fname, lname } = this.props.CustomerReducer.customerData;

    return(
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7 mb-5" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={4}>
                      { fname } { lname }
                    </Col>
                    <Col sm={8}>
                      <Link to="/dashboard/customers">
                        <Button className="pull-right" color="primary" style={{float:'right'}} size="sm">
                          Back to Customers
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <Row className="m-0">
                  <Col lg="12">
                    <ul className="stepper stepper-horizontal">
                      <li className={activeTab === 1? 'completed':''}>
                        <a onClick={() => this.toggleTab(1)}>
                          <span className="circle">
                              <i className="fa fa-shower pl-1" aria-hidden="true"></i>
                          </span>
                          <span className="label">List of Cars</span>
                        </a>
                      </li>
                      <div className="line"></div>
                      <li className={activeTab === 2? 'completed':''}>
                        <a onClick={() => this.toggleTab(2)}>
                          <span className="circle">
                              <i className="fa fa-sad-tear pt-1" aria-hidden="true"></i>
                          </span>
                          <span className="label">List of Addresses</span>
                        </a>
                      </li>
                      <div className="line"></div>
                      <li className={activeTab === 3? 'completed':''}>
                        <a onClick={() => this.toggleTab(3)}>
                          <span className="circle">
                              <i className="fa fa-sad-tear pt-1" aria-hidden="true"></i>
                          </span>
                          <span className="label">List of Credit Cards</span>
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
                { this.renderCars() }
                { this.renderAddresses() }
                { this.renderCreditCards() }
              </Card>
            </div>
          </Row>
        </Container>
        { this.renderEditAddressModal() }
      </>
    )
  }
}

// export default CustomerDetails;
const mapStateToProps = (state) =>({
  ...state
})

const mapDispatchToProps = (dispatch) =>({
  customerData: (customer) => dispatch(customerData(customer))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
