import { getRequestWithToken, putRequest, postRequest, deleteRequest } from "../Constant/Api";

export const getServices = (params) => {
    const api_url = 'services';  
    return getRequestWithToken(api_url,params);
};

export const createService = (params) => {
    const api_url = `services`;
    return postRequest(api_url,params);
};

export const updateService = (params) => {

    const api_url = `services/${params._id}`;

    return putRequest(api_url,params);
};

export const updateServiceLogo = (params) => {
    const api_url = `services/${params._id}/logo`;

    return putRequest(api_url, params, { "Content-Type": 'multipart/form-data' });
};

export const getAdditionalServices = (params) => {
    const api_url = `services/${params.service_id}/additionals`;  
    return getRequestWithToken(api_url,params);
}

export const updateAdditionalService = (params) => {
    const api_url = `services/additionals/${params._id}`;
    return putRequest(api_url,params);
};

export const updateAdditionalServiceLogo = (params) => {
    const api_url = `services/additionals/${params._id}/logo`;

    return putRequest(api_url, params, { "Content-Type": 'multipart/form-data' });
};

export const createAdditionalService = (params) => {
    const api_url = `services/${params.service_id}/additionals/`;
    return postRequest(api_url,params);
};

export const assignSibling = (params) => {
    const api_url = `services/${params.service_parent_id}/siblings`;
    return putRequest(api_url,params);
};

export const deleteSiblings = (params) => {
    const api_url = `services/${params.service_id}/siblings`;
    return deleteRequest(api_url,params);
};
