import React from 'react';
import Loader from 'react-loader-spinner';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SolutionUsageByWasher from './SolutionUsageByWasher';
import Table from 'reactstrap/es/Table';


class SolutionUsage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      solutionUsageByWasher: null,
      solutionUsageByWasherModal: false
    };
    this.reloadWasherData(this.props.washers);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.washers !== nextProps.washers) {
      this.reloadWasherData(nextProps.washers);
    }
  }

  reloadWasherData = (washers) => {
    if (!washers) {
      this.washerData = {};
      return;
    }
    this.washerData = washers.reduce((acc, washer) => ({
      ...acc,
      [washer._id]: washer
    }), {});
  };

  openSolutionUsageByWasherModal = (usageData) => {
    this.setState({ solutionUsageByWasher: usageData, solutionUsageByWasherModal: true });
  };

  closeSolutionUsageByWasherModal = () => {
    this.setState({ solutionUsageByWasherModal: false });
  };

  renderSolutionUsageByWasherModal = () => {
    const { solutionName } = this.props;
    const { solutionUsageByWasher, solutionUsageByWasherModal } = this.state;
    const { washerId, usageHistory, totalUsage } = (solutionUsageByWasher || {});
    const washer = this.washerData[washerId] || {};
    return (
      <Modal
        size="md"
        isOpen={solutionUsageByWasherModal}
        toggle={this.closeSolutionUsageByWasherModal}
      >
        <ModalHeader toggle={this.closeSolutionUsageByWasherModal}>
          <span style={{ fontSize: 20 }}>
            { solutionName } Usage
          </span>
          <br />
          <br />
          <span style={{ fontSize: 20 }}>
            by { washer.name } ({ totalUsage } in total)
          </span>
        </ModalHeader>
        <ModalBody>
          <SolutionUsageByWasher
            usageHistory={usageHistory}
          />
        </ModalBody>
      </Modal>
    );
  };

  render() {
    const { isLoadingWashers, usageDetails } = this.props;
    if (!usageDetails) {
      return null;
    }
    const { usageHistoryByWasher } = usageDetails;
    return (
      <>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">#</th>
            <th className="col">Washer Name</th>
            <th className="col">Total Usage</th>
            <th className="col">Action</th>
          </tr>
          </thead>
          <tbody>
          {
            usageHistoryByWasher.map((usageData, index) => {
              const { washerId, totalUsage } = usageData;
              const washer = this.washerData[washerId];
              return (
                <tr className="row m-0" key={`${washerId}`}>
                  <td className="col">
                    { index + 1 }
                  </td>
                  <td className="col">
                    {isLoadingWashers && <Loader type="Circles" color="#5e72e4" height={30} width={30}/>}
                    {!isLoadingWashers && washer.name }
                  </td>
                  <th className="col">{totalUsage}</th>
                  <td className="col">
                    <i
                      onClick={() => this.openSolutionUsageByWasherModal(usageData)}
                      className="fa fa-search btn p-1"
                    ></i>
                  </td>
                </tr>
              );
            })
          }
          { usageHistoryByWasher.length === 0 && <tr>
            <td colSpan="4" style={{ textAlign: 'center' }}>No usage </td>
          </tr> }
          </tbody>
        </Table>
        { this.renderSolutionUsageByWasherModal() }
      </>
    );
  }
}

export default SolutionUsage;

