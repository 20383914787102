import React from "react";
import { toast } from "react-toastify";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,  
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardFooter
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { getLegalList, updateLegal } from "../../actions/Legal";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Loader from 'react-loader-spinner';

class Legal extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isLoading:true,
            legals: [],
            description: '',
            isVisible: false,
            id: ''
        }
    }

    componentWillMount(){
        // this.setState({isLoading:true});
        getLegalList().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;

                    this.setState({
                        legals:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    onTypeChange(e){
        const {name, value} = e.target;
        
        if(value) {
            let { legals } = this.state;
            let legal = legals[value];
            this.setState({
                isVisible: true,
                description: legal.description,
                id: legal._id
            })
        } else {
            this.setState({
                isVisible: false
            })
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {id, description} = this.state;

        
        this.setState({ isLoading:true });

        updateLegal({
            _id: id,
            description: description
        }).then(response => {
            console.log(response);
            if(response.data.success){
                this.setState({err:false,success:true,message:'Legal details have been updated added!'}); toast.success('Legal details have been updated added!');
                setTimeout(()=>{
                    this.setState({ isVisible:false });
                    this.componentWillMount();
                },3000)
            }else{
                this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
            setTimeout(() =>{
                this.setState({err:false,success:false,isLoading:false});
            },3000);
        });
    }

    render(){

        const {legals, isLoading, isVisible, description} = this.state;
        return(
            <>
                <Header />
                <Container className="mt--7" fluid>
                    <Col sm="12" md="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                
                            </CardHeader>
                            <CardBody className="px-lg-5 py-lg-5">
                                {
                                    isLoading &&
                                    <span colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></span>
                                    
                                }
                                {
                                    !isLoading &&
                                        <Form role="form">
                                            <FormGroup>
                                                {/* <Label for="exampleSelect">Select</Label> */}
                                                <InputGroup className="input-group-alternative mb-3">
                                                    <Input type="select" name="legalType" id="legalType" onChange={this.onTypeChange.bind(this)}>
                                                        <option value="">Select type</option>
                                                        {
                                                            legals.map((legal, key) => {
                                                                return (
                                                                    <option value={key} key={key}>{legal.title}</option>
                                                                );
                                                            })
                                                        }
                                                    </Input>
                                                </InputGroup>
                                            </FormGroup>
                                            {isVisible && 
                                                <>
                                                    <FormGroup>
                                                        <CKEditor
                                                            editor={ ClassicEditor }
                                                            data={description}
                                                            // config={{toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList' ]}}
                                                            onChange={ ( event, editor ) => {
                                                                const description = editor.getData().getText();
                                                                this.setState({description});
                                                            } }
                                                        />
                                                    </FormGroup>
                                                    <div className="text-center mt-3">
                                                        <Button color="primary"  onClick={this.onSubmit.bind(this)} type="button">Update</Button>
                                                    </div>
                                                </>
                                            }
                                            {/* {isVisible && 
                                                <div className="text-center mt-3">
                                                    <Button color="primary" type="button">Submit</Button>
                                                </div>
                                            } */}
                                        </Form>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Container>
            </>
        )
    }
}

export default Legal;
