import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import moment from "moment";
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";
import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { getCities } from "../../actions/Cities";
import { updateWasher, filterWashers } from "../../actions/Washers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { washerData } from "../../lib/Action/Washer";

class EditWasher extends React.Component {
  limit = 10;
  constructor(props) {
    super(props);

    this.validator = new FormValidator(Rules.editWasherCoorporate);

    this.state = {
      isLoading: false,
      cities: [],
      fname: "",
      lname: "",
      email: "",
      loginPhoneNo: "",
      cityId: "",
      cityName: "",
      dob: "",
      gender: "",
      parasutName: "",
      parasutId: "",
      iban: "",
      accountingId: "",
      legalName: "",
      legalEmail: "",
      taxNumber: "",
      taxOffice: "",
      accountStatus: false,
      countryPhoneCode: "+33",
      phone: "",
      _id: "",
      selectDate: new Date(),
      current_tab: 1,
      parentId: "",
      commissionRate: "",
      identityNumber: "",
      contactName: "",
      contactSurname: "",
      subMerchantExternalId: "",
      gsmNumber: "",
      washers: [],
      pages: 1,
      keyword: "",
      sortBy: "fname",
      dir: 1,
      type: "",
      submerchantType: "personal",
      current_page: 1,
      parasut: "",
      password: "",
      confirmPassword: "",
      showPasword: false,
      showConfirmPassword: false,
      validation: this.validator.valid(),
      defaultSubmerchantType: "",
      isWasherSelect: false,
      isCommissionRate: false,
      isIdentityNumber: false,
      isLegalEmail: false,
      isLegalAddress: false,
      isLegalCompanyTitle: false,
      isTaxOffice: false,
      isContactName: false,
      isContactSurname: false,
      parents: [],
      err: false,
      message: "",
      success: false,
      washerData: {},
      fromType: ""
    };
    this.selectDateChange = this.selectDateChange.bind(this);
  }

  filterQuery() {
    const {
      sortBy,
      keyword,
      dir,
      current_page,
      pages,
      type,
      submerchantType
    } = this.state;

    let query = {};

    if (sortBy !== "") {
      query.sortBy = sortBy;
    }

    if (keyword !== "") {
      query.keyword = keyword;
    }

    if (dir !== "") {
      query.dir = dir;
    }

    if (type !== "") {
      query.type = type;
    }

    if (submerchantType !== "") {
      query.submerchantType = submerchantType;
    }

    query.start = (current_page - 1) * this.limit;
    query.limit = this.limit;

    return query;
  }

  componentWillMount() {
    this.setState({ isLoading: true });
    filterWashers(this.filterQuery())
      .then(response => {
        if (response.data.success) {
          if (response.data.success.data.users) {
            let responseData = response.data.success.data.users;
            let totalUsers = responseData.totalUsers;
            let pages = Math.ceil(totalUsers / this.limit);
            this.setState({
              washers: responseData.users,
              isLoading: false,
              totalUsers: responseData.totalUsers,
              pages
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });

    if (this.props.WasherReducer.washerData) {
      this.setState(
        {
          washerData: this.props.WasherReducer.washerData,
          fromType: this.props.WasherReducer.washerData.fromType,
          fname: this.props.WasherReducer.washerData.fname,
          lname: this.props.WasherReducer.washerData.lname,
          email: this.props.WasherReducer.washerData.email,
          accountStatus: this.props.WasherReducer.washerData.accountStatus,
          loginPhoneNo: this.props.WasherReducer.washerData.loginPhoneNo,
          picture: this.props.WasherReducer.washerData.picture,
          cityId: this.props.WasherReducer.washerData.cityId,
          cityName: this.props.WasherReducer.washerData.cityName,
          dob: this.props.WasherReducer.washerData.dob,
          gender: this.props.WasherReducer.washerData.gender,
          parents: this.props.WasherReducer.washerData.parents,
          commissionRate: this.props.WasherReducer.washerData.commissionRate,
          defaultSubmerchantType:
            this.props.WasherReducer.washerData.parents.length > 0
              ? "existing"
              : "new",
          submerchant: this.props.WasherReducer.washerData.submerchant
            ? this.props.WasherReducer.washerData.submerchant
            : "",
          submerchantType: this.props.WasherReducer.washerData.submerchant
            ? this.props.WasherReducer.washerData.submerchant.submerchantType
            : this.state.submerchantType,
          parasut: this.props.WasherReducer.washerData.parasut
            ? this.props.WasherReducer.washerData.parasut
            : "",
          _id: this.props.WasherReducer.washerData._id,
          countryPhoneCode:
            this.props.WasherReducer.washerData.loginPhoneNo.slice(0, 3) ==
            "+90"
              ? this.props.WasherReducer.washerData.loginPhoneNo.slice(0, 3)
              : "+90",
          phone:
            this.props.WasherReducer.washerData.loginPhoneNo.slice(0, 3) ==
            "+90"
              ? this.props.WasherReducer.washerData.loginPhoneNo.slice(3)
              : this.props.WasherReducer.washerData.loginPhoneNo.slice(0)
        },
        () => {
          const { submerchant, parasut } = this.state;
          if (submerchant) {
            this.setState({
              legalName: submerchant.legalName,
              parentId: submerchant.parentId,
              identityNumber: submerchant.identityNumber,
              legalCompanyTitle: submerchant.legalCompanyTitle,
              legalEmail: submerchant.legalEmail,
              legalAddress: submerchant.legalAddress,
              taxOffice: submerchant.taxOffice,
              taxNumber: submerchant.taxNumber,
              subMerchantExternalId: submerchant.subMerchantExternalId,
              iban: submerchant.iban,
              accountingId: submerchant.accountingId,
              gsmNumber: submerchant.gsmNumber,
              contactName: submerchant.contactName,
              contactSurname: submerchant.contactSurname
            });
          }
          if (parasut) {
            this.setState({
              parasutId: parasut.parasutId,
              parasutName: parasut.parasutName
            });
          }
        }
      );
    }

    getCities()
      .then(response => {
        if (response.data.success) {
          if (response.data.success.data) {
            let responseData = response.data.success.data;
            this.setState({
              cities: responseData,
              isLoading: false
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  onSubmitPersonal(e) {
    e.preventDefault();
    const {
      fname,
      lname,
      email,
      cityId,
      cityName,
      phone,
      dob,
      gender,
      countryPhoneCode,
      _id,
      washerData
    } = this.state;

    this.setState({ isLoading: true });
    updateWasher({
      fname,
      lname,
      email,
      cityId,
      cityName,
      loginPhoneNo: `${countryPhoneCode}${phone}`,
      dob,
      gender,
      countryPhoneCode,
      _id
    })
      .then(response => {
        if (response.data.success) {
          washerData["fname"] = fname;
          washerData["lname"] = lname;
          washerData["email"] = email;
          washerData["cityId"] = cityId;
          washerData["cityName"] = cityName;
          washerData["gender"] = gender;
          washerData["dob"] = dob;
          washerData["countryPhoneCode"] = countryPhoneCode;
          washerData["loginPhoneNo"] = `${countryPhoneCode}${phone}`;

          this.props.washerData(washerData);

          this.setState({
            err: false,
            success: true,
            message: "Washer have been successfully updated!"
          });
          toast.success("Washer have been successfully updated!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        } else {
          this.setState({
            err: true,
            message: "Unable to edit!",
            isLoading: false
          });
          toast.error("Unable to edit!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        }
      })
      .catch(error => {
        this.setState({ err: true, message: "Server Error!" });
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 3000);
      });
  }

  onSubmitNewCoorporate(e) {
    e.preventDefault();
    const {
      parents,
      parentId,
      submerchantType,
      legalName,
      legalEmail,
      subMerchantExternalId,
      gsmNumber,
      contactName,
      contactSurname,
      accountingId,
      legalAddress,
      legalCompanyTitle,
      taxOffice,
      identityNumber,
      taxNumber,
      iban,
      commissionRate,
      _id,
      washerData
    } = this.state;

    if (
      commissionRate === "" ||
      commissionRate == undefined ||
      identityNumber == "" ||
      identityNumber == undefined ||
      legalEmail == "" ||
      legalEmail == undefined
    ) {
      if (commissionRate == "" || commissionRate == undefined) {
        this.setState({
          isCommissionRate: true
        });
      }
      if (identityNumber == "" || identityNumber == undefined) {
        this.setState({
          isIdentityNumber: true
        });
      }
      if (legalEmail == "" || legalEmail == undefined) {
        this.setState({
          isLegalEmail: true
        });
      }
      if (legalAddress == "" || legalAddress == undefined) {
        this.setState({
          isLegalAddress: true
        });
      }
      return;
    }

    if (submerchantType === "limited" || submerchantType === "private") {
      if (
        legalCompanyTitle == "" ||
        legalCompanyTitle == undefined ||
        taxOffice == "" ||
        taxOffice == undefined
      ) {
        if (legalCompanyTitle == "" || legalCompanyTitle == undefined) {
          this.setState({
            isLegalCompanyTitle: true
          });
        }
        if (taxOffice == "" || taxOffice == undefined) {
          this.setState({
            isTaxOffice: true
          });
        }
        return;
      }
    }

    if (submerchantType === "personal") {
      if (
        contactName == "" ||
        contactName == undefined ||
        contactSurname == "" ||
        contactSurname == undefined
      ) {
        if (contactName == "" || contactName == undefined) {
          this.setState({
            isContactName: true
          });
        }
        if (contactSurname == "" || contactSurname == undefined) {
          this.setState({
            isContactSurname: true
          });
        }
        return;
      }
    }

    this.setState({ isLoading: true });
    updateWasher({
      submerchant: {
        parents,
        parentId,
        submerchantType,
        legalName,
        legalEmail,
        subMerchantExternalId,
        gsmNumber,
        contactName,
        contactSurname,
        accountingId,
        legalAddress,
        legalCompanyTitle,
        taxOffice,
        identityNumber,
        taxNumber,
        iban
      },
      _id,
      commissionRate
    })
      .then(response => {
        if (response.data.success) {
          washerData["submerchant"] = {
            parentId,
            submerchantType,
            legalName,
            legalEmail,
            subMerchantExternalId,
            gsmNumber,
            contactName,
            contactSurname,
            accountingId,
            legalAddress,
            legalCompanyTitle,
            taxOffice,
            identityNumber,
            taxNumber,
            iban
          };

          this.props.washerData(washerData);

          this.setState({
            err: false,
            success: true,
            message: "Washer have been successfully updated!"
          });
          toast.success("Washer have been successfully updated!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        } else {
          this.setState({
            err: true,
            message: response.data.error.message,
            isLoading: false
          });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ err: true, message: "Server Error!" });
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 3000);
      });
  }

  onSubmitExistingCoorporate(e) {
    e.preventDefault();
    const { parents, commissionRate, _id, washerData } = this.state;

    if (parents.length == 0) {
      this.setState({
        isWasherSelect: true
      });
      return;
    }

    this.setState({ isLoading: true });
    updateWasher({
      parents,
      commissionRate,
      _id
    })
      .then(response => {
        if (response.data.success) {
          washerData["parents"] = parents;
          washerData["commissionRate"] = commissionRate;

          this.props.washerData(washerData);

          this.setState({
            err: false,
            success: true,
            message: "Washer have been successfully updated!"
          });
          toast.success("Washer have been successfully updated!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        } else {
          this.setState({
            err: true,
            message: "Unable to edit!",
            isLoading: false
          });
          toast.error("Unable to edit!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        }
      })
      .catch(error => {
        this.setState({ err: true, message: "Server Error!" });
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 3000);
      });
  }

  onSubmitParasut(e) {
    e.preventDefault();
    const { parasutId, parasutName, _id, washerData } = this.state;

    this.setState({ isLoading: true });
    updateWasher({
      parasut: {
        parasutId,
        parasutName
      },
      _id
    })
      .then(response => {
        if (response.data.success) {
          washerData["parasut"] = {
            parasutId,
            parasutName
          };

          this.props.washerData(washerData);

          this.setState({
            err: false,
            success: true,
            message: "Parasut have been successfully updated!"
          });
          toast.success("Parasut have been successfully updated!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        } else {
          this.setState({
            err: true,
            message: "Unable to edit!",
            isLoading: false
          });
          toast.error("Unable to edit!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        }
      })
      .catch(error => {
        this.setState({ err: true, message: "Server Error!" });
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 3000);
      });
  }

  onSubmitPassword(e) {
    e.preventDefault();
    const { password, confirmPassword, _id } = this.state;
    if (password != confirmPassword) {
      return;
    }

    this.setState({ isLoading: true });
    updateWasher({
      password,
      _id
    })
      .then(response => {
        if (response.data.success) {
          this.setState({
            err: false,
            success: true,
            message: "Password have been successfully updated!"
          });
          toast.success("Password have been successfully updated!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        } else {
          this.setState({
            err: true,
            message: "Unable to edit!",
            isLoading: false
          });
          toast.error("Unable to edit!");
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        }
      })
      .catch(error => {
        this.setState({ err: true, message: "Server Error!" });
        setTimeout(() => {
          this.setState({ err: false, success: false, isLoading: false });
        }, 3000);
      });
  }

  onChange(e) {
    const { name, value } = e.target;
    const { cities } = this.state;
    if (name == "cityId") {
      this.setState({
        cityId: cities[value]._id,
        cityName: cities[value].cityName
      });
    } else {
      this.setState({ [name]: value });
    }
  }

  selectDateChange(date) {
    if (!date) {
      return;
    }
    console.log(date);
    this.setState(
      {
        selectDate: date
      },
      () => {
        const selectedDate = this.state.selectDate;
        var month = selectedDate.getMonth() + 1;
        var day = selectedDate.getDate();
        var year = selectedDate.getFullYear();
        this.setState({
          dob: year + "-" + month + "-" + day
        });
      }
    );
  }

  setTab(t) {
    this.setState({ current_tab: t });
  }

  isPersonalNotFilled() {
    const { email, fname, lname, phone, dob, cityId, gender } = this.state;
    return (
      fname === this.props.WasherReducer.washerData.fname &&
      lname === this.props.WasherReducer.washerData.lname &&
      email === this.props.WasherReducer.washerData.email &&
      cityId == this.props.WasherReducer.washerData.cityId &&
      gender == this.props.WasherReducer.washerData.gender
    );
  }

  isParasutNotFilled() {
    const { parasutId, parasutName } = this.state;
    return parasutId == "" || parasutName == "";
  }

  isPasswordFilled() {
    const { password, confirmPassword } = this.state;
    return password == "" || confirmPassword == "";
  }

  togglePassword(e) {
    e.preventDefault();
    let { showPasword } = this.state;
    showPasword = !showPasword;
    this.setState({ showPasword });
  }

  toggleConfirmPassword(e) {
    e.preventDefault();
    let { showConfirmPassword } = this.state;
    showConfirmPassword = !showConfirmPassword;
    this.setState({ showConfirmPassword });
  }

  handleCheckbox(e, id) {
    const { parents } = this.state;
    let parentsArr = [...parents];
    let index = parentsArr.indexOf(id);
    if (index !== -1) {
      parentsArr.splice(index, 1);
    } else {
      parentsArr.push(id);
    }
    this.setState({
      parents: parentsArr
    });
  }

  checkWasherCheck(id) {
    const { parents } = this.state;

    if (parents) {
      let index = parents.indexOf(id);
      if (index !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  EditPersonalInformation() {
    const {
      fname,
      lname,
      email,
      countryPhoneCode,
      phone,
      cities,
      isLoading,
      gender,
      dob,
      cityId,
      cityName,
      err,
      message,
      success
    } = this.state;
    return (
      <>
        {err && <Alert color="danger">{message}</Alert>}
        {success && <Alert color="success">{message}</Alert>}
        <Form role="form" method="POST">
          <div className="pl-lg-4">
            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-fname">
                    First Name*
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-fname"
                    placeholder="Enter a First Name"
                    type="text"
                    name="fname"
                    defaultValue={fname}
                    onChange={this.onChange.bind(this)}
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-lname">
                    Last Name*
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-lname"
                    placeholder="Enter a Last Name"
                    type="text"
                    name="lname"
                    defaultValue={lname}
                    onChange={this.onChange.bind(this)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email*
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-email"
                    placeholder="Enter a Email"
                    type="email"
                    name="email"
                    defaultValue={email}
                    onChange={this.onChange.bind(this)}
                  />
                </FormGroup>
              </Col>

              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-phone">
                    Phone*
                  </label>
                  <InputGroup>
                    <InputGroupAddon
                      className="input-shadow"
                      addonType="prepend"
                    >
                      <InputGroupText className="border-none">
                        {countryPhoneCode}
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="input-shadow"
                      id="input-phone"
                      placeholder="Enter a Phone"
                      type="text"
                      name="phone"
                      defaultValue={phone}
                      onChange={this.onChange.bind(this)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6" sm="12">
                <FormGroup
                  style={{ display: "grid" }}
                  className="datePickerDiv"
                >
                  <label className="form-control-label" htmlFor="input-dob">
                    DOB
                  </label>
                  <InputGroup className="datepicker-input-group input-shadow">
                    <DatePicker
                      selected={new Date(dob)}
                      onChange={this.selectDateChange}
                      dateFormat="yyyy-MM-dd"
                      className="datepicker-form-control border-none"
                      popperPlacement="bottom"
                      // minDate={new Date()}
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"] // don't allow it to flip to be above
                        },
                        preventOverflow: {
                          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                        },
                        hide: {
                          enabled: false // turn off since needs preventOverflow to be enabled
                        }
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText className="border-none">
                        <i className="fa fa-calendar" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup
                  style={{ display: "grid" }}
                  className="datePickerDiv"
                >
                  <label className="form-control-label" htmlFor="input-ibanNum">
                    Gender
                  </label>
                  <div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          checked={gender === "m"}
                          type="radio"
                          className="form-check-input"
                          onChange={this.onChange.bind(this)}
                          name="gender"
                          value="m"
                        />{" "}
                        Male
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          checked={gender === "f"}
                          type="radio"
                          className="form-check-input"
                          onChange={this.onChange.bind(this)}
                          name="gender"
                          value="f"
                        />{" "}
                        Female
                      </label>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-cityId">
                    Select City
                  </label>

                  <Input
                    className="input-shadow"
                    type="select"
                    name="cityId"
                    id="cityId"
                    onChange={this.onChange.bind(this)}
                  >
                    <option value="">Select City</option>
                    {cities.map((c, k) => {
                      return (
                        <option selected={cityId == c._id} value={k} key={k}>
                          {c.cityName}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <div className="text-right">
                  {!isLoading && (
                    <Button
                      className="mt-4"
                      onClick={this.onSubmitPersonal.bind(this)}
                      color="primary"
                      type="button"
                      disabled={this.isPersonalNotFilled()}
                    >
                      Update
                    </Button>
                  )}
                  {isLoading && (
                    <Loader
                      type="Circles"
                      color="#5e72e4"
                      height={30}
                      width={30}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </>
    );
  }

  EditCorporateInformation() {
    const {
      isLoading,
      _id,
      isWasherSelect,
      isIdentityNumber,
      isLegalAddress,
      isLegalEmail,
      isLegalCompanyTitle,
      isCommissionRate,
      isTaxOffice,
      isContactName,
      isContactSurname,
      defaultSubmerchantType,
      washers,
      submerchantType,
      legalName,
      taxOffice,
      taxNumber,
      contactName,
      contactSurname,
      legalCompanyTitle,
      accountingId,
      gsmNumber,
      parentId,
      commissionRate,
      identityNumber,
      legalEmail,
      legalAddress,
      subMerchantExternalId,
      iban,
      message,
      err,
      success
    } = this.state;
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;
    return (
      <>
        {err && <Alert color="danger">{message}</Alert>}
        {success && <Alert color="success">{message}</Alert>}

        <Form role="form" method="POST">
          <div className="pl-lg-4">
            {/* <Row className="mb-4">
                            <Col md="6" sm="12">
                                <div style={{display: 'grid'}}>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input type="radio" checked={defaultSubmerchantType === 'existing'} className="form-check-input" onChange={this.onChange.bind(this)}  name="defaultSubmerchantType" value="existing" /> Existing Submerchant 
                                        </label>
                                    </div>
                                    <div className="form-check-inline">
                                        <label className="form-check-label">
                                            <input type="radio"checked={defaultSubmerchantType === 'new'} className="form-check-input" onChange={this.onChange.bind(this)}  name="defaultSubmerchantType" value="new" /> New Submerchant
                                        </label>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}

            {/* {defaultSubmerchantType === 'existing' && */}
            <>
              {/* <Row>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-parentId"
                                            >
                                                Parent*
                                            </label>
                                            <ul>
                                                {
                                                    washers.map((washer, key) => {
                                                        return(
                                                            <>
                                                                {washer._id != _id &&
                                                                    <li key={key} style={{listStyleType: 'none'}}>
                                                                        <input checked={this.checkWasherCheck(washer._id)} onChange={e => this.handleCheckbox(e, washer._id)} type="checkbox" value={washer._id} /> {washer.fname} {washer.lname}
                                                                    </li>
                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            {isWasherSelect && 
                                                <InputError message='Please Select Washer' />
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-commissionRate"
                                            >
                                                Comission Rate 
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-commissionRate"
                                                placeholder="Enter a Comission Rate"
                                                type="number"
                                                name="commissionRate"
                                                defaultValue={commissionRate}
                                                onChange={this.onChange.bind(this)} 
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row> */}
              {/* <Row>
                                    <Col sm="12">
                                        <div className="text-right">
                                            { !isLoading &&  
                                                <Button className="mt-4" onClick={this.onSubmitExistingCoorporate.bind(this)} color="primary" type="button">
                                                    Update
                                                </Button>
                                            }
                                            {
                                                isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                            }
                                        </div>
                                    </Col>
                                </Row>  */}
            </>
            {/* }

                        {defaultSubmerchantType === 'new' && */}
            <>
              <Row>
                <Col md="6" sm="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-submerchantType"
                    >
                      SubMerchant Type
                    </label>
                    <Input
                      type="select"
                      name="submerchantType"
                      id="submerchantType"
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="">No SubMerchant Type</option>
                      <option
                        selected={submerchantType == "limited"}
                        value="limited"
                      >
                        Limited
                      </option>
                      <option
                        selected={submerchantType == "private"}
                        value="private"
                      >
                        Private
                      </option>
                      <option
                        selected={submerchantType == "personal"}
                        value="personal"
                      >
                        personal
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              {(submerchantType === "limited" ||
                submerchantType === "private" ||
                submerchantType === "personal") && (
                <>
                  <Row>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-commissionRate"
                        >
                          Commission Rate*
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-commissionRate"
                          placeholder="Enter a Comission Rate"
                          type="number"
                          name="commissionRate"
                          defaultValue={commissionRate}
                          onChange={this.onChange.bind(this)}
                        />
                        {isCommissionRate && (
                          <InputError message="Commission Rate is Required" />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-identityNumber"
                        >
                          Identity Number*
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-identityNumber"
                          placeholder="Enter a Identity Number"
                          type="text"
                          name="identityNumber"
                          defaultValue={identityNumber}
                          onChange={this.onChange.bind(this)}
                        />
                        {isIdentityNumber && (
                          <InputError message="Identity Number is Required" />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-iban"
                        >
                          Iban
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-iban"
                          placeholder="Enter a Iban"
                          type="text"
                          name="iban"
                          defaultValue={iban}
                          onChange={this.onChange.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-legalName"
                        >
                          Legal Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-legalName"
                          placeholder="Enter a Legal Name"
                          type="text"
                          name="legalName"
                          defaultValue={legalName}
                          onChange={this.onChange.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-legalEmail"
                        >
                          Legal Email*
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-legalEmail"
                          placeholder="Enter a Legal Email"
                          type="text"
                          name="legalEmail"
                          defaultValue={legalEmail}
                          onChange={this.onChange.bind(this)}
                        />
                        {isLegalEmail && (
                          <InputError message="Legal Email is Required" />
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-legalAddress"
                        >
                          Legal Address*
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-legalAddress"
                          placeholder="Enter a Legal Address"
                          type="text"
                          name="legalAddress"
                          defaultValue={legalAddress}
                          onChange={this.onChange.bind(this)}
                        />
                        {isLegalAddress && (
                          <InputError message="Legal Address is Required" />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-accountingId"
                        >
                          Accounting Id
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-accountingId"
                          placeholder="Enter a Accounting Id"
                          type="text"
                          name="accountingId"
                          defaultValue={accountingId}
                          onChange={this.onChange.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-gsmNumber"
                        >
                          GSM Number
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-gsmNumber"
                          placeholder="Enter a GSM Number"
                          type="text"
                          name="gsmNumber"
                          defaultValue={gsmNumber}
                          onChange={this.onChange.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" sm="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-parentId"
                        >
                          Parent
                        </label>
                        <ul>
                          {washers.map((washer, key) => {
                            return (
                              <>
                                {washer._id != _id && (
                                  <li
                                    key={key}
                                    style={{ listStyleType: "none" }}
                                  >
                                    <input
                                      checked={this.checkWasherCheck(
                                        washer._id
                                      )}
                                      onChange={e =>
                                        this.handleCheckbox(e, washer._id)
                                      }
                                      type="checkbox"
                                      value={washer._id}
                                    />{" "}
                                    {washer.fname} {washer.lname}
                                  </li>
                                )}
                              </>
                            );
                          })}
                        </ul>
                        {isWasherSelect && (
                          <InputError message="Please Select Washer" />
                        )}
                      </FormGroup>
                    </Col>
                    {(submerchantType === "limited" ||
                      submerchantType === "private") && (
                      <Col md="6" sm="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-legalCompanyTitle"
                          >
                            Legal Company Title*
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-legalCompanyTitle"
                            placeholder="Enter a Legal Company Title"
                            type="text"
                            name="legalCompanyTitle"
                            defaultValue={legalCompanyTitle}
                            onChange={this.onChange.bind(this)}
                          />
                          {isLegalCompanyTitle && (
                            <InputError message="Legal Company Title is Required" />
                          )}
                        </FormGroup>
                      </Col>
                    )}
                    {submerchantType === "personal" && (
                      <Col md="6" sm="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-contactName"
                          >
                            Contact Name*
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-contactName"
                            placeholder="Enter a Contact Name"
                            type="text"
                            name="contactName"
                            defaultValue={contactName}
                            onChange={this.onChange.bind(this)}
                          />
                          {isContactName && (
                            <InputError message="Contact Name is Required" />
                          )}
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  {(submerchantType === "limited" ||
                    submerchantType === "private") && (
                    <Row>
                      {/* <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-legalCompanyTitle"
                                                        >
                                                            Legal Company Title*
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-legalCompanyTitle"
                                                            placeholder="Enter a Legal Company Title"
                                                            type="text"
                                                            name="legalCompanyTitle"
                                                            defaultValue={legalCompanyTitle}
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                        {isLegalCompanyTitle && 
                                                            <InputError message="Legal Company Title is Required" />
                                                        }
                                                    </FormGroup>
                                                </Col> */}
                      <Col md="6" sm="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-taxOffice"
                          >
                            Tax Office*
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-taxOffice"
                            placeholder="Enter a Tax Office"
                            type="text"
                            name="taxOffice"
                            defaultValue={taxOffice}
                            onChange={this.onChange.bind(this)}
                          />
                          {isTaxOffice && (
                            <InputError message="Tax Office is Required" />
                          )}
                        </FormGroup>
                      </Col>
                      {submerchantType === "limited" && (
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-taxNumber"
                            >
                              Tax Number
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-taxNumber"
                              placeholder="Enter a Tax Number"
                              type="text"
                              name="taxNumber"
                              defaultValue={taxNumber}
                              onChange={this.onChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  )}
                  {/* {(submerchantType === 'limited')  && 
                                            <Row>
                                                <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-taxNumber"
                                                        >
                                                            Tax Number 
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-taxNumber"
                                                            placeholder="Enter a Tax Number"
                                                            type="text"
                                                            name="taxNumber"
                                                            defaultValue={taxNumber}
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        } */}
                  {submerchantType === "personal" && (
                    <Row>
                      {/* <Col md="6" sm="12">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-contactName"
                                                        >
                                                            Contact Name*
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-contactName"
                                                            placeholder="Enter a Contact Name"
                                                            type="text"
                                                            name="contactName"
                                                            defaultValue={contactName}
                                                            onChange={this.onChange.bind(this)} 
                                                        />
                                                        {isContactName && 
                                                            <InputError message="Contact Name is Required" />
                                                        }
                                                    </FormGroup>
                                                </Col> */}
                      <Col md="6" sm="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-contactSurname"
                          >
                            Contact Surname*
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-contactSurname"
                            placeholder="Enter a Contact Surname"
                            type="text"
                            name="contactSurname"
                            defaultValue={contactSurname}
                            onChange={this.onChange.bind(this)}
                          />
                          {isContactSurname && (
                            <InputError message="Contact Surname is Required" />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm="12">
                      <div className="text-right">
                        {!isLoading && (
                          <Button
                            className="mt-4"
                            onClick={this.onSubmitNewCoorporate.bind(this)}
                            color="primary"
                            type="button"
                          >
                            Update
                          </Button>
                        )}
                        {isLoading && (
                          <Loader
                            type="Circles"
                            color="#5e72e4"
                            height={30}
                            width={30}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
            {/* } */}
          </div>
        </Form>
      </>
    );
  }

  EditParasutInformation() {
    const {
      isLoading,
      parasutId,
      parasutName,
      err,
      message,
      success
    } = this.state;
    return (
      <>
        {err && <Alert color="danger">{message}</Alert>}
        {success && <Alert color="success">{message}</Alert>}
        <Form role="form" method="POST">
          <div className="pl-lg-4">
            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-parasutId"
                  >
                    Parasut Id
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-parasutId"
                    placeholder="Enter a Parasut Id"
                    type="text"
                    name="parasutId"
                    defaultValue={parasutId}
                    onChange={this.onChange.bind(this)}
                  />
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-parasutName"
                  >
                    Parasut Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-parasutName"
                    placeholder="Enter a Parasut Name"
                    type="text"
                    name="parasutName"
                    defaultValue={parasutName}
                    onChange={this.onChange.bind(this)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div className="text-right">
                  {!isLoading && (
                    <Button
                      className="mt-4"
                      onClick={this.onSubmitParasut.bind(this)}
                      color="primary"
                      type="button"
                      disabled={this.isParasutNotFilled()}
                    >
                      Update
                    </Button>
                  )}
                  {isLoading && (
                    <Loader
                      type="Circles"
                      color="#5e72e4"
                      height={30}
                      width={30}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </>
    );
  }

  EditPasswordForm() {
    const {
      isLoading,
      password,
      confirmPassword,
      showConfirmPassword,
      showPasword,
      err,
      message,
      success
    } = this.state;
    return (
      <>
        {err && <Alert color="danger">{message}</Alert>}
        {success && <Alert color="success">{message}</Alert>}
        <Form role="form" method="POST">
          <div className="pl-lg-4">
            <Row>
              <Col md="6" sm="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-password"
                  >
                    Password
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      id="input-password"
                      placeholder="********"
                      type={showPasword ? "text" : "password"}
                      name="password"
                      defaultValue={password}
                      onChange={this.onChange.bind(this)}
                    />
                    <InputGroupAddon addonType="append">
                      <a onClick={this.togglePassword.bind(this)}>
                        <InputGroupText style={{ height: "100%" }}>
                          <i
                            className={
                              !showPasword ? "fas fa-eye-slash" : "fas fa-eye"
                            }
                          />
                        </InputGroupText>
                      </a>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="6" sm="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      className="form-control-alternative"
                      id="input-confirmPassword"
                      placeholder="********"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      defaultValue={confirmPassword}
                      onChange={this.onChange.bind(this)}
                    />
                    <InputGroupAddon addonType="append">
                      <a onClick={this.toggleConfirmPassword.bind(this)}>
                        <InputGroupText style={{ height: "100%" }}>
                          <i
                            className={
                              !showConfirmPassword
                                ? "fas fa-eye-slash"
                                : "fas fa-eye"
                            }
                          />
                        </InputGroupText>
                      </a>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div className="text-right">
                  {!isLoading && (
                    <Button
                      className="mt-4"
                      onClick={this.onSubmitPassword.bind(this)}
                      color="primary"
                      type="button"
                      disabled={this.isPasswordFilled()}
                    >
                      Update
                    </Button>
                  )}
                  {isLoading && (
                    <Loader
                      type="Circles"
                      color="#5e72e4"
                      height={30}
                      width={30}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </>
    );
  }

  render() {
    let {
      fname,
      fromType,
      lname,
      email,
      legalEmail,
      current_tab,
      submerchantType,
      accountingId,
      legalAddress,
      legalCompanyTitle,
      cities,
      cityId,
      taxOffice,
      gsmNumber,
      cityName,
      loginPhoneNo,
      dob,
      legalName,
      taxNumber,
      gender,
      parasutName,
      parasutId,
      iban,
      commissionRate,
      countryPhoneCode,
      phone,
      isLoading
    } = this.state;

    return (
      <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
          <Row>
            <Col sm="12" md="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Washer</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link
                        to={
                          fromType === "washer"
                            ? "/dashboard/washers"
                            : "/dashboard/submerchant"
                        }
                      >
                        <Button color="primary" size="sm">
                          Back to{" "}
                          {fromType === "washer" ? "Technicians" : "Submerchants"}
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="12">
                      <ul className="stepper stepper-horizontal">
                        <li className={current_tab === 1 ? "completed" : ""}>
                          <a onClick={this.setTab.bind(this, 1)}>
                            <span className="circle">
                              <i className="fa fa-info" aria-hidden="true" />
                            </span>
                            <span className="label">Personal Information</span>
                          </a>
                        </li>
                        <div className="line" />
                        <li className={current_tab === 2 ? "completed" : ""}>
                          <a onClick={this.setTab.bind(this, 2)}>
                            <span className="circle">
                              <i className="fa fa-child" aria-hidden="true" />
                            </span>
                            <span className="label">Corporate Information</span>
                          </a>
                        </li>
                        <div className="line" />
                        <li className={current_tab === 3 ? "completed" : ""}>
                          <a onClick={this.setTab.bind(this, 3)}>
                            <span className="circle">
                              <i className="fa fa-child" aria-hidden="true" />
                            </span>
                            <span className="label">Parasut Information</span>
                          </a>
                        </li>
                        <div className="line" />
                        <li className={current_tab === 4 ? "completed" : ""}>
                          <a onClick={this.setTab.bind(this, 4)}>
                            <span className="circle">
                              <i className="fa fa-key" aria-hidden="true" />
                            </span>
                            <span className="label">Change Password</span>
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>

                  {current_tab === 1 && this.EditPersonalInformation()}
                  {current_tab === 2 && this.EditCorporateInformation()}
                  {current_tab === 3 && this.EditParasutInformation()}
                  {current_tab === 4 && this.EditPasswordForm()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  washerData: washer => dispatch(washerData(washer))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditWasher);
