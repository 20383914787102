import { DOCUMENT_SUCCESS } from '../Action/Document';

const initialState = {
  isLoading: false,
}

const Document = (state = initialState, action) =>{

  if(state === null || state === undefined || action === null){
    return { ...state }
  }

  switch(action.type){
    case DOCUMENT_SUCCESS :
      const documentData = action.document;
      return {
        ...state,
        documentData
      };
    default:
      return state;
  }

}

export default Document;
