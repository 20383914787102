import { CAMPAIGN_SUCCESS } from '../Action/Campaign';

const initialState = {
  isLoading: false,
}

const Campaign = (state = initialState, action) =>{

  if(state === null || state === undefined || action === null){
    return { ...state }
  }

  switch(action.type){
    case CAMPAIGN_SUCCESS :
      const campaignData = action.campaign;
      return {
        ...state,
        campaignData
      };
    default:
      return state;
  }

}

export default Campaign;
