import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import moment from "moment";
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { getCities } from "../../actions/Cities";
import { updateCustomer } from "../../actions/Customers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { customerData } from "../../lib/Action/Customer";

// import { updateAdmin } from "../../actions/Admins";

class EditCustomer extends React.Component {
    constructor(props){
        super(props);

        // this.validator = new FormValidator(Rules.editAdmin);

        this.state = {
            isLoading: false,
            cities: [],
            fname: '',
            lname: '',
            email: '',
            loginPhoneNo: '',
            cityId: '',
            cityName: '',
            password: '',
            dob: '',
            gender: '',
            parasutName: '',
            parasutId: '',
            ibanNum: '',
            accountingId: '',
            legalName:  '',
            taxNumber:  '',
            taxOffice:  '',
            accountStatus: false,
            countryPhoneCode:'+33',
            phone: '',
            _id: '',
            selectDate: new Date(),
            current_tab:1,
            confirmPassword: '',
            showPasword:false,
            showConfirmPassword:false, 
            customerData : {}
        }
        this.selectDateChange = this.selectDateChange.bind(this);
    }


    componentWillMount(){
        if(this.props.CustomerReducer.customerData){
            console.log(this.props.CustomerReducer.customerData);
            this.setState({
                customerData: this.props.CustomerReducer.customerData,
                fname: this.props.CustomerReducer.customerData.fname,
                lname: this.props.CustomerReducer.customerData.lname,
                email: this.props.CustomerReducer.customerData.email,
                accountStatus: this.props.CustomerReducer.customerData.accountStatus,
                loginPhoneNo: this.props.CustomerReducer.customerData.loginPhoneNo,
                picture: this.props.CustomerReducer.customerData.picture,
                cityId: this.props.CustomerReducer.customerData.cityId,
                cityName: this.props.CustomerReducer.customerData.cityName,
                dob: this.props.CustomerReducer.customerData.dob != null ? this.props.CustomerReducer.customerData.dob : new Date(),
                gender: this.props.CustomerReducer.customerData.gender,
                parasutName: this.props.CustomerReducer.customerData.parasut ? this.props.CustomerReducer.customerData.parasut.parasutName : '',
                parasutId : this.props.CustomerReducer.customerData.parasut ? this.props.CustomerReducer.customerData.parasut.parasutId : '',
                accountingId: this.props.CustomerReducer.customerData.accountingId,
                _id: this.props.CustomerReducer.customerData._id,
                countryPhoneCode : this.props.CustomerReducer.customerData.loginPhoneNo.slice(0, 3) == '+90' ? this.props.CustomerReducer.customerData.loginPhoneNo.slice(0, 3) : '+90',
                phone: this.props.CustomerReducer.customerData.loginPhoneNo.slice(0, 3) == '+90' ? this.props.CustomerReducer.customerData.loginPhoneNo.slice(3) : this.props.CustomerReducer.customerData.loginPhoneNo.slice(0),
            });
        }

        getCities().then( response => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        cities:responseData,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    onSubmitPersonal(e){
        e.preventDefault();
        const {fname, lname, email, cityId, cityName, phone, dob, gender, countryPhoneCode, _id, customerData} = this.state;
        this.setState({ isLoading:true });
        updateCustomer({
            fname, lname, email, cityId, cityName, loginPhoneNo:`${countryPhoneCode}${phone}`, dob, gender, countryPhoneCode, _id
        }).then(response => {
            if(response.data.success){
                customerData['fname'] = fname;
                customerData['lname'] = lname;
                customerData['email'] = email;
                customerData['cityId'] = cityId;
                customerData['cityName'] = cityName;
                customerData['gender'] = gender;
                customerData['dob'] = dob;
                customerData['countryPhoneCode'] = countryPhoneCode;
                customerData['loginPhoneNo'] = `${countryPhoneCode}${phone}`;

                this.props.customerData(customerData);

                this.setState({err:false,success:true,message:'Information have been successfully updated!'}); toast.success('Information have been successfully updated!');
                setTimeout(()=>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000)
            }else{
                this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
            setTimeout(() =>{
                this.setState({err:false,success:false,isLoading:false});
            },3000);
        });
    }

    onSubmitParasut(e){
        e.preventDefault();
        const {parasutId, parasutName, _id, customerData} = this.state;
        this.setState({ isLoading:true });
        updateCustomer({
            "parasut": {
                parasutId, parasutName
            }, _id
        }).then(response => {
            if(response.data.success){
                customerData['parasut'] = {
                    parasutId, parasutName
                };

                this.props.customerData(customerData);

                this.setState({err:false,success:true,message:'Parasut have been successfully updated!'}); toast.success('Parasut have been successfully updated!');
                setTimeout(()=>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000)
            }else{
                this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
            setTimeout(() =>{
                this.setState({err:false,success:false,isLoading:false});
            },3000);
        });
    }

    onSubmitPassword(e){
        e.preventDefault();
        const {password, confirmPassword, _id} = this.state;
        if(password != confirmPassword){
            return;
        } 

        this.setState({ isLoading:true });
        updateCustomer({
            password, _id
        }).then(response => {
            console.log(response);
            if(response.data.success){
                this.setState({err:false,success:true,message:'Password have been successfully updated!'}); toast.success('Password have been successfully updated!');
                setTimeout(()=>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000)
            }else{
                this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
            setTimeout(() =>{
                this.setState({err:false,success:false,isLoading:false});
            },3000);
        });
    }

    onChange(e){
        const {name, value} = e.target;
        const { cities } = this.state;
        if(name == 'cityId'){
            this.setState({
                cityId: cities[value]._id,
                cityName: cities[value].cityName
            });
        } else {
            this.setState({[name]: value});
        }
    } 

    selectDateChange(date) { if (!date) { return; }
        console.log(date);
        this.setState({
            selectDate: date
        },()=> {
            const selectedDate = this.state.selectDate;
            var month = (selectedDate .getMonth() + 1);
            var day = (selectedDate .getDate());
            var year = (selectedDate .getFullYear());
            this.setState({
                dob: year+'-'+month+'-'+day
            })
        });
    }

    setTab(t){
        this.setState({current_tab:t});
    }

    isPersonalNotFilled(){
        const {email,fname,lname, phone, dob, cityId, gender} = this.state;
        return (
            fname === this.props.CustomerReducer.customerData.fname && 
            lname === this.props.CustomerReducer.customerData.lname &&
            email ===  this.props.CustomerReducer.customerData.email &&
            cityId ==  this.props.CustomerReducer.customerData.cityId &&
            gender ==  this.props.CustomerReducer.customerData.gender 
        )
    }

    isParasutNotFilled(){
        const {parasutId, parasutName} = this.state;
        return (
            parasutId == '' || parasutName == ''
        )
    }

    isPasswordFilled(){
        const {password, confirmPassword} = this.state;
        return (
            password == '' || confirmPassword == ''
        )
    }

    togglePassword(e){
        e.preventDefault();
        let {showPasword} = this.state;
          showPasword = !showPasword;
        this.setState({showPasword})
    }
    
    toggleConfirmPassword(e){
        e.preventDefault();
        let {showConfirmPassword} = this.state;
          showConfirmPassword = !showConfirmPassword;
        this.setState({showConfirmPassword})
    }

    EditPersonalInformation(){
        const {err, success, message, fname, lname, email, countryPhoneCode, phone, cities, isLoading, gender, dob, cityId, cityName} = this.state; 
        return(
            <>
                {err &&
                    <Alert color="danger">
                        {message}
                    </Alert>
                }
                {success &&
                    <Alert color="success">
                        {message}
                    </Alert>
                }
                <Form role="form" method="POST">
                    <div className="pl-lg-4">
                        <Row>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-fname"
                                    >
                                        First Name*
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-fname"
                                        placeholder="Enter a First Name"
                                        type="text"
                                        name="fname"
                                        defaultValue={fname}
                                        onChange={this.onChange.bind(this)} 
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-lname"
                                    >
                                        Last Name*
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-lname"
                                        placeholder="Enter a Last Name"
                                        type="text"
                                        name="lname"
                                        defaultValue={lname}
                                        onChange={this.onChange.bind(this)} 
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                    >
                                        Email*
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-email"
                                        placeholder="Enter a Email"
                                        type="email"
                                        name="email"
                                        defaultValue={email}
                                        onChange={this.onChange.bind(this)} 
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" sm="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-phone"
                                        >
                                            Phone*
                                        </label>
                                        <InputGroup>
                                            <InputGroupAddon className="input-shadow" addonType="prepend">
                                                <InputGroupText className="border-none">{countryPhoneCode}</InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                className="input-shadow"
                                                id="input-phone"
                                                placeholder="Enter a Phone"
                                                type='text'
                                                name="phone"
                                                defaultValue={phone}
                                                onChange={this.onChange.bind(this)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" sm="12">
                                <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-dob"
                                    >
                                        DOB
                                    </label>
                                    <InputGroup className="datepicker-input-group input-shadow">
                                        <DatePicker
                                            selected={new Date(dob)}
                                            onChange={this.selectDateChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="datepicker-form-control border-none"
                                            popperPlacement="bottom"
                                            // minDate={new Date()}
                                            popperModifiers={{
                                                flip: {
                                                    behavior: ["bottom"] // don't allow it to flip to be above
                                                },
                                                preventOverflow: {
                                                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                },
                                                hide: {
                                                    enabled: false // turn off since needs preventOverflow to be enabled
                                                }
                                            }}
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText className="border-none"><i className="fa fa-calendar"></i></InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col md="6" sm="12">
                                <FormGroup   style={{display: 'grid'}} className="datePickerDiv">
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-ibanNum"
                                    >
                                        Gender
                                    </label>
                                    <div>
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                                <input checked={gender === 'm'} type="radio" className="form-check-input" onChange={this.onChange.bind(this)}  name="gender" value="m" /> Male
                                            </label>
                                        </div>
                                        <div className="form-check-inline">
                                            <label className="form-check-label">
                                                <input checked={gender === 'f'} type="radio" className="form-check-input" onChange={this.onChange.bind(this)}  name="gender" value="f" /> Female
                                            </label>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-cityId"
                                    >
                                        Select City
                                    </label>

                                    <Input className="input-shadow" type="select" name="cityId" id="cityId" onChange={this.onChange.bind(this)}>
                                        <option value=''>Select City</option>
                                        {
                                            cities.map((c, k) => {
                                                return (
                                                    <option selected={cityId == c._id} value={k} key={k}>{c.cityName}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row>
                            <Col sm="12">
                                <div className="text-right">
                                    { !isLoading &&  
                                        <Button className="mt-4" onClick={this.onSubmitPersonal.bind(this)} color="primary" type="button"  disabled={this.isPersonalNotFilled()}>
                                            Update
                                        </Button>
                                    }
                                    {
                                        isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                    }
                                </div>
                            </Col>
                        </Row> 
                    </div>
                </Form>
            </>
        )
    }

    EditParasutInformation(){
        const {isLoading, parasutId, parasutName, err, message, success} = this.state; 
        return(
            <>
                {err &&
                    <Alert color="danger">
                        {message}
                    </Alert>
                }
                {success &&
                    <Alert color="success">
                        {message}
                    </Alert>
                }
                <Form role="form" method="POST">
                    <div className="pl-lg-4">
                        <Row>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-parasutId"
                                    >
                                        Parasut Id
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-parasutId"
                                        placeholder="Enter a Parasut Id"
                                        type="text"
                                        name="parasutId"
                                        defaultValue={parasutId}
                                        onChange={this.onChange.bind(this)} 
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-parasutName"
                                    >
                                        Parasut Name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-parasutName"
                                        placeholder="Enter a Parasut Name"
                                        type="text"
                                        name="parasutName"
                                        defaultValue={parasutName}
                                        onChange={this.onChange.bind(this)} 
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <div className="text-right">
                                    { !isLoading &&  
                                        <Button className="mt-4" onClick={this.onSubmitParasut.bind(this)} color="primary" type="button"  disabled={this.isParasutNotFilled()}>
                                            Update
                                        </Button>
                                    }
                                    {
                                        isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </>
        )
    }

    EditPasswordForm() {
        const {isLoading, password, confirmPassword, showConfirmPassword, showPasword, err, message, success} = this.state; 
        return(
            <>
                {err &&
                    <Alert color="danger">
                        {message}
                    </Alert>
                }
                {success &&
                    <Alert color="success">
                        {message}
                    </Alert>
                }
                <Form role="form" method="POST">
                    <div className="pl-lg-4">
                        <Row>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-password"
                                    >
                                        Password
                                    </label>
                                    <InputGroup className="input-group-alternative"> 
                                        <Input
                                            className="form-control-alternative"
                                            id="input-password"
                                            placeholder="********"
                                            type={showPasword?'text':'password'}
                                            name="password"
                                            defaultValue={password}
                                            onChange={this.onChange.bind(this)} 
                                        />
                                        <InputGroupAddon addonType="append">
                                            <a onClick={this.togglePassword.bind(this)}>
                                                <InputGroupText style={{height:'100%'}}><i className={!showPasword?'fas fa-eye-slash':'fas fa-eye'}></i></InputGroupText>
                                            </a>
                                        </InputGroupAddon>
                                    </InputGroup> 
                                </FormGroup>
                            </Col>
                            <Col md="6" sm="12">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-confirmPassword"
                                    >
                                        Confirm Password
                                    </label>
                                    <InputGroup className="input-group-alternative">
                                        <Input
                                            className="form-control-alternative"
                                            id="input-confirmPassword"
                                            placeholder="********"
                                            type={showConfirmPassword?'text':'password'}
                                            name="confirmPassword"
                                            defaultValue={confirmPassword}
                                            onChange={this.onChange.bind(this)} 
                                        />
                                        <InputGroupAddon addonType="append">
                                            <a onClick={this.toggleConfirmPassword.bind(this)}>
                                                <InputGroupText  style={{height:'100%'}}><i className={!showConfirmPassword?'fas fa-eye-slash':'fas fa-eye'}></i></InputGroupText>
                                            </a>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <div className="text-right">
                                    { !isLoading &&  
                                        <Button className="mt-4" onClick={this.onSubmitPassword.bind(this)} color="primary" type="button"  disabled={this.isPasswordFilled()}>
                                            Update
                                        </Button>
                                    }
                                    {
                                        isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </>
        )
    }

    render() {
        let {fname, lname, email, cities, cityId, cityName, loginPhoneNo, dob, gender, parasutName, parasutId, countryPhoneCode, phone, isLoading, current_tab} = this.state;
        
        return (
            <>
            <Header />
            {/* Table */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col sm="12" md="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Edit Customer</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Link to="/dashboard/customers">
                                        <Button
                                            color="primary"
                                            size="sm"
                                        >
                                        Back to Customers
                                        </Button>
                                    </Link>
                                </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <ul className="stepper stepper-horizontal">
                                            <li className={current_tab === 1? 'completed':''}>
                                                <a onClick={this.setTab.bind(this,1)}>
                                                <span className="circle">
                                                    <i className="fa fa-info" aria-hidden="true"></i>
                                                </span>
                                                <span className="label">Personal Information</span>
                                                </a>
                                            </li>
                                            <div className="line"></div>
                                            <li className={current_tab === 2? 'completed':''}>
                                                <a onClick={this.setTab.bind(this,2)}>
                                                <span className="circle">
                                                    <i className="fa fa-child" aria-hidden="true"></i>
                                                </span>
                                                <span className="label">Parasut Information</span>
                                                </a>
                                            </li>
                                            <div className="line"></div>
                                            <li className={current_tab === 3? 'completed':''}>
                                                <a onClick={this.setTab.bind(this,3)}>
                                                <span className="circle">
                                                <i className="fa fa-key" aria-hidden="true"></i>
                                                </span>
                                                <span className="label">Change Password</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>

                                { current_tab === 1 && this.EditPersonalInformation()}
                                { current_tab === 2 && this.EditParasutInformation()}
                                { current_tab === 3 && this.EditPasswordForm()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                
            </>
        );
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    customerData: (customer) => dispatch(customerData(customer))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomer);
