import React from "react";
import { toast } from "react-toastify";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import Header from "../../components/Headers/Header";
import DrawMap from "../../components/Maps/DrawMap";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polygon
} from "react-google-maps";

import { getZones, createZone } from "../../actions/Zones";
import { getCities } from "../../actions/Cities";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";

class Zones extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator(Rules.addZone);

    this.state = {
      cities: [],
      zoneMapModal: false,
      coordinates: "",
      zones: [],
      zoneAddModal: false,
      name: "",
      code: "",
      cityId: "",
      cityName: "",
      zoneCoordinates: "",
      hasSurge: false,
      surgeAmount: 0,
      validation: this.validator.valid()
    };
  }

  viewCoordinates = coordinates => {
    this.setState(prevState => ({
      zoneMapModal: !prevState.zoneMapModal
    }));

    if (!this.state.zoneMapModal) {
      this.setState({
        selectedMarker: false,
        coordinates: coordinates
      });
    } else {
      this.setState({
        selectedMarker: false,
        coordinates: []
      });
    }
  };

  componentWillMount() {
    this.setState({ isLoading: true });
    getZones()
      .then(response => {
        if (response.data.success) {
          if (response.data.success.data) {
            let responseData = response.data.success.data;
            this.setState({
              zones: responseData,
              isLoading: false
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  addZoneModal() {
    const { isLoadingCities, hasLoadedCities = false } = this.state;
    const willLoadCities = !hasLoadedCities && !isLoadingCities;
    this.setState(prevState => ({
      zoneAddModal: !prevState.zoneAddModal,
      isLoadingCities: isLoadingCities || willLoadCities
    }));
    if (willLoadCities) {
      getCities()
        .then(response => {
          if (!response.data.success) {
            throw new Error(`!response.data.success ${response}`);
          }
          if (!response.data.success.data) {
            throw new Error(`!response.data.success.data ${response}`);
          }
          let responseData = response.data.success.data;
          this.setState({
            cities: responseData,
            isLoadingCities: false,
            hasLoadedCities: true
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    const { cities } = this.state;
    if (name == "zoneCoordinates") {
      this.setState({ [name]: JSON.parse(value) });
    } else if (name == "cityId") {
      if (value !== "") {
        this.setState({
          cityId: cities[value]._id,
          cityName: cities[value].cityName
        });
      } else {
        this.setState({
          cityId: "",
          cityName: "Please select city"
        });
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const {
      name,
      code,
      cityId,
      zoneCoordinates,
      hasSurge,
      surgeAmount
    } = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.setState({ isLoading: true });

      createZone({
        name,
        code,
        cityId,
        zoneCoordinates,
        hasSurge,
        surgeAmount
      })
        .then(response => {
          console.log(response);
          if (response.data.success.data) {
            this.addZoneModal();
            this.componentWillMount();
            this.state({
              name: "",
              code: "",
              cityId: "",
              zoneCoordinates: "",
              hasSurge: false,
              surgeAmount: 0
            });
          } else {
            this.setState({
              err: true,
              message: "Unable to add!",
              isLoading: false
            });
            toast.error("Unable to add!");
            setTimeout(() => {
              this.setState({ err: false, success: false, isLoading: false });
            }, 3000);
          }
        })
        .catch(error => {
          this.setState({ err: true, message: "Server Error!" });
          setTimeout(() => {
            this.setState({ err: false, success: false, isLoading: false });
          }, 3000);
        });
    }
  }
  handleReturnCoords = coords => {
    console.log(coords);
    if (coords === null) {
      this.setState({ zoneCoordinates: "" });
    } else {
      this.setState({ zoneCoordinates: [coords] });
    }
  };

  render() {
    const {
      zoneMapModal,
      zones,
      isLoading,
      isLoadingCities,
      cities,
      zoneAddModal,
      name,
      code,
      cityId,
      zoneCoordinates,
      hasSurge,
      surgeAmount
    } = this.state;
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    const MapWithAMarker = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyANl6n1ocSKfkecsPu4BqWvIv_BLb4HmmQ&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />
      }),
      withScriptjs,
      withGoogleMap
    )(props => {
      var coordinateArray = [];
      var i = 0;
      for (let coordinate of props.markers) {
        coordinateArray[i] = {
          lat: coordinate[0],
          lng: coordinate[1]
        };
        i++;
      }

      let polygonCoords = coordinateArray.map(ll => {
        return { lat: ll.lng, lng: ll.lat };
      });
      return (
        <GoogleMap
          defaultZoom={10}
          defaultCenter={{
            lat: props.markers.length > 0 ? polygonCoords[0].lat : 28.0426,
            lng: props.markers.length > 0 ? polygonCoords[0].lng : 40.96331
          }}
        >
          {
            // props.markers.map((marker, key) => {
            //     return (
            //         <Marker key={key}
            //             position={{ lat: marker[0], lng: marker[1] }}
            //         >
            //         </Marker>
            //     )
            // })
          }
          <Polygon
            path={polygonCoords}
            //key={1}
            options={{
              fillColor: "#000",
              fillOpacity: 0.4,
              strokeColor: "#000",
              strokeOpacity: 1,
              strokeWeight: 1
            }}
          />
        </GoogleMap>
      );
    });

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={4} />
                    <Col sm={8}>
                      <Button
                        onClick={e => this.addZoneModal()}
                        className="pull-right"
                        color="primary"
                        style={{ float: "right" }}
                        size="sm"
                      >
                        Add Zone
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr className="row m-0">
                      <th className="col">S No.</th>
                      <th className="col">Name</th>
                      <th className="col">Code</th>
                      <th className="col">City Name</th>
                      <th className="col">View Coordinates</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <Loader
                            type="Circles"
                            color="#5e72e4"
                            height={40}
                            width={40}
                          />
                        </td>
                      </tr>
                    )}

                    {!isLoading && zones == null && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}

                    {!isLoading &&
                      zones != null &&
                      zones.map((zone, key) => {
                        return (
                          <tr className="row m-0" key={key}>
                            <th className="col">{key + 1}</th>
                            <td className="col">{zone.name}</td>
                            <td className="col">{zone.code}</td>
                            <td className="col">{zone.cityId.cityName}</td>
                            <td className="col">
                              <i
                                onClick={e =>
                                  this.viewCoordinates(
                                    zone.fence.coordinates[0]
                                  )
                                }
                                className="fa fa-eye btn p-1"
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          size="lg"
          isOpen={zoneMapModal}
          toggle={e => this.viewCoordinates()}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.viewCoordinates()}>
            <span style={{ fontSize: 20 }}> View </span>
          </ModalHeader>
          <ModalBody>
            <MapWithAMarker
              selectedMarker={this.state.selectedMarker}
              markers={this.state.coordinates}
            />
          </ModalBody>
        </Modal>

        <Modal
          size="lg"
          isOpen={zoneAddModal}
          toggle={e => this.addZoneModal()}
          className={this.props.className}
        >
          <ModalHeader toggle={e => this.addZoneModal()}>
            <span style={{ fontSize: 20 }}> Add Zone </span>
          </ModalHeader>
          <ModalBody>
            <Form role="form" method="POST">
              <div className="pl-lg-4">
                <Row>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="name">
                        Name*
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="name"
                        placeholder="Enter a name"
                        type="text"
                        name="name"
                        onChange={this.onChange.bind(this)}
                      />
                      <InputError
                        show={validation.name.isInvalid}
                        message={validation.name.message}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-code"
                      >
                        Code*
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-code"
                        placeholder="Enter a Code"
                        type="text"
                        name="code"
                        onChange={this.onChange.bind(this)}
                      />
                      <InputError
                        show={validation.code.isInvalid}
                        message={validation.code.message}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-cityId"
                      >
                        Select City*
                      </label>

                      <Input
                        className="input-shadow"
                        type="select"
                        name="cityId"
                        onChange={this.onChange.bind(this)}
                      >
                        <option value="">Select City</option>
                        {cities.map((c, k) => {
                          return (
                            <option value={k} key={k}>
                              {c.cityName}
                            </option>
                          );
                        })}
                      </Input>
                      <InputError
                        show={validation.cityId.isInvalid}
                        message={validation.cityId.message}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6" sm="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="zoneCoordinates"
                      >
                        Zone Coordinates*
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="zoneCoordinates"
                        placeholder="Enter a Coordinates"
                        type="text"
                        name="zoneCoordinates"
                        value={JSON.stringify(this.state.zoneCoordinates)}
                        disabled={true}
                        onChange={this.onChange.bind(this)}
                      />
                      <InputError
                        show={validation.zoneCoordinates.isInvalid}
                        message={validation.zoneCoordinates.message}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <DrawMap
                  handleReturnCoords={this.handleReturnCoords}
                  zones={zones}
                />
                <Row>
                  <Col sm="12">
                    <div className="text-right">
                      {!(isLoading || isLoadingCities) && (
                        <Button
                          className="mt-4"
                          onClick={this.onSubmit.bind(this)}
                          color="primary"
                          type="button"
                        >
                          Submit
                        </Button>
                      )}
                      {(isLoading || isLoadingCities) && (
                        <Loader
                          type="Circles"
                          color="#5e72e4"
                          height={30}
                          width={30}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default Zones;
