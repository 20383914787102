import {
  getRequestWithToken,
  putRequest,
  postRequest,
  putMobileRequest,
  deleteRequest
} from '../Constant/Api';

export const filterCampaigns = filter => {
  console.log(filter);
  let query = '';

  Object.entries(filter).forEach(
    ([key, value]) => (query += `${key}=${value}&&`)
  );

  const api_url = `campaigns?${query}`;

  return getRequestWithToken(api_url, filter);
};

export const createCampaign = params => {
  const api_url = `campaigns`;
  return postRequest(api_url, params);
};

export const updateCampaign = params => {
  const api_url = `campaigns/${params._id}`;
  return putRequest(api_url, params);
};
export const deleteCampaign = params => {
  const api_url = `campaigns/${params._id}`;
  return deleteRequest(api_url, params);
};

export const getCampaignDetails = params => {
  const api_url = `campaigns/${params._id}`;

  return getRequestWithToken(api_url);
};

export const updateCampaignImage = params => {
  const api_url = `campaigns/${params._id}/image`;

  return putRequest(api_url, params, { 'Content-Type': 'multipart/form-data' });
};
