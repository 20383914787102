import React from "react";
import { toast } from "react-toastify";
import { getEligibleWashersForBooking } from '../../actions/Washers';
import { getBookingDetail, updateBooking, reBook, updateBookingStatus } from '../../actions/Bookings';
import Switch from "react-ios-switch/lib";
import { Col, Input, InputGroup, ModalBody, Row, Table, UncontrolledCarousel } from 'reactstrap';
import { OTOPARLAK_LOGO } from "../../Constant/Constant";
import moment, { fn } from 'moment';
import {
  Nav,
  NavItem,
  Form,
  Label,
  Button,
  NavLink,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  TabPane,
  TabContent,
  FormGroup,
  ButtonGroup
} from "reactstrap";
import Loader from 'react-loader-spinner';
import Select from "react-select";

const statusCodeList = [
  { statusCode: 100, statusMessage: "Reserved" },
  { statusCode: 101, statusMessage: "Confirmed" },
  { statusCode: 102, statusMessage: "Washer on the way" },
  { statusCode: 103, statusMessage: "Cleaning Started" },
  { statusCode: 200, statusMessage: "Cleaning Completed" },
  { statusCode: 400, statusMessage: "Cancelled" },
  { statusCode: 401, statusMessage: "Cancelled (Paid)" }
];

const statusCodeToMessage = (() => {
  const codeToMessage = statusCodeList
    .reduce((acc, { statusCode, statusMessage }) =>
      ({ ...acc, [statusCode]: statusMessage}), {});
  return (statusCode) => codeToMessage[statusCode] || statusCode;
})();

class BookingDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "General",
      newWasher: null,
      eligibleWashers: [],
      eligibleWasherSelectables: []
    };
  }

  componentWillMount() {
    const { bookingId } = this.props;
    this.setState({
      bookingDetailsLoading: true
    }, () => {
      getBookingDetail({ bookingId })
        .then(response => {
          if (response.data.success.data) {
            const booking = response.data.success.data;
            const { washerId } = booking;
            this.setState({
              booking,
              washerId,
              bookingDetailsLoading: false
            }, this.reloadEligibleWashers);
          } else {
            throw new Error(response.data);
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({
            bookingDetailsLoading: false
          });
        });
    });
  }

  reloadEligibleWashers = () => {
    const { booking } = this.state;
    const { washerId, bookingDateTime } = booking;
    this.setState({
      isLoadingEligibleWashers: true
    });
    getEligibleWashersForBooking({
      washerId: booking.statusCode === 400 ? null : washerId._id,
      bookingDateTime
    }).then(response => {
      if (response.data.success.data) {
        const { washers } = response.data.success.data;
        this.setState({
          eligibleWashers: washers,
          isLoadingEligibleWashers: false
        });
      }
    }).catch(err => {
      console.error(err);
      this.setState({ isLoadingEligibleWashers: false });
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  onChangeWasher = (washer, { action }) => {
    if (action === 'select-option') {
      this.setState({
        newWasher: washer
      })
    }
  };

  isNotFilledRebook = () => {
    const {booking, statusCode, newWasher} = this.state;
    return (
      !newWasher
    );
  };

  get canRebook() {
    const { booking } = this.state;
    return [100, 101, 400].includes(booking.statusCode);
  }

  onSubmitRebook = (e) => {
    e.preventDefault();
    const { newWasher, booking } = this.state;
    if (!this.isNotFilledRebook()) {
      let payload = {
        washerId: newWasher._id,
        bookingId: booking._id
      };
      this.setState({ isRebooking: true });

      reBook(payload)
        .then(response => {
          if (response.data.success.data) {
            payload.washerId = newWasher;
            this.setState({
              isRebooking: false,
              booking: {
                ...booking,
                ...payload
              },
              newWasher: null
            });
            this.reloadEligibleWashers();
            const { reloadWashers } = this.props;
            reloadWashers && reloadWashers();
            toast.success("Successfully rebooked booking.");
          } else {
            toast.error("Failed to rebook booking.");
          }
        }).catch(console.error);
    }
  };

  onChangeStatus = (status) => {
    const { booking } = this.state;
    this.setState({
      isUpdatingStatus: true
    }, () => {
      updateBookingStatus({
        bookingId: booking._id,
        status,
        ...(status === 'cancel' ? {
          note: 'Admin cancelled booking.',
          isChange: false
        } : {
          isForce: true
        })
      }).then((response) => {
        if (response.data.success.data) {
          const statusCode = this.getStatusCode(status);
          this.setState({
            isUpdatingStatus: false,
            booking: {
              ...booking,
              statusCode,
            }
          });
          const { onChangeBooking } = this.props;
          onChangeBooking && onChangeBooking({ ...booking, statusCode });
          toast.success("Successfully updated booking status.");
        } else {
          this.setState({
            isUpdatingStatus: false
          });
          toast.error("Failed to update booking status.");
        }
      }).catch(console.error);
    });
  };

  canChangeStatus = (status) => {
    const { statusCode } = this.state.booking;
    if (statusCode === 200) {
      return false;
    }
    switch (status) {
      case 'cancel':
        return statusCode !== 400 && statusCode !== 401;
      case 'ontheway':
        return statusCode !== 102;
      case 'start':
        return statusCode !== 103;
      case 'end':
        return statusCode !== 200;
      default:
        throw new Error(`Unknown incident status to change '${status}'`);
    }
  };

  getStatusCode = (status) => {
    switch (status) {
      case 'cancel':
        return 400;
      case 'ontheway':
        return 102;
      case 'start':
        return 103;
      case 'end':
        return 200;
      default:
        throw new Error(`Unknown incident status, '${status}'`);
    }
  };

  renderGeneral = () => {
    const {
      updatesDisabled
    } = this.props;
    const {
      bookingDuration,
      washerIncome,
      discountValue,
      totalPrice,
      applicablePrice,
      note,
      bookingDate,
      bookingTime,
      bookingDateTime,
      created,
      statusCode
    } = this.state.booking;
    const {
      isUpdatingStatus
    } = this.state;

    return (
      <>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr className="row m-0">
              <th className="col">Status</th>
              <th className="col">Creation</th>
              <th className="col">Incident Date</th>
              <th className="col">Incident Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr className="row m-0">
              <td className="col">
                { statusCodeToMessage(statusCode) }
              </td>
              <td className="col">{moment(created).fromNow()}</td>
              <td className="col">{`${bookingDate} ${bookingTime} (${moment(
                bookingDateTime
              ).fromNow()})`}</td>
              <td className="col">{`${bookingDuration} minutes`}</td>
            </tr>
          </tbody>
        </Table>
       
        { !updatesDisabled && (
          <Row>
            <Col sm="12">

              <div className="text-center mt-3">
                <Label className="mr-3">
                  Change Booking Status:
                </Label>
                { !isUpdatingStatus && (
                  <ButtonGroup>
                    {
                      [
                        { status: 'cancel', btnText: 'Cancel', btnColor: 'danger' },
                        { status: 'ontheway', btnText: 'On The Way', btnColor: 'warning' },
                        { status: 'start', btnText: 'Start', btnColor: 'success' },
                        { status: 'end', btnText: 'Finish', btnColor: 'primary' },
                      ].map(({ status, btnText, btnColor }, index) => (
                        <Button
                          key={`status-btn-${index}`}
                          disabled={ !this.canChangeStatus(status) }
                          onClick={ () => this.onChangeStatus(status)}
                          color={btnColor}
                        >
                          { btnText }
                        </Button>
                      ))
                    }
                  </ButtonGroup> )}
                { isUpdatingStatus && <Loader type="Circles" color="#5e72e4" height={30} width={30} /> }
              </div>
            </Col>
          </Row>
        ) }
      </>
    );
  };
  renderPayment = () => {
    const { payment } = this.state.booking;
    if (!payment) {
      return null;
    }
    const {
      mode,
      gateway,
      response
    } = payment;
    const { systemTime, price, paidPrice, merchantCommissionRateAmount } = response || {};
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">Mode</th>
            <th className="col">Gateway</th>
            <th className="col">Time</th>
            <th className="col">Price</th>
            <th className="col">Paid Price</th>
            <th className="col">Merchant Commission</th>
          </tr>
        </thead>
        <tbody>
          <tr className="row m-0">
            <td className="col">{mode}</td>
            <td className="col">{gateway}</td>
            <td className="col">{moment(systemTime).fromNow()}</td>
            <td className="col">{price}</td>
            <td className="col">{paidPrice}</td>
            <td className="col">{merchantCommissionRateAmount}</td>
          </tr>
        </tbody>
      </Table>
    );
  };
  renderPhotos = () => {
    const {
      photos: { cleaningBegin = [], cleaningEnd = [] } = {}
    } = this.state.booking;

    const items = [
      ...cleaningBegin.map(src => ({
        src,
        header: 'Cleaning Begin',
      })),
      ...cleaningEnd.map(src => ({
        src,
        header: 'Cleaning End',
      }))];

    return (
      <>
        { items.length > 0 ? (
          <UncontrolledCarousel items={items} className='booking-carousel' />
        ) : (
          <h4>No Photos available.</h4>
        )}
      </>
    );
  };
  renderCar = () => {
    const { car } = this.state.booking;
    if (!car) {
      return null;
    }
    const { brand, plateNo } = car;
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">Brand</th>
            <th className="col">Plate Number</th>
          </tr>
        </thead>
        <tbody>
          <tr className="row m-0">
            <td className="col">{brand}</td>
            <td className="col">{plateNo}</td>
          </tr>
        </tbody>
      </Table>
    );
  };
  renderCustomer = () => {
    const { customerId: customer } = this.state.booking;
    if (!customer) {
      return null;
    }
    const { fname, lname, picture, email, loginPhoneNo } = customer;
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">Photo</th>
            <th className="col">First Name</th>
            <th className="col">Last Name</th>
            <th className="col">Email</th>
            <th className="col">Phone</th>
          </tr>
        </thead>
        <tbody>
          <tr className="row m-0">
            <td className="col">
              {picture && <img src={picture} className="icon" />}
            </td>
            <td className="col">{fname}</td>
            <td className="col">{lname}</td>
            <td className="col">{email}</td>
            <td className="col">{loginPhoneNo}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  renderWasher = () => {
    const { updatesDisabled } = this.props;
    const { washerId, eligibleWashers, isLoadingEligibleWashers, isRebooking } = this.state;
    const { washerId: washer = null } = this.state.booking;
    return (
      <>
        { washer && (
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr className="row m-0">
              <th className="col">Photo</th>
              <th className="col">First Name</th>
              <th className="col">Last Name</th>
              <th className="col">Email</th>
              <th className="col">Phone</th>
            </tr>
          </thead>
          <tbody>
            <tr className="row m-0">
              <td className="col">
                {washer.picture && <img src={washer.picture} className="icon" />}
              </td>
              <td className="col">{washer.fname}</td>
              <td className="col">{washer.lname}</td>
              <td className="col">{washer.email}</td>
              <td className="col">{washer.loginPhoneNo}</td>
            </tr>
          </tbody>
        </Table> )}
        { !updatesDisabled && this.canRebook && (
          <Form role="form">
            <FormGroup>
              <Label>Change Technician</Label>
              <br/>
              {<Select
                isLoading={isLoadingEligibleWashers}
                name='washerId'
                placeholder='Select a technician to reassign this incident'
                isSearchable={true}
                onChange={this.onChangeWasher}
                options={eligibleWashers}
                getOptionLabel={({ fname, lname, averageRating }) => `${fname} ${lname} (${averageRating})`}
                getOptionValue={({ _id }) => _id}
              />}
            </FormGroup>
            <div className="text-center">
              { !isRebooking && (
                <Button disabled={this.isNotFilledRebook()} onClick={this.onSubmitRebook} color="primary" type="button">
                  Change Technician
                </Button> )}
              { isRebooking && <Loader type="Circles" color="#5e72e4" height={30} width={30} /> }
            </div>
          </Form> )}
      </>
    );
  };
  renderServices = () => {
    const { services = [] } = this.state.booking;
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">#</th>
            <th className="col">Logo</th>
            <th className="col">Name</th>
          </tr>
        </thead>
        <tbody>
          {services.map(({ name, logo }, index) => (
            <tr className="row m-0" key={`service-${index}`}>
              <td className="col">{index + 1}</td>
              <td className="col">
                <img src={logo || OTOPARLAK_LOGO} className="icon" />
              </td>
              <td className="col">{name}</td>
            </tr>
          ))}
          {services.length === 0 ? (
            <tr className="row m-0">
              <td className="col">
                No services for this booking.
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    );
  };
  renderAdditionalServices = () => {
    const { additionalServices = [] } = this.state.booking;
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">#</th>
            <th className="col">Logo</th>
            <th className="col">Name</th>
          </tr>
        </thead>
        <tbody>
          {additionalServices.map(({ name, logo }, index) => (
            <tr className="row m-0" key={`additionalService-${index}`}>
              <td className="col">{index + 1}</td>
              <td className="col">
                <img src={logo || OTOPARLAK_LOGO} className="icon" />
              </td>
              <td className="col">{name}</td>
            </tr>
          ))}
          {additionalServices.length === 0 ? (
            <tr className="row m-0">
              <td className="col">
                No additional services for this booking.
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    );
  };

  renderSubmerchants = () => {
    const { submerchants = [] } = this.state.booking;
    if (!submerchants.length) {
      return null;
    }
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr className="row m-0">
            <th className="col">#</th>
            <th className="col">Name</th>
            <th className="col">Category1</th>
            <th className="col">Item Type</th>
            <th className="col">Price</th>
            <th className="col">Submerchant Price</th>
          </tr>
        </thead>
        <tbody>
          {submerchants.map(
            (
              {
                id,
                price,
                name,
                category1,
                itemType,
                subMerchantPrice,
                commitionTotal
              },
              index
            ) =>
              id ? (
                <tr className="row m-0" key={`submerchant-${id}`}>
                  <td className="col">{index + 1}</td>
                  <td className="col">{name || ""}</td>
                  <td className="col">{category1 || ""}</td>
                  <td className="col">{itemType || ""}</td>
                  <td className="col">{price || ""}</td>
                  <td className="col">{subMerchantPrice || ""}</td>
                </tr>
              ) : (
                <tr className="row m-0" key={`submerchant-total`}>
                  <td className="col-8"></td>
                  <td className="col-2">
                    Commition Total
                  </td>
                  <td className="col-2">{commitionTotal}</td>
                </tr>
              )
          )}
        </tbody>
      </Table>
    );
  };

  renderAddress = () => {
    const { address } = this.state.booking;
    if (!address) {
      return null;
    }
    const { name, detail, location: [lng, lat]} = address;
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
        <tr className="row m-0">
          <th className="col">Name</th>
          <th className="col">Detail</th>
          <th className="col">Location (Lat/Lon)</th>
        </tr>
        </thead>
        <tbody>
          <tr className="row m-0">
            <td className="col">{name}</td>
            <td className="col">{detail}</td>
            <td className="col">
              <a href={`http://maps.google.com/?q=${lat},${lng}`} target="_blank" rel="noreferrer noopener">
                <i style={{marginRight: 15 }} className="fa fa-map-pin" />
                {lat} / {lng}
              </a>
            </td>
          </tr>
        </tbody>
      </Table>
    );
  };

  toggleTab = tab => {
    this.setState({
      activeTab: tab
    });
  };

  render() {
    const { activeTab, isRebooking, bookingDetailsLoading } = this.state;
    if (bookingDetailsLoading) {
      return (
        <Loader type="Circles" color="#5e72e4" height={30} width={30} />
      );
    }
    return (
      <>
        <Nav tabs>
          {[
            "General",
            "Photos",
            "Customer",
            "Technician",
            "Address"
          ].map(tab => (
            <NavItem key={`tabLink-${tab}`}>
              <NavLink
                className={activeTab === tab ? "active" : ""}
                onClick={() => this.toggleTab(tab)}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={"General"}>{this.renderGeneral()}</TabPane>
          <TabPane tabId={"Photos"}>{this.renderPhotos()}</TabPane>
          <TabPane tabId={"Customer"}>{this.renderCustomer()}</TabPane>
          <TabPane tabId={"Technician"}>{this.renderWasher()}</TabPane>
          <TabPane tabId={"Address"}>{this.renderAddress()}</TabPane>
        </TabContent>
      </>
    );
  }
}

export default BookingDetails;
