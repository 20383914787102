import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table
  } from "reactstrap";
import Switch from 'react-ios-switch';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
import { getServiceSolutions, updateServiceSolution } from '../../actions/Solutions';
import { serviceSolutionData } from '../../lib/Action/ServiceSolution';
import { getSolutions } from '../../actions/Solutions';

class ServiceSolutions extends React.Component {

    constructor() {
        super();
        this.state = { 
            isLoadingSolutions:true,
            isLoadingServiceSolutions:true,
            solutions: [],
            serviceSolutions: new Set()
        }
    }
    get isLoading() {
        return this.state.isLoadingServiceSolutions && this.state.isLoadingSolutions;
    }

    changeStatus(id){
        let  { serviceSolutions } = this.state;

        const status = !serviceSolutions.has(id);

        updateServiceSolution({
            serviceId: this.props.ServiceReducer.serviceData._id,
            solutionId: id,
            status: status
        }).then(response => {

        }).catch(error => {
            this.setState({ err:true, message: 'Server Error!'})
        });

        if (status) {
            this.setState({
                serviceSolutions: new Set([...serviceSolutions, id])
            });
        } else {
            let nextServiceSolutions = new Set([...serviceSolutions]);
            nextServiceSolutions.delete(id);
            this.setState({
               serviceSolutions: nextServiceSolutions
            });
        }
    }

    componentWillMount(){
        if(this.props.ServiceReducer.serviceData){
            getServiceSolutions({
                serviceId: this.props.ServiceReducer.serviceData._id
            }).then((response) => {
                if(response.data.success){
                    if(response.data.success.data){
                        let responseData = response.data.success.data;
                        this.setState({
                            serviceSolutions: new Set(responseData.map(({ solutionId }) => solutionId)),
                            isLoadingServiceSolutions:false
                        });
                    }
                }
            })
        }
        getSolutions().then((response) => {
            if(response.data.success){
                if(response.data.success.data){
                    let responseData = response.data.success.data;
                    this.setState({
                        solutions:responseData,
                        isLoadingSolutions:false
                    });
                }
            }
        });
    }



    render(){

        const {solutions, serviceSolutions} = this.state;
        const isLoading = this.isLoading;
        const { name } = this.props.ServiceReducer.serviceData;
        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={4}>
                                            { name }
                                        </Col>
                                        <Col sm={8}>
                                            <Link to="/dashboard/services">
                                                <Button className="pull-right" color="primary" style={{float:'right'}} size="sm">
                                                    Back to Services
                                                </Button>
                                            </Link>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr className="row m-0">
                                            <th className="col">Name</th>
                                            <th className="col">Stock</th>
                                            <th className="col">Type</th>
                                            <th className="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                        !isLoading && solutions.length == 0 && <tr>
                                            <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                        </tr>
                                        }

                                        { 
                                            !isLoading && solutions.length > 0 &&
                                            solutions.map((solution,key) => {
                                                return (
                                                    <tr className="row m-0" key={key}>
                                                        <th className="col">{solution.name}</th>
                                                        <td className="col">{solution.stock}</td>
                                                        <td className="col">{solution.type}</td>
                                                        <td  className="col">
                                                            <Switch
                                                              checked={serviceSolutions.has(solution._id)}
                                                              className="switch"
                                                              onChange={() => this.changeStatus(solution._id)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    serviceSolutionData: (service) => dispatch(serviceSolutionData(service))
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceSolutions);
