import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import _ from 'lodash';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,  
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardFooter
} from "reactstrap";
import Switch from 'react-ios-switch';
import Header from "../../components/Headers/Header";
import { filterWashers, updateWasher } from "../../actions/Washers";
import { washerData } from "../../lib/Action/Washer";

class SubMerchant extends React.Component {
    limit = 10;
    constructor(props) {
        super(props);
        this.state = { 
            isLoading:true,
            washers: [],
            totalUsers: '',
            pages:1,
            current_page:1,
            dir:1,
            type: 'submerchants',
            subMerchantType: 'all'
        }
    }

    componentWillMount(){
        this.setState({isLoading:true});
        filterWashers(this.filterQuery()).then( response => {
            if(response.data.success){
                if(response.data.success.data.users){
                    console.log(response.data.success.data.users);
                    let responseData = response.data.success.data.users;
                    let totalUsers = responseData.totalUsers;
                    let pages  = Math.ceil(totalUsers/this.limit );
                    this.setState({
                        washers:responseData.users,
                        isLoading:false,
                        totalUsers: responseData.totalUsers,
                        pages,
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    filterQuery(){
        const  {dir, current_page, pages, type }  = this.state;
        
        let query = {}

        if(dir !== ''){
            query.dir = dir;
        }
    
        if(type !== ''){
            query.type = type;
        }

        query.start = (current_page - 1)*this.limit;
        query.limit = this.limit;
    
        return query;
    }

    goToEdit(washer){
        washer['fromType'] = 'submerchant';
        this.props.washerData(washer);
        this.props.history.push("/dashboard/washer-edit");
    }

    goToPage(page){
        this.setState({current_page:page}, () =>{
          this.getWashers(false);
        });
    }

    getWashers(page = true){
        this.setState({isLoading:true});
        const query = this.filterQuery();
        filterWashers(query).then( response => {
            let totalusers = response.data.success.data.users.totalUsers;
            let pages  = Math.ceil(totalusers/50 );
            console.log(response);
            if(page){
                this.setState({
                admins:response.data.success.data.users.users,
                pages,
                isLoading:false
                });
            }else{
                this.setState({
                admins:response.data.success.data.users.users,
                isLoading:false
                });
            }
        }).catch(error=> {
            console.log(error)
        });
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    render(){

        const { washers, isLoading, totalUsers, subMerchantType, pages, current_page } = this.state;

        return(
            <>
                <Header />
                {/* Table */}
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={3}>
                                            <InputGroup className="input-group-alternative">
                                                <Input type="select" name="subMerchantType" id="subMerchantType" onChange={this.onChange.bind(this)}>
                                                    <option value="all">All</option>
                                                    <option value="personal">Personal</option>
                                                    <option value="private">Private</option>
                                                    <option value="limited">Limited</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                {subMerchantType == 'all' && 
                                    <>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr className="row m-0">
                                                    <th className="col">Type</th>
                                                    <th className="col">Rate</th>
                                                    <th className="col">IBAN</th>
                                                    <th className="col">Email</th>
                                                    <th className="col">GSM</th>
                                                    <th className="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    isLoading && <tr>
                                                        <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                                    </tr>
                                                }

                                                { 
                                                    !isLoading && totalUsers == 0 && <tr>
                                                        <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                                    </tr>
                                                }
                                                {
                                                    !isLoading && totalUsers > 0 &&
                                                    washers.map((washer, key) => {
                                                        return(
                                                            <tr className="row m-0" key={key}>
                                                                <th className="col" style={{textTransform: 'capitalize'}}>
                                                                    {washer.submerchant.submerchantType}
                                                                </th>
                                                                <td className="col">{washer.commissionRate}</td>
                                                                <td className="col">{washer.submerchant.iban}</td>
                                                                <td className="col">{washer.submerchant.legalEmail}</td>
                                                                <td className="col">{washer.submerchant.gsmNumber}</td>
                                                                <td className="col">
                                                                    <i style={{marginRight: 15}} onClick={() => this.goToEdit(washer)} className="fa fa-edit btn p-1"></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0"
                                                    listClassName="justify-content-end mb-0"
                                                >
                                                    { 
                                                        pages >1 &&
                                                        _.times(pages, i => {
                                                        return (
                                                            <PaginationItem key={i} className={current_page === i+1 ?'active':''}>
                                                                <PaginationLink
                                                                    href="#"
                                                                    onClick={this.goToPage.bind(this,i+1)}
                                                                >
                                                                    {i+1}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        );
                                                        })
                                                    }
                                                </Pagination>
                                            </nav>
                                        </CardFooter>
                                    </>
                                }
                                {subMerchantType == 'personal' && 
                                    <>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr className="row m-0">
                                                    <th className="col">Type</th>
                                                    <th className="col">Rate</th>
                                                    <th className="col">Name</th>
                                                    <th className="col">Email</th>
                                                    <th className="col">Identity No.</th>
                                                    <th className="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    isLoading && <tr>
                                                        <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                                    </tr>
                                                }

                                                { 
                                                    !isLoading && totalUsers == 0 && <tr>
                                                        <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                                    </tr>
                                                }
                                                {
                                                    !isLoading && totalUsers > 0 &&
                                                    washers.map((washer, key) => {
                                                        return(
                                                            <tr className="row m-0" key={key}>
                                                                <th className="col" style={{textTransform: 'capitalize'}}>
                                                                    {washer.submerchant.submerchantType}
                                                                </th>
                                                                <td className="col">{washer.commissionRate}</td>
                                                                <td className="col">{washer.submerchant.contactName + ' ' +washer.submerchant.contactSurname}</td>
                                                                <td className="col">{washer.submerchant.legalEmail}</td>
                                                                <td className="col">{washer.submerchant.identityNumber}</td>
                                                                <td className="col">
                                                                    <i style={{marginRight: 15}} onClick={() => this.goToEdit(washer)} className="fa fa-edit btn p-1"></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0"
                                                    listClassName="justify-content-end mb-0"
                                                >
                                                    { 
                                                        pages >1 &&
                                                        _.times(pages, i => {
                                                        return (
                                                            <PaginationItem key={i} className={current_page === i+1 ?'active':''}>
                                                                <PaginationLink
                                                                    href="#"
                                                                    onClick={this.goToPage.bind(this,i+1)}
                                                                >
                                                                    {i+1}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        );
                                                        })
                                                    }
                                                </Pagination>
                                            </nav>
                                        </CardFooter>
                                    </>
                                }
                                {(subMerchantType == 'private' || subMerchantType == 'limited') && 
                                    <>
                                        <Table className="align-items-center table-flush" responsive>
                                            <thead className="thead-light">
                                                <tr className="row m-0">
                                                    <th className="col">Type</th>
                                                    <th className="col">Rate</th>
                                                    <th className="col">Company</th>
                                                    <th className="col">Email</th>
                                                    <th className="col">Tax No.</th>
                                                    <th className="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    isLoading && <tr>
                                                        <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                                    </tr>
                                                }

                                                { 
                                                    !isLoading && totalUsers == 0 && <tr>
                                                        <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                                    </tr>
                                                }
                                                {
                                                    !isLoading && totalUsers > 0 &&
                                                    washers.map((washer, key) => {
                                                        return(
                                                            <tr className="row m-0" key={key}>
                                                                <th className="col" style={{textTransform: 'capitalize'}}>
                                                                    {washer.submerchant.submerchantType}
                                                                </th>
                                                                <td className="col">{washer.commissionRate}</td>
                                                                <td className="col">{washer.submerchant.legalCompanyTitle}</td>
                                                                <td className="col">{washer.submerchant.legalEmail}</td>
                                                                <td className="col">{washer.submerchant.taxNumber}</td>
                                                                <td className="col">
                                                                    <i style={{marginRight: 15}} onClick={() => this.goToEdit(washer)} className="fa fa-edit btn p-1"></i>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                        <CardFooter className="py-4">
                                            <nav aria-label="...">
                                                <Pagination
                                                    className="pagination justify-content-end mb-0"
                                                    listClassName="justify-content-end mb-0"
                                                >
                                                    { 
                                                        pages >1 &&
                                                        _.times(pages, i => {
                                                        return (
                                                            <PaginationItem key={i} className={current_page === i+1 ?'active':''}>
                                                                <PaginationLink
                                                                    href="#"
                                                                    onClick={this.goToPage.bind(this,i+1)}
                                                                >
                                                                    {i+1}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        );
                                                        })
                                                    }
                                                </Pagination>
                                            </nav>
                                        </CardFooter>
                                    </>
                                }
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    washerData: (washer) => dispatch(washerData(washer))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubMerchant);
