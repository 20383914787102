import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const getCarMakes = (params) => {
    const api_url = 'car-makes';  
    return getRequestWithToken(api_url,params);
};

export const createCarMake = (params) => {

    const api_url = `car-makes`;

    return postRequest(api_url,params);
  
};