import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Label
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimeLinePlugin from "@fullcalendar/resource-timeline";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Loader from "react-loader-spinner";
import Header from "../../components/Headers/Header";
import BookingDetails from "./BookingDetails";
import "./main.scss";
import DatePicker from "react-datepicker";
import { filterBookings, getBookingDetail, getBookingsReport } from "../../actions/Bookings";
import { getZones } from "../../actions/Zones";
import { filterWashersForZone, filterWashers } from "../../actions/Washers";
import InputError from "../../components/Elements/InputError";
import moment from "moment";
import { SERVER_URL } from "../../Constant/Constant";

class Bookings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      zoneId: "",
      zones: [],
      washers: [],
      bookingDetailModal: false,
      bookingDetailId: null,
      selectStartDate: new Date(moment().subtract(1, "months")),
      selectEndDate: new Date()
    };

    this.filterWashers = this.filterWashers.bind(this);
    this.closeBookingDetailsModal = this.closeBookingDetailsModal.bind(this);
    this.openBookingDetailsModal = this.openBookingDetailsModal.bind(this);
  }

  componentWillMount() {
    // this.setState({isLoading:true});
    this.selectStartDateChange(new Date(moment().subtract(1, "months")));
    this.selectEndDateChange(new Date());
    getZones()
      .then(response => {
        if (response.data.success) {
          if (response.data.success.data) {
            let responseData = response.data.success.data;
            this.setState({
              zones: responseData
            });
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
    this.filterWashers();
  }

  filterWashers() {
    const { zoneId } = this.state;
    filterWashersForZone(zoneId ? { zoneId } : {})
      .then(response => {
        if (response.data.success.data) {
          const washers = response.data.success.data.map(washer => ({
            ...washer,
            title: washer.name,
            id: washer._id
          }));
          this.setState({ washers });
        } else {
          throw new Error(response.data);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  loadEvents = (info, successCallback, failureCallback) => {
    const { zoneId } = this.state;
    filterBookings({
      start: info.startStr,
      end: info.endStr,
      timeZone: info.timeZone,
      zoneId
    }).then((response) => {
      if (response.data.success) {
        if (response.data.success.data) {
          const bookingsArr = response.data.success.data;
          const res = bookingsArr.map(
            (
              {
                bookingId,
                washerId,
                zoneId: bookingZone,
                title,
                start,
                end,
                statusCode
              },
              key
            ) => ({
              id: bookingId,
              bookingId,
              resourceId: washerId,
              washerId,
              zoneId: bookingZone,
              statusCode,
              title,
              start,
              end,
              backgroundColor:
                statusCode === 100
                  ? "#bfbd25"
                  : statusCode === 101
                  ? "#C3FF00"
                  : statusCode === 102
                    ? "#d2a51c"
                    : statusCode === 103
                      ? "#45A163"
                      : statusCode === 200
                        ? "#6585FF"
                        : statusCode === 400
                          ? "#d06c6c"
                          : statusCode === 401
                            ? "#c33030"
                            : ""
            })
          );
          return successCallback(res);
        }
      }
      throw new Error(response.data);
    }).catch(failureCallback);
  }

  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      if (name === "zoneId") {
        this.filterWashers();
      }
    });
  };

  openBookingDetailsModal(booking) {
    const bookingDetailId = booking.extendedProps.bookingId;
    this.setState({
      bookingDetailId,
      bookingDetailModal: true
    });
  }

  closeBookingDetailsModal() {
    this.setState({
      bookingDetailModal: false,
      bookingDetailId: null
    });
  }

  renderBookingDetailsModal() {
    const { bookingDetailId, bookingDetailModal } = this.state;
    return (
      <Modal
        size="xl"
        isOpen={bookingDetailModal}
        toggle={this.closeBookingDetailsModal}
      >
        <ModalHeader toggle={this.closeBookingDetailsModal}>
          <span style={{ fontSize: 20 }}>Booking Details</span>
        </ModalHeader>
        <ModalBody>
          <BookingDetails
            bookingId={bookingDetailId}
            reloadWashers={this.filterWashers}
          />
        </ModalBody>
      </Modal>
    );
  }

  toggleReportModal = () => {
    const { reportUrl, reportModal } = this.state;
    this.setState({ reportModal: !reportModal });
    if (!reportUrl && !reportModal) {
      // getBookingsReport()
      //   .then(res => {
      //     if (res.data.success.data) {
      //       this.setState({
      //         reportUrl: res.data.success.data
      //       });
      //     }
      //   })
      //   .catch(console.error);
    }
  };

  selectStartDateChange = date => {
    if (!date) {
      return;
    }
    console.log("start Date : ", date);
    this.setState(
      {
        selectStartDate: date
      },
      () => {
        const selectedDate = this.state.selectStartDate;
        var month = moment(selectedDate).format("YYYY-MM-DD");
        var day = moment(selectedDate).format("DD");
        console.log("month ", month);
        var year = selectedDate.getFullYear();
        this.setState({
          expiryDateStart: moment(selectedDate).format("YYYY-MM-DD")
        });
      }
    );
  };
  selectEndDateChange = date => {
    if (!date) {
      return;
    }
    console.log("end Date: ", date);
    this.setState(
      {
        selectEndDate: date
      },
      () => {
        const selectedDate = this.state.selectEndDate;
        var month = moment(selectedDate).format("mm");
        var day = moment(selectedDate).format("DD");

        var year = selectedDate.getFullYear();
        this.setState({
          expiryDateEnd: moment(selectedDate).format("YYYY-MM-DD")
        });
        console.log(this.state.expiryDateEnd);
      }
    );
  };

  downloadReport = () => {
    const { expiryDateStart, expiryDateEnd } = this.state;
    this.setState({ reportLoading: true });
    if (!expiryDateStart && !expiryDateEnd) return;
    const date = `?start=${expiryDateStart}&&end=${expiryDateEnd}`;
    getBookingsReport({ date: date })
      .then(res => {
        if (res.data.success.data) {
          this.setState({
            reportUrl: res.data.success.data,
            reportLoading: false
          });
        }
        window.open(res.data.success.data, "_blank"); //to open new page
      })
      .catch(err => {
        this.setState({ reportLoading: false });
        console.log(err);
      });
  };

  renderReport = () => {
    const { reportModal, reportUrl, reportLoading } = this.state;
    return (
      <>
        <Button
          className="pull-right m-2 mr-0"
          color="warning"
          style={{ float: "right" }}
          size="sm"
          onClick={this.toggleReportModal}
        >
          <i className="fa fa-file-download" /> Download Report
        </Button>
        <Modal
          size={"sz"}
          isOpen={reportModal}
          toggle={this.toggleReportModal}
          className="text-center"
        >
          <ModalHeader toggle={this.toggleReportModal}>
            Download Report for Bookings
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                Start Date
                <DatePicker
                  selected={this.state.selectStartDate}
                  onChange={this.selectStartDateChange}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  popperPlacement="bottom"
                  style={{ textAlign: "center" }}
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false // turn off since needs preventOverflow to be enabled
                    }
                  }}
                />
              </Col>
              <Col>
                End Date
                <DatePicker
                  selected={this.state.selectEndDate}
                  onChange={this.selectEndDateChange}
                  dateFormat="yyyy-MM-dd"
                  className="form-control"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                      enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                      enabled: false // turn off since needs preventOverflow to be enabled
                    }
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="text-center">
                {!reportLoading && (
                  <Button
                    href={reportUrl}
                    color="primary"
                    onClick={this.downloadReport}
                  >
                    <i className="fa fa-file-download" /> Download Report
                  </Button>
                )}
                {reportLoading && (
                  <Loader
                    type="Circles"
                    color="#5e72e4"
                    height={30}
                    width={30}
                  />
                )}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  };

  render() {
    const { zones, isLoading, zoneId, washers } = this.state;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={3}>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="input-shadow"
                          type="select"
                          name="zoneId"
                          id="zoneId"
                          onChange={this.onChange.bind(this)}
                        >
                          <option value="">Select Region</option>
                          {zones.map((z, k) => {
                            return (
                              <option value={z._id} key={k}>
                                {z.name}
                              </option>
                            );
                          })}
                        </Input>
                      </InputGroup>
                    </Col>
                    <Col sm={9}>{this.renderReport()}</Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <FullCalendar
                    ref={this.calenderRef}
                    defaultView="resourceTimelineDay"
                    header={{
                      left: "prev,next today",
                      center: "title",
                      right: "resourceTimelineDay,resourceTimelineWeek"
                    }}
                    aspectRatio={1.5}
                    timeZone="Europe\/Istanbul"
                    resourceLabelText="Technicians"
                    plugins={[resourceTimeLinePlugin, interactionPlugin]}
                    eventTextColor="#fff"
                    resources={washers}
                    events={this.loadEvents}
                    eventClick={({ event }) => {
                      this.openBookingDetailsModal(event);
                    }}
                  />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        {this.renderBookingDetailsModal()}
      </>
    );
  }
}

export default Bookings;
