import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { updateSolution, updateSolutionLogo } from "../../actions/Solutions";
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';
import { solutionData } from '../../lib/Action/Solution';

class EditSolution extends React.Component {
  constructor(props){
    super(props);

    this.validator = new FormValidator(Rules.editAdmin);

    this.state = {
      isLoading: false,
      name : '',
      description : '',
      type : '',
      stock : '',
      expiryDate: '',
      selectDate: new Date(),
      defaultUsage: '',
      _id: '',
      err: false,
      success: false,
      logo: null,
      logoReady: false,
      validation: this.validator.valid()
    }

    this.selectDateChange = this.selectDateChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
  }


  componentWillMount(){
    if(this.props.SolutionReducer.solutionData){
      console.log(this.props.SolutionReducer.solutionData);
      this.setState({
        name: this.props.SolutionReducer.solutionData.name,
        defaultUsage: this.props.SolutionReducer.solutionData.defaultUsage,
        type: this.props.SolutionReducer.solutionData.type,
        stock: this.props.SolutionReducer.solutionData.stock,
        expiryDate: this.props.SolutionReducer.solutionData.expiryDate,
        description: this.props.SolutionReducer.solutionData.description,
        _id: this.props.SolutionReducer.solutionData._id,
        // selectDate: moment(this.props.SolutionReducer.solutionData.expiryDate),
      });
    }
  }

  onSubmit(e){
    e.preventDefault();
    const {name, description, type, stock, expiryDate, _id, defaultUsage} = this.state;

    this.setState({ isLoading:true });
    const payload = {
      name, description, type, stock, expiryDate, _id, defaultUsage
    };
    updateSolution(payload).then(response => {
      if(response.data.success){
        this.props.solutionData(payload);

        this.setState({err:false,success:true,message:'Solution have been successfully updated!'}); toast.success('Solution have been successfully updated!');
        setTimeout(()=>{
          this.setState({ err: false, success: false, isLoading: false });
        },3000)
      }else{
        this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
        setTimeout(() =>{
          this.setState({err:false,success:false,isLoading:false});
        },3000);
      }
    }).catch(error=> {
      this.setState({err:true,message:'Server Error!'});
      setTimeout(() =>{
        this.setState({err:false,success:false,isLoading:false});
      },3000);
    });
  }

  onChange(e){
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  selectDateChange(date) { if (!date) { return; }
    this.setState({
      selectDate: date
    },()=> {
      const selectedDate = this.state.selectDate;
      var month = (selectedDate .getMonth() + 1);
      var day = (selectedDate .getDate());
      var year = (selectedDate .getFullYear());
      this.setState({
        expiryDate: year+'-'+month+'-'+day
      })
    });
  }

  isNotFilled(){
    const {name,description, type, stock, expiryDate, defaultUsage} = this.state;
    return (
      name === this.props.SolutionReducer.solutionData.name &&
      description === this.props.SolutionReducer.solutionData.description &&
      expiryDate === this.props.SolutionReducer.solutionData.expiryDate &&
      type === this.props.SolutionReducer.solutionData.type &&
      stock === this.props.SolutionReducer.solutionData.stock &&
      defaultUsage === this.props.SolutionReducer.solutionData.defaultUsage
    )
  }

  handleImageChange(imageFile) {
    const { _id } = this.state;
    let prevLogo = this.state.logo;
    this.setState({
      logo: imageFile,
      logoReady: !!imageFile // cast to bool
    });
    let payload = new FormData();
    payload._id = _id;
    payload.append("picture", imageFile);
    updateSolutionLogo(payload).then(response => {
      if (response.data.success) {
        this.props.solutionData(response.data.success.data);

        this.setState({ err: false, success: true, message: 'Solution logo have been successfully updated!' });
        setTimeout(() => {
          this.setState({ err: false, success: false });
        }, 3000);
      } else {
        this.setState({ err: true, message: 'Failed to update solution logo!', logo: prevLogo, logoReady: !!prevLogo });
        setTimeout(() => {
          this.setState({ err: false, success: false });
        }, 3000);

      }
    }).catch(error => {
      console.error('logo upload error', error.message);
      this.setState({ err: true, message: 'Server Error!', logo: prevLogo, logoReady: !!prevLogo });
      setTimeout(() => {
        this.setState({ err: false, success: false });
      }, 3000);
    });
  }

  render() {
    let {name, description, type, stock, expiryDate, defaultUsage, _id, isLoading, selectDate, logo} = this.state;
    // console.log(selectDate);
    // console.log(moment(expiryDate).format('ddd MMM DD YYYY h:mm:ss ZZ'));
    return (
      <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
          <Row>
            <Col sm="12" md="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Solution</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link to="/dashboard/solutions">
                        <Button
                          color="primary"
                          size="sm"
                        >
                          Back to Solutions
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" method="POST">
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              Name*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-name"
                              placeholder="Enter a name"
                              type="text"
                              name="name"
                              defaultValue={name}
                              onChange={this.onChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-stock"
                            >
                              Stock*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-stock"
                              placeholder="Enter a Stock"
                              type="text"
                              name="stock"
                              defaultValue={stock}
                              onChange={this.onChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-type"
                            >
                              Type*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-type"
                              placeholder="Enter a Type"
                              type="text"
                              name="type"
                              defaultValue={type}
                              onChange={this.onChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-description"
                            >
                              Description*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-description"
                              placeholder="Enter a Description"
                              type="textarea"
                              name="description"
                              value={description}
                              onChange={this.onChange.bind(this)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                            <label
                              className="form-control-label"
                              htmlFor="input-selectDate"
                            > Expiry Date </label>
                            <InputGroup className="datepicker-input-group input-shadow">
                              <DatePicker
                                selected={new Date(expiryDate)}
                                onChange={this.selectDateChange}
                                dateFormat="yyyy-MM-dd"
                                className="datepicker-form-control border-none"
                                popperPlacement="bottom"
                                minDate={new Date()}
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"] // don't allow it to flip to be above
                                  },
                                  preventOverflow: {
                                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                  },
                                  hide: {
                                    enabled: false // turn off since needs preventOverflow to be enabled
                                  }
                                }}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText className="border-none"><i className="fa fa-calendar"></i></InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-defaultUsage"
                            >
                              Default Usage*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-defaultUsage"
                              placeholder="Enter a Default Usage"
                              type="text"
                              name="defaultUsage"
                              onChange={this.onChange.bind(this)}
                              defaultValue={defaultUsage}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="logo"
                            >
                              Logo*
                            </label>
                            <UploadableImage
                              uploadable={true}
                              imageSource={logo}
                              onChange={this.handleImageChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="12">
                          <div className="text-right">
                            { !isLoading &&
                            <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button" disabled={this.isNotFilled()}>
                              Update
                            </Button>
                            }
                            {
                              isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                            }
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </>
    );
  }
}

const mapStateToProps = (state) =>({
  ...state
})

const mapDispatchToProps = (dispatch) =>({
  solutionData: (solution) => dispatch(solutionData(solution))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSolution);
