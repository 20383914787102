import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import CountryCodes from "../../lib/CountryCode";
import InputError from "../../components/Elements/InputError";
import { adminUser } from "../../lib/Action/Admin";
import { updateAdmin } from "../../actions/Admins";

class AddAdmin extends React.Component {
    constructor(props){
        super(props);

        this.validator = new FormValidator(Rules.editAdmin);

        this.state = {
            email : '',
            password: '',
            fname:'',
            lname:'',
            phone:'',
            countryPhoneCode:'+33',
            err:false,
            success:false,
            showPasword:false,
            showConfirmPassword:false,
            validation: this.validator.valid(),
            isLoading:false,
            confirm_password:'',
            cityName: '',
            countryName: '',
            _id: '',
            adminData: {},
            message: ''
        }
    }

    onSubmit(e){
        e.preventDefault();
        const {email,fname,lname, adminData} = this.state;
        const validation = this.validator.validate(this.state);
        this.setState({ validation });
        this.submitted = true;

        if (validation.isValid) {
            this.setState({ isLoading:true });
            updateAdmin({
                _id:this.state._id,
                fname:this.state.fname,
                lname:this.state.lname,
            }).then(response => {
                if(response.data.success){
                    adminData['fname'] = fname;
                    adminData['lname'] = lname;

                    this.props.adminUser(adminData);

                    this.setState({err:false,success:true,message:'Admin details have been successfully updated!'}); toast.success('Admin details have been successfully updated!');
                    setTimeout(()=>{
                        this.setState({err:false,success:false,isLoading:false});
                        // this.props.history.push("/dashboard/admin-edit");
                    },3000)
                }else{
                    this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
                    setTimeout(() =>{
                        this.setState({err:false,success:false,isLoading:false});
                    },3000);
                }
            }).catch(error=> {
                this.setState({err:true,message:'Server Error!'});
                setTimeout(() =>{
                    this.setState({err:false,success:false,isLoading:false});
                },3000);

            });
        }
    }

    onChange(e){
        const {name, value} = e.target;
        this.setState({[name]: value});
    } 


    isNotFilled(){
        const {email,fname,lname} = this.state;
        return (fname === this.props.other.adminData.fname && lname === this.props.other.adminData.lname)
    }

    componentWillMount(){
        if(this.props.other.adminData){
            this.setState({
                adminData: this.props.other.adminData,
                email: this.props.other.adminData.email,
                fname: this.props.other.adminData.fname,
                lname: this.props.other.adminData.lname,
                _id: this.props.other.adminData._id,
            });
        }
    }

    render() {
        let {email,fname,lname,err,success, message, showPasword, isLoading,showConfirmPassword,password,confirm_password} = this.state;
        let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
        
        return (
            <>
            <Header />
            {/* Table */}
            <Container className="mt--7" fluid>
                <Row>
                    <Col sm="12" md="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Edit Admin</h3>
                                </Col>
                                <Col className="text-right" xs="4">
                                    <Link to="/dashboard/admin">
                                        <Button
                                            color="primary"
                                            size="sm"
                                        >
                                        Back to Admins
                                        </Button>
                                    </Link>
                                </Col>
                                </Row>
                            </CardHeader>
                            {err &&
                                <Alert color="danger">
                                    {message}
                                </Alert>
                            }
                            {success &&
                                <Alert color="success">
                                    {message}
                                </Alert>
                            }
                            <CardBody>
                                <Form role="form" method="POST">
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-fname"
                                                    >
                                                        First name*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-fname"
                                                        placeholder="Enter a First name"
                                                        type="text"
                                                        name="fname"
                                                        defaultValue={fname}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.fname.isInvalid} message={validation.fname.message} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-lname"
                                                    >
                                                        Last name*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-lname"
                                                        placeholder="Enter a Last name"
                                                        type="text"
                                                        name="lname"
                                                        defaultValue={lname}
                                                        onChange={this.onChange.bind(this)} 
                                                    />
                                                    <InputError show={validation.lname.isInvalid} message={validation.lname.message} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6" sm="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Email address*
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-email"
                                                        placeholder="Enter a Email address"
                                                        type="email"
                                                        name="email"
                                                        defaultValue={email}
                                                        onChange={this.onChange.bind(this)} 
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="12">
                                                <div className="text-right">
                                                    { !isLoading &&  
                                                        <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button" disabled={this.isNotFilled()}>
                                                            Update
                                                        </Button>
                                                    }
                                                    {
                                                        isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                                                    }
                                                </div>
                                            </Col>
                                        </Row> 
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                
            </>
        );
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    adminUser: (adminData) => dispatch(adminUser(adminData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddAdmin);
