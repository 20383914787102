import { getRequestWithToken, putRequest, postRequest } from "../Constant/Api";

export const filterBookings = filter => {
  let query = "";

  Object.entries(filter).forEach(
    ([key, value]) => (query += `${key}=${value}&`)
  );

  const api_url = `bookings?${query}`;
  return getRequestWithToken(api_url, filter);
};

export const getBookingDetail = params => {
  const api_url = `bookings/${params.bookingId}`;
  return getRequestWithToken(api_url);
};

export const getBookingsReport = params => {
  let api_url = "";
  if (params.date) api_url = `bookings/reports/export/${params.date}`;
  else api_url = "bookings/reports/export/";
  console.log("report bookings, : ", api_url);
  return getRequestWithToken(api_url, params);
};

export const reBook = params => {
  const api_url = `bookings/${params.bookingId}/rebook`;

  return putRequest(api_url, params);
};

export const updateBookingStatus = params => {
  const api_url = `bookings/${params.status}/${params.bookingId}`;

  return putRequest(api_url, params);
};
