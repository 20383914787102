import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";

import { updateCampaign, updateCampaignImage } from '../../actions/Campaigns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { campaignData } from '../../lib/Action/Campaign';
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';
import Switch from 'react-ios-switch';

// import { updateAdmin } from "../../actions/Admins";

class EditCampaign extends React.Component {
  constructor(props){
    super(props);

    this.validator = new FormValidator(Rules.addCampaign);

    this.state = {
      isLoading: false,
      title : '',
      description : '',
      showMode : '',
      userType : '',
      selectDate: new Date(),
      expiryDate: '',
      validation: this.validator.valid(),
      image: null,
      imageReady: false,
      isSystemMsg:false,
      isUpdateNeeded:false,
      _id: ''
    };
  }


  componentWillMount(){
    if(this.props.CampaignReducer.campaignData){
      console.log(this.props.CampaignReducer.campaignData);
      this.setState({
        title: this.props.CampaignReducer.campaignData.title,
        description: this.props.CampaignReducer.campaignData.description,
        showMode: this.props.CampaignReducer.campaignData.showMode,
        userType: this.props.CampaignReducer.campaignData.userType,
        _id: this.props.CampaignReducer.campaignData._id,
        expiryDate: this.props.CampaignReducer.campaignData.expiryDate,
        image: this.props.CampaignReducer.campaignData.imageUrl,
        imageReady: !!this.props.CampaignReducer.campaignData.imageUrl,
        isUpdateNeeded:this.props.CampaignReducer.campaignData.isUpdateNeeded,
        isSystemMsg:this.props.CampaignReducer.campaignData.isSystemMsg,
        selectDate:new Date(this.props.CampaignReducer.campaignData.expiryDate)
      });
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {_id, title, description, showMode, userType, expiryDate, isSystemMsg,isUpdateNeeded} = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.setState({isLoading:true});
      updateCampaign({
        _id, title, description, showMode, userType, expiryDate, isSystemMsg,isUpdateNeeded
      }).then(response => {
        if(response.data.success){
          toast.success('Campaign details have been successfully updated!');
          this.setState({err:false,success:true,message:'Campaign details have been successfully updated!'}); toast.success('Campaign details have been successfully updated!');
          setTimeout(()=>{
            this.props.history.push("/dashboard/campaigns");
          },3000)
        }else{
          this.setState({err:true,message:'Unable to edit!',isLoading:false}); toast.error('Unable to edit!');
          setTimeout(() =>{
            this.setState({err:false,success:false,isLoading:false});
          },3000);
        }
      }).catch(error=> {
        this.setState({err:true,message:'Server Error!'});
        setTimeout(() =>{
          this.setState({err:false,success:false,isLoading:false});
        },3000);

      });
    }
  }

  onChange = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value || ''});
  }

  selectDateChange = (date) => { if (!date) { return; }
    this.setState({
      selectDate: date
    },()=> {
      const selectedDate = this.state.selectDate;
      var month = (selectedDate .getMonth() + 1);
      var day = (selectedDate .getDate());
      var year = (selectedDate .getFullYear());
      this.setState({
        expiryDate: year+'-'+month+'-'+day
      })
    });
  }

  isNotFilled = () => {
    const {title, description, showMode, userType, expiryDate,isSystemMsg,isUpdateNeeded} = this.state;
    return (
      title === this.props.CampaignReducer.campaignData.title &&
      description === this.props.CampaignReducer.campaignData.description &&
      showMode === this.props.CampaignReducer.campaignData.showMode &&
      userType === this.props.CampaignReducer.campaignData.userType &&
      expiryDate === this.props.CampaignReducer.campaignData.expiryDate &&
      isSystemMsg === this.props.CampaignReducer.campaignData.isSystemMsg &&
      isUpdateNeeded === this.props.CampaignReducer.campaignData.isUpdateNeeded
    );
  };

  handleImageChange = (imageFile) => {
    const { _id } = this.state;
    let prevImage = this.state.image;
    this.setState({
      image: imageFile,
      imageReady: !!imageFile // cast to bool
    });
    let payload = new FormData();
    payload._id = _id;
    payload.append("picture", imageFile);
    updateCampaignImage(payload).then(response => {
      if (response.data.success) {
        this.props.campaignData(response.data.success.data);
        toast.success('Campaign image have been successfully updated!');
        this.setState({ err: false, success: true, message: 'Campaign image have been successfully updated!' });
        setTimeout(() => {
          this.setState({ err: false, success: false });
        }, 3000);
      } else {
        this.setState({ err: true, message: 'Failed to update campaign image!', image: prevImage, imageReady: !!prevImage });
        setTimeout(() => {
          this.setState({ err: false, success: false });
        }, 3000);

      }
    }).catch(error => {
      console.error('image upload error', error.message);
      this.setState({ err: true, message: 'Server Error!', image: prevImage, imageReady: !!prevImage });
      setTimeout(() => {
        this.setState({ err: false, success: false });
      }, 3000);
    });
  }

  render() {
    let {isLoading, title, description, showMode, userType,
      image, isSystemMsg,isUpdateNeeded } = this.state;
    let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
    return (
      <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
          <Row>
            <Col sm="12" md="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Edit Campaign</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link to="/dashboard/campaigns">
                        <Button
                          color="primary"
                          size="sm"
                        >
                          Back to Campaigns
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" method="POST">
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-title"
                            >
                              Title*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-title"
                              placeholder="Enter a title"
                              type="text"
                              name="title"
                              defaultValue={title}
                              onChange={this.onChange}
                            />
                            <InputError show={validation.title.isInvalid} message={validation.title.message} />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-description"
                            >
                              Description*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-description"
                              placeholder="Enter a Description"
                              type="textarea"
                              name="description"
                              value={description}
                              onChange={this.onChange}
                            />
                            <InputError show={validation.description.isInvalid} message={validation.description.message} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="showMode"
                            >
                              Show Mode*
                            </label>
                            <Input type="select" name="showMode" id="showMode" onChange={this.onChange} defaultValue={showMode}>
                              <option value="">Select show mode</option>
                              <option value="android">Android</option>
                              <option value="ios">iOS</option>
                            </Input>
                            <InputError show={validation.showMode.isInvalid} message={validation.showMode.message}/>
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="userType"
                            >
                              User Type*
                            </label>
                            <Input type="select" name="userType" id="userType" onChange={this.onChange} defaultValue={userType}>
                              <option value="">Select user type</option>
                              <option value="customer">Customer</option>
                              <option value="washer">Washer</option>
                            </Input>
                            <InputError show={validation.userType.isInvalid} message={validation.userType.message} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                            <label
                              className="form-control-label"
                              htmlFor="input-selectDate"
                            > Expiry Date </label>
                            <DatePicker
                              selected={this.state.selectDate}
                              onChange={this.selectDateChange}
                              dateFormat="yyyy-MM-dd"
                              className="form-control"
                              popperPlacement="bottom"
                              minDate={new Date()}
                              popperModifiers={{
                                flip: {
                                  behavior: ["bottom"] // don't allow it to flip to be above
                                },
                                preventOverflow: {
                                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                },
                                hide: {
                                  enabled: false // turn off since needs preventOverflow to be enabled
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <div className="px-1 my-1 text-center">
                            <label className="form-control-label" htmlFor="input-isSystemMsg">Is System Message ?</label>
                            <br />
                            <Switch
                              checked={isSystemMsg}
                              className="switch center"
                              id="isSystemMsg"
                              name="isSystemMsg"

                              onChange={(e) => { this.setState({ isSystemMsg: e }) }}
                              // onChange={() => this.changeStatus(key, customer._id, customer.accountStatus)}
                            />
                          </div>
                        </Col>
                        <Col sm="3">
                          <div className="px-1 my-1 text-center">
                            <label className="form-control-label" htmlFor="input-isUpdateNeeded">Is Update Needed ?</label>
                            <br />
                            <Switch
                              checked={isUpdateNeeded}
                              className="switch center"
                              id="isUpdateNeeded"
                              name="isUpdateNeeded"
                              onChange={(e) => { this.setState({ isUpdateNeeded: e }) }}
                              // onChange={() => this.changeStatus(key, customer._id, customer.accountStatus)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="image"
                            >
                              Image*
                            </label>
                            <UploadableImage
                              uploadable={true}
                              imageSource={image}
                              onChange={this.handleImageChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <div className="text-right">
                            { !isLoading &&
                            <Button className="mt-4" onClick={this.onSubmit} color="primary" type="button" disabled={this.isNotFilled()}>
                              Update
                            </Button>
                            }
                            {
                              isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                            }
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </>
    );
  }
}

const mapStateToProps = (state) =>({
  ...state
})

const mapDispatchToProps = (dispatch) =>({
  campaignData: (campaign) => dispatch(campaignData(campaign))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaign);
