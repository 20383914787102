import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Container
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FormValidator from "../../lib/Rules/FormValidator";
import Rules from "../../lib/Rules/FormRules";
import InputError from "../../components/Elements/InputError";

import { createSolution, updateSolutionLogo } from "../../actions/Solutions";
import UploadableImage from '../../components/Elements/UploadableImage/UploadableImage';

class AddSolution extends React.Component {
  constructor(props){
    super(props);

    this.validator = new FormValidator(Rules.addSolution);

    this.state = {
      isLoading: false,
      name : '',
      description : '',
      type : '',
      stock : '',
      expiryDate: '',
      selectDate: new Date(),
      defaultUsage: '',
      err:false,
      success:false,
      logo: null,
      logoReady: false,
      validation: this.validator.valid()
    }
    this.selectDateChange = this.selectDateChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.uploadLogoPhoto = this.uploadLogoPhoto.bind(this);
  }

  onSubmit(e){
    e.preventDefault();
    const {name, type, stock, expiryDate, defaultUsage, description} = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.setState({ isLoading:true });
      createSolution(
        {name, type, stock, expiryDate, defaultUsage, description}
      ).then(response => {
        console.log(response);
        if(response.data.success.data){
          this.uploadLogoPhoto(response.data.success.data);
        }else{
          this.setState({err:true,message:'Unable to add!',isLoading:false}); toast.error('Unable to add!');
          setTimeout(() =>{
            this.setState({err:false,success:false,isLoading:false});
          },3000);
        }
      }).catch(error=> {
        this.setState({err:true,message:'Server Error!'});
        setTimeout(() =>{
          this.setState({err:false,success:false,isLoading:false});
        },3000);
      });
    }
  }

  uploadLogoPhoto({ _id }) {
    const { logo } = this.state;
    let payload = new FormData();
    payload._id = _id;
    payload.append('picture', logo);
    updateSolutionLogo(payload ).then(response => {
      if (response.data.success.data) {
        this.setState({err:false,success:true,message:'Solution have been successfully added!'}); toast.success('Solution have been successfully added!');
        setTimeout(()=>{
          this.setState({isLoading:false});
          this.props.history.push("/dashboard/solutions");
        },3000)
      } else{
        this.setState({err:true,message:'Successfully added but failed to upload logo! Please edit its logo!'}); toast.warn('Successfully added but failed to upload logo! Please edit its logo!');
        setTimeout(() =>{
          this.setState({err:false,isLoading:false});
          this.props.history.push("/dashboard/solutions");
        },3000);
      }
    }).catch(error => {
      this.setState({ err:true, message: 'Successfully added but server error when uploading logo! Please edit its logo!' });
      setTimeout(() =>{
        this.setState({err:false,isLoading:false});
        this.props.history.push("/dashboard/solutions");
      },3000);
    });
  }

  handleImageChange(imageFile) {
    this.setState({
      logo: imageFile,
      logoReady: !!imageFile // cast to bool
    });
  }

  onChange(e){
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  selectDateChange(date) { if (!date) { return; }
    this.setState({
      selectDate: date
    },()=> {
      const selectedDate = this.state.selectDate;
      var month = (selectedDate .getMonth() + 1);
      var day = (selectedDate .getDate());
      var year = (selectedDate .getFullYear());
      this.setState({
        expiryDate: year+'-'+month+'-'+day
      })
    });
  }

  isNotFilled(){
    const {name,description, stock, type, expiryDate, defaultUsage} = this.state;
    return (
      name === '' ||
      description === '' ||
      stock === '' ||
      type === '' ||
      expiryDate === '' ||
      defaultUsage === ''
    )
  }

  render() {
    let {err,success, message, name, description, stock, type, expiryDate, defaultUsage, isLoading} = this.state;
    let validation = this.submitted ? this.validator.validate(this.state):this.state.validation;
    return (
      <>
        <Header />
        {/* Table */}
        <Container className="mt--7" fluid>
          <Row>
            <Col sm="12" md="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Add Solution</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Link to="/dashboard/solutions">
                        <Button
                          color="primary"
                          size="sm"
                        >
                          Back to Solutions
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" method="POST">
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              Name*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-fname"
                              placeholder="Enter a name"
                              type="text"
                              name="name"
                              onChange={this.onChange.bind(this)}
                            />
                            <InputError show={validation.name.isInvalid} message={validation.name.message} />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-stock"
                            >
                              Stock*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-stock"
                              placeholder="Enter a Stock"
                              type="text"
                              name="stock"
                              onChange={this.onChange.bind(this)}
                            />
                            <InputError show={validation.stock.isInvalid} message={validation.stock.message} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-type"
                            >
                              Type*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-type"
                              placeholder="Enter a Type"
                              type="text"
                              name="type"
                              onChange={this.onChange.bind(this)}
                            />
                            <InputError show={validation.type.isInvalid} message={validation.type.message} />
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-description"
                            >
                              Description*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-description"
                              placeholder="Enter a Description"
                              type="textarea"
                              name="description"
                              onChange={this.onChange.bind(this)}
                            />
                            <InputError show={validation.description.isInvalid} message={validation.description.message} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" sm="12">
                          <FormGroup  style={{display: 'grid'}} className="datePickerDiv">
                            <label
                              className="form-control-label"
                              htmlFor="input-selectDate"
                            > Expiry Date </label>
                            <InputGroup className="datepicker-input-group input-shadow">
                              <DatePicker
                                selected={this.state.selectDate}
                                onChange={this.selectDateChange}
                                dateFormat="yyyy-MM-dd"
                                className="datepicker-form-control border-none"
                                popperPlacement="bottom"
                                minDate={new Date()}
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"] // don't allow it to flip to be above
                                  },
                                  preventOverflow: {
                                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                  },
                                  hide: {
                                    enabled: false // turn off since needs preventOverflow to be enabled
                                  }
                                }}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText className="border-none"><i className="fa fa-calendar"></i></InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="6" sm="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-defaultUsage"
                            >
                              Default Usage*
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-defaultUsage"
                              placeholder="Enter a Default Usage"
                              type="text"
                              name="defaultUsage"
                              onChange={this.onChange.bind(this)}
                            />
                            <InputError show={validation.defaultUsage.isInvalid} message={validation.defaultUsage.message} />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <FormGroup>
                            <label className="form-control-label" htmlFor="logo">
                              Logo*
                            </label>
                            <UploadableImage
                              uploadable={ true }
                              onChange={this.handleImageChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <div className="text-right">
                            { !isLoading &&
                            <Button className="mt-4" onClick={this.onSubmit.bind(this)} color="primary" type="button">
                              Submit
                            </Button>
                            }
                            {
                              isLoading && <Loader type="Circles" color="#5e72e4" height={30} width={30} />
                            }
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
const mapStateToProps = (state) =>({
  ...state
})

const mapDispatchToProps = (dispatch) =>({

})

export default connect(mapStateToProps, mapDispatchToProps)(AddSolution);
