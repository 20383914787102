import React from "react";
import { toast } from "react-toastify";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polygon
} from "react-google-maps";
import { compose, withProps } from "recompose";
import { getLocations } from "../../actions/Washers";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const MapWithAMarker = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyANl6n1ocSKfkecsPu4BqWvIv_BLb4HmmQ&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const icon = {
    url: require("../../assets/img/icons/icon.png"),
    size: { width: 60, height: 80 },
    anchor: { x: 15, y: 50 },
    scaledSize: { width: 30, height: 50 }
  };
  console.log(props.markers);
  return (
    <GoogleMap
      defaultZoom={11}
      defaultCenter={{
        lng: 28.94966,
        lat: 41.01384
      }}
    >
      {props.markers.map((marker, index) => (
        <Marker
          icon={icon}
          key={index}
          position={{ lat: marker.location.latitude, lng: marker.location.longitude }}
          onClick={() => props.openMarkerInfo(marker)}
        >
          {marker.isOpen && (
            <InfoWindow onCloseClick={() => props.openMarkerInfo(marker)}>
              <div>
                <h5>
                  {marker.fname} {marker.lname}
                </h5>
                <h5>{marker.loginPhoneNo}</h5>
                <span style={{ color: "green" }}>
                  {marker.lastBooking === null && "Müsait"}
                </span>
                <span style={{ color: "blue" }}>
                  {marker.lastBooking &&
                    marker.lastBooking.statusCode === 102 &&
                    "Yolda"}
                </span>
                <span style={{ color: "orange" }}>
                  {marker.lastBooking &&
                    marker.lastBooking.statusCode === 103 &&
                    "Parlatıyor"}
                </span>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: []
    };
  }

  componentWillMount() {
    getLocations().then(res => {
      console.log(res);
      if (res && res.data && res.data.success && res.data.success.data) {
        this.setState({ markers: res.data.success.data });
        console.log(this.state.markers);
      }
    });
    // setInterval(() => {
    //   getLocations().then(res => {
    //     console.log(res);
    //     if (res && res.data && res.data.success && res.data.success.data) {
    //       this.setState({ markers: res.data.success.data });
    //       console.log(this.state.markers);
    //     }
    //   });
    // }, 30000);
  }

  openMarkerInfo = marker => {
    let markers = [...this.state.markers];
    markers.find(x => x._id === marker._id).isOpen = !marker.isOpen;

    this.setState({ markers: markers });
  };
  render() {
    const { markers } = this.state;
    console.log(markers);
    return (
      <>
        <div className="pb-3 pl-3 pr-3  bg-gradient-info">
          <MapWithAMarker
            isMarkerShown
            markers={markers}
            openMarkerInfo={marker => this.openMarkerInfo(marker)}
          />
        </div>
      </>
    );
  }
}

export default Header;
