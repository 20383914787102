import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

// reactstrap components
import {
  Container, Row, Col, Card, CardHeader, CardTitle, ButtonGroup, Button, CardBody
} from 'reactstrap';

import DashboardHeader from "../../components/Headers/DashboardHeader";
import WashersMap from "../../components/Maps/WashersMap";
import ZoneHeatMap from '../../components/Maps/ZoneHeatMap';

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.toggleTab = this.toggleTab.bind(this);
    this.state = {
      activeTab: 1
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    return (
      <>
        <DashboardHeader />
        <Row className="bg-gradient-info m-0">
          <Col lg="12">
            <ul className="stepper stepper-horizontal">
              <li className={activeTab === 1? 'completed':''}>
                <a onClick={() => this.toggleTab(1)}>
                  <span className="circle">
                      <i className="fa fa-shower pl-1" aria-hidden="true"></i>
                  </span>
                  <span className="label text-white">Online Technicians</span>
                </a>
              </li>
              <div className="line"></div>
              <li className={activeTab === 2? 'completed':''}>
                <a onClick={() => this.toggleTab(2)}>
                  <span className="circle">
                      <i className="fa fa-sad-tear pt-1" aria-hidden="true"></i>
                  </span>
                  <span className="label text-white">Backlog</span>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        { activeTab === 1 && <WashersMap /> }
        { activeTab === 2 && <ZoneHeatMap /> }
      </>
    );
  }
}

export default Index;

// const mapStateToProps = (state) =>({
//   ...state
// })

// const mapDispatchToProps = (dispatch) =>({

// })

// export default connect(mapStateToProps, mapDispatchToProps)(Index);
