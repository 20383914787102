import React from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, CardTitle } from "reactstrap";

import HeatMap from './HeatMap/HeatMap';
import { getNonOperablePositionList, getNonOperablePositionReport } from '../../actions/Zones';
import Loader from 'react-loader-spinner';

class ZoneHeatMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: []
    };
  }

  componentWillMount() {
    getNonOperablePositionList().then(res => {
      if (res && res.data && res.data.success && res.data.success.data) {
        this.setState({
          positions: res.data.success.data
            .map(({ location: [lng,lat]}) => ({ lat, lng }))
        })
      } else {
        console.log('missing data in nonoperable get', res);
      }
    }).catch(console.error);
  }

  toggleReportModal = () => {
    const { reportUrl, reportModal } = this.state;
    this.setState({ reportModal: !reportModal });
    if (!reportUrl && !reportModal) {
      getNonOperablePositionReport().then(res => {
        if (res.data.success.data) {
          this.setState({
            reportUrl: res.data.success.data
          });
        }
      }).catch(console.error);
    }
  };

  renderReport = () => {
    const { reportModal, reportUrl } = this.state;
    return (
      <>
        <div className="text-center bg-white">
          <a href={'#'} onClick={this.toggleReportModal}>
            <i className="fa fa-file-download" /> Download Report
          </a>
        </div>
        <Modal size={'sz'} isOpen={reportModal} toggle={this.toggleReportModal} className='text-center'>
          <ModalHeader toggle={this.toggleReportModal}>
            Download Report for NonOperable Zones
          </ModalHeader>
          <ModalBody>
            { reportUrl && <a href={reportUrl}>
              <i className="fa fa-file-download" /> Download Report
            </a> }
            { !reportUrl && <Loader type="Circles" color="#5e72e4" height={30} width={30} /> }
          </ModalBody>
        </Modal>
      </>
    )
  };


  render() {
    const { positions } = this.state;
    return (
      <div className="pb-3 pl-3 pr-3 bg-gradient-info">
        { this.renderReport() }
        <HeatMap
          positions={positions}
          options={{
            radius: 12,
            opacity: 0.8
          }}
        />
      </div>
    )
  }

}

export default ZoneHeatMap;
