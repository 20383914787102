import React from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import FilePreview from './FilePreview';

/**
 * uploadable: bool (default: false)
 * placeholderImage: string (url/src to default image)
 * placeholderImageElement: react element (default null)
 * imageSource: string (url/src to current image)
 * onChange: function(imageFile):void
 * title: string (default: 'image')
 * shape: 'circle' | 'rounded' | 'square' (default: rounded),
 * accept: string (default: 'image/*') use '*' to accept all files
 */

class UploadableImage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imagePreview: null,
      fileType: null,
      fileName: null
    };

    this.handleImageChange = this.handleImageChange.bind(this);
    this.getShapeBorderRadius = this.getShapeBorderRadius.bind(this);
    this.getImageHolderStyle = this.getImageHolderStyle.bind(this);
    this.getImagePreviewStyle = this.getImagePreviewStyle.bind(this);
    this.renderImageInput = this.renderImageInput.bind(this);
    this.renderImage = this.renderImage.bind(this);
  }

  handleImageChange(e) {
    e.preventDefault();
    const { onChange } = this.props;
    const imageFile = e.target.files[0];
    if (!imageFile) {
      return;
    }
    const { name, type } = imageFile;
    if (type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState(
          {
            imagePreview: reader.result,
            fileType: type,
            fileName: name
          },
          () => onChange && onChange(imageFile)
        );
      };
      reader.readAsDataURL(imageFile);
    } else {
      this.setState({
        imagePreview: null,
        fileType: type,
        fileName: name
      }, () => onChange && onChange(imageFile));
    }
  }

  getShapeBorderRadius() {
    const { shape } = this.props;
    switch (shape) {
      case "circle":
        return "50%";
      case "rounded":
        return 8;
      case "square":
        return 0;
    }
  }

  getImageHolderStyle() {
    const { uploadable } = this.props;
    let { size } = this.props;
    if (uploadable) {
      size += 2;
    }
    return {
      ...(uploadable ? styles.imageHolderDashed : styles.imageHolder),
      borderRadius: this.getShapeBorderRadius(),
      width: size,
      maxWidth: size,
      height: size,
      maxHeight: size
    };
  }

  getImagePreviewStyle() {
    return {
      ...styles.imagePreview,
      borderRadius: this.getShapeBorderRadius()
    };
  }

  renderImageInput() {
    const { uploadable, accept } = this.props;
    if (!uploadable) {
      return null;
    }
    return (
      <input
        type={"file"}
        onChange={this.handleImageChange}
        style={styles.imageInput}
        accept={accept || "image/*"}
      />
    );
  }

  renderImage() {
    const { imagePreview, fileName, fileType } = this.state;
    const {
      uploadable,
      imageSource,
      placeholderImage,
      placeholderImageElement,
      title
    } = this.props;
    if (!imagePreview && fileName) {
      return (
        <div style={styles.imagePreview}>
          <FilePreview
            fileName={fileName}
            fileType={fileType}
          />
        </div>
      );
    }
    if (imagePreview || imageSource || placeholderImage) {
      return (
        <img
          src={imagePreview || imageSource || placeholderImage}
          style={styles.imagePreview}
          alt={title}
          title={title}
        />
      );
    }
    if (uploadable && placeholderImageElement) {
      return <div style={styles.imagePreview}>{placeholderImageElement}</div>;
    }
  }

  render() {
    const imageHolderStyle = this.getImageHolderStyle();
    return (
      <div style={imageHolderStyle}>
        {this.renderImageInput()}
        {this.renderImage()}
      </div>
    );
  }
}

UploadableImage.propTypes = {
  size: PropTypes.number,
  shape: PropTypes.oneOf(["circle", "rounded", "square"]),
  uploadable: PropTypes.bool,
  placeholderImage: PropTypes.string,
  placeholderImageElement: PropTypes.node,
  imageSource: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  title: PropTypes.string
};

UploadableImage.defaultProps = {
  size: 120,
  shape: "rounded",
  uploadable: false,
  title: "image",
  placeholderImageElement: <i className="fa fa-upload" />
};

const styles = {
  imageHolder: {
    margin: "4px 4px 8px",
    position: "relative",
    overflow: "hidden"
  },
  imageHolderDashed: {
    marginTop: -2,
    position: "relative",
    overflow: "hidden",
    border: "1px dashed #444"
  },
  imageInput: {
    position: "absolute",
    top: -20,
    left: -20,
    right: -20,
    bottom: -20,
    width: "calc(100% + 40px)",
    height: "calc(100% + 40px)",
    zIndex: 1,
    fontSize: 0
  },
  imagePreview: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "calc(100% - 8px)",
    height: "calc(100% - 8px)",
    margin: 4,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center"
  }
};

export default UploadableImage;
