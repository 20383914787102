
import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    messagingSenderId: "1003190681428"
});

const messaging = initializedFirebaseApp.messaging();

messaging.usePublicVapidKey(
    "BOuNypMAXBUA1CU1W0aq3ZCt2Ncuwh9gs9cYYAo61ry3MvPsP6RfRLJi6_iIL6_R0rP7laLzSA0tVaCkQKSEuIM"
);

export { messaging };