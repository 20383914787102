import React from "react";
import { toast } from "react-toastify";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import _ from 'lodash';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,  
    Label,
    Pagination,
    PaginationItem,
    PaginationLink,
    CardFooter
} from "reactstrap";
import Switch from 'react-ios-switch';
import Header from "../../components/Headers/Header";
import { filterAdmins, updateAdmin } from "../../actions/Admins";
import CountryCodes from "../../lib/CountryCode";
import { adminUser } from "../../lib/Action/Admin";

class Admin extends React.Component {
    limit = 10;
    constructor() {
        super();
        this.state = { 
            checked: false ,
            adminModal: false,
            isLoading:true,
            pages:1,
            current_page:1,
            keywords:'',
            sortBy:'fname',
            dir:'asc',
            admins: [],
            _id: '',
            fname: '',
            lname: '',
            loginPhoneNo: '',
            email: '',
            countryPhoneCode: '',
            countryName: '',
            cityId:'',
            cityName: '',
            password: '',
            admin: {
                _id: '',
                fname: '',
                lname: '',
                loginPhoneNo: '',
                email: '',
                countryPhoneCode: '',
                countryName: '',
                cityId:'',
                cityName: '',
                password: '',
            }
        };
    }

    componentWillMount(){
        // this.setState({isLoading:true});

        filterAdmins(this.filterQuery()).then( response => {
            if(response.data.success){
                if(response.data.success.data.users){
                    let responseData = response.data.success.data.users;
                    let totalUsers = responseData.totalUsers;
                    let pages  = Math.ceil(totalUsers/this.limit );
                    
                    this.setState({
                        admins:responseData.users,
                        pages,
                        isLoading:false
                    });
                }
            }
        }).catch(error=> {
        console.log(error)
        });
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value},() => {
            this.getAdmins();
        });
    }

    clearFilter(){
        this.setState({keywords:''},() => {
          this.getAdmins();
        });
    }

    filterQuery(){
        const  {sortBy, keywords, dir, current_page }  = this.state;
        
        let query = {}
    
        if(sortBy !== ''){
          query.sortBy = sortBy;
        }
        
        if(keywords !== ''){
          query.keywords = keywords;
        }
        
        if(dir !== ''){
          query.dir = dir;
        }
    
        query.start = (current_page - 1)*this.limit;
        query.limit = this.limit;
    
        return query;
    }

    changeStatus(key,id,status){
        let  { admins } = this.state;
        admins[key].accountStatus = !status;
    
        this.setState({
            admins,
        });
        
        updateAdmin({
          _id:id,
          accountStatus:!status
        }).then(response => {
            // this.componentWillMount();
        }).catch(error=> {
          this.setState({err:true,message:'Server Error!'});
        });
    
    }

    goToEdit(admin){
        this.props.adminUser(admin);
        this.props.history.push("/dashboard/admin-edit");
    }

    goToPage(page){
        this.setState({current_page:page}, () =>{
          this.getAdmins(false);
        });
    }

    sort(by,dir){
        this.setState({sortBy:by,dir}, () =>{
          this.getAdmins();
        });
    }
    
    getAdmins(page = true){
        this.setState({isLoading:true});
        const query = this.filterQuery();
        filterAdmins(query).then( response => {
            let totalusers = response.data.success.data.users.totalUsers;
            let pages  = Math.ceil(totalusers/50 );
            console.log(response);
            if(page){
                this.setState({
                admins:response.data.success.data.users.users,
                pages,
                isLoading:false
                });
            }else{
                this.setState({
                admins:response.data.success.data.users.users,
                isLoading:false
                });
            }
        }).catch(error=> {
            console.log(error)
        });
    }

    render(){

        const { adminModal, admins, isLoading, admin, pages, current_page, keywords} = this.state;
        return(
            <>
                <Header />
                {/* Table */}
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={4}>
                                            <InputGroup className="input-group-alternative">
                                                <Input
                                                    className="form-control-alternative"
                                                    value={keywords}
                                                    id="input-keywords"
                                                    placeholder="Search"
                                                    type="text"
                                                    name="keywords"
                                                    onChange={this.onChange.bind(this)} 
                                                />
                                                { 
                                                    keywords &&  
                                                    <InputGroupAddon addonType="append">
                                                    <a onClick={this.clearFilter.bind(this)}>
                                                        <InputGroupText  style={{height:'100%'}}><i className='fas fa-times-circle'></i></InputGroupText>
                                                    </a>
                                                    </InputGroupAddon>
                                                }
                                            </InputGroup>
                                        </Col>
                                        <Col sm={8}>
                                            <Link to="/dashboard/admin-add"><Button className="pull-right" color="primary" style={{float:'right'}} size="sm">Add Admin</Button></Link>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Sr. No.</th>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Phone NUmber</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" style={{textAlign: 'center'}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                        !isLoading && admins == null && <tr>
                                            <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                        </tr>
                                        }

                                        { 
                                            !isLoading && admins != null &&
                                            admins.map((admin,key) => {
                                                return (
                                                    <tr key={key}>
                                                        <th>{key+1}</th>
                                                        <td>{admin.fname}</td>
                                                        <td>{admin.lname}</td>
                                                        <td>{admin.loginPhoneNo}</td>
                                                        <td>{admin.email}</td>
                                                        <td>
                                                            <Switch
                                                                checked={admin.accountStatus}
                                                                className="switch"
                                                                onChange={() => this.changeStatus(key,admin._id,admin.accountStatus)}
                                                            />
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <i onClick={() => this.goToEdit(admin)} className="fa fa-edit btn p-1"></i>

                                                            {/* <Link to={{ pathname: '/dashboard/admin-edit', admin}}>
                                                                <i className="fa fa-edit"></i>
                                                            </Link> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <Pagination
                                            className="pagination justify-content-end mb-0"
                                            listClassName="justify-content-end mb-0"
                                        >
                                            { 
                                                pages >1 &&
                                                _.times(pages, i => {
                                                return (
                                                    <PaginationItem key={i} className={current_page === i+1 ?'active':''}>
                                                        <PaginationLink
                                                            href="#"
                                                            onClick={this.goToPage.bind(this,i+1)}
                                                        >
                                                            {i+1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                );
                                                })
                                            }
                                        </Pagination>
                                    </nav>
                                </CardFooter>
                            </Card>
                        </div>     
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state) =>({
    ...state
})

const mapDispatchToProps = (dispatch) =>({
    adminUser: (admin) => dispatch(adminUser(admin))
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
