import React from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import _ from 'lodash';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Container,
    Table, Pagination, PaginationItem, PaginationLink, CardFooter
} from 'reactstrap';
import moment from "moment";
import Switch from 'react-ios-switch';
import Loader from 'react-loader-spinner';
import Header from "../../components/Headers/Header";
import { filterPromocodes, updatePromocode } from "../../actions/Promocode";
import { promocodeData } from '../../lib/Action/Promocode';
import { setLocalValue  } from "../../lib/Helper";
import { OTOPARLAK_LOGO } from '../../Constant/Constant';
import Label from 'reactstrap/es/Label';

class Promocode extends React.Component {
    limit = 10;
    constructor() {
        super();
        this.state = { 
            isLoading:true,
            promocodes: [],
            totalPromocodes: '',
            pages: 1,
            startIndex: 1,
            endIndex:1,
            current_page: 1,
            sortBy: 'title',
            dir: 'asc',
            filterAutos: '',
            filterActives: '',
            filterInvites: '',
            filterExpireds: ''
        }
    }

    componentWillMount(){
        this.getPromocodes();
    }

    filterQuery = () => {
        const { sortBy, dir, current_page, pages, filterAutos, filterActives,
            filterInvites, filterExpireds } = this.state;

        let query = {};
        if (sortBy !== '') {
            query.sortBy = sortBy;
        }
        if (dir !== '') {
            query.dir = dir;
        }

        if (filterAutos == 1) {
            query.autos = 1;
        } else if (filterAutos == -1) {
            query.nonautos = 1;
        }
        if (filterActives == 1) {
            query.actives = 1;
        } else if (filterActives == -1) {
            query.inactives = 1;
        }
        if (filterInvites == 1) {
            query.invites = 1;
        } else if (filterInvites == -1) {
            query.noninvites = 1;
        }
        if (filterExpireds == 1) {
            query.expireds = 1;
        }

        query.start = (current_page - 1) * this.limit;
        query.limit = this.limit;

        return query;
    }

    goToPage = (page) => {
        const { pages, current_page } = this.state;
        let { startIndex, endIndex } = this.state;
        if(page > current_page){
            endIndex = page <= 2 ? Math.min(5,pages):Math.min(page+2,pages);
            startIndex =  page >= pages-2 ? Math.min(page-2 , pages-5):Math.max(startIndex,page-2)
        }
        else if(page < current_page){
            endIndex = page <= 2 ? Math.min(5 , pages) : Math.min(endIndex , page+2);
            startIndex = page >= pages-2 ? Math.min(pages-5 , page-2) : Math.max(1,page-2);
        }


        this.setState({current_page:page,startIndex:startIndex,endIndex:endIndex}, () =>{
            this.getPromocodes(false);
        });
    }

    getPromocodes = (page = true) => {
        this.setState({ isLoading: true });
        const query = this.filterQuery();
        filterPromocodes(query).then(response => {
            const responseData = response.data.success.data[0] || {};
            const { promocodes = [], totalCount = 0 } = responseData;
            const nextState = {
                promocodes,
                isLoading: false
            };
            if (page) {
                const pages = Math.ceil(totalCount / this.limit);
                const endIndex = Math.min(pages, 5);
                nextState.pages = pages;
                nextState.totalPromocodes = totalCount;
                nextState.endIndex = endIndex;
            }
            this.setState(nextState);
        }).catch(console.error);
    }

    sort = (sortBy, dir) => {
        this.setState({ sortBy, dir }, () => {
            this.getPromocodes();
        })
    }

    handleChangeFilter = (e) => {
        const { name, value } = e.target;
        this.setState({[name]: value}, () => {
           this.getPromocodes();
        });
    }

    goToEdit = (promocode) => {
        this.props.promocodeData(promocode);
        this.props.history.push("/dashboard/promocode-edit");
    }

    changeStatus = (key,id,status) => {
        let  { promocodes } = this.state;
        promocodes = [...promocodes];
        promocodes[key] = {
            ...promocodes[key],
            isActive: !status
        };

        this.setState({
            promocodes,
        });

        updatePromocode({
            _id:id,
            isActive:!status
        }).then(response => {
            if (this.state.filterActives) {
                this.getPromocodes();
            }
        }).catch(error=> {
            this.setState({err:true,message:'Server Error!'});
        });
    }

    CustomPagination = () => {
        const {startIndex,endIndex,current_page,pages}=this.state;
        return (<Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
            <PaginationItem disabled={current_page <= 1}>
                <PaginationLink onClick={this.goToPage.bind(this,1)} first href="#" /></PaginationItem>
            {
                _.times(endIndex - startIndex +1,i  => {
                    return (
                      <PaginationItem key={startIndex+i} className={current_page === startIndex + i ? 'active' : ''}>
                          <PaginationLink
                            href="#"
                            onClick={this.goToPage.bind(this, startIndex + i )}
                          >
                              {i + startIndex }
                          </PaginationLink>
                      </PaginationItem>
                    );
                })
            }
            <PaginationItem disabled={current_page >= pages}>
                <PaginationLink onClick={this.goToPage.bind(this, pages)} last href="#" /></PaginationItem>

        </Pagination>)
    };

    render(){

        const {promocodes, isLoading, totalPromocodes, dir, sortBy,
            filterAutos, filterActives, filterInvites, filterExpireds} = this.state;

        return(
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    {/* Table */}
                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <Col sm={1}>
                                            <Label className='mt-2 text-center d-block'>Filters:</Label>
                                        </Col>
                                        <Col sm={1}>
                                            <InputGroup className="input-group-alternative">
                                                <Input type="select" name="filterAutos" onChange={this.handleChangeFilter}>
                                                    <option selected={filterAutos === ''} value=""></option>
                                                    <option selected={filterAutos === '1'} value="1">Autos</option>
                                                    <option selected={filterAutos === '-1'} value="-1">Non Autos</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <InputGroup className="input-group-alternative">
                                                <Input type="select" name="filterActives" onChange={this.handleChangeFilter}>
                                                    <option selected={filterActives === ''} value=""></option>
                                                    <option selected={filterActives === '1'} value="1">Actives</option>
                                                    <option selected={filterActives === '-1'} value="-1">Non Actives</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <InputGroup className="input-group-alternative">
                                                <Input type="select" name="filterInvites" onChange={this.handleChangeFilter}>
                                                    <option selected={filterInvites === ''} value=""></option>
                                                    <option selected={filterInvites === '1'} value="1">Invites</option>
                                                    <option selected={filterInvites === '-1'} value="-1">Non Invites</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={1}>
                                            <InputGroup className="input-group-alternative">
                                                <Input type="select" name="filterExpireds" onChange={this.handleChangeFilter}>
                                                    <option selected={filterExpireds === ''} value=""></option>
                                                    <option selected={filterExpireds === '1'} value="1">Expireds</option>
                                                </Input>
                                            </InputGroup>
                                        </Col>
                                        <Col sm={7}>
                                            <Link to="/dashboard/promocode-add"><Button className="pull-right" color="primary" style={{float:'right'}} size="sm">Add Promocode</Button></Link>
                                        </Col>
                                    </Row>  
                                </CardHeader>
                                <Table className="align-items-center table-flush" responsive>
                                    <thead className="thead-light">
                                        <tr className="row m-0">
                                            <th className="col">Logo</th>
                                            <th className="col" onClick={() => this.sort('title',
                                              (sortBy === 'title' && dir === 'asc') ? 'desc' : 'asc')}>
                                                Title
                                                { sortBy === 'title' && <i
                                                  className={`fas ${dir === 'asc' ? 'fa-sort-up' : 'fa-sort-down'} mr-4`} ></i>}
                                            </th>
                                            <th className="col" onClick={() => this.sort('created',
                                              (sortBy === 'created' && dir === 'asc') ? 'desc' : 'asc')}>
                                                Creation
                                                { sortBy === 'created' && <i
                                                  className={`fas ${dir === 'asc' ? 'fa-sort-up' : 'fa-sort-down'} mr-4`} ></i>}
                                            </th>
                                            <th className="col">Ride Type</th>
                                            <th className="col">Code</th>
                                            <th className="col">Amount</th>
                                            <th className="col">Status</th>
                                            <th className="col" style={{textAlign: 'center'}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            isLoading && <tr>
                                                <td colSpan="6" style={{textAlign:'center'}}><Loader type="Circles" color="#5e72e4" height={40} width={40} /></td>
                                            </tr>
                                        }

                                        { 
                                        !isLoading && totalPromocodes === 0 && <tr>
                                            <td colSpan="6" style={{textAlign:'center'}}>No data available</td>
                                        </tr>
                                        }

                                        { 
                                            !isLoading && totalPromocodes > 0 && promocodes &&
                                            promocodes.map((promocode,key) => {
                                                return (
                                                    <tr className="row m-0" key={key}>
                                                        <td className="col">
                                                            {
                                                                <img
                                                                  className="icon"
                                                                  src={promocode.logo || OTOPARLAK_LOGO}
                                                                  alt={`${promocode.name}`}
                                                                  title={`${promocode.name}`}
                                                                />
                                                            }
                                                        </td>
                                                        <th className="col">{promocode.title}</th>
                                                        <th className="col">{moment(promocode.created).fromNow()}</th>
                                                        <td className="col">{promocode.rideType}</td>
                                                        <td className="col">{promocode.code}</td>
                                                        <td className="col">{promocode.amount}</td>
                                                        <td className="col">
                                                            <Switch
                                                              checked={promocode.isActive}
                                                              className="switch"
                                                              onChange={() => this.changeStatus(key,promocode._id,promocode.isActive)}
                                                            />
                                                        </td>
                                                        <td className="col" style={{textAlign: 'center'}}>
                                                            <i onClick={() => this.goToEdit(promocode)} className="fa fa-edit btn p-1"></i>

                                                            {/* <Link to={{ pathname: '/dashboard/admin-edit', admin}}>
                                                                <i className="fa fa-edit"></i>
                                                            </Link> */}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                      
                                    </tbody>
                                </Table>
                                <CardFooter className="py-4">
                                    <nav aria-label="...">
                                        <this.CustomPagination/>
                                    </nav>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
}

const mapStateToProps = (state) =>({
      ...state
})

const mapDispatchToProps = (dispatch) =>({
     promocodeData: (promocode) => dispatch(promocodeData(promocode))
})

export default connect(mapStateToProps, mapDispatchToProps)(Promocode);
