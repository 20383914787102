import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Table, Modal, ModalHeader, ModalBody
} from 'reactstrap';
import Switch from 'react-ios-switch';
import Loader from 'react-loader-spinner';
import Header from '../../components/Headers/Header';
import { getSolutions } from '../../actions/Solutions';
import { solutionData } from '../../lib/Action/Solution';
import { filterWashersForZone } from '../../actions/Washers';
import { OTOPARLAK_LOGO } from '../../Constant/Constant';
import BookingDetails from '../Bookings/BookingDetails';
import SolutionUsage from './SolutionUsage';

class Solutions extends React.Component {

  constructor() {
    super();
    this.state = {
      isLoading: true,
      solutions: [],
      service_id: '',
      washers: [],
      isLoadingWashers: true,
      solutionUsageModal: false,
      solutionUsageSolution: null
    };
  }

  componentWillMount() {

    getSolutions().then((response) => {
      if (response.data.success) {
        if (response.data.success.data) {
          let responseData = response.data.success.data;
          this.setState({
            solutions: responseData,
            isLoading: false
          });
        }
      }
    });
    filterWashersForZone({}).then((response) => {
      if (response.data.success.data) {
        const washers = response.data.success.data;
        this.setState({
          washers,
          isLoadingWashers: false
        });
      } else {
        throw new Error(response.data);
      }
    }).catch(error => {
      console.error(error);
      this.setState({ isLoadingWashers: false });
    });
  }

  goToEdit(solution) {
    this.props.solutionData(solution);
    this.props.history.push('/dashboard/solution-edit');
  }

  openSolutionUsageModal = (solution) => {
    this.setState({
      solutionUsageModal: true,
      solutionUsageSolution: solution
    });
  };

  closeSolutionUsageModal = () => {
    this.setState({
      solutionUsageModal: false
    });
  };

  renderSolutionUsageModal = () => {
    const { solutionUsageSolution, solutionUsageModal, washers, isLoadingWashers } = this.state;
    const { usageDetails, name } = solutionUsageSolution || {};
    return (
      <Modal
        size="lg"
        isOpen={solutionUsageModal}
        toggle={this.closeSolutionUsageModal}
      >
        <ModalHeader toggle={this.closeSolutionUsageModal}>
          <span style={{ fontSize: 20 }}>
            { name } Usage Details
            ({ (usageDetails || {}).totalUsage } in total)
          </span>
        </ModalHeader>
        <ModalBody>
          <SolutionUsage
            solutionName={name}
            usageDetails={usageDetails}
            washers={washers}
            isLoadingWashers={isLoadingWashers}
          />
        </ModalBody>
      </Modal>
    )
  };

  render() {

    const { solutions, isLoading } = this.state;
    return (
      <>
        <Header/>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col sm={4}>

                    </Col>
                    <Col sm={8}>
                      <Link to="/dashboard/solution-add"><Button className="pull-right" color="primary" style={{ float: 'right' }} size="sm">Add Solution</Button></Link>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                  <tr className="row m-0">
                    <th className="col">Logo</th>
                    <th className="col">Name</th>
                    <th className="col">Stock</th>
                    <th className="col">Type</th>
                    <th className="col">Default Usage</th>
                    <th className="col">Total Usage</th>
                    <th className="col">Action</th>
                  </tr>
                  </thead>
                  <tbody>

                  {
                    isLoading && <tr>
                      <td colSpan="6" style={{ textAlign: 'center' }}><Loader type="Circles" color="#5e72e4" height={40} width={40}/></td>
                    </tr>
                  }

                  {
                    !isLoading && solutions.length == 0 && <tr>
                      <td colSpan="7" style={{ textAlign: 'center' }}>No data available</td>
                    </tr>
                  }

                  {
                    !isLoading && solutions.length > 0 &&
                    solutions.map((solution, key) => {
                      return (
                        <tr className="row m-0" key={key}>
                          <td className="col">
                            {
                              <img
                                className="icon"
                                src={solution.logo || OTOPARLAK_LOGO}
                                alt={`${solution.name}`}
                                title={`${solution.name}`}
                              />
                            }
                          </td>
                          <th className="col">{solution.name}</th>
                          <td className="col">{solution.stock}</td>
                          <td className="col">{solution.type}</td>
                          <td className="col">{solution.defaultUsage}</td>
                          <td className="col">{solution.usageDetails.totalUsage}</td>
                          <td className="col">
                            <i onClick={() => this.goToEdit(solution)} className="fa fa-edit btn p-1"></i>
                            <i onClick={() => this.openSolutionUsageModal(solution)} className="fa fa-list btn p-1"></i>
                          </td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
        { this.renderSolutionUsageModal() }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state
});

const mapDispatchToProps = (dispatch) => ({
  solutionData: (service) => dispatch(solutionData(service))
});

export default connect(mapStateToProps, mapDispatchToProps)(Solutions);
